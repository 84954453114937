import { Star } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import "./progresScore.scss";

function ProgresScore(props: any) {
	const [score, setScore] = useState<number>(0);
	const speed = 5;
	const incNbrRec = (i: number, endNbr: number) => {
		if (i <= endNbr) {
			setScore(i);
			setTimeout(function () {
				//Delay a bit before calling the function again.
				incNbrRec(i + 1, endNbr);
			}, speed);
		}
	};
	const incEltNbr = () => {
		incNbrRec(0, parseInt(props.score));
	};
	useEffect(() => {
		incEltNbr();
	}, [props.score]);
	const theme = props.theme;
	return (
		<div className="progress-bar">
			<div
				className="bar"
				style={{
					border: `2px solid ${theme.palette.backgroundPositive.main}`,
				}}
			>
				<div
					className="circle"
					style={{
						border: `3px solid ${theme.palette.backgroundPositive.main}`,
					}}
				>
					<Star
						className="star"
						sx={{ color: theme.palette.colortext.light }}
					/>
				</div>
				<div
					className="indicator"
					style={{
						backgroundColor: theme.palette.backgroundPositive.main,
						color: theme.palette.colortext.light,
					}}
				>
					<div className="text">Score: {score}</div>
				</div>
			</div>
		</div>
	);
}

export default ProgresScore;
