import { Box, Button, Stack, Typography } from "@mui/material";
import ControlledAccordions from "./controlledAccordions";

const FrequentlyAskedQuestions = () => {
  return (
    <Stack spacing={4}>
      <Stack
        color="#000000"
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        // maxWidth={{ sm: "100%", xs: "100%", lg: "25%" }}
      >
        <Typography variant="h4" component="div" fontWeight="semi-bold">
          Questions fréquemment posées
        </Typography>

        <Box display="block">
          <Button
            href="#CONTACT"
            variant="contained"
            sx={{ textTransform: "none" }}
            disableElevation
          >
            Contactez-nous
          </Button>
        </Box>
      </Stack>

      <Box>
        <ControlledAccordions />
      </Box>
    </Stack>
  );
};

export default FrequentlyAskedQuestions;
