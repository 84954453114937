import { createSlice } from "@reduxjs/toolkit";

const initialState = { title: "", code: 0 };
const menuSlice = createSlice({
  name: "menu",
  initialState: initialState,
  reducers: {
    setCurrentMenu: (state, action) => {
      state = action.payload;
      return action.payload;
    },
  },
});

export const { setCurrentMenu } = menuSlice.actions;

export const selectCurrentMenu = (state: any) => state.menu;

export const menuReducer = menuSlice.reducer;
