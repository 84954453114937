import React from "react";
import Lottie from "react-lottie";
import AppToolBar from "../../../components/AppToolBar";
import "./css/home.scss";
import studaLogo from "../../../assets/lotties/studa.json";
import { useSelector } from "react-redux";
import { selectCurrentMode } from "../../../store/slices/modeSlice";
import { useIntl } from "react-intl";
import { Typography } from "@mui/material";

function Home({ theme }: any) {
	const intl = useIntl();
	const { formatMessage } = intl;

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: studaLogo,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};
	const mode = useSelector(selectCurrentMode);
	return (
		<div className="home-screen">
			<div className="home-app-bar">
				<AppToolBar theme={theme} />
			</div>
			<div
				className="content-home"
				style={{
					backgroundColor:
						mode === "dark"
							? theme.palette.contentbox.dark
							: theme.palette.contentbox.light,
				}}
			>
				<div className="logo">
					<Lottie
						options={defaultOptions}
						height={150}
						width={150}
					/>
				</div>
				<div
					style={{
						color:
							mode === "dark"
								? theme.palette.colortext.dark
								: theme.palette.colortext.light,
					}}
				>
					<Typography component="h2" className="title">{formatMessage({id: "studa_title"})}</Typography>
					<div style={{marginTop:"40px"}} className="description">
					{formatMessage({id: "studa_message"})}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Home;
