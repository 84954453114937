import { Box, Stack, Typography } from "@mui/material";
import ContactForm from "./contact-form";

const ContactSection = () => {
  return (
    <Stack
      direction={{ xs: "column", sm: "column", md: "row" }}
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={{ xs: 5, xm: 5, md: 5, lg: 10 }}
    >
      {/* Right content */}
      <Stack
        color="#000000"
        spacing={3}
        maxWidth={{ md: 600, xs: "100%", sm: "100%" }}
      >
        <Typography variant="h4" component="div" fontWeight="semi-bold">
          Contactez nous
        </Typography>
        <Typography variant="body1">
          Contactez l'équipe Studa pour vous aider en cas de questions ou de problèmes.
           puis-je avoir. Nous avons hâte d'avoir de tes nouvelles.
        </Typography>
      </Stack>

      {/* Form contact */}
      <Box>
        <ContactForm />
      </Box>
    </Stack>
  );
};

export default ContactSection;
