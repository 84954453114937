import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import studaLogo from "../../../assets/lotties/studa.json";
import "./appChatBar.scss";
import ProgresScore from "./ProgresScore";
import ProgressionBar from "./ProgressionBar";
import StructureService from "../../structure-service";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/userSlice";
import { selectCurrentMode } from "../../../store/slices/modeSlice";
import { useIntl } from "react-intl";
function AppChatBar({ title, progression, totalQuestions, theme }: any) {
  const intl = useIntl();
  const { formatMessage } = intl;
  const user = useSelector(selectUser);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: studaLogo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [labels, setLabels] = useState<string[]>([]);
  useEffect(() => {
    getTopicPathLabel();
  }, []);
  const getTopicPathLabel = () => {
    setIsLoading(true);
    StructureService.getLabelTopicPath(user?.currentClass)
      .then((res) => {
        setIsLoading(false);
        setLabels(res.data.data);
        console.log(res);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const mode = useSelector(selectCurrentMode);
  return (
    <div
      className="app-chat-bar"
      style={{
        backgroundColor:
          mode === "dark"
            ? theme.palette.leftbar.dark
            : theme.palette.appbar.light,
      }}
    >
      <Box>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <Lottie options={defaultOptions} height={40} width={40} />
            </div>
            <div
              style={{
                color:
                  mode === "dark"
                    ? theme.palette.colortext.dark
                    : theme.palette.colortext.light,
              }}
            >
              <div className="title">{title}</div>
              <div className="subtitle" style={{ fontSize: "12px" }}>
                {isLoading ? (
                  <span>{formatMessage({ id: "loading" })}...</span>
                ) : (
                  <div>{labels[1]}</div>
                )}
              </div>
            </div>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {totalQuestions > 0 && progression && (
              <>
                <ProgresScore score={progression.totalNote} theme={theme} />
                <Box sx={{ p: 1 }}></Box>
                {progression.progression > 0 && (

                  <ProgressionBar
                    score={progression.progression}
                    parcours={`${progression.questionsVisited}/${totalQuestions}`}
                    theme={theme}
                  />
                )}
              </>
            )}
          </Stack>
        </div>
      </Box>
    </div>
  );
}

AppChatBar.defaultProps = {
  subject: {},
};
AppChatBar.propTypes = {
  title: PropTypes.string.isRequired,
  progression: PropTypes.object,
  totalQuestions: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};
export default AppChatBar;
