import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useState } from "react";
import Lottie from "react-lottie";
import studaLogo from "../../../assets/lotties/studa.json";
import { useLocation, useNavigate } from "react-router-dom";
import "./css/Connexion.scss";
import { MuiTelInput } from "mui-tel-input";
import AuthService from "../services/auth-service";
import { AuthSigninInputDto } from "../dtos/inputs/authSignIn";
import { EnumConnectedVia } from "../enums/auth.enums";
import { getDevice } from "../../../helpers/helpers";
import { useIntl } from "react-intl";

function ConnexionSms({ theme }: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const { formatMessage } = intl;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: studaLogo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [infoPhone, setInfoPhone] = React.useState<any>(
    location.state?.infoPhone ?? null
  );
  const [phone, setPhone] = React.useState(infoPhone?.numberValue ?? "+237");
  const [errorMessages, setErrorMessages] = useState([]);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const goBack = () => {};

  const handleChangePhone = (phone: string, info: any) => {
    setInfoPhone(info);
    if (info.nationalNumber != "") {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
    setPhone(phone);
  };

  const doLoginSMS = async () => {
    setIsLoading(true);
    setErrorMessages([]);
    let device;
    await getDevice().then((res) => {
      if (res) {
        device = res;
        let myPhone =
          infoPhone.countryCallingCode + "#" + infoPhone.nationalNumber;

        let body: AuthSigninInputDto = {
          service: EnumConnectedVia.SMS,
          phone: myPhone,
          device: device,
        };
        AuthService.login(body)
          .then((result) => {
            setIsLoading(false);
            navigate("/auth/validation-code", {
              state: { infoPhone: infoPhone },
            });
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }
    });
  };
  return (
    <div className="connexion-box">
      <div className="wrapper">
        <div className="container">
          <div className="logo" style={{ paddingBottom: "55px" }}>
            <Lottie options={defaultOptions} height={60} width={60} />
          </div>
          <div className="">
            <div className="title title-bold">
              {formatMessage({ id: "enter_phone_number" })}
            </div>
            <div className="subtitle">
              {formatMessage({ id: "description_validation_code" })}
            </div>
            <div className="" style={{ minWidth: "100%", paddingTop: "25px" }}>
              <MuiTelInput
                value={phone}
                onChange={handleChangePhone}
                style={{
                  minWidth: "100%",
                  boxSizing: "content-box",
                }}
              />
            </div>
            <div
              className="btn-connexion btn-connexion-2"
              style={{ marginTop: "20px" }}
            >
              <LoadingButton
                disabled={disabledBtn}
                disableElevation
                loading={isLoading}
                className="btn btn-lg"
                endIcon={<></>}
                loadingPosition="end"
                variant="contained"
                style={{ textTransform: "none", fontSize: "16px" }}
                onClick={doLoginSMS}
              >
                {formatMessage({ id: "send_your_code" })}
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
      <div className="section-back">
        <Button
          onClick={() => {
            navigate("/auth");
          }}
          sx={{
            textTransform: "none",
            fontSize: "0.95rem",
          }}
        >
		  {formatMessage({ id: "connect_differently" })}
        </Button>
      </div>
    </div>
  );
}

export default ConnexionSms;
