import KEYS from "../../../helpers/KEYS";
import {
	EnumAccompanyType,
	EnumConversationStatus,
	EnumLang,
	EnumQuestionType,
	EnumVersion,
} from "../enums/chat.enums";

export class Conversation {
	conversationId?: string;
	path?: string;
	username?: String;
	status?: EnumConversationStatus;
	topicLabel?: Array<string>;
	lastMessage?: Message;
	createdAt?: number;
	updatedAt?: number;
	totalQuestions?: number;
	progression?: Record<string, any>;
	lang?: EnumLang;

	Conversation(
		conversationId?: string,
		path?: string,
		username?: String,
		status?: EnumConversationStatus,
		topicLabel?: Array<string>,
		lastMessage?: Message,
		createdAt?: number,
		updatedAt?: number,
		totalQuestions?: number,
		progression?: Record<string, any>,
		lang?: EnumLang
	) {
		this.conversationId = conversationId;
		this.path = path;
		this.username = username;
		this.status = status;
		this.topicLabel = topicLabel;
		this.lastMessage = lastMessage;
		this.createdAt = createdAt;
		this.totalQuestions = totalQuestions;
		this.progression = progression;
		this.lang = lang;
		this.updatedAt = updatedAt;
	}
}

export class Message {
	position?: number;
	conversationId?: string;
	type?: EnumAccompanyType;
	messageId?: number;
	content?: ContentMessage;
	createdAt?: number;
	question?: Question;
	answerString?: string;
	answersArray?: Array<number>;
	options?: Array<string>;
	optionsEvaluation?: Array<OptionEvaluation>;
	userAnswer?: Record<string, any>;

	Message(
		position?: number,
		conversationId?: string,
		type?: EnumAccompanyType,
		messageId?: number,
		content?: ContentMessage,
		createdAt?: number,
		question?: Question,
		answerString?: string,
		answersArray?: Array<number>,
		options?: Array<string>,
		optionsEvaluation?: Array<OptionEvaluation>,
		userAnswer?: Record<string, any>
	) {
		this.position = position;
		this.conversationId = conversationId;
		this.type = type;
		this.messageId = messageId;
		this.content = content;
		this.createdAt = createdAt;
		this.question = question;
		this.answerString = answerString;
		this.answersArray = answersArray;
		this.options = options;
		this.optionsEvaluation = optionsEvaluation;
		this.userAnswer = userAnswer;
	}
}

export class ConversationRequest {
	path?: String;
	lang?: String;
	conversationId?: String;
	class_?: String;
	lastEvaluatedKey?: LastEvaluatedKey;

	ConversationRequest(
		path?: String,
		lang?: String,
		conversationId?: String,
		class_?: String,
		lastEvaluatedKey?: LastEvaluatedKey
	) {
		this.path = path;
		this.lang = lang;
		this.lastEvaluatedKey = lastEvaluatedKey;
		this.conversationId = conversationId;
		this.class_ = class_;
	}
	toMap() {
		const bodyParameters = {
			path: this.path,
			lang: this.lang,
		};
		return bodyParameters;
	}

	toMap2() {
		const bodyParameters = {
			sortKey: this.lastEvaluatedKey?.conversationId,
			hashKey: this.lastEvaluatedKey?.username,
			limit: KEYS.LIMIT,
			path: this.class_,
		};
		return bodyParameters;
	}

	toMap3() {
		const bodyParameters = {
			lang: this.lang,
		};
		return bodyParameters;
	}

	topMapQuery() {
		return { conversationId: this.conversationId };
	}
}

export class LastEvaluatedKey {
	conversationId?: String;
	username?: String;
	messageId?: number;

	constructor(conversationId?: String, username?: String, messageId?: number) {
		this.conversationId = conversationId;
		this.username = username;
		this.messageId = messageId;
	}
}

export class ContentMessage {
	message?: string;
	note?: number;
	totalNote?: number;
	progression?: number;
	answer?: string;
	explanation?: string;
	answers?: Array<Record<string, any>>; // Record<string, any> equivalent a Map<string, dynamic>
	content?: string;
	questionsVisited?: number;
	faildMessage?: string;
	color?: string
	//List<QuestionsVisited>? questionsVisited;

	ContentMessage(
		message?: string,
		note?: number,
		totalNote?: number,
		progression?: number,
		answer?: string,
		explanation?: string,
		answers?: Array<Record<string, any>>, // Record<string, any> equivalent a Map<string, dynamic>
		content?: string,
		questionsVisited?: number,
		faildMessage?: string,
		color?: string
	) {
		this.message = message;
		this.note = note;
		this.totalNote = totalNote;
		this.progression = progression;
		this.answer = answer;
		this.explanation = explanation;
		this.answers = answers;
		this.content = content;
		this.questionsVisited = questionsVisited;
		this.faildMessage = faildMessage;
		this.color =  color;
	}
}
export class Question {
	content: string;
	version: EnumVersion;
	questionId: string;
	type: EnumQuestionType;
	images?: Array<string>;

	Question(
		content: string,
		version: EnumVersion,
		questionId: string,
		type: EnumQuestionType,
		images?: Array<string>
	) {
		this.content = content;
		this.version = version;
		this.questionId = questionId;
		this.type = type;
		this.images = images;
	}

	//   public getImageUrls(): Array<String> {
	//     let imageUrls: Array<string> = [];
	//     this.images?.forEach((element) => {
	//       if (element != null) {
	//         imageUrls.push(
	//           /* add */ "${BackendService.HOST_RESOURCE_IMAGE_QUESTION}/${Uri.encodeComponent(element)}"
	//         );
	//       }
	//     });
	//     return imageUrls;
	//   }
}

export class OptionEvaluation {
	content?: string;
	isChecked?: boolean = false;

	OptionEvaluation(content?: string, isChecked: boolean = false) {
		this.content = content;
		this.isChecked = isChecked;
	}
}

export class TopicPathRequest {
	topicPath?: string;
	path?: string;

	TopicPath(topicPath?: string, path?: string) {
		this.topicPath = topicPath;
		this.path = path;
	}

	toMap(): Record<string, any> {
		const queryParameters = {
			topicPath: this.topicPath,
		};
		return queryParameters;
	}
	toMap2(): Record<string, any> {
		const queryParameters = {
			path: this.path,
		};
		return queryParameters;
	}
}

export class Topic {
	code?: string;
	name?: string;
	path?: string;
	quizStatus?: string;
	topics?: Array<Topic>;
	numberTopicStart?: number;
	conversationId?: string;
	totalQuestions?: number;
	progression?: any;
	// progressLevel: any;

	/*Topic(
		code?: string,
		name?: string,
		path?: string,
		quizStatus?: string,
		topics?: Array<Topic>,
		numberTopicStart?: number,
		conversationId?: string,
		totalQuestions?: number,
		progression?: any
	) {
		this.code = code;
		this.name = name;
		this.path = path;
		this.quizStatus = quizStatus;
		this.topics = topics;
		this.numberTopicStart = numberTopicStart;
		this.conversationId = conversationId;
		this.totalQuestions = totalQuestions;
		this.progression = progression;
	}*/
}

export class UserResponse {
	answersArray?: number[];
	content?: string;

	UserResponse(answersArray?: number[], content?: string) {
		this.answersArray = answersArray;
		this.content = content;
	}
}
