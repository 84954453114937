import { createSlice } from "@reduxjs/toolkit";
import localStorageService from "../../localStorageService";

const initialState = localStorageService.getThemeMode();
const modeSlice = createSlice({
	name: "mode",
	initialState: initialState,
	reducers: {
		setCurrentMode: (state, action) => {
			console.log(action);
			state = action.payload;
			localStorageService.setThemeMode(action.payload);
			return action.payload;
		},
	},
});

export const { setCurrentMode } = modeSlice.actions;

export const selectCurrentMode = (state: any) => state.mode;

export const modeReducer = modeSlice.reducer;
