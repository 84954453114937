import { Box, List } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import Lottie, { Options } from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import { selectLanguageLocal } from "../../../store/slices/languageSlice";
import {
  EnumStatusCode,
  EnumStatusResponse,
} from "../enums/subscription.enums";
import { Subscription } from "../interfaces/subscription.interface";
import SubscriptionService from "../services/subscription-service";
import SubscriptionItem from "./SubscriptionItem";
import studaLoad from "../../../assets/lotties/load.json";
import studaNotFoundDark from "../../../assets/lotties/not-found-black.json";
import studaNotFoundLight from "../../../assets/lotties/not-found-white.json";
import {
  saveAllSubscriptions,
  selectCurrentSubscriptions,
} from "../../../store/slices/subscriptionsSlice";
import Chip from "@mui/material/Chip";
import "./css/subscriptionList.scss";
import { selectCurrentMode } from "../../../store/slices/modeSlice";

function SubscriptionList({ theme }: any) {
  const intl = useIntl();
  const { formatMessage } = intl;
  const dispatch = useDispatch();
  const languageLocal: string = useSelector(selectLanguageLocal);
  const currentAllSubscriptionsStore: Array<Subscription> = useSelector(
    selectCurrentSubscriptions
  );
  const mode = useSelector(selectCurrentMode);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [dense, setDense] = React.useState(false);
  const [isSubscriptions, setIsSubscriptions] = React.useState<
    Array<Subscription>
  >([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isEmpty, setIsEmpty] = React.useState<boolean>(true);
  const [classesFullname, setclassesFullname] = React.useState<
    { code: string; name: string; country: string }[]
  >([]);
  const [classesSelected, setClassesSelected] = React.useState<Array<string>>(
    []
  );

  useEffect(() => {
    getclassesFullname();
    getAllSubscriptionsUser();
  }, []);

  const getAllSubscriptionsUser = async () => {
    setIsLoading(true);
    if (!window.navigator.onLine) {
      enqueueSnackbar(formatMessage({ id: "no_internet_connection" }), {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
      return;
    }

    const lang = languageLocal;
    await SubscriptionService.getUserSubscriptions(lang)
      .then(
        (data: any) => {
          console.log("data response subscriptions: ", data);
          if (
            data.code === EnumStatusCode.SUCCESS &&
            data.statusCode === EnumStatusCode.SUCCESS
          ) {
            let subscriptionsList: Array<Subscription> =
              data.data as Array<Subscription>;
            if (subscriptionsList.length > 0) {
              setIsSubscriptions(subscriptionsList);
              setIsEmpty(false);
              dispatch(saveAllSubscriptions(subscriptionsList));
            }
          }
        },
        (error) => {
          console.log("Error: ", error);
          if (error?.response?.data?.code === EnumStatusResponse.FAILURE) {
            const code = error?.response?.data?.code;
            switch (error?.response?.data?.statusCode) {
              case EnumStatusCode.UNKNOWN_ERROR:
                enqueueSnackbar(
                  formatMessage({ id: "an_error_has_occurred" }),
                  {
                    variant: "error",
                    anchorOrigin: { vertical: "bottom", horizontal: "right" },
                  }
                );
                break;
              default:
                enqueueSnackbar(
                  formatMessage({ id: "an_error_has_occurred" }),
                  {
                    variant: "error",
                    anchorOrigin: { vertical: "bottom", horizontal: "right" },
                  }
                );
            }
          }
        }
      )
      .finally(() => {
        console.log("Completed request!");
        setIsLoading(false);
      });
  };

  const getclassesFullname = () => {
    let classesFullname = SubscriptionService.getUserClassesFullname()
      .then(
        (data: any) => {
          let result = data.data.data;
          setclassesFullname(result);
        },
        (error) => {
          console.log("Error: Could not get classFullName ", error);
        }
      )
      .finally(() => {});

    console.log("classesFullname", classesFullname);
  };

  const handleClick = (key1: string) => {
    let classes = [...classesSelected];
    let key = key1.trim();

    if (classes.includes(key1))
      classes = classes.filter((classCode) => classCode !== key);
    else {
      classes.push(key.trim());
    }

    if (classes.length === 0) {
      console.log("No subscription selected All subscriptions");

      setIsSubscriptions(currentAllSubscriptionsStore);
      setIsEmpty(false);
    } else {
      const classFiltered = currentAllSubscriptionsStore.filter(
        (subscription) => classes.includes(subscription.path.trim())
      );

      if (classFiltered.length <= 0) {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
        setIsSubscriptions(classFiltered);
      }
      console.log("Filter result", classFiltered);
    }

    setClassesSelected(classes);
  };

  const loadOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: studaLoad,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const notFoundOptionsDark: Options = {
    loop: true,
    autoplay: true,
    animationData: studaNotFoundDark,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const notFoundOptionsLight: Options = {
    loop: true,
    autoplay: true,
    animationData: studaNotFoundLight,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return !isLoading && isSubscriptions.length > 0 ? (
    <div className="subcription-list">
      <Box
        sx={{
          alignItems: "center",
          alignContent: "center",
          paddingLeft: "10px ",
        }}
      >
        <div
          className="title"
          style={{
            color:
              mode === "dark"
                ? theme.palette.colortext.dark
                : theme.palette.colortext.light,
          }}
        >
          {formatMessage({ id: "filter_by_class" })}
        </div>
      </Box>
      <div style={{ width: "100%", paddingLeft: "5px", paddingRight: 1 }}>
        {classesFullname.map((classe) =>
          classesSelected.includes(classe.code) ? (
            <Chip
              key={classe?.code}
              label={`${classe?.country}-${classe?.name}`}
              onClick={() => handleClick(classe.code)}
              style={{
                fontSize: "0.85rem",
                marginRight: "10px",
                color: "#FFFFFF",
                backgroundColor: "#303F9F",
                marginBottom: 4,
              }}
              sx={{ fontColor: "white", fontWeight: 400 }}
            />
          ) : (
            <Chip
              key={classe.code}
              label={`${classe?.country}-${classe?.name}`}
              onClick={() => handleClick(classe.code)}
              style={{
                fontSize: "0.85rem",
                marginRight: "10px",
                backgroundColor:
                  mode == "dark"
                    ? theme.palette.appbar.dark
                    : theme.palette.contentbox.light,
                color: mode == "dark" ? "white" : theme.palette.appbar.dark,
                marginBottom: 4,
              }}
              sx={{ fontColor: "white", fontWeight: 400 }}
            />
          )
        )}
      </div>
      <Box
        sx={{ width: "100%", paddingLeft: 1, paddingRight: 1 }}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isEmpty ? (
          <div>
            <div
              className="title title-bold"
              style={{
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              {mode === "dark" ? (
                <Lottie
                  options={notFoundOptionsDark}
                  height={100}
                  width={100}
                  style={{ marginTop: "30px" }}
                />
              ) : (
                <Lottie
                  options={notFoundOptionsLight}
                  height={100}
                  width={100}
                  style={{ marginTop: "30px" }}
                />
              )}

              <div
                style={{
                  fontSize: "16px",
                  color:
                    mode === "dark"
                      ? theme.palette.colortext.dark
                      : theme.palette.colortext.light,
                }}
              >
                {formatMessage({ id: "empty_subscriptions" })}
              </div>
            </div>
          </div>
        ) : (
          <List dense={dense} style={{ width: "100%" }}>
            {isSubscriptions.map(
              (subscription: Subscription, index: number) => (
                <div key={index}>
                  <SubscriptionItem
                    index={index}
                    subscription={subscription}
                    theme={theme}
                    classNameLabel={classesFullname.find(
                      (cl) => cl.code == subscription.path
                    )}
                  />
                </div>
              )
            )}
          </List>
        )}
      </Box>
    </div>
  ) : !isLoading && isSubscriptions.length === 0 && isEmpty ? (
    <div
      className="title title-bold"
      style={{
        textAlign: "center",
        paddingTop: "20px",
      }}
    >
      {mode === "dark" ? (
        <Lottie
          options={notFoundOptionsDark}
          height={100}
          width={100}
          style={{ paddingBottom: "15px" }}
        />
      ) : (
        <Lottie
          options={notFoundOptionsLight}
          height={100}
          width={100}
          style={{ paddingBottom: "15px" }}
        />
      )}

      <div
        className="title"
        style={{
          color:
            mode === "dark"
              ? theme.palette.colortext.dark
              : theme.palette.colortext.light,
        }}
      >
        {formatMessage({ id: "not_subscription_found" })}
      </div>
    </div>
  ) : (
    <Lottie options={loadOptions} height={80} width={80} />
  );
}
export default SubscriptionList;
