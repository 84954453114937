import {
  AccountCircleOutlined,
  Logout,
  QuestionAnswer,
  Settings,
  MoreVert,
  AutoStories,
} from "@mui/icons-material";
import {
  Avatar,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./topMenu.scss";
import { useDispatch, useSelector } from "react-redux";
import { clearUser, selectUser } from "../store/slices/userSlice";
import cookieService from "../cookieService";
import { setCurrentMenu } from "../store/slices/menuSlice";
import { useSnackbar } from "notistack";
import { ConversationRequest } from "../modules/conversation/interfaces/chat.interface";
import SubscriptionService from "../modules/subscription/services/subscription-service";
import { EnumStatusCode } from "../modules/subscription/enums/subscription.enums";
import { EnumStatusResponse } from "../modules/authentification/enums/auth.enums";
import { useIntl } from "react-intl";
import { selectLanguageLocal } from "../store/slices/languageSlice";
import { User } from "../interfaces/user.interface";
import { Subscription } from "../modules/subscription/interfaces/subscription.interface";
import {
  selectCurrentSubscription,
  setCurrentSubscription,
} from "../store/slices/subscriptionSlice";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import PersonIcon from "@mui/icons-material/Person";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import { selectCurrentMode } from "../store/slices/modeSlice";
import KEYS from "../helpers/KEYS";

function TopMenu({ onSelectPathMenu, theme }: any) {
  const intl = useIntl();
  const { formatMessage } = intl;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const user: User = useSelector(selectUser);

  const languageLocal: string = useSelector(selectLanguageLocal);

  const currentSubscription: Subscription = useSelector(
    selectCurrentSubscription
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [, setActiveMenu] = useState<string>("conversation");
  const [isCode, setIsCode] = React.useState<EnumStatusResponse>();
  const [isSubscription, setIsSubscription] = React.useState<Subscription>();
  const [isLoading, setIsLoading] = React.useState<boolean>();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogOut = () => {
    setAnchorEl(null);
    cookieService.removeToken();
    cookieService.removeRefreshToken();
    dispatch(clearUser());
  };
  const onChangeClasse = () => {
    handleClose();
    dispatch(
      setCurrentMenu({
        title: formatMessage({ id: "select_the_country" }),
        code: 7,
      })
    );
  };

  const getCurrentSubscriptionUser = () => {
    setIsLoading(true);
    if (!window.navigator.onLine) {
      enqueueSnackbar(formatMessage({ id: "no_internet_connection" }), {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
      return;
    }

    let body: ConversationRequest = new ConversationRequest();
    body.path = user.currentClass;
    body.lang = languageLocal;

    SubscriptionService.getCurrentUserSubscription(body)
      .then(
        (data: any) => {
          if (
            data.code === EnumStatusCode.SUCCESS &&
            data.statusCode === EnumStatusCode.SUCCESS
          ) {
            setIsCode(data.code);
            dispatch(setCurrentSubscription(data.data as Subscription));
          }
        },
        (error) => {
          if (error?.response?.data?.code === EnumStatusResponse.FAILURE) {
            const code = error?.response?.data?.code;
            setIsCode(code);

            switch (error?.response?.data?.statusCode) {
              case EnumStatusCode.SUBSCRIPTION_NOT_FOUND:
                break;

              case EnumStatusCode.UNKNOWN_ERROR:
                enqueueSnackbar(
                  formatMessage({ id: "an_error_has_occurred" }),
                  {
                    variant: "error",
                    anchorOrigin: { vertical: "bottom", horizontal: "right" },
                  }
                );
                break;
              default:
                enqueueSnackbar(
                  formatMessage({ id: "an_error_has_occurred" }),
                  {
                    variant: "error",
                    anchorOrigin: { vertical: "bottom", horizontal: "right" },
                  }
                );
            }
          }
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCurrentSubscriptionUser();
  }, []);

  useEffect(() => {
    setIsSubscription(currentSubscription);
  });

  const mode = useSelector(selectCurrentMode);
  const MyIconButton = styled(IconButton)(({ theme }) => ({
    color:
      mode === "dark"
        ? theme.palette.iconcolor.dark
        : theme.palette.iconcolor.light,
  }));

  const checkStatusSubscription = (): string => {
    const check: boolean =
      isSubscription?.username === undefined &&
      isSubscription?.subscriptionId === undefined &&
      isSubscription?.path === undefined &&
      isSubscription?.payments === undefined;

    if (check) {
      return formatMessage({ id: "subscribe_to_subscription" });
    } else {
      return formatMessage({ id: "my_subscriptions" });
    }
  };

  const onHandleClickStatusSubscription = () => {
    const isVerify: boolean =
      isSubscription?.username === undefined &&
      isSubscription?.subscriptionId === undefined &&
      isSubscription?.path === undefined &&
      isSubscription?.payments === undefined;

    if (isVerify) {
      dispatch(
        setCurrentMenu({
          title: formatMessage({ id: "subscription_type_list" }),
          code: 5,
        })
      );
    } else {
      dispatch(
        setCurrentMenu({
          title: formatMessage({ id: "subscription_type_detail" }),
          code: 6,
          from: "TOP_MENU",
        })
      );
    }
  };

  return (
    <div
      className="top-menu"
      style={{
        backgroundColor:
          mode === "dark"
            ? theme.palette.leftbar.dark
            : theme.palette.leftbar.light,
      }}
    >
      <div className="container">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "5px",
          }}
        >
          <div
            className="wrap-btn"
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            {user?.image !== null &&
            user?.image !== "" &&
            user?.image !== undefined ? (
              <Avatar
                onClick={() =>
                  dispatch(setCurrentMenu({ title: "Profile", code: 1 }))
                }
                sx={{ width: 35, height: 35 }}
                src={`${KEYS.HOST_RESOURCE}/${
                  KEYS.PICTURE
                }/${encodeURIComponent(user?.image)}`}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <MyIconButton
                aria-label="Profile"
                //className="btn"
                onClick={() =>
                  dispatch(setCurrentMenu({ title: "Profile", code: 1 }))
                }
              >
                <AccountCircleOutlined style={{ fontSize: "1.2rem" }} />
              </MyIconButton>
            )}
          </div>

          {/* other item  */}
          <div style={{ display: "flex" }}>
            <div
              className="wrap-btn"
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tooltip
                title={formatMessage({ id: "start_conversation_on_subject" })}
                placement="bottom-start"
              >
                <MyIconButton
                  aria-label="Comment"
                  //className="btn"
                  onClick={() => {
                    dispatch(setCurrentMenu({ title: "SubjectList", code: 4 }));
                  }}
                  style={{ textAlign: "center" }}
                >
                  <AutoStories style={{ fontSize: "1.2rem" }} />
                </MyIconButton>
              </Tooltip>
            </div>

            <div
              className="wrap-btn"
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tooltip
                title={formatMessage({ id: "start_conversation" })}
                placement="bottom-start"
              >
                <MyIconButton
                  aria-label="Comment"
                  //className="btn"
                  onClick={() => {
                    setActiveMenu("conversation");
                    onSelectPathMenu("conversation");
                  }}
                  style={{ textAlign: "center" }}
                >
                  <QuestionAnswer style={{ fontSize: "1.2rem" }} />
                </MyIconButton>
              </Tooltip>
            </div>

            <div
              className="wrap-btn"
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tooltip title="Menu" placement="bottom-start">
                <IconButton
                  aria-label="Settings"
                  className="btn"
                  onClick={handleClick}
                  style={{
                    textAlign: "center",
                    color:
                      mode === "dark"
                        ? theme.palette.iconcolor.dark
                        : theme.palette.iconcolor.light,
                  }}
                >
                  <MoreVert style={{ fontSize: "1.2rem" }} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>

        <Menu
          className="menu-list"
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          style={{
            marginTop: -12,
            marginRight: 15,
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: -10,
                right: 17,
                width: 10,
                height: 10,
                backgroundColor:
                  mode === "dark"
                    ? theme.palette.leftbar.dark
                    : theme.palette.leftbar.light,
                transform: "translateY(50%) rotate(45deg)",
                zIndex: 0,
              },
              fontFamily: "Nunito",
              backgroundColor:
                mode === "dark"
                  ? theme.palette.leftbar.dark
                  : theme.palette.leftbar.light,
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            onClick={() => {
              dispatch(setCurrentMenu({ title: "Profile", code: 1 }));
              setAnchorEl(null);
            }}
            sx={{
              fontFamily: "Nunito",
              color:
                mode === "dark"
                  ? theme.palette.colortext.dark
                  : theme.palette.colortext.light,
              fontSize: "0.85rem",
            }}
          >
            <Avatar
              sx={{
                backgroundColor: "#EEEEEE",
                width: "inherit !important",
                height: "inherit !important",
                p: 0.25,
              }}
            >
              <PersonIcon
                style={{
                  color: theme.palette.primary.main,
                  fontSize: "0.85rem",
                }}
              />
            </Avatar>
            {formatMessage({id:"profile"})}
          </MenuItem>

          {!user.isTestAccount && (
            <MenuItem
              onClick={() => {
                dispatch(
                  setCurrentMenu({ title: "Subscription list", code: 9 })
                );
              }}
              sx={{
                fontFamily: "Nunito",
                color:
                  mode === "dark"
                    ? theme.palette.colortext.dark
                    : theme.palette.colortext.light,
                fontSize: "0.85rem",
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: "#EEEEEE",
                  width: "inherit !important",
                  height: "inherit !important",
                  p: 0.25,
                }}
              >
                <SubscriptionsIcon
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: "0.85rem",
                  }}
                />
              </Avatar>
              {/* <Avatar /> */}
              {formatMessage({ id: "my_subscriptions" })}
              
            </MenuItem>
          )}

          {isLoading || user.isTestAccount ? null : (
            <MenuItem
              sx={{
                color:
                  mode === "dark"
                    ? theme.palette.colortext.dark
                    : theme.palette.colortext.light,
                fontSize: "0.85rem",
              }}
              onClick={() => {
                console.log("menuuuu: ", isCode);
                onHandleClickStatusSubscription();
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: "#EEEEEE",
                  width: "inherit !important",
                  height: "inherit !important",
                  p: 0.25,
                }}
              >
                <AutorenewIcon
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: "0.85rem",
                  }}
                />
              </Avatar>
              {checkStatusSubscription()}
            </MenuItem>
          )}

          <Divider />

          <MenuItem
            onClick={onChangeClasse}
            sx={{
              fontFamily: "Nunito",
              color:
                mode === "dark"
                  ? theme.palette.colortext.dark
                  : theme.palette.colortext.light,
              fontSize: "0.85rem",
            }}
          >
            <Avatar
              sx={{
                backgroundColor: "#EEEEEE",
                width: "inherit !important",
                height: "inherit !important",
                p: 0.25,
              }}
            >
              <Settings
                fontSize="small"
                style={{
                  color: theme.palette.primary.main,
                  fontSize: "0.85rem",
                }}
              />
            </Avatar>
            {formatMessage({ id: "change_my_class" })}
          </MenuItem>
          <MenuItem
            onClick={onLogOut}
            sx={{
              fontFamily: "Nunito",
              color:
                mode === "dark"
                  ? theme.palette.colortext.dark
                  : theme.palette.colortext.light,
              fontSize: "0.85rem",
            }}
          >
            <Avatar
              sx={{
                backgroundColor: "#EEEEEE",
                width: "inherit !important",
                height: "inherit !important",
                p: 0.25,
              }}
            >
              <Logout
                fontSize="small"
                style={{
                  color: theme.palette.primary.main,
                  fontSize: "0.85rem",
                }}
              />
            </Avatar>
            {formatMessage({ id: "logout" })}
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}

export default TopMenu;
