export enum EnumStatusResponse {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export enum EnumLang {
  fr = "fr",
  en = "en",
}

export enum EnumStatusCode {
  OK = "OK",
  SERVER_IS_ALIVE = "SERVER_IS_ALIVE",
  REFRESHTOKEN_NOT_PROVIDED = "REFRESHTOKEN_NOT_PROVIDED",
  USER_NOT_FOUND = "USER_NOT_FOUND",
  GET_USERS_ERROR = "GET_USERS_ERROR",
  DEVICE_NOT_FOUND = "DEVICE_NOT_FOUND",
  INVALID_TRANSACTION_ID = "INVALID_TRANSACTION_ID",
  PAYMENT_AMOUNT_INVALID = "PAYMENT_AMOUNT_INVALID",
  SUBSCRIPTION_NOT_FOUND = "SUBSCRIPTION_NOT_FOUND",
  SUBSCRIPTION_TYPE_NOT_VALID = "SUBSCRIPTION_TYPE_NOT_VALID",
  SUBSCRIPTION_TYPE_NOT_FOUND = "SUBSCRIPTION_TYPE_NOT_FOUND",
  PAYMENT_NOT_FOUND = "PAYMENT_NOT_FOUND",
  SUBSCRIPTION_DELETED = "SUBSCRIPTION_DELETED",
  SUBSCRIPTION_ID_NOT_PROVIDED = "SUBSCRIPTION_ID_NOT_PROVIDED",
  MESSAGE_NOT_FOUND = "MESSAGE_NOT_FOUND",
  SUBSCRIPTION_ALREADY_EXIST = "SUBSCRIPTION_ALREADY_EXIST",
  SUBSCRIPTION_RESTARTED = "SUBSCRIPTION_RESTARTED",
  END_OF_SUBSCRIPTION = "END_OF_SUBSCRIPTION",
  SUBSCRIPTION_COMPLETED = "SUBSCRIPTION_COMPLETED",
  SUBSCRIPTION_START = "SUBSCRIPTION_START",
  QUESTION_NOT_FOUND = "QUESTION_NOT_FOUND",
  CINET_PAY_ERROR = "CINET_PAY_ERROR",
  CREATE_SUBSCRIPTION_ERROR = "CREATE_SUBSCRIPTION_ERROR",
  UPDATE_MESSAGE_ERROR = "UPDATE_MESSAGE_ERROR",
  DYNAMO_ERROR = "DYNAMO_ERROR",
  SMS_SUCCESSFULLY_SENDED = "SMS_SUCCESSFULLY_SENDED",
  AWS_SNS_ERROR = "AWS_SNS_ERROR",
  SEND_SMS_ERROR = "SEND_SMS_ERROR",
  SEND_EMAIL_ERROR = "SEND_EMAIL_ERROR",
  EMPTY_PARAMS_ERROR = "EMPTY_PARAMS_ERROR",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  TOKEN_REFRESH_SUCCESS = "TOKEN_REFRESH_SUCCESS",
  DELETE_SUCCESS = "DELETE_SUCCESS",
  SUCCESS = "SUCCESS",
  FAILLED = "FAILLED",
  PARSING_STRUCTURE_FILE_ERROR = "PARSING_STRUCTURE_FILE_ERROR",
  FILE_NOT_PROVIDED = "FILE_NOT_PROVIDED",
  FILE_UPLOAD_FAILED = "FILE_UPLOAD_FAILED",
  PATH_NOT_PROVIDED = "PATH_NOT_PROVIDED",
  PATH_VALIDATION_FAILED = "PATH_VALIDATION_FAILED",
  VALIDATION_MIDDLEWARE_ERROR = "VALIDATION_MIDDLEWARE_ERROR",
  WRONG_AUTH_TOKEN = "WRONG_AUTH_TOKEN",
  MISSING_AUTH_TOKEN = "MISSING_AUTH_TOKEN",
  AUTH_TOKEN_ERROR = "AUTH_TOKEN_ERROR",
  TOKEN_EXPIRED_ERROR = "TOKEN_EXPIRED_ERROR",
  REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR",
  INVALID_REFRESH_TOKEN = "INVALID_REFRESH_TOKEN",
  S3_UPLAOD_ERROR = "S3_UPLAOD_ERROR",
  S3_GETOBJECT_ERROR = "S3_GETOBJECT_ERROR",
  S3_DELETE_OBJECT_ERROR = "S3_DELETE_OBJECT_ERROR",
  CODE_NOT_PROVIDED = "CODE_NOT_PROVIDED",
  PHONE_NOT_PROVIDED = "PHONE_NOT_PROVIDED",
  SERVICE_NOT_PROVIDED = "SERVICE_NOT_PROVIDED",
  FACEBOOK_GOOGLE_USER_DATA_NOT_PROVIDED = "FACEBOOK_GOOGLE_USER_DATA_NOT_PROVIDED",
  USERNAME_NOT_PROVIDED = "USERNAME_NOT_PROVIDED",
  CODE_EXPIRED = "CODE_EXPIRED",
  CODE_INCORRECT = "CODE_INCORRECT",
  PHONE_VALIDATED = "PHONE_VALIDATED",
  TIME_REMAINING_ERROR = "TIME_REMAINING_ERROR",
  VERIFICATION_CODE_SENDED = "VERIFICATION_CODE_SENDED",
  RESEND_CODE_LIMIT_ERROR = "RESEND_CODE_LIMIT_ERROR",
  UPDATE_USER_ERROR = "UPDATE_USER_ERROR",
  CREATE_USER_ERROR = "CREATE_USER_ERROR",
  INVALID_SOCIAL_TYPE = "INVALID_SOCIAL_TYPE",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_ERROR = "LOGIN_ERROR",
  FACEBOOK_TOKEN_VERIFIED = "FACEBOOK_TOKEN_VERIFIED",
  GOOGLE_TOKEN_VERIFIED = "GOOGLE_TOKEN_VERIFIED",
  FACEBOOK_TOKEN_ERROR = "FACEBOOK_TOKEN_ERROR",
  GOOGLE_TOKEN_ERROR = "GOOGLE_TOKEN_ERROR",
  COUNTRY_NOT_FOUND = "COUNTRY_NOT_FOUND",
  COUNTRY_NOT_PROVIDED = "COUNTRY_NOT_PROVIDED",
  SUBJECT_NOT_FOUND = "SUBJECT_NOT_FOUND",
  CLASS_NOT_FOUND = "CLASS_NOT_FOUND",
  STRUCTURE_FILE_NOT_FOUND = "STRUCTURE_FILE_NOT_FOUND",
  UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
  PHOTO_UPDATED_SUCCESSFULLY = "PHOTO_UPDATED_SUCCESSFULLY",
  INVALID_COUNTRY_CODE = "INVALID_COUNTRY_CODE",
  INVALID_PHONE_NUMBER = "INVALID_PHONE_NUMBER",
  IMAGE_DOWNLOAD_ERROR = "IMAGE_DOWNLOAD_ERROR",
  EMAIL_OCCUPIED = "EMAIL_OCCUPIED",
  PHONE_OCCUPIED = "PHONE_OCCUPIED",
  GET_NEXT_QUESTION_ERROR = "GET_NEXT_QUESTION_ERROR",
  NO_MORE_QUESTIONS = "NO_MORE_QUESTIONS",
  PAYMENTS_OVERLABS = "PAYMENTS_OVERLABS",
  PAYMENTS_NOT_COMPLET = "PAYMENTS_NOT_COMPLET",
  PAYMENTS_ALREADY_UPDATED = "PAYMENTS_ALREADY_UPDATED",
  UPDATE_SUBSCRIPTION_ERROR = "UPDATE_SUBSCRIPTION_ERROR",
}

export enum Subscription {
  NOT_STARTED = "NOT_STARTED",
}

export enum EnumSubscriptionTypeCode {
  DISCOVER = "DISCOVER",
  STARTER = "STARTER",
  PREMIUM = "PREMIUM",
  GOLD = "GOLD",
  FRIENDLY = "FRIENDLY",
  DAY = "DAY",
}

export enum EnumSubscriptionVisibility {
  SELECTABLE = "SELECTABLE",
  HIDDEN = "HIDDEN",
}

export enum EnumuCancelRaisonCode {
  TOO_EXPENSIVE = "TOO_EXPENSIVE",
  EXPERT_BETTER = "EXPERT_BETTER",
  DONT_HAVE_MONEY = "DONT_HAVE_MONEY",
  "OTHER" = "OTHER",
}

export enum SubscriptionStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  EXPIRED = "EXPIRED",
  CANCELED = "CANCELED",
  CURRENT = "CURRENT",
}

export enum EnumSubscriptionState {
  PAID = "PAID",
  UNPAID = "UNPAID",
}

export enum EnumDurationType {
  HOUR = "HOUR",
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
  YEAR = "YEAR",
}

export enum EnumPaymentChannels {
  MOBILE_MONEY = "MOBILE_MONEY",
  ALL = "ALL",
  CREDIT_CARD = "CREDIT_CARD",
  WALLET = "WALLET",
}

export enum EnumStatusPayment {
  SUCCES = "SUCCES",
  PENDING = "PENDING",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  INSUFFICIENT_BALANCE = "INSUFFICIENT_BALANCE",
  WAITING_CUSTOMER_PAYMENT = "WAITING_CUSTOMER_PAYMENT",
}

export enum EnumPaymentProvider {
  CINET_PAY = "CINET_PAY",
}

export enum EnumCurrency {
  XAF = "XAF",
  XOF = "XOF",
  EUR = "EUR",
  USD = "$",
}

export enum EnumBackTo {
  ABONNEMENTS = "ABONNEMENTS",
  PROFIL = "PROFIL",
  SUBJECTS = "SUBJECTS",
  COUNTRY = "COUNTRY",
}

