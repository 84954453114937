import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCurrentMode } from "../../../store/slices/modeSlice";
import { EnumActionConversation } from "../enums/chat.enums";
import { useIntl } from "react-intl";

function ConfirmRestart(props: any) {
  const intl = useIntl();
  const { formatMessage } = intl;
  const [open, setOpen] = React.useState(props.open);
  const handleClose = () => {
    props.handleClose();
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);
  const mode = useSelector(selectCurrentMode);
  const theme = props.theme;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            backgroundColor:
              mode === "dark"
                ? theme?.palette.leftbar.dark
                : theme?.palette.leftbar.light,
            color:
              mode === "dark"
                ? theme?.palette.colortext.dark
                : theme?.palette.colortext.light,
          }}
        >
          {props.action === EnumActionConversation.DELETE_CONVERSATION
            ? formatMessage({ id: "deleting_conversation" })
            : formatMessage({ id: "restarting_conversation" })}
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor:
              mode === "dark"
                ? theme?.palette.leftbar.dark
                : theme?.palette.leftbar.light,
            color:
              mode === "dark"
                ? theme?.palette.colortext.dark
                : theme?.palette.colortext.light,
          }}
        >
          <DialogContentText
            id="alert-dialog-description"
            style={{
              color:
                mode === "dark"
                  ? theme?.palette.colortext.dark
                  : theme?.palette.colortext.light,
            }}
          >
            {formatMessage({ id: "you_are_preparing_to" })}
            {props.action === EnumActionConversation.DELETE_CONVERSATION
              ? formatMessage({ id: "delete" })
              : formatMessage({ id: "restart" })}{" "}
            {formatMessage({ id: "this_action_is_irreversible_continue" })}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor:
              mode === "dark"
                ? theme?.palette.leftbar.dark
                : theme?.palette.leftbar.light,
          }}
        >
          <Button
            onClick={() => {
              handleClose();
              props.confirm();
            }}
            sx={{
              textTransform: "none",
            }}
          >
            {formatMessage({ id: "continue" })}
          </Button>
          <Button
            onClick={handleClose}
            autoFocus
            sx={{
              textTransform: "none",
            }}
          >
            {formatMessage({ id: "cancel" })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConfirmRestart;
