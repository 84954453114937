import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import Lottie, { Options } from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import { User } from "../../../interfaces/user.interface";
import { selectUser } from "../../../store/slices/userSlice";
import { EnumStatusResponse } from "../../authentification/enums/auth.enums";
import { EnumActionConversation, EnumStatusCode } from "../enums/chat.enums";
import { Topic, TopicPathRequest } from "../interfaces/chat.interface";
import ChatService from "../services/chat-service";
import TopicItem from "./TopicItem";
import studaLoad from "../../../assets/lotties/load.json";
import { Box, Typography, List } from "@mui/material";
import { setCurrentSubscriptionType } from "../../../store/slices/subscriptionTypeSlice";
import { setCurrentConversation } from "../../../store/slices/conversationSlice";
import { selectCurrentMode } from "../../../store/slices/modeSlice";
function TopicList({ path, theme }: any) {
  const user: User = useSelector(selectUser);
  console.log("currentClass: ", user.currentClass);
  const dispatch = useDispatch();

  const intl = useIntl();
  const { formatMessage } = intl;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(true);
  const [topics, setTopics] = React.useState<Array<Topic>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<number>(-1);

  useEffect(() => {
    getAllTopicOfCurrentSubject();
  }, []);

  /* useEffect(() => {
		getAllTopicOfCurrentSubject();
	}, []); */

  const getAllTopicOfCurrentSubject = () => {
    setIsLoading(true);
    if (!window.navigator.onLine) {
      // setMessage({ content: formatMessage({ id: "no_internet_connection" }) });
      enqueueSnackbar(formatMessage({ id: "no_internet_connection" }), {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
      return;
    }

    let body: TopicPathRequest = new TopicPathRequest();
    console.log("path: ", path);
    body.path = path && path.length > 0 ? path : user.currentClass;

    ChatService.getAllSubject(body)
      .then(
        (data: any) => {
          console.log("data response: ", data);
          if (
            data.code === EnumStatusCode.SUCCESS &&
            data.statusCode === EnumStatusCode.SUCCESS
          ) {
            let topicList: Array<Topic> = data.data.items as Array<Topic>;
            console.log("setSubjects: ", topicList);
            if (topicList.length > 0) {
              setTopics(topicList);
            }
          }
        },
        (error) => {
          console.log("Error: ", error);
          if (error?.response?.data?.code === EnumStatusResponse.FAILURE) {
            switch (error?.response?.data?.statusCode) {
              case EnumStatusCode.PATH_VALIDATION_FAILED:
                enqueueSnackbar("Oupps!! Une erreur est survenue", {
                  variant: "error",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
                break;
              case EnumStatusCode.COUNTRY_NOT_FOUND:
                enqueueSnackbar("Oupps!! Une erreur est survenue", {
                  variant: "error",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
                break;

              case EnumStatusCode.SUBJECT_NOT_FOUND:
                enqueueSnackbar("Oupps!! Une erreur est survenue", {
                  variant: "error",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
                break;
              default:
                enqueueSnackbar("Oupps!! Une erreur est survenue", {
                  variant: "error",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
            }
          }
        }
      )
      .finally(() => {
        console.log("Completed request!");
        setIsLoading(false);
      });
  };

  const loadOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: studaLoad,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const selectedIndex = (index: number) => {
    setSelectedItem(index);
  };

  const handleConversation = (
    subject: Topic,
    action: EnumActionConversation
  ) => {
    console.log(subject);
    console.log(action);
    /*navigate("/dashboard/conversation", {
			state: { subject, action },
		});*/

    dispatch(setCurrentSubscriptionType({})); // Reset value subscriptionTypeSlice
    dispatch(setCurrentConversation({ subject, action }));
  };
  const mode = useSelector(selectCurrentMode);
  return !isLoading && topics.length > 0 ? (
    <div style={{ fontFamily: "nunito" }}>
      <Box sx={{ width: "100%" }}>
        {/* <div>
          <Typography
            variant="h5"
            component="h2"
            sx={{
              paddingLeft: 1.5,
              paddingTop: 1,
              // marginTop: "1rem",
            }}
            // style={{ fontWeight: "bold", fontSize: '20px' }}
          >
            Liste des matières
          </Typography>
        </div> */}

        <div>
          <List
            //dense={dense}
            sx={{
              paddingTop: "10px",
              paddingLeft: "6px",
              paddingRight: "6px",
            }}
          >
            {topics.map((topic, index) => (
              <div
                key={index}
                style={{
                  backgroundColor:
                    selectedItem === index
                      ? mode === "dark"
                        ? theme.palette.appbar.dark
                        : "rgb(238, 238, 238)"
                      : "",
                }}
              >
                <TopicItem
                  index={index}
                  topic={topic}
                  theme={theme}
                  onStartConversation={() =>
                    handleConversation(
                      topic,
                      EnumActionConversation.START_CONVERSATION
                    )
                  }
                  onContinueConversation={() =>
                    handleConversation(
                      topic,
                      EnumActionConversation.CONTINUE_CONVERSATION
                    )
                  }
                  onRestartConversation={() =>
                    handleConversation(
                      topic,
                      EnumActionConversation.RESTART_CONVERSATION
                    )
                  }
                  onSelectedIndex={() => {
                    selectedIndex(index);
                  }}
                />
              </div>
            ))}
          </List>
        </div>
      </Box>
    </div>
  ) : !isLoading && topics.length === 0 ? (
    <div
      className="title title-bold"
      style={{
        marginTop: "10rem",
        textAlign: "center",
        fontSize: "18px",
      }}
    >
      Aucun thème
    </div>
  ) : (
    <Lottie
      options={loadOptions}
      height={80}
      width={80}
      style={{ marginTop: "8rem" /* paddingBottom: "55px" */ }}
    />
  );
}

TopicList.defaultProps = {
  path: "",
};

TopicList.propTypes = {
  path: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
};

export default TopicList;
