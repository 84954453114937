import firebase from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
	apiKey: "AIzaSyCTK9IevTBVDz5e9K4GHKpGEc03Q1XXgvU",
	authDomain: "studa-31f8e.firebaseapp.com",
	projectId: "studa-31f8e",
	storageBucket: "studa-31f8e.appspot.com",
	messagingSenderId: "493964662446",
	appId: "1:493964662446:web:212f430e7e32a1f5d8bf3f",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);


Notification.requestPermission().then((permission) => {
	
	if (permission === "granted") {
		console.log("Notification permission granted.");
	} else {
		console.log("Unable to get permission to notify.");
	}

	navigator.serviceWorker.addEventListener("message", (message) => console.log(message));

});



export default messaging;
