import { useState } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import Button from "react-multi-date-picker/components/button";
import "./css/DateAndTimePickers.scss";
import { selectCurrentMode } from "../../../store/slices/modeSlice";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

export default function DateAndTimePickers({
  onHandleClickDate,
  onStartDate,
  theme,
}: any) {
  const intl = useIntl();
  const { formatMessage } = intl;
  const [value, setValue] = useState<any>(new Date(onStartDate));
  const setValueDateTimePicker = (date: any) => {
    const unixDateToMillisecond: any = parseInt(
      date.unix + "" + date.millisecond
    );
    setValue(unixDateToMillisecond);
    onHandleClickDate(unixDateToMillisecond);
  };
  const mode = useSelector(selectCurrentMode);
  return (
    <DatePicker
      value={value}
      format="Date:YYYY/MM/DD, Time:HH:mm:ss"
      formattingIgnoreList={["Date", "Time"]}
      plugins={[<TimePicker position="bottom" />]}
      onChange={(date: DateObject) => {
        setValueDateTimePicker(date);
        setValue(date);
      }}
      render={
        <Button
          className="custom-button"
          style={{
            backgroundColor:
              mode === "dark"
                ? theme.palette.contentbox.dark
                : theme.palette.contentbox.light,
          }}
        >
          {formatMessage({id:"today"})}
        </Button>
      }
    />
  );
}
