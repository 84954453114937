import { createSlice } from "@reduxjs/toolkit";
import { Conversation } from "../../modules/conversation/interfaces/chat.interface";

const initialState = {};
const conversationsSlice = createSlice({
	name: "conversations",
	initialState: initialState,
	reducers: {
		setConversationsList: (state, action) => {
			state = action.payload;
			return action.payload;
		},
	},
});

export const { setConversationsList } = conversationsSlice.actions;

export const selectConversationsList = (state: any) => state.conversations;

export const conversationsReducer = conversationsSlice.reducer;
