import {
	Avatar,
	Button,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Stack,
	Tooltip,
	Typography,
	ListItemIcon,
} from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import TopicIcon from "@mui/icons-material/Topic";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentMenu } from "../../../store/slices/menuSlice";
import PropTypes from "prop-types";
import React from "react";
import { EnumTopicStatus } from "../enums/chat.enums";
import { useStyles } from "../../../helpers/sharedStyle";
import "./css/topic.scss";
import { selectCurrentMode } from "../../../store/slices/modeSlice";
import {
	CheckCircleOutlined,
	PauseCircleOutlined,
	PlayCircleFilledWhiteOutlined,
} from "@mui/icons-material";

function TopicItem({
	index,
	topic,
	onSelectedIndex,
	onStartConversation,
	onContinueConversation,
	onRestartConversation,
	theme,
}: any) {
	const dispatch = useDispatch();
	const classes = useStyles();

	// onClick={() => dispatch(setCurrentMenu({ title: "theme", code: 2 }))}
	const handleStartItem = (e: React.MouseEvent) => {
		e.stopPropagation();
		onStartConversation();
	};
	const handleContinueItem = (e: React.MouseEvent) => {
		e.stopPropagation();
		onContinueConversation();
	};
	const handleRestartItem = (e: React.MouseEvent) => {
		e.stopPropagation();
		onRestartConversation();
	};
	const handleSelectedIndex = (e: React.MouseEvent) => {
		e.stopPropagation();
		onSelectedIndex();
	};
	const mode = useSelector(selectCurrentMode);
	return (
		<ListItem
			disablePadding
			alignItems="flex-start"
			secondaryAction={
				<Stack
					direction="row"
					spacing={2}
				>
					{topic.quizStatus === "QUIZ_NOT_STARTED" ? (
						<Tooltip
							title="Démarrer"
							placement="top"
						>
							<IconButton
								edge="end"
								aria-label="play-circle"
								onClick={(e) => {
									handleSelectedIndex(e);
									handleStartItem(e);
								}}
							>
								<PlayCircleFilledWhiteOutlined
									style={{
										color:
											mode === "dark"
												? theme.palette.colortext.dark
												: theme.palette.colortext.light,
										//backgroundColor: "#303F9",
										//borderRadius: "50%",
										fontSize: "1.2rem",
									}}
								/>
							</IconButton>
						</Tooltip>
					) : topic.quizStatus === "IN_PROGRESS" ? (
						<div>
							<Tooltip
								title="Continuer"
								placement="top"
							>
								<IconButton
									edge="end"
									aria-label="pause-circle"
									style={{ marginRight: "2px", color: "orange" }}
									onClick={(e) => {
										handleSelectedIndex(e);
										handleContinueItem(e);
									}}
								>
									<PauseCircleOutlined
										style={{
											borderRadius: "50%",
											fontSize: "1.2rem",
										}}
									/>
								</IconButton>
							</Tooltip>

							<Tooltip
								title="Redémarrer"
								placement="top"
							>
								<IconButton
									edge="end"
									aria-label="refresh"
									onClick={(e) => {
										handleSelectedIndex(e);
										handleContinueItem(e);
									}}
								>
									<RefreshIcon
										style={{
											color:
												mode === "dark"
													? theme.palette.colortext.dark
													: theme.palette.colortext.light,
											fontSize: "1rem",
										}}
									/>
								</IconButton>
							</Tooltip>
						</div>
					) : (
						<div>
							<Tooltip
								title="Parcourir"
								placement="top"
							>
								<IconButton
									edge="end"
									aria-label="check-circle"
									style={{ marginRight: "2px", color: "green" }}
									onClick={(e) => {
										handleSelectedIndex(e);
									}}
								>
									<CheckCircleOutlined
										style={{
											borderRadius: "50%",
											fontSize: "1.2rem",
										}}
									/>
								</IconButton>
							</Tooltip>
							<Tooltip
								title="Redémarrer"
								placement="top"
							>
								<IconButton
									edge="end"
									aria-label="refresh"
									onClick={(e) => {
										handleSelectedIndex(e);
									}}
								>
									<RefreshIcon
										style={{
											color:
												mode === "dark"
													? theme.palette.colortext.dark
													: theme.palette.colortext.light,
											fontSize: "1.2rem",
										}}
									/>
								</IconButton>
							</Tooltip>
						</div>
					)}
				</Stack>
			}
		>
			<ListItemButton
				sx={{
					padding: 0.8,
				}}
			>
				<ListItemAvatar
					sx={{
						fontSize: "0.85rem",
						minWidth: 0,
						paddingRight: "10px",
					}}
				>
					<Avatar
						sx={{
							backgroundColor: "#EEEEEE",
							width: "20px !important",
							height: "20px !important",
							p: 0.25,
						}}
					>
						<BookmarkIcon style={{ color: "303F9F", fontSize: "0.85rem" }} />
					</Avatar>
				</ListItemAvatar>

				<ListItemText
					secondaryTypographyProps={
						{
							//fontSize: "12px",
						}
					}
					primary={
						<Tooltip
							title={topic.name}
							placement="top"
						>
							<Typography
								style={{
									color:
										mode === "dark"
											? theme.palette.colortext.dark
											: theme.palette.colortext.light,
									fontSize: "0.85rem",
									lineHeight: "0.85rem",
								}}
								classes={{ root: classes.customBox }}
							>
								{topic.name}
							</Typography>
						</Tooltip>
					}
					secondary={
						<React.Fragment>
							<Typography
								sx={{
									display: "inline",
									fontSize: "0.75rem",
									color:
										mode === "dark"
											? theme.palette.secondarytext.dark
											: theme.palette.secondarytext.light,
								}}
								component="span"
								variant="body2"
								// color="primary"
							>
								{topic.quizStatus === EnumTopicStatus.QUIZ_NOT_STARTED ? (
									<span style={{ color: "303F9F" }}>Commencer</span>
								) : topic.quizStatus === EnumTopicStatus.IN_PROGRESS ? (
									<span style={{ color: "orange" }}>En cours</span>
								) : (
									<span style={{ color: "green" }}>Complète</span>
								)}
							</Typography>
						</React.Fragment>
					}
				/>
			</ListItemButton>
		</ListItem>
	);
}

TopicItem.defaultProps = {
	topic: {},
};

TopicItem.propTypes = {
	index: PropTypes.number.isRequired,
	topic: PropTypes.object.isRequired,
	onSelectedIndex: PropTypes.func.isRequired,
	onStartConversation: PropTypes.func.isRequired,
	onContinueConversation: PropTypes.func.isRequired,
	onRestartConversation: PropTypes.func.isRequired,
	theme: PropTypes.object.isRequired,
};

export default TopicItem;
