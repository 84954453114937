import axios from "axios";
import cookieService from "../../../cookieService";
import KEYS from "../../../helpers/KEYS";
import { ResponseUserInputDto } from "../dtos/inputs/responseInput";
import {
	ConversationRequest,
	TopicPathRequest,
} from "../interfaces/chat.interface";

const authHeader = () => {
	const token = cookieService.getToken();
	if (token) {
		return {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};
	} else {
		return {};
	}
};

const getAllStartConversation = (body: ConversationRequest) => {
	const url: string = KEYS.BACKEND_HOST_CHAT + "/all-user-conversations";
	const headers = authHeader();
	const params = {
		...body.toMap2(),
	};

	return axios
		.get(url, { params: params, headers: headers })
		.then((response) => {
			return response.data;
		});
};

const getAllSubject = (body: TopicPathRequest) => {
	const url: string = KEYS.BACKEND_HOST_CHAT + "/get-conversations-infos";
	const headers = authHeader();
	const params = {
		...body.toMap2(),
	};
	return axios
		.get(url, { params: params, headers: headers })
		.then((response) => {
			return response.data;
		});
};

const startConversation = async (path: string, lang: string) => {
	const url: string = KEYS.BACKEND_HOST_CHAT + "/start-conversation";
	const headers = authHeader();
	const response = await axios.post(url, { path, lang }, { headers: headers });
	return response.data;
};

const loadHistory = async (conversationId: string, messageId?: number) => {
	const url: string = KEYS.BACKEND_HOST_CHAT + "/get-conversation-history";
	const params = {
		conversationId,
		sortKey: messageId,
		hashKey: conversationId,
	};
	const headers = authHeader();
	const response = await axios.get(url, { params: params, headers: headers });
	return response.data;
};

const restartConversation = async (conversationId: string) => {
	const url: string = KEYS.BACKEND_HOST_CHAT + "/restart-conversation";
	const params = {
		conversationId,
	};
	const body = {
		lang: "fr",
	};
	const headers = authHeader();
	const response = await axios.put(url, body, {
		params: params,
		headers: headers,
	});
	return response.data;
};

const deleteConversation = async (conversationId: string) => {
	const url: string = KEYS.BACKEND_HOST_CHAT + "/delete-conversation";
	const params = {
		conversationId,
	};

	const headers = authHeader();
	const response = await axios.delete(url, {
		params: params,
		headers: headers,
	});
	return response.data;
};

const sendUserResponse = async (messageUserResponse: any) => {
	let responseuserInput: ResponseUserInputDto = {
		conversationId: messageUserResponse.conversationId,
		messageId: messageUserResponse.messageId,
		answer: messageUserResponse.answer,
		lang: messageUserResponse.lang,
	};
	const url: string = KEYS.BACKEND_HOST_CHAT + "/send-user-response";
	const headers = authHeader();
	const response = await axios.post(
		url,
		{ ...responseuserInput },
		{ headers: headers }
	);
	return response.data;
};

const ChatService = {
	getAllStartConversation,
	getAllSubject,
	startConversation,
	loadHistory,
	sendUserResponse,
	restartConversation,
	deleteConversation,
};
export default ChatService;
