import { ModelTraining } from "@mui/icons-material";
import React from "react";
import "./progresScore.scss";
import { useSelector } from "react-redux";
import { selectCurrentMode } from "../../../store/slices/modeSlice";

function ProgressionBar(props: any) {
	const theme = props.theme;
	const mode = useSelector(selectCurrentMode);
	return (
		<div className="progress-bar">
			<div
				className="bar"
				style={{
					border: `2px solid ${theme.palette.backgroundPositive.main}`,
				}}
			>
				<div
					className="circle"
					style={{
						border: `3px solid ${theme.palette.backgroundPositive.main}`,
					}}
				>
					<ModelTraining
						className="star"
						sx={{ color: theme.palette.colortext.light }}
					/>
				</div>
				<div
					className="indicator"
					//style={{ backgroundColor: "yellow", color: "black" }}
					style={{
						backgroundColor: theme.palette.backgroundPositive.main,
						color: theme.palette.colortext.light,
					}}
				>
					<div
						className="text"
						//style={{ color: "black" }}
					>
						Progression: {props.score.toFixed(2)}% ({props.parcours})
					</div>
				</div>
			</div>
		</div>
	);
}

export default ProgressionBar;
