import { KeyboardBackspace } from "@mui/icons-material";
import {
  IconButton,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import EditProfile from "../modules/authentification/pages/EditProfile";
import Profile from "../modules/authentification/pages/Profile";
import { Topic } from "../modules/conversation/interfaces/chat.interface";
import TopicList from "../modules/conversation/pages/TopicList";
import "./displayBox.scss";
import PropTypes from "prop-types";
import SubjectList from "../modules/conversation/pages/SubjectList";
import DetailSubscription from "../modules/subscription/pages/DetailSubscription";
import SelectCountry from "../modules/authentification/pages/SelectCountry";
import SelectClass from "../modules/authentification/pages/SelectClass";
import SubscriptionTypeList from "../modules/subscription/pages/SubscriptionTypeList";
import SubscriptionList from "../modules/subscription/pages/SubscriptionList";
import { selectCurrentBackTo } from "../store/slices/backToSlice";
import {
  EnumBackTo,
  EnumLang,
} from "../modules/subscription/enums/subscription.enums";
import { subscribe, unsubscribe } from "../helpers/helpers";
import { selectLanguageLocal } from "../store/slices/languageSlice";
import { selectCurrentMenu } from "../store/slices/menuSlice";
import KEYS from "../helpers/KEYS";
import { Subscription } from "../modules/subscription/interfaces/subscription.interface";
import { useSelector } from "react-redux";
import { selectCurrentSubscription } from "../store/slices/subscriptionSlice";
import React, { useEffect } from "react";
import { selectCurrentMode } from "../store/slices/modeSlice";
import { useIntl } from "react-intl";

function DisplayBox({ menu, onHideLeftBox, theme }: any) {
  const currentMenu = useSelector(selectCurrentMenu);
  const curentSubscription: Subscription = useSelector(
    selectCurrentSubscription
  );

  const currentBackTo = useSelector(selectCurrentBackTo);

  const languageLocal: string = useSelector(selectLanguageLocal);

  const [isSubstitleSubscription, setIsSubstitleSubscription] =
    React.useState<Subscription>();

  const [isCountrySelected, setIsCountrySelected] = React.useState<any>({});
  const [isClasseSelected, setIsClasseSelected] = React.useState<any>({});
  const [countryCode, setCountryCode] = React.useState<string>(
    currentMenu && currentMenu.countryCode ? currentMenu.countryCode : ""
  );
  const intl = useIntl();
  const { formatMessage } = intl;

  useEffect(() => {
    setIsSubstitleSubscription(curentSubscription);
  });

  const listenerCountry = (data: any) => {
    setIsCountrySelected(data.detail);
  };

  const listenerClasse = (data: any) => {
    setIsClasseSelected(data.detail);
  };

  useEffect(() => {
    subscribe("currentCountry", listenerCountry);
    subscribe("currentClasse", listenerClasse);
    return () => {
      unsubscribe("currentCountry", listenerCountry);
      unsubscribe("currentClasse", listenerClasse);
    };
  }, []);
  const mode = useSelector(selectCurrentMode);
  const renderContentTitle = (
    code: number,
    subject: Topic,
    subscription?: Subscription
  ) => {
    const isCheckFrom: boolean =
      currentBackTo.backTo === EnumBackTo.ABONNEMENTS ? true : false;

    switch (code) {
      case 1:
        // display #Profile
        return formatMessage({ id: "profile" });
      case 2:
        // dispay #Topic list
        return formatMessage({ id: "start_conversation_on_topic" });
      case 3:
        // dispay #Edit profil
        return formatMessage({ id: "edit_your_profile" });
      case 4:
        // dispay #Subject list
        return formatMessage({ id: "start_conversation_on_subject" });
      case 5:
        // dispay #Subscription list
        return formatMessage({ id: "subscribe_to_subscription" });
      case 6:
        // dispay #Detail Subscription type
        return `${formatMessage({ id: "my_subscription" })} ${
          isCheckFrom ? "" : formatMessage({ id: "current" })
        }`;
      case 7:
        // dispay #Select country
        return formatMessage({ id: "select_the_country" });
      case 8:
        // dispay #Select class
        return formatMessage({ id: "select_the_class" });
      case 9:
        // dispay #Subscription list user
        return formatMessage({ id: "my_subscriptions" });
      default:
        // code block
        return "";
    }
  };

  const renderContentSubTitle = (
    code: number,
    subject: Topic,
    subscription?: Subscription
  ) => {
    let check_subscription: boolean =
      subscription?.subscriptionType !== undefined;
    let check_subject: boolean =
      subject &&
      subject.name !== "" &&
      subject.name !== null &&
      subject.name !== undefined;

    switch (code) {
      case 1:
        // display #Profile
        return "";
      case 2:
        // dispay #Topic list
        return check_subject ? subject.name : "";
      case 3:
        // dispay #Edit profil
        return "";
      case 4:
        // dispay #Subject list
        return "";
      case 5:
        // dispay #Subscription list
        return "";
      case 6:
        // dispay #Detail Subscription type
        return check_subscription
          ? `Abonnement ${subscription?.subscriptionType.name} - ${subscription?.subscriptionType.amount} ${subscription?.subscriptionType.currency} / ${subscription?.subscriptionType?.durationQuantity} ${subscription?.subscriptionType?.name}`
          : "";
      // return check ? `Abonnement ${subscription?.subscriptionType.name} - ${subscription?.subscriptionType.amount} ${subscription?.subscriptionType.currency} / ${subscription?.subscriptionType?.durationQuantity} ${subscription?.subscriptionType?.name}`: "";
      case 7:
        // dispay #Select country
        return languageLocal === EnumLang.en && isCountrySelected
          ? isCountrySelected.name_en
          : isCountrySelected.name_fr;
      case 8:
        // dispay #Select class
        return isClasseSelected ? isClasseSelected.name : "";
      case 9:
        // dispay #Subscription list user
        return "";
      default:
        // code block
        return "";
    }
  };

  const renderContentBox = (code: number, path: string) => {
    console.log("le code est ", code);
    switch (code) {
      case 1:
        // display #Profile
        return Profile({ theme });
      case 2:
        // dispay #Topic list <TopicList path={path} />;
        return TopicList({ path: path, theme: theme });
      case 3:
        // dispay #Edit profil
        return EditProfile({ theme });
      case 4:
        // display #Subject list
        return SubjectList({ theme });
      case 5:
        // dispay #Subscription type
        return SubscriptionTypeList({ theme });
      case 6:
        // dispay #Detail Subscription type
        return DetailSubscription({ theme });
      // return <DetailSubscription subscription={subscription} />;
      case 7:
        // dispay #Select country
        return SelectCountry({ theme });
      case 8:
        // dispay #Select class
        return SelectClass({ theme });
      case 9:
        // dispay #Subscription list
        return SubscriptionList({ theme });
      default:
        // code block
        return <>{"Default"}</>;
    }
  };

  return (
    <Box>
      <div className="display-box">
        <div
          className="toolbar"
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.colortext.dark,
          }}
        >
          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "start",
              gap: 5,
              alignItems: "center",
              // border: "1px solid red",
            }}
          >
            <ListItem
              alignItems="center"
              style={{ paddingLeft: 0 }}
              secondaryAction={
                currentMenu.code === 7 || currentMenu.code === 8 ? (
                  <Stack direction="row" spacing={2}>
                    {/* is country list */}
                    {currentMenu.code === 7 &&
                    isCountrySelected &&
                    isCountrySelected.flag !== undefined ? (
                      <img
                        src={isCountrySelected.flag}
                        alt="flag"
                        width={20}
                        height={15}
                        // width="20"
                        // height="20"
                        onClick={() => {}}
                      />
                    ) : null}

                    {/* is class list */}
                    {currentMenu.code === 8 &&
                    countryCode &&
                    countryCode !== "" ? (
                      <img
                        src={`${KEYS.HOST_RESOURCE}/flags/${countryCode}.png`.toLowerCase()}
                        alt="flag"
                        width={20}
                        height={15}
                        onClick={() => {
                          console.log(isCountrySelected);
                          console.log(isCountrySelected.code);
                        }}
                      />
                    ) : null}
                  </Stack>
                ) : (
                  ""
                )
              }
            >
              <IconButton
                className="btn"
                style={{ marginRight: 5 }}
                onClick={() => onHideLeftBox()}
              >
                <KeyboardBackspace style={{ color: "white" }} />{" "}
              </IconButton>
              <div style={{ fontWeight: 600 }}>
                {/* <div> */}

                <ListItemText
                  primary={
                    <Typography style={{ fontSize: "0.85rem" }}>
                      {renderContentTitle(
                        menu.code,
                        menu?.subject,
                        isSubstitleSubscription
                      )}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      style={{
                        color: "#FFFFFF8C",
                        fontWeight: 600,
                        fontSize: "0.75rem",
                      }}
                    >
                      {renderContentSubTitle(
                        menu.code,
                        menu?.subject,
                        isSubstitleSubscription
                      )}
                    </Typography>
                  }
                />
              </div>
            </ListItem>
          </div>
        </div>
        <div
          className="content-display-box"
          style={{
            backgroundColor:
              mode === "dark"
                ? theme.palette.leftbar.dark
                : theme.palette.leftbar.light,
          }}
        >
          {renderContentBox(menu.code, menu?.subject?.path)}
        </div>
      </div>
    </Box>
  );
}

DisplayBox.propTypes = {
  menu: PropTypes.object.isRequired,
  onHideLeftBox: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

export default DisplayBox;
