export enum EnumConversationStatus {
	NOT_STARTED = "NOT_STARTED",
	IN_PROGRESS = "IN_PROGRESS",
	COMPLETE = "COMPLETE",
}

export enum EnumAccompanyType {
	WELCOME = "WELCOME",
	CONGRATULATION = "CONGRATULATION",
	SORROW = "SORROW",
	QUESTION = "QUESTION",
	ANSWER = "ANSWER",
	SUBSCRIPTION = "SUBSCRIPTION",
	SUBSCRIPTION_INFO = "SUBSCRIPTION_INFO",
	FAILED_EVALUATION = "FAILED_EVALUATION",
	SUCESS_EVALUATION = "SUCESS_EVALUATION",
	CORRECTION = "CORRECTION",
	USER_RESPONSE = "USER_RESPONSE",
	END_CONVERSATION = "END_CONVERSATION",
}

export enum EnumVersion {
	CURRENT = "CURRENT",
	SUBQUESTION = "SUBQUESTION",
}

export enum EnumQuestionType {
	OPEN = "OPEN",
	SINGLE_CHOICE = "SINGLE_CHOICE",
	MULTI_CHOICE = "MULTI_CHOICE",
}

export enum EnumLang {
	en = "en",
	fr = "fr",
}

export enum EnumStatusCode {
	SERVER_IS_ALIVE = "SERVER_IS_ALIVE",
	REFRESHTOKEN_NOT_PROVIDED = "REFRESHTOKEN_NOT_PROVIDED",
	USER_NOT_FOUND = "USER_NOT_FOUND",
	GET_USERS_ERROR = "GET_USERS_ERROR",
	DEVICE_NOT_FOUND = "DEVICE_NOT_FOUND",
	CONVERSATION_NOT_FOUND = "CONVERSATION_NOT_FOUND",
	CONVERSATION_DELETED = "CONVERSATION_DELETED",
	CONVERSATION_ID_NOT_PROVIDED = "CONVERSATION_ID_NOT_PROVIDED",
	MESSAGE_NOT_FOUND = "MESSAGE_NOT_FOUND",
	CONVERSATION_ALREADY_EXIST = "CONVERSATION_ALREADY_EXIST",
	CONVERSATION_RESTARTED = "CONVERSATION_RESTARTED",
	END_OF_CONVERSATION = "END_OF_CONVERSATION",
	CONVERSATION_COMPLETED = "CONVERSATION_COMPLETED",
	CONVERSATION_START = "CONVERSATION_START",
	QUESTION_NOT_FOUND = "QUESTION_NOT_FOUND",
	UPDATE_CONVERSATION_ERROR = "UPDATE_CONVERSATION_ERROR",
	UPDATE_MESSAGE_ERROR = "UPDATE_MESSAGE_ERROR",
	DYNAMO_ERROR = "DYNAMO_ERROR",
	SMS_SUCCESSFULLY_SENDED = "SMS_SUCCESSFULLY_SENDED",
	AWS_SNS_ERROR = "AWS_SNS_ERROR",
	SEND_SMS_ERROR = "SEND_SMS_ERROR",
	EMPTY_PARAMS_ERROR = "EMPTY_PARAMS_ERROR",
	UNKNOWN_ERROR = "UNKNOWN_ERROR",
	TOKEN_REFRESH_SUCCESS = "TOKEN_REFRESH_SUCCESS",
	DELETE_SUCCESS = "DELETE_SUCCESS",
	SUCCESS = "SUCCESS",
	FAILLED = "FAILLED",
	PARSING_STRUCTURE_FILE_ERROR = "PARSING_STRUCTURE_FILE_ERROR",
	FILE_NOT_PROVIDED = "FILE_NOT_PROVIDED",
	FILE_UPLOAD_FAILED = "FILE_UPLOAD_FAILED",
	PATH_NOT_PROVIDED = "PATH_NOT_PROVIDED",
	PATH_VALIDATION_FAILED = "PATH_VALIDATION_FAILED",
	VALIDATION_MIDDLEWARE_ERROR = "VALIDATION_MIDDLEWARE_ERROR",
	WRONG_AUTH_TOKEN = "WRONG_AUTH_TOKEN",
	MISSING_AUTH_TOKEN = "MISSING_AUTH_TOKEN",
	AUTH_TOKEN_ERROR = "AUTH_TOKEN_ERROR",
	TOKEN_EXPIRED_ERROR = "TOKEN_EXPIRED_ERROR",
	REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR",
	INVALID_REFRESH_TOKEN = "INVALID_REFRESH_TOKEN",
	S3_UPLAOD_ERROR = "S3_UPLAOD_ERROR",
	S3_GETOBJECT_ERROR = "S3_GETOBJECT_ERROR",
	S3_DELETE_OBJECT_ERROR = "S3_DELETE_OBJECT_ERROR",
	CODE_NOT_PROVIDED = "CODE_NOT_PROVIDED",
	PHONE_NOT_PROVIDED = "PHONE_NOT_PROVIDED",
	SERVICE_NOT_PROVIDED = "SERVICE_NOT_PROVIDED",
	FACEBOOK_GOOGLE_USER_DATA_NOT_PROVIDED = "FACEBOOK_GOOGLE_USER_DATA_NOT_PROVIDED",
	USERNAME_NOT_PROVIDED = "USERNAME_NOT_PROVIDED",
	CODE_EXPIRED = "CODE_EXPIRED",
	CODE_INCORRECT = "CODE_INCORRECT",
	PHONE_VALIDATED = "PHONE_VALIDATED",
	TIME_REMAINING_ERROR = "TIME_REMAINING_ERROR",
	VERIFICATION_CODE_SENDED = "VERIFICATION_CODE_SENDED",
	RESEND_CODE_LIMIT_ERROR = "RESEND_CODE_LIMIT_ERROR",
	UPDATE_USER_ERROR = "UPDATE_USER_ERROR",
	CREATE_USER_ERROR = "CREATE_USER_ERROR",
	INVALID_SOCIAL_TYPE = "INVALID_SOCIAL_TYPE",
	LOGIN_SUCCESS = "LOGIN_SUCCESS",
	LOGIN_ERROR = "LOGIN_ERROR",
	FACEBOOK_TOKEN_VERIFIED = "FACEBOOK_TOKEN_VERIFIED",
	GOOGLE_TOKEN_VERIFIED = "GOOGLE_TOKEN_VERIFIED",
	FACEBOOK_TOKEN_ERROR = "FACEBOOK_TOKEN_ERROR",
	GOOGLE_TOKEN_ERROR = "GOOGLE_TOKEN_ERROR",
	COUNTRY_NOT_FOUND = "COUNTRY_NOT_FOUND",
	COUNTRY_NOT_PROVIDED = "COUNTRY_NOT_PROVIDED",
	SUBJECT_NOT_FOUND = "SUBJECT_NOT_FOUND",
	CLASS_NOT_FOUND = "CLASS_NOT_FOUND",
	STRUCTURE_FILE_NOT_FOUND = "STRUCTURE_FILE_NOT_FOUND",
	UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
	PHOTO_UPDATED_SUCCESSFULLY = "PHOTO_UPDATED_SUCCESSFULLY",
	INVALID_COUNTRY_CODE = "INVALID_COUNTRY_CODE",
	INVALID_PHONE_NUMBER = "INVALID_PHONE_NUMBER",
	IMAGE_DOWNLOAD_ERROR = "IMAGE_DOWNLOAD_ERROR",
	EMAIL_OCCUPIED = "EMAIL_OCCUPIED",
	PHONE_OCCUPIED = "PHONE_OCCUPIED",
	GET_NEXT_QUESTION_ERROR = "GET_NEXT_QUESTION_ERROR",
	NO_MORE_QUESTIONS = "NO_MORE_QUESTIONS",
	NO_QUESTIONS = "NO_QUESTIONS",
}

export enum EnumTopicStatus {
	QUIZ_NOT_STARTED = "QUIZ_NOT_STARTED",
	QUIZ_IN_PROGRESS = "QUIZ_IN_PROGRESS",
	QUIZ_COMPLETE = "QUIZ_COMPLETE",
	IN_PROGRESS = "IN_PROGRESS",
}

export enum EnumActionConversation {
	START_CONVERSATION = "START_CONVERSATION",
	CONTINUE_CONVERSATION = "CONTINUE_CONVERSATION",
	RESPONSE_CONVERSATION = "RESPONSE_CONVERSATION",
	RESTART_CONVERSATION = "RESTART_CONVERSATION",
	BROWSE_CONVERSATION = "BROWSE_CONVERSATION",
	DELETE_CONVERSATION = "DELETE_CONVERSATION",
}
