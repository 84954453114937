import {
  Edit,
  MailOutline,
  PersonOutline,
  PhoneOutlined,
  TimerOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import { formatDate, getCorrectPhoneNumber } from "../../../helpers/helpers";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setUser } from "../../../store/slices/userSlice";
import { setCurrentMenu } from "../../../store/slices/menuSlice";
import { selectCurrentMode } from "../../../store/slices/modeSlice";
import KEYS from "../../../helpers/KEYS";
import profile from "../../../assets/images/profile.jpeg";
import "./css/Profile.scss";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import UserService from "../services/user-service";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import {
  EnumStatusCode,
  EnumStatusResponse,
} from "../../subscription/enums/subscription.enums";
import { selectLanguageLocal } from "../../../store/slices/languageSlice";

function Profile({ theme }: any) {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const mode = useSelector(selectCurrentMode);
  const languageLocal: string = useSelector(selectLanguageLocal);

  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);

  const loadFile = (event: any) => {
    let image: any = document.getElementById("output");
    image.src = URL.createObjectURL(event.target.files[0]);
    const files = event.target.files;
    const formData = new FormData();
    formData.append("file", files[0]);

    updatePhotoUser(formData);
  };

  const updatePhotoUser = async (data: any) => {
    setIsLoadingAction(true);

    if (!window.navigator.onLine) {
      enqueueSnackbar(formatMessage({ id: "no_internet_connection" }), {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
      return;
    }
    UserService.updatePhotoUser(data)
      .then((data: any) => {
        if (
          data.data.code === EnumStatusCode.SUCCESS &&
          data.data.statusCode === EnumStatusCode.PHOTO_UPDATED_SUCCESSFULLY
        ) {
          let newUser = { ...user };
          const newFileName: string = data.data.data.fileName;
          newUser.image = newFileName;
          dispatch(setUser({ ...newUser }));

          enqueueSnackbar(
            formatMessage({ id: "Photo_uploaded_successfully" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
            }
          );
        }
      })
      .catch((error: any) => {
        console.log("Error", error);
        if (error?.response?.data?.code === EnumStatusResponse.FAILURE) {
          let oldUser = { ...user };

          let image: any = document.getElementById("output");
          image.src = `${KEYS.HOST_RESOURCE}/${
            KEYS.PICTURE
          }/${encodeURIComponent(oldUser?.image)}`;

          const code = error?.response?.data?.code;
          switch (error?.response?.data?.statusCode) {
            case EnumStatusCode.FILE_NOT_PROVIDED:
              enqueueSnackbar(
                formatMessage({ id: "the_image_was_not_uploaded" }),
                {
                  variant: "error",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                }
              );
              break;
            case EnumStatusCode.UNKNOWN_ERROR:
              enqueueSnackbar(formatMessage({ id: "an_error_has_occurred" }), {
                variant: "error",
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
              });
              break;
            default:
              enqueueSnackbar(formatMessage({ id: "an_error_has_occurred" }), {
                variant: "error",
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
              });
          }
        }
      })
      .finally(() => {
        console.log("Completed request!");
        setIsLoadingAction(false);
      });
  };

  return (
    <div className="profile">
      {isLoadingAction && (
        <Box sx={{ pt: "0.5px", /* pb: "10px", */ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <div className="wrap">
        <div className="profile-pic" style={{}}>
          <label
            htmlFor="file"
            className="-label"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <span>
              <CameraAltIcon />
            </span>

            <span style={{ width: 100 }}>CHANGER LA PHOTO DE PROFIL</span>
          </label>
          <input
            id="file"
            type="file"
            name="img"
            onChange={(e) => loadFile(e)}
          />
          <img
            src={
              user?.image !== null &&
              user?.image !== "" &&
              user?.image !== undefined
                ? `${KEYS.HOST_RESOURCE}/${KEYS.PICTURE}/${encodeURIComponent(
                    user?.image
                  )}`
                : profile
            }
            id="output"
            width="200"
            alt=""
          />
        </div>
      </div>

      <Card
        sx={{
          backgroundColor:
            mode === "dark"
              ? theme.palette.leftbar.dark
              : theme.palette.leftbar.light,
        }}
        elevation={0}
      >
        <CardContent
          style={{
            textAlign: "center",
            fontSize: "15px",
            color:
              mode === "dark"
                ? theme.palette.colortext.dark
                : theme.palette.colortext.light,
            //color: "#333"
          }}
        >
          <List sx={{ width: "100%", maxWidth: 360 }} component="nav">
            <ListItem style={{ paddingBottom: "17px" }}>
              <ListItemIcon
                style={{
                  minWidth: "40px",
                  paddingRight: "5px",
                  color:
                    mode === "dark"
                      ? theme.palette.colortext.dark
                      : theme.palette.colortext.light,
                }}
              >
                <PersonOutline
                  style={{ fontFamily: "nunito", fontSize: "20px" }}
                />
              </ListItemIcon>
              <span>
                {user?.name ?? `[${formatMessage({ id: "your_full_name" })}]`}
              </span>
            </ListItem>
            <ListItem style={{ paddingBottom: "17px" }}>
              <ListItemIcon
                style={{
                  minWidth: "40px",
                  paddingRight: "5px",
                  color:
                    mode === "dark"
                      ? theme.palette.colortext.dark
                      : theme.palette.colortext.light,
                }}
              >
                <PhoneOutlined
                  style={{ fontFamily: "nunito", fontSize: "20px" }}
                />
              </ListItemIcon>
              <span>
                {getCorrectPhoneNumber(user?.phone) ??
                  `[${formatMessage({ id: "your_phone" })}]`}
              </span>
            </ListItem>
            <ListItem style={{ paddingBottom: "17px" }}>
              <ListItemIcon
                style={{
                  minWidth: "40px",
                  paddingRight: "5px",
                  color:
                    mode === "dark"
                      ? theme.palette.colortext.dark
                      : theme.palette.colortext.light,
                }}
              >
                <MailOutline
                  style={{ fontFamily: "nunito", fontSize: "20px" }}
                />
              </ListItemIcon>
              <span>
                {user?.email ?? `[${formatMessage({ id: "your_email" })}]`}
              </span>
            </ListItem>
            <ListItem style={{ paddingBottom: "17px" }}>
              <ListItemIcon
                style={{
                  minWidth: "40px",
                  paddingRight: "5px",
                  color:
                    mode === "dark"
                      ? theme.palette.colortext.dark
                      : theme.palette.colortext.light,
                }}
              >
                <TimerOutlined
                  style={{ fontFamily: "nunito", fontSize: "20px" }}
                />
              </ListItemIcon>
              <span>
                {formatMessage({ id: "member_since" })}{" "}
                {formatDate(user?.createdAt, languageLocal)}
              </span>
            </ListItem>
          </List>
          <CardActions>
            <Button
              size="medium"
              variant="outlined"
              endIcon={<Edit />}
              style={{ textTransform: "none", fontFamily: "nunito" }}
              onClick={() => {
                dispatch(setCurrentMenu({ title: "", code: 0 })); // close first when you want to open #DisplayBox on another #DisplayBox

                setTimeout(function () {
                  dispatch(setCurrentMenu({ title: "EditerProfil", code: 3 })); // open next displayBox
                });
              }}
            >
              {formatMessage({ id: "edit_my_profil" })}
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </div>
  );
}

export default Profile;
