import { useEffect, useState } from "react";
import TopMenu from "../components/TopMenu";
import LeftDrawer from "../components/LeftDrawer";
import DisplayBox from "../components/DisplayBox";
import "./studaLayout.scss";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentMenu, setCurrentMenu } from "../store/slices/menuSlice";
import ConversationScreen from "../modules/conversation/pages/ConversationScreen";
import defaultBgImage from "../assets/images/bg-whatsapp.png"; //bg-whatsapp.png //default-bg.jpg
import { selectCurrentConversation } from "../store/slices/conversationSlice";
import BoxPayment from "../modules/subscription/pages/BoxPayment";
import {
  selectCurrentSubscriptionType,
  setCurrentSubscriptionType,
} from "../store/slices/subscriptionTypeSlice";
import {
  Subscription,
  SubscriptionType,
} from "../modules/subscription/interfaces/subscription.interface";
import Home from "../modules/conversation/pages/Home";
import {
  selectCurrentBackTo,
  setCurrentBackTo,
} from "../store/slices/backToSlice";
import { EnumBackTo } from "../modules/subscription/enums/subscription.enums";
import { selectCurrentMode } from "../store/slices/modeSlice";
import Terms from "../modules/conversation/pages/Terms";
import { selectPage } from "../store/slices/pageSlice";
import Help from "../modules/conversation/pages/Help";
import Policy from "../modules/conversation/pages/Policy";
import { selectCurrentSubscription } from "../store/slices/subscriptionSlice";
import { useIntl } from "react-intl";

function StudaLayout(props: any) {
  const intl = useIntl();
  const { formatMessage } = intl;
  const currentMenu = useSelector(selectCurrentMenu);
  const currentConversation = useSelector(selectCurrentConversation);
  const currentSubscriptionType: SubscriptionType = useSelector(
    selectCurrentSubscriptionType
  );
  const currentSubscription: Subscription = useSelector(
    selectCurrentSubscription
  );
  const currentBackTo = useSelector(selectCurrentBackTo);
  const dispatch = useDispatch();
  const [showLeftBox, setShowLeftBox] = useState<boolean>(false);
  const [selectMenu, setSelectMenu] = useState<any>();
  const [pathMenu, setPathMenu] = useState<string>("conversation");
  const [conversationSelected, setConversationSelected] = useState<any>();

  const [subscriptionTypeSelected, setSubscriptionTypeSelected] =
    useState<any>();

  useEffect(() => {
    if (currentConversation?.action !== "") defineCurrentConversation();
  }, [currentConversation]);

  useEffect(() => {
    showCurrentMenu();
    defineCurrentSubscriptionType();
  });
  const setMenu = (menu: any) => {
    setSelectMenu(menu);
    setShowLeftBox(true);
  };
  const theme = props.theme;
  const defineCurrentConversation = () => {
    if (
      currentConversation?.subject?.code !==
        conversationSelected?.subject?.code ||
      currentConversation?.subject?.conversationId !==
        conversationSelected?.subject?.conversationId
    ) {
      setConversationSelected(currentConversation);
    }
    if (!conversationSelected) {
      setConversationSelected(currentConversation);
    }
  };

  const hideLeftBox = () => {
    if (currentBackTo.backTo === EnumBackTo.ABONNEMENTS) {
      dispatch(setCurrentMenu({ title: "", code: 0 }));
      setTimeout(() => {
        dispatch(
          setCurrentMenu({
            title: formatMessage({ id: "subscription_list" }),
            code: 9,
          })
        );
      });
      dispatch(setCurrentBackTo({ backTo: "" }));

      dispatch(setCurrentSubscriptionType({})); // Reset value subscriptionTypeSlice To hide #BoxPayment
    } else if (currentBackTo.backTo === EnumBackTo.COUNTRY) {
      dispatch(setCurrentMenu({ title: "", code: 0 }));
      setTimeout(() => {
        dispatch(
          setCurrentMenu({
            title: formatMessage({ id: "select_the_country" }),
            code: 7,
          })
        );
      });
      dispatch(setCurrentBackTo({ backTo: "" }));
    } else if (currentBackTo.backTo === EnumBackTo.SUBJECTS) {
      dispatch(setCurrentMenu({ title: "", code: 0 }));
      setTimeout(() => {
        dispatch(
          setCurrentMenu({
            title: formatMessage({ id: "subject_list" }),
            code: 4,
          })
        );
      });
      dispatch(setCurrentBackTo({ backTo: "" }));
    } else {
      dispatch(setCurrentMenu({ title: "", code: 0 }));
      setShowLeftBox(false);

      dispatch(setCurrentSubscriptionType({})); // Reset value subscriptionTypeSlice To hide #BoxPayment
    }
  };

  const showCurrentMenu = () => {
    switch (currentMenu.code) {
      case 1: // #Profile
        setMenu(currentMenu);
        break;
      case 2: // #Topic list
        setMenu(currentMenu);
        break;
      case 3: // #Edit profil
        setMenu(currentMenu);
        break;
      case 4: // #Subject list
        setMenu(currentMenu);
        break;
      case 5: // #Subscription list
        setMenu(currentMenu);
        break;
      case 6: // #Detail Subscription type
        setMenu(currentMenu);
        break;
      case 7: // #Select country
        setMenu(currentMenu);
        break;
      case 8: // #Select class
        setMenu(currentMenu);
        break;
      case 9: // #Subscription list user
        setMenu(currentMenu);
        break;
      default:
        setShowLeftBox(false);
    }
  };

  const selectPathMenu = (pathMenu: string) => {
    setPathMenu(pathMenu);
  };

  const defineCurrentSubscriptionType = () => {
    setSubscriptionTypeSelected(currentSubscriptionType);
  };

  const isEmpty = (obj: SubscriptionType): any => {
    return Object.keys(obj).length === 0;
  };

  const isNull = (obj: any): boolean => {
    return obj && Object.keys(obj).length > 0 ? false : true;
  };
  
  const page = useSelector(selectPage);
  const mode = useSelector(selectCurrentMode);

  const renderPage = (page: string) => {
    switch (page) {
      case "home":
        return <Home theme={props.theme} />;
      case "terms":
        return (
          <div
            className="box-apropos"
            style={{
              padding: "15px",
              textAlign: "justify",
              height: "100%",
              backgroundColor:
                mode === "dark"
                  ? theme.palette.contentbox.dark
                  : theme.palette.contentbox.light,
            }}
          >
            <Terms theme={props.theme} />
          </div>
        );
      case "policy":
        return (
          <div
            className="box-apropos"
            style={{
              padding: "15px",
              textAlign: "justify",
              height: "100%",
              backgroundColor:
                mode === "dark"
                  ? theme.palette.contentbox.dark
                  : theme.palette.contentbox.light,
            }}
          >
            <Policy theme={props.theme} />
          </div>
        );
      case "help":
        return (
          <div
            className="box-apropos"
            style={{
              padding: "15px",
              textAlign: "justify",
              height: "100%",
              backgroundColor:
                mode === "dark"
                  ? theme.palette.contentbox.dark
                  : theme.palette.contentbox.light,
            }}
          >
            <Help theme={props.theme} />
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className="layout">
      <div className="left-layout">
        {showLeftBox && (
          <div className="wrap-display-box">
            <DisplayBox
              theme={props.theme}
              menu={selectMenu}
              onHideLeftBox={hideLeftBox}
            ></DisplayBox>
          </div>
        )}
        <div className="wrap-left-menu">
          <TopMenu
            theme={props.theme}
            onSetMenu={setMenu}
            onSelectPathMenu={selectPathMenu}
            className="top-menu"
          ></TopMenu>
          <LeftDrawer
            pathMenu={pathMenu}
            theme={props.theme}
            className="left-drawer"
          />
        </div>
      </div>
      <div className="contain-layout">
        <div
          style={{
            backgroundImage: mode == "dark" ? `none` : `url(${defaultBgImage})`,
            backgroundColor:
              mode == "dark" ? theme.palette.contentbox.dark : `transparent`,
            backgroundSize: "contain",
            height: "100%",
          }}
        >
          {subscriptionTypeSelected && !isEmpty(subscriptionTypeSelected) && (
            <BoxPayment
              subscriptionTypeSelected={subscriptionTypeSelected}
              onSubscription={
                /*   1680382994368  */ /*  0  */ currentSubscription &&
                currentSubscription.subscriptionId
                  ? currentSubscription
                  : null
              }
              theme={props.theme}
            />
          )}
          {conversationSelected && !isEmpty(conversationSelected) && (
            <ConversationScreen
              conversationSelected={conversationSelected}
              theme={props.theme}
            />
          )}
          {isNull(subscriptionTypeSelected) &&
            isNull(conversationSelected) &&
            renderPage(page)}
        </div>
      </div>
    </div>
  );
}
export default StudaLayout;
