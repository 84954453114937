import axios from "axios";
import cookieService from "../../../cookieService";
import KEYS from "../../../helpers/KEYS";
import { ConversationRequest } from "../../conversation/interfaces/chat.interface";
import {
	CancelSubscriptionRequest,
	PaymentRequest,
} from "../interfaces/subscription.interface";

const authHeader = () => {
	const token = cookieService.getToken();
	if (token) {
		return {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};
	} else {
		return {};
	}
};

const getAllSubscriptions = () => {
	const url: string =
		KEYS.BACKEND_HOST_SUBSCRIPTION + "/subscription-type?lang=en";
	const headers = authHeader();
	console.log("getting subscription types******");
	return axios
		.get(url, { headers: headers })
		.then((response) => {
			console.log("data*******", response);
			return response.data;
		})
		.catch((error) => {
			console.log("error", error);
		});
};

const getUserClassesFullname = () => {
	const url: string = KEYS.BACKEND_HOST_STRUCTURE + "/user-classes-fullname";
	const headers = authHeader();
	return axios.get(url, { headers: headers });
};

const createDirectPayment = (paymentRequest: PaymentRequest): Promise<any> => {
	const url: string = KEYS.BACKEND_HOST_SUBSCRIPTION + "/direct-payment";
	const headers = authHeader();
	return axios.post(
		url,
		{
			subscriptionId: paymentRequest.subscriptionId,
			lang: paymentRequest.lang,
			subscriptionTypeCode: paymentRequest.subscriptionTypeCode,
			voucherCode: paymentRequest.voucherCode
		},
		{ headers: headers }
	);
};

const createPaymentLinkPayment = (
	paymentRequest: PaymentRequest
): Promise<string> => {
	const url: string = KEYS.BACKEND_HOST_SUBSCRIPTION + "/create-link";
	const headers = authHeader();
	return axios
		.post(
			url,
			{
				subscriptionId: paymentRequest.subscriptionId,
				lang: paymentRequest.lang,
				subscriptionTypeCode: paymentRequest.subscriptionTypeCode,
				startDate: paymentRequest.startDate,
				voucherCode: paymentRequest.voucherCode
			},
			{ headers: headers }
		);
};

const updatePayment = (transactionId: string, lang?: string) => {
	const url: string = KEYS.BACKEND_HOST_SUBSCRIPTION + "/update-payment";
	const headers = authHeader();
	return axios
		.put(
			url,
			{ transactionId: transactionId, lang: lang },
			{ headers: headers }
		)
		.then((response) => {
			console.log("response.data", response.data);
			return response.data;
		})
		.catch((error) => {
			console.log("error", error);
		});
};

const getCurrentUserSubscription = (body: ConversationRequest) => {
	const url: string = KEYS.BACKEND_HOST_SUBSCRIPTION + "/user-subscription";
	const headers = authHeader();
	const params = {
		...body.toMap(),
	};
	return axios
		.get(url, { params: params, headers: headers })
		.then((response) => {
			return response.data;
		});
};

const getUserSubscriptions = (lang: string) => {
	const url: string = KEYS.BACKEND_HOST_SUBSCRIPTION + "/user-subscriptions";
	const headers = authHeader();

	return axios
		.get(url, { params: { lang: lang }, headers: headers })
		.then((response) => {
			return response.data;
		});
};

const getUserSubscriptionsById = (lang: string, subscriptionId: number) => {
	const url: string =
		KEYS.BACKEND_HOST_SUBSCRIPTION + "/user-subscriptions-by-id";
	const headers = authHeader();

	return axios
		.get(url, { params: { lang, subscriptionId }, headers: headers })
		.then((response) => {
			return response.data;
		});
};

const getCancelReasons = async (lang: string) => {
	const url: string = KEYS.BACKEND_HOST_SUBSCRIPTION + "/cancel-reasons";
	const headers = authHeader();

	return await axios
		.get(url, { params: { lang: lang }, headers: headers })
		.then((response) => {
			return response.data;
		});
};

const cancelSubscription = async (body: CancelSubscriptionRequest) => {
	const url: string = KEYS.BACKEND_HOST_SUBSCRIPTION + "/cancel-subscription";
	const headers = authHeader();
	const params: CancelSubscriptionRequest = {
		subscriptionId: body.subscriptionId,
		cancelRaisonMessage: body.cancelRaisonMessage,
		cancelReasonCode: body.cancelReasonCode,
		lang: body.lang,
	};

	return await axios
		.put(url, { ...params }, { headers: headers })
		.then((response) => {
			console.log("response.data", response.data);
			return response.data;
		})
		.catch((error) => {
			console.log("error", error);
		});
};

const SubscriptionService = {
	getUserSubscriptionsById,
	getAllSubscriptions,
	getUserClassesFullname,
	createDirectPayment,
	createPaymentLinkPayment,
	updatePayment,
	getCurrentUserSubscription,
	getUserSubscriptions,
	getCancelReasons,
	cancelSubscription,
};
export default SubscriptionService;
