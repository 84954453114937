import {
  Link,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import studaLogo from "../../../assets/lotties/studa.json";
import Lottie from "react-lottie";
import { ValidationCodeInputDto } from "../dtos/inputs/validationCodeInput";
import AuthService from "../services/auth-service";
import LoadingButton from "@mui/lab/LoadingButton";
import cookieService from "../../../cookieService";
import ReactCodeInput from "react-code-input";
import { useDispatch } from "react-redux";
import { setUser } from "../../../store/slices/userSlice";
import { ResendCodeInputDto } from "../dtos/inputs/resendCodeInput";
import MyTimer from "../components/MyTimer";
import { EnumStatusCode } from "../enums/auth.enums";
import { getDeviceId } from "../../../helpers/helpers";
import { useIntl } from "react-intl";
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function ValidationCode(props: any) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: studaLogo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [code, setCode] = React.useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoading2, setIsLoading2] = useState<boolean>(false);
  const [infoPhone, setInfoPhone] = useState<any>(location.state.infoPhone);
  const [timeRemaining, setTimeRemaining] = useState<number>(0);
  const [showTimer, setShowTimer] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState<boolean>(false);

  const intl = useIntl();
  const { formatMessage } = intl;

  const handleChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  const handlePinChange = (pinCode: string) => {
    setCode(pinCode);
    setHasError(false);
    setErrorMessage("");
    console.log(pinCode);
  };
  const codeStyle = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: "monospace",
      margin: "4px",
      MozAppearance: "textfield",
      width: "15px",
      borderRadius: "3px",
      fontSize: "14px",
      height: "26px",
      paddingLeft: "7px",
      backgroundColor: "black",
      color: "lightskyblue",
      border: "1px solid lightskyblue",
    },
    inputStyleInvalid: {
      fontFamily: "monospace",
      margin: "4px",
      MozAppearance: "textfield",
      width: "15px",
      borderRadius: "3px",
      fontSize: "14px",
      height: "26px",
      paddingLeft: "7px",
      backgroundColor: "black",
      color: "red",
      border: "1px solid red",
    },
  };

  const doValidationCode = () => {
    setIsLoading(true);

    let myPhone = infoPhone.countryCallingCode + "#" + infoPhone.nationalNumber;

    let body: ValidationCodeInputDto = {
      //code: code.split("-").join(""),
      code: code,
      phone: myPhone,
      deviceID: getDeviceId(),
    };
    AuthService.validationCode(body)
      .then((result) => {
        console.log(result);
        setIsLoading(false);
        let user = null;
        if (result.data.code === "SUCCESS") {
          user = result.data.data.payload;
          dispatch(setUser(user));
          cookieService.saveToken(result.data.data.accessToken.token);
          cookieService.saveRefreshToken(result.data.data.refreshToken.token);
        }
        console.log(`La currentClass est ${user.currentClass}`);
        if (user && user.currentClass == undefined && user.currentClass == "")
          navigate("/auth/select-country");
        else navigate("/dashboard/home");
      })
      .catch((err) => {
        //console.log(err);
        let statusCode = err?.response?.data?.statusCode;
        switch (statusCode) {
          case EnumStatusCode.CODE_INCORRECT:
            setErrorMessage("Le code est incorrect");
            break;
          case EnumStatusCode.CODE_EXPIRED:
            setErrorMessage("Le code a expiré");
            break;
          case EnumStatusCode.CODE_NOT_PROVIDED:
            setErrorMessage("Vous devez renseigner le code");
            break;
          default:
            break;
        }
        setHasError(true);

        setIsLoading(false);
      });
  };
  const doResendCode = () => {
    setIsLoading2(true);

    let myPhone = infoPhone.countryCallingCode + "#" + infoPhone.nationalNumber;

    let body: ResendCodeInputDto = {
      username: myPhone,
    };
    AuthService.resendCode(body)
      .then((result) => {
        console.log(result);
        setIsLoading2(false);
        let time_ = result?.data?.data?.timeRemaining ?? 0;
        setShowTimer(true);
        setTimeRemaining(time_);
        /*let user = null;
				if (result.data.code === "SUCCESS") {
					user = result.data.data.payload;
					dispatch(setUser(user));
					cookieService.saveToken(result.data.data.accessToken.token);
					cookieService.saveRefreshToken(result.data.data.refreshToken.token);
				}
				if (user && (user.currentClass == null || user.currentClass == ""))
					navigate("/auth/select-country");
				else navigate("/dashboard");*/
      })
      .catch((err) => {
        console.log(err);
        let time_ = err?.response?.data?.data?.timeRemaining ?? 0;
        setShowTimer(true);
        setTimeRemaining(time_);
        setIsLoading2(false);
      });
  };
  const removeTimer = () => {
    setShowTimer(false);
  };
  return (
    <div className="connexion-box">
      <div className="wrapper">
        <div className="container">
          <div className="logo" style={{ paddingBottom: "55px" }}>
            <Lottie options={defaultOptions} height={60} width={60} />
          </div>
          <div className="">
            <div className="title title-bold">
              {formatMessage({ id: "enter_the_code_receive_by_whataspp" },{"number":infoPhone.nationalNumber})}
            </div>
            <div className="subtitle">
              {formatMessage({ id: "problem_with_this_number" })}
              <Link
                sx={{ m: 1 }}
                component="button"
                variant="body2"
                onClick={() => {
                  navigate("/auth/connexion-sms", {
                    state: { infoPhone: infoPhone },
                  });
                }}
              >
                {formatMessage({ id: "change" })}
              </Link>
            </div>
            <div
              className=""
              style={{
                minWidth: "100%",
                paddingTop: "25px",
                textAlign: "center",
              }}
            >
              <>
                <ReactCodeInput
                  inputStyle={{
                    width: 50,
                    height: 42,
                    marginLeft: 10,
                    fontSize: 20,
                    textAlign: "center",
                    borderRadius: 7,
                  }}
                  name={""}
                  inputMode={"tel"}
                  type="text"
                  fields={4}
                  onChange={handlePinChange}
                  value={code}
                />
                {hasError ? <div className="error">{errorMessage}</div> : <></>}
              </>
            </div>
            <div
              className="btn-connexion btn-connexion-2"
              style={{ marginTop: "20px" }}
            >
              <LoadingButton
                disabled={showTimer}
                disableElevation
                loading={isLoading2}
                className="btn btn-lg"
                endIcon={<></>}
                loadingPosition="end"
                variant="outlined"
                style={{ textTransform: "none", fontSize: "16px" }}
                onClick={doResendCode}
              >
                {formatMessage({ id: "resend_the_code" })}

                {/*<Countdown
									date={Date.now() + timeRemaining * 1000}
									autoStart={true}
									intervalDelay={1000}
									precision={0}
									renderer={(props) => (
										<div style={{ paddingLeft: "15px" }}>
											{" "}
											{props.total / 1000}sec
										</div>
									)}
                                    />*/}
                {showTimer ? (
                  <MyTimer
                    timeRemaining={timeRemaining}
                    moveTimer={removeTimer}
                  />
                ) : (
                  <></>
                )}
              </LoadingButton>
            </div>
            <div className="btn-connexion btn-connexion-2">
              <LoadingButton
                disableElevation
                loading={isLoading}
                className="btn btn-lg"
                endIcon={<></>}
                loadingPosition="end"
                variant="contained"
                style={{ textTransform: "none", fontSize: "16px" }}
                onClick={doValidationCode}
              >
				{formatMessage({ id: "validate" })}
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ValidationCode;
