import { IntlProvider } from "react-intl";
import enTranslation from "./translations/en.json";
import frTranslation from "./translations/fr.json";
import React from "react";
import KEYS from "./helpers/KEYS";

type valueTranslation = {
	[key: string]: string;
};

interface Translation {
	[key: string]: valueTranslation;
	// fr: valueTranslation;
	// en: valueTranslation;
}

const messageKeys: Translation = { fr: frTranslation, en: enTranslation };

class IntlProviderWrapper extends React.Component<any, any> {
	// constructor(...args) {
	//   super(...args);

	constructor(args: any) {
		super(args);

		// this.switchToEnglish = () =>
		//   this.setState({ locale: "en", messages: enTranslation });

		// this.switchToDeutsch = () =>
		//   this.setState({ locale: "de", messages: deTranslation });

		const defaultLocale: string =
			/* navigator.language.split(/[-_]/)[0] */ "en"; // language without region code
		const userLocal: string =
			localStorage.getItem(KEYS.USER_LOCALE as string) || defaultLocale;
		localStorage.setItem(KEYS.USER_LOCALE as string, userLocal);
		const messages = !(userLocal in messageKeys)
			? frTranslation
			: messageKeys[userLocal];
		this.state = {
			locale: userLocal,
			messages: messages,
		};
		this.manageLanguage = this.manageLanguage.bind(this);
	}

	manageLanguage(event: any) {
		let userLocal: any = event.detail.value || this.state.locale;
		if (!(userLocal in messageKeys)) userLocal = "fr";
		this.setState({ locale: userLocal, messages: messageKeys[userLocal] });
		localStorage.setItem(KEYS.USER_LOCALE as string, userLocal);
	}

	componentDidMount() {
		document.addEventListener(
			"language-change",
			this.manageLanguage.bind(this)
		);
	}

	componentWillUnmount() {
		document.removeEventListener(
			"language-change",
			this.manageLanguage.bind(this)
		);
	}

	render() {
		const { children } = this.props;
		const { locale, messages } = this.state;
		return (
			<IntlProvider
				key={locale}
				locale={locale}
				messages={messages}
				defaultLocale="fr"
			>
				{children}
			</IntlProvider>
		);
	}
}

export default IntlProviderWrapper;
