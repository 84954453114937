import { getOffsetTime } from "../../../helpers/helpers";
import {
  EnumCurrency,
  EnumDurationType,
  EnumPaymentProvider,
  EnumStatusPayment,
  EnumSubscriptionState,
  EnumuCancelRaisonCode,
  SubscriptionStatus,
} from "../enums/subscription.enums";

export class SubscriptionType {
  code?: string;
  name?: string;
  description?: string;
  durationQuantity?: number;
  durationType?: string;
  amount?: number;
  currency?: string;
  visibility?: string;
  discountable?: boolean;
  recommended?: boolean;
  economy?: number;
  periodicity?: string;
  alertTimeQuantity?: number;
  alertTimeType?: EnumDurationType;
}

export class PaymentRequest {
  subscriptionTypeCode?: string;
  subscriptionId?: number;
  lang?: string;
  startDate?: number;
  voucherCode?: string;
}

export declare type MapObject = {
  [key: string]: any;
};
export class Payment {
  transactionId: string;
  paidBy?: string; // Nom du payeur
  amount: number;
  currency: EnumCurrency;
  startDate?: number;
  endDate?: number;
  method?: string;
  provider?: EnumPaymentProvider;
  operatorId?: string; //L'identifiant du paiement de l'opérateur | Chaîne de caractère
  metadata?: PaymentMeta;
  invoiceData?: MapObject;
  status: EnumStatusPayment;
  description?: string;
  invoiceFileName?: string; // Le lien de la facture
  paiementLink?: string; // Le lien du paiement
  paymentToken?: string; // Le lien du paiement
  paymentDate?: string;
  createdAt: number;
  updatedAt?: number;
  isDeleted: boolean;
  deletedAt?: number;
}

export class PaymentMeta {
  metadata?: string;
  operationId?: string;
  paymentCurrency?: string;
  paymentAmount?: string;
  paymentMethod?: string;
  paymentDate?: string;
  celPhoneNum?: string; //le numero qui a servi a faire le paiement
  cpmPhonePrefixe?: string; //le prefixe du pays
}
export class Subscription {
  username: string; //partition key
  subscriptionId: number; // sort key
  path: string; //path represent la classe
  startDate?: number; // Date à laquelle l'utilisateur souscrit à un abonnement pour la première fois et effectue un payment
  currency: string;
  status: SubscriptionStatus | EnumSubscriptionState;
  state: EnumSubscriptionState;
  nextBillingDate?: number; //est un attribut du DTO(attribut calculé) est egale au endDate
  subscriptionType: SubscriptionType;
  payments: Array<Payment>;
  cancelReasonMessage?: string;
  cancelDate?: number;
  createdAt: number;
  updatedAt?: number;
  isDeleted?: boolean;
  deletedAt?: number;

  showAlertButton(nextBillingDate: number, subscriptionType: SubscriptionType) {
    const d = new Date();
    let time = d.getTime();

    console.log("nextBillingDate++", nextBillingDate);

    // let calcul: number =
    //   nextBillingDate -
    //   getOffsetTime(
    //     subscriptionType?.alertTimeQuantity,
    //     subscriptionType?.alertTimeType
    //   );
    // console.log("calcul++", calcul);

    if (
      /* DateTime.now().millisecondsSinceEpoch */ time >=
      nextBillingDate -
      getOffsetTime(
        this.subscriptionType?.alertTimeQuantity,
        this.subscriptionType?.alertTimeType
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
}

export class CancelSubscriptionRequest {
  subscriptionId: string;
  cancelRaisonMessage?: string;
  cancelReasonCode?: EnumuCancelRaisonCode;
  lang?: string;
}
