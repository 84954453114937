import { AppBar, Stack, Toolbar, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import studaLogo from "../assets/lotties/studa.json";
import "./appToolBar.scss";
import { useSelector } from "react-redux";
import { selectUser } from "../store/slices/userSlice";
import StructureService from "../modules/structure-service";
import KEYS from "../helpers/KEYS";
import { selectCurrentMode } from "../store/slices/modeSlice";

function AppToolBar({ theme }: any) {
  const user = useSelector(selectUser);
  // console.log("currentClass: ", user.currentClass);

  const flagCountry =
    KEYS.HOST_RESOURCE +
    "/flags/" +
    user?.currentClass.split("#")[0].toLowerCase() +
    ".png";
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: studaLogo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [labels, setLabels] = useState<string[]>([]);

  //   useEffect(() => {
  //     getTopicPathLabel();
  //   }, []);

  useEffect(() => {
    getTopicPathLabel();
  }, [user]);

  const getTopicPathLabel = () => {
    setIsLoading(true);
    StructureService.getLabelTopicPath(user?.currentClass)
      .then((res) => {
        setIsLoading(false);
        setLabels(res.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  //const mode = theme.palette.mode;
  const mode = useSelector(selectCurrentMode);
  return (
    <div className="app-toolbar">
      <div className="container">
        <div className="transparent-bg"></div>
        <Toolbar
          disableGutters
          className="toolbar"
          style={{
            backgroundColor:
              mode === "dark"
                ? theme.palette.leftbar.dark
                : theme.palette.appbar.light,
          }}
          sx={{ minHeight: "60px !important" }}
        >
          <div
            className="logo"
            style={{
              color:
                mode === "dark"
                  ? theme.palette.colortext.dark
                  : theme.palette.colortext.light,
            }}
          >
            <Lottie options={defaultOptions} height={30} width={30} />
            <div className="title">Studa</div>
          </div>
          <Box sx={{ flexGrow: 1 }} />
          <div
            className="content"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color:
                mode === "dark"
                  ? theme.palette.colortext.dark
                  : theme.palette.colortext.light,
            }}
          >
            {isLoading ? (
              <span>Chargement...</span>
            ) : (
              <div
                style={{
                  paddingRight: "15px",
                  textAlign: "right",
                  fontFamily: "Nunito",
                  fontSize: "0.85rem",
                }}
              >
                <div>{labels[0]}</div>
                <div>{labels[1]}</div>
              </div>
            )}
            <div>
              <img src={flagCountry} width="20px" />
            </div>
          </div>
        </Toolbar>
      </div>
    </div>
  );
}

export default AppToolBar;
