import axios from "axios";
import cookieService from "../cookieService";
import KEYS from "../helpers/KEYS";
/*import { AuthSigninInputDto } from '../dtos/inputs/authSignIn';
import { ValidationCodeInputDto } from '../dtos/inputs/validationCodeInput';*/

/* axios.interceptors.response.use(
  (res) => {
    debugger;
    console.log("res:: ", res);
    debugger;
    return res;
  },
  (err) => {
    debugger;
    throw new Error(err.response.data.message);
  }
); */

const authHeader = () => {
	const token = cookieService.getToken();
	if (token) {
		return {
			Authorization: "Bearer " + token,
			"Content-Type": "application/json",
		};
	} else {
		return {};
	}
};
const getCountries = () => {
	let source: string = "country";
	const url = KEYS.BACKEND_HOST_STRUCTURE + "/level?source=" + source;
	const headers = authHeader();
	return axios.get(url);
};
const getClasses = (value: string) => {
	let source: string = "country";
	const url =
		KEYS.BACKEND_HOST_STRUCTURE + "/level?source=" + source + "&value=" + value;
	const headers = authHeader();
	return axios.get(url);
};

const getLabelTopicPath = (path: string) => {
	const url =
		KEYS.BACKEND_HOST_STRUCTURE +
		"/topic-path-label?topicPath=" +
		path.replaceAll("#", "%23");
	const headers = authHeader();
	return axios.get(url);
};

const StructureService = {
	getCountries,
	getClasses,
	getLabelTopicPath,
};
export default StructureService;
