import { createSlice } from "@reduxjs/toolkit";

const initialState = { loading: false };
const loadingSlice = createSlice({
	name: "loading",
	initialState: initialState,
	reducers: {
		setCurrentLoading: (state, action) => {
			state = action.payload;
			return action.payload;
		},
	},
});

export const { setCurrentLoading } = loadingSlice.actions;

export const selectCurrentLoading = (state: any) => state.loading;

export const loadingReducer = loadingSlice.reducer;
