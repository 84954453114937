import {
  Box,
  ButtonGroup,
  InputAdornment,
  Link,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Person,
  Email,
  Female,
  Male,
  PersonOutline,
} from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { MuiTelInput } from "mui-tel-input";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/userSlice";
import { UserPayload } from "../interfaces/auth.interface";
import { EnumUserSex } from "../enums/auth.enums";
import { DatePicker } from "@mui/x-date-pickers";
import UserService from "../services/user-service";
import { setUser } from "../../../store/slices/userSlice";
import { EditUserProfileInputDto } from "../dtos/inputs/editProfile";
import { getDevice, phoneNumberToDisplay } from "../../../helpers/helpers";
import ValidationCodeComponent from "../components/ValidationCodeComponent";
import { User } from "../../../interfaces/user.interface";
import { selectCurrentMode } from "../../../store/slices/modeSlice";
import { useIntl } from "react-intl";

function EditProfile({ theme }: any) {
  const user: UserPayload = useSelector(selectUser);
  const dispatch = useDispatch();
  const intl = useIntl();
  const { formatMessage } = intl;

  let number: string = "";
  if (user.phone !== "" && user.phone !== undefined) {
    let phone_: Array<string> = user.phone.split("#");
    number = `+${phone_[0]}${phone_[1]}`;
  }
  const [open, setOpen] = React.useState(false);
  const [typeValidCode, setTypeValidCode] = React.useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [value, setValue] = useState<Dayjs | null>(
    dayjs(user.birthday !== undefined ? new Date(user.birthday) : null)
  );

  const [infoPhone, setInfoPhone] = React.useState<any>();

  const [phone, setPhone] = React.useState(
    user.phone !== "" && user.phone !== undefined ? number : "+237"
  );

  const [newPhone, setNewPhone] = useState(phoneNumberToDisplay(user.newPhone));
  const [newEmail, setNewEmail] = useState(user.newEmail);

  const [sexe, setSexe] = useState<EnumUserSex>(
    (user.sex !== undefined
      ? user.sex.toLocaleLowerCase()
      : EnumUserSex.MALE.toLocaleLowerCase()) as EnumUserSex
  );
  const [name, setName] = useState<string>(
    (user.name !== "" ? user.name : "") as string
  );
  const [email, setEmail] = useState<string>(
    (user.email !== "" ? user.email : "") as string
  );
  const handleChangePhone = (phone: string, info: any) => {
    setInfoPhone(info);
    if (info.nationalNumber != "") {
    } else {
    }
    setPhone(phone);
  };

  const handleClickOpen = (type: number) => {
    setOpen(true);
    setTypeValidCode(type);
  };

  const handleClose = (newUser?: User) => {
    setOpen(false);
    if (newUser != null) {
      setPhone(phoneNumberToDisplay(newUser.phone));
      setEmail(newUser.email ?? "");
    }
  };
  const handleSexeChange = (sexe: string) => {
    switch (sexe) {
      case "male":
        setSexe(EnumUserSex.MALE.toLocaleLowerCase() as EnumUserSex);
        break;
      case "female":
        setSexe(EnumUserSex.FEMALE.toLocaleLowerCase() as EnumUserSex);
        break;
      default:
        setSexe(EnumUserSex.MALE.toLocaleLowerCase() as EnumUserSex);
    }
  };

  const handleTextInputNameChange = (event: any) => {
    setName(event.target.value);
  };

  const handleTextInputEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handleTextInputBirthdayChange = (newValue: Dayjs | null) => {
    setValue(newValue);
  };
  const mode = useSelector(selectCurrentMode);
  const uiRoundedTextField = {
    "& .MuiOutlinedInput-root": {
      borderRadius: "15px",

      legend: {
        marginLeft: "30px",
      },
      color:
        mode === "dark"
          ? theme.palette.colortext.dark
          : theme.palette.colortext.light,
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingLeft: "20px !important",
      borderRadius: "50px",
    },
    "& .MuiInputLabel-outlined": {},
    "& .MuiInputLabel-shrink": {
      marginLeft: "25px",
      paddingRight: 0,
      background: "white",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor:
        mode === "dark"
          ? theme.palette.outlinedinput.dark
          : theme.palette.outlinedinput.light,
    },
  };
  const doEditProfile = async () => {
    let device;
    setIsLoading(true);
    await getDevice().then((res: any) => {
      if (res) {
        device = res;

        let newUser: EditUserProfileInputDto = {
          device: device,
        };
        newUser.name = name;
        newUser.email = email;
        newUser.sex = sexe.toUpperCase() as EnumUserSex;
        newUser.birthday = value?.toDate().getTime();

        let tel = "";
        if (
          infoPhone != null &&
          infoPhone.countryCallingCode != "" &&
          infoPhone.nationalNumber
        ) {
          tel = infoPhone.countryCallingCode + "#" + infoPhone.nationalNumber;
        }
        if (tel !== user.phone && tel !== "") {
          newUser.phone = tel;
        }

        if (email !== user.email && email !== "") {
          newUser.email = email;
        }
        console.log(newUser);
        UserService.editUser(newUser)
          .then((result) => {
            setIsLoading(false);
            const user: User = result.data.data;
            dispatch(setUser(result.data.data));
            setNewPhone(phoneNumberToDisplay(user.newPhone));
            setPhone(phoneNumberToDisplay(user.phone));
            setNewEmail(user.newPhone);
            setEmail(user.email ?? "");
          })
          .catch(() => {
            setIsLoading(false);
          });
      }
    });
  };

  return (
    <Paper
      sx={{
        padding: 2,
        marginTop: "2rem",
        position: "relative",
        overflow: "hidden",
        scrollbarWidth: "thin",
        "&::-webkit-scrollbar": {
          width: "0.2em",
        },
        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#888",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
        "&:hover": {
          overflow: "auto !important",
        },
        backgroundColor:
          mode === "dark"
            ? theme.palette.leftbar.dark
            : theme.palette.leftbar.light,
      }}
      elevation={0}
    >
      <Box
        sx={{
          width: 500,
          maxWidth: "100%",
          paddingBottom: "15px",
        }}
      >
        <Stack spacing={2.5}>
          <TextField
            fullWidth
            label={formatMessage({ id: "your_full_name" })}
            id="name"
            value={name}
            onChange={handleTextInputNameChange}
            sx={uiRoundedTextField}
            style={{}}
            size="small"
            InputLabelProps={{
              style: {
                fontFamily: "Nunito",
                backgroundColor:
                  mode === "dark"
                    ? theme.palette.leftbar.dark
                    : theme.palette.leftbar.light,
                color:
                  mode === "dark"
                    ? theme.palette.labelinput.dark
                    : theme.palette.labelinput.light,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person
                    sx={{
                      width: 24,
                      color:
                        mode === "dark"
                          ? theme.palette.colortext.dark
                          : theme.palette.colortext.light,
                    }}
                  />
                </InputAdornment>
              ),
              style: {
                fontFamily: "Nunito",
                borderColor:
                  mode === "dark"
                    ? theme.palette.colortext.dark
                    : theme.palette.colortext.light,
              },
            }}
          />

          <TextField
            fullWidth
            label={formatMessage({ id: "your_email" })}
            id="email"
            value={email}
            onChange={handleTextInputEmailChange}
            sx={uiRoundedTextField}
            size="small"
            InputLabelProps={{
              style: {
                fontFamily: "Nunito",
                backgroundColor:
                  mode === "dark"
                    ? theme.palette.leftbar.dark
                    : theme.palette.leftbar.light,
                color:
                  mode === "dark"
                    ? theme.palette.labelinput.dark
                    : theme.palette.labelinput.light,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email
                    sx={{
                      width: 24,
                      color:
                        mode === "dark"
                          ? theme.palette.colortext.dark
                          : theme.palette.colortext.light,
                    }}
                  />
                </InputAdornment>
              ),
              style: {
                fontFamily: "Nunito",
              },
            }}
          />
          {user.newEmail && (
            <div
              style={{
                fontStyle: "italic",
                textAlign: "center",
                maxWidth: "300px",
              }}
            >
              <span>{user.newEmail}</span>{" "}
              {formatMessage({ id: "is_this_your_new_address_email" })}
              <Link
                component="button"
                variant="body2"
                style={{ paddingLeft: "5px" }}
                onClick={() => handleClickOpen(2)}
              >
                {formatMessage({ id: "validate" })}
              </Link>
            </div>
          )}
          <MuiTelInput
            value={phone}
            onChange={handleChangePhone}
            style={{
              minWidth: "100%",
            }}
            sx={uiRoundedTextField}
            inputProps={{
              style: {
                height: "10px",
                fontFamily: "Nunito",
              },
            }}
          />
          {user.newPhone && (
            <div
              style={{
                fontStyle: "italic",
                textAlign: "center",
                maxWidth: "300px",
              }}
            >
              <span>{newPhone} </span>
              {formatMessage({ id: "is_it_your_new_number" })}
              <Link
                component="button"
                variant="body2"
                style={{ paddingLeft: "5px" }}
                onClick={() => handleClickOpen(1)}
              >
                {formatMessage({ id: "validate" })}
              </Link>
            </div>
          )}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={value}
              onChange={(newValue) => handleTextInputBirthdayChange(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="dob"
                  sx={{ ...uiRoundedTextField }}
                  size="small"
                />
              )}
            />
          </LocalizationProvider>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: 5,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontFamily: "Nunito",
              }}
            >
              <PersonOutline
                sx={{
                  width: 34,
                  color:
                    mode === "dark"
                      ? theme.palette.colortext.dark
                      : theme.palette.colortext.light,
                }}
              />

              <span
                style={{
                  color:
                    mode === "dark"
                      ? theme.palette.colortext.dark
                      : theme.palette.colortext.light,
                }}
              >
                {formatMessage({ id: "your_sex" })}
              </span>
            </div>

            <ButtonGroup
              fullWidth
              variant="outlined"
              aria-label="outlined button group"
              disableElevation
              style={{
                borderStyle: "solid",
                borderWidth: "1px",
                borderRadius: "20px",
                borderColor: "#303F9F",
              }}
            >
              <LoadingButton
                disableElevation
                className="btn"
                endIcon={<> </>}
                loadingPosition="end"
                variant={
                  sexe === EnumUserSex.MALE.toLocaleLowerCase()
                    ? "contained"
                    : "text"
                }
                startIcon={<Male />}
                sx={{ borderRadius: "20px" }}
                style={{
                  textTransform: "none",
                  fontFamily: "Nunito",
                }}
                onClick={() => handleSexeChange("male")}
              >
                {formatMessage({ id: "male" })}
              </LoadingButton>
              <LoadingButton
                className="btn"
                endIcon={<> </>}
                loadingPosition="end"
                variant={
                  sexe === EnumUserSex.FEMALE.toLocaleLowerCase()
                    ? "contained"
                    : "text"
                }
                startIcon={<Female />}
                sx={{ borderRadius: "20px" }}
                style={{
                  textTransform: "none",
                  fontFamily: "Nunito",
                }}
                onClick={() => handleSexeChange("female")}
              >
                {formatMessage({ id: "female" })}
              </LoadingButton>
            </ButtonGroup>
          </div>
          <div
            className="btn-connexion btn-connexion-2"
            style={{ width: "100%" }}
          >
            <LoadingButton
              disableElevation
              loading={isLoading}
              className="btn btn-lg"
              endIcon={<></>}
              loadingPosition="end"
              variant="contained"
              style={{
                textTransform: "none",
                fontSize: "16px",
                borderRadius: "20px",
                width: "100%",
              }}
              sx={{
                "&:disabled": {
                  backgroundColor:
                    mode === "dark"
                      ? theme.palette.appbar.dark
                      : theme.palette.appbar.light,
                  color:
                    mode === "dark"
                      ? theme.palette.colortext.dark
                      : theme.palette.colortext.light,
                },
              }}
              onClick={doEditProfile}
            >
              {formatMessage({ id: "save" })}
            </LoadingButton>
          </div>
        </Stack>
      </Box>

      {open && (
        <ValidationCodeComponent
          open={open}
          typeValidCode={typeValidCode}
          theme={theme}
          metadata={{
            newEmail: newEmail,
            newPhone: newPhone,
            username: user.username,
          }}
          handleClose={handleClose}
        />
      )}
    </Paper>
  );
}

export default EditProfile;
