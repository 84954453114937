import { createSlice } from "@reduxjs/toolkit";

const initialState = "home";

const pageSlice = createSlice({
	name: "page",
	initialState: initialState,
	reducers: {
		setPage: (state, action) => {
			state = action.payload;
			return action.payload;
		},
	},
});

export const { setPage } = pageSlice.actions;

export const selectPage = (state: any) => state.page;

/*export const selectedCountry = (state) => (state.user != null ? state.user.selectedCountry : null);*/

export const pageReducer = pageSlice.reducer;
