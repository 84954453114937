import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import IntlProviderWrapper from "./IntlProviderWrapper";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { SnackbarProvider } from "notistack";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import fr from "javascript-time-ago/locale/fr.json";
import ru from "javascript-time-ago/locale/ru.json";
import { registerServiceWorker } from "./serviceWorker";

TimeAgo.addDefaultLocale(fr);
TimeAgo.addLocale(en);
TimeAgo.addLocale(ru);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <SnackbarProvider maxSnack={3}>
    <IntlProviderWrapper>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </IntlProviderWrapper>
  </SnackbarProvider>
);
/*<IntlProviderWrapper>
    
    <GoogleOAuthProvider clientId="562890599106-e52hro1e5vkl22qhrhv8jqndfr5cdk0l.apps.googleusercontent.com">
      <Router>
        <App />
      </Router>
    </GoogleOAuthProvider>
  </IntlProviderWrapper>*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
registerServiceWorker()
reportWebVitals();
navigator.serviceWorker.addEventListener("message", (message) => console.log("received message:", message));

