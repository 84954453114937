import { EnumConnectedVia } from '../../enums/auth.enums';
import { DeviceData, UserCredentialsFromAuthAPIDto, UserDataFromAuthAPIDto } from '../../interfaces/auth.interface';
import { IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class AuthSigninInputDto {
  @IsEnum(EnumConnectedVia)
  service: EnumConnectedVia;

  @IsOptional()
  @IsString()
  phone?: string;

  @IsOptional()
  data?: UserDataFromAuthAPIDto;

  @IsOptional()
  credentials?: UserCredentialsFromAuthAPIDto;

  @IsNotEmpty()
  device: DeviceData;
}
