import { Box, Card, CardContent, Typography } from "@mui/material";
import "./css/subscriptionItem.scss";
import { useStyles1 } from "../../../helpers/sharedStyle";
import PropTypes from "prop-types";
import moment from "moment";
import {
  EnumBackTo,
  EnumSubscriptionState,
  SubscriptionStatus,
} from "../enums/subscription.enums";
import { setCurrentMenu } from "../../../store/slices/menuSlice";
import { setCurrentSubscription } from "../../../store/slices/subscriptionSlice";
import { setCurrentBackTo } from "../../../store/slices/backToSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentMode } from "../../../store/slices/modeSlice";
import { useIntl } from "react-intl";
function SubscriptionItem({ subscription, index, theme, classNameLabel }: any) {
  const dispatch = useDispatch();
  const classes = useStyles1();
  const intl = useIntl();
  const { formatMessage } = intl;

  const goToDetailSubscription = async () => {
    await dispatch(setCurrentMenu({ title: "", code: 0 }));
    setTimeout(function () {
      dispatch(
        setCurrentMenu({
          title: formatMessage({ id: "details_of_subscription_types" }),
          code: 6,
        })
      );
    });
    dispatch(setCurrentSubscription(subscription));
  };
  const mode = useSelector(selectCurrentMode);
  return (
    <Box
      onClick={() => {
        goToDetailSubscription();
        dispatch(setCurrentBackTo({ backTo: EnumBackTo.ABONNEMENTS }));
      }}
      sx={{ width: "100%", marginTop: "25px", position: "relative" }}
    >
      <div style={{ width: "100%", marginTop: "25px" }}>
        <div
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            zIndex: 1000,
            width: "100%",
            position: "absolute",
            display: "flex",
            justifyContent: "space-between",
            top: "-15px",
          }}
        >
          <div></div>
          <div
            className="unpayBadge"
            style={{
              maxWidth: "350px",
              color: theme.palette.colortext.light,
              backgroundColor:
                subscription.state === EnumSubscriptionState.PAID
                  ? theme.palette.backgroundPositive.main
                  : theme.palette.faildchat.main,
            }}
          >
            <span>
              {subscription.state === EnumSubscriptionState.PAID
                ? "PAYE"
                : "IMPAYE"}
            </span>
          </div>
        </div>
        <Card
          className={classes.cardRoot}
          style={{
            width: "100%",
            color:
              mode === "dark"
                ? theme.palette.cardContent.dark
                : theme.palette.cardContent.light,
            fontWeight: "600",

            backgroundColor:
              mode == "dark"
                ? theme.palette.appbar.dark
                : theme.palette.contentbox.light,
          }}
          elevation={0}
        >
          <CardContent
            style={{
              width: "100%",
              color:
                mode === "dark"
                  ? theme.palette.colortext.dark
                  : theme.palette.colortext.light,
              fontWeight: 600,
              padding: "5px 8px 2px",
              borderRadius: "5px",
            }}
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              style={{ marginBottom: "5px" }}
            >
              <Box>
                <Typography
                  style={{ fontSize: "0.9rem", marginBottom: "20px" }}
                  component="span"
                >
                  {subscription?.subscriptionType?.periodicity}
                </Typography>
              </Box>
              <Box></Box>
            </Box>

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginBottom: "5px",
              }}
            >
              <Typography
                component="span"
                className={classes.title}
                style={{ fontSize: "0.90rem" }}
              >
                {`Pack ${subscription.subscriptionType.name}`}
              </Typography>
              <Typography component="span" style={{ fontSize: "0.85rem" }}>
                {subscription.subscriptionType.amount}{" "}
                {subscription.subscriptionType.currency}
              </Typography>
            </Box>
            <Box
              /* p={1} */ style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "50px",
                width: "100%",
                marginBottom: "5px",
              }}
            >
              <Typography style={{ fontSize: "0.85rem" }} component="span">
                {`${formatMessage({id:"from"})} ${moment(
                  subscription.startDate !== null
                    ? subscription.startDate
                    : subscription.createdAt
                ).format("LLLL")}`}{" "}
                {`${formatMessage({id:"to"})} ${moment(subscription.nextBillingDate).format("LLLL")}`}
              </Typography>
              <Typography
                style={{ fontSize: "0.85rem" }}
                component="span"
              ></Typography>
            </Box>
            {classNameLabel ? (
              <Box>
                <Typography
                  style={{
                    fontSize: "0.85rem",
                    color:
                      mode === "dark"
                        ? theme.palette.colortext.dark
                        : theme.palette.colortext.light,
                  }}
                  component="span"
                >
                  {classNameLabel?.country.toUpperCase()} /{" "}
                  {classNameLabel?.name.toUpperCase()}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography
                  style={{
                    fontSize: "0.85rem",
                    color:
                      mode === "dark"
                        ? theme.palette.colortext.dark
                        : theme.palette.colortext.light,
                  }}
                  component="span"
                >
                  {subscription.path}
                </Typography>
              </Box>
            )}

            <Box>
              <Typography
                style={{
                  fontSize: "0.85rem",
                  color:
                    subscription.status == SubscriptionStatus.CURRENT
                      ? "green"
                      : mode == "dark"
                      ? theme.palette.colortext.dark
                      : theme.palette.colortext.light,
                  fontWeight: 700,
                }}
                component="span"
              >
                {subscription.status}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </div>
    </Box>
  );
}

SubscriptionItem.defaultProps = {
  subscription: {},
};

SubscriptionItem.propTypes = {
  index: PropTypes.number.isRequired,
  subscription: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  classNameLabel: PropTypes.any,
};

export default SubscriptionItem;
