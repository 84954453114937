import "./css/conversation.scss";
import {
  Stack,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider,
  Box,
  Typography,
  LinearProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import ChatService from "../services/chat-service";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import {
  Conversation,
  ConversationRequest,
  LastEvaluatedKey,
  Topic,
} from "../interfaces/chat.interface";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/userSlice";
import { User } from "../../../interfaces/user.interface";
import {
  EnumStatusCode,
  EnumStatusResponse,
} from "../../authentification/enums/auth.enums";
import ConversationItem from "./ConversationItem";
import Lottie from "react-lottie";
import studaLoad from "../../../assets/lotties/load.json";
import studaNotFoundDark from "../../../assets/lotties/not-found-black.json";
import studaNotFoundLight from "../../../assets/lotties/not-found-white.json";
import { setCurrentMenu } from "../../../store/slices/menuSlice";
import {
  selectCurrentMode,
  setCurrentMode,
} from "../../../store/slices/modeSlice";
import {
  Gavel,
  HelpOutline,
  LightModeOutlined,
  Policy,
} from "@mui/icons-material";
import {
  EnumActionConversation,
  EnumConversationStatus,
  EnumTopicStatus,
} from "../enums/chat.enums";
import {
  selectCurrentConversation,
  setCurrentConversation,
} from "../../../store/slices/conversationSlice";
import { setPage } from "../../../store/slices/pageSlice";
import ConfirmRestart from "../components/ConfirmRestart";
import {
  selectConversationsList,
  setConversationsList,
} from "../../../store/slices/conversationsSlice";
import { setCurrentSubject } from "../../../store/slices/subjectSlice";
import { isEmpty } from "class-validator";
import { selectCurrentLoading } from "../../../store/slices/loadingSlice";
import Switch from "@mui/material/Switch";
import KEYS from "../../../helpers/KEYS";
import {
  selectLanguageLocal,
  setLanguageLocal,
} from "../../../store/slices/languageSlice";
import { EnumLocalLang } from "../components/enums/conversation.enums";

function ConversationList({ theme }: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const { formatMessage } = intl;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const user: User = useSelector(selectUser);
  console.log("currentClass: ", user.currentClass);
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(true);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [conversations, setConversations] = React.useState<Array<Conversation>>(
    []
  );
  const [selectedSubjet, setSelectedSubject] = useState<Topic>();
  const [selectedAction, setSelectedAction] =
    useState<EnumActionConversation>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = useState<boolean>(false);
  const [selectIndex, setSelectIndex] = useState<number>();
  const loadOptions = {
    loop: true,
    autoplay: true,
    animationData: studaLoad,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const notFoundOptionsDark = {
    loop: true,
    autoplay: true,
    animationData: studaNotFoundDark,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const notFoundOptionsLight = {
    loop: true,
    autoplay: true,
    animationData: studaNotFoundLight,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const mode = useSelector(selectCurrentMode);
  const conversation = useSelector(selectCurrentConversation);
  const currentConversation = useSelector(selectCurrentConversation);
  const conversationList = useSelector(selectConversationsList);
  const currentLoading = useSelector(selectCurrentLoading);
  // console.log("la valeur de current loading: ", currentLoading);
  const [checked, setChecked] = React.useState(true);
  const languageLocal: string = useSelector(selectLanguageLocal);
  const flag = languageLocal == "en" ? "gb" : "fr";
  const fullLangName = languageLocal == "en" ? "Englais" : "Français";

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setLanguageLocal(languageLocal == EnumLocalLang.FR ? "en" : "fr"));

    //console.log("new local", languageLocal);
  };

  useEffect(() => {
    let filterResult = conversations.find(
      (element: Conversation) => element?.path === conversation?.subject?.path
    );
    if (filterResult) {
      let index = conversations.findIndex(
        (element: Conversation) =>
          element.conversationId === filterResult?.conversationId
      );
      setSelectIndex(index);
    } else {
      getAllStartConversation();
    }
  }, [user]);

  useEffect(() => {
    if (conversation && !isEmpty(conversation)) {
      let filterResult = conversations.find(
        (element: Conversation) => element?.path === conversation?.subject?.path
      );

      if (filterResult) {
        let index = conversations.findIndex(
          (element: Conversation) =>
            element.conversationId === filterResult?.conversationId
        );
        setSelectIndex(index);
      }
    }
  }, [conversation]);

  useEffect(() => {
    if (languageLocal == EnumLocalLang.FR) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  }, [languageLocal]);

  const getAllStartConversation = () => {
    setIsLoading(true);
    if (!window.navigator.onLine) {
      enqueueSnackbar(formatMessage({ id: "no_internet_connection" }), {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
      return;
    }

    let lastEvaluatedKey: LastEvaluatedKey = new LastEvaluatedKey();
    lastEvaluatedKey.conversationId = "";
    lastEvaluatedKey.username = "";

    let body: ConversationRequest = new ConversationRequest();
    body.lastEvaluatedKey = lastEvaluatedKey;
    body.class_ = user.currentClass;

    ChatService.getAllStartConversation(body)
      .then(
        (data: any) => {
          if (
            data.code === EnumStatusCode.SUCCESS &&
            data.statusCode === EnumStatusCode.SUCCESS
          ) {
            let conversationList: Array<Conversation> = data.data
              .conversations as Array<Conversation>;

            let filterConversationsList: Array<Conversation> =
              conversationList.sort((a, b) => {
                if (a.lastMessage?.createdAt! < b.lastMessage?.createdAt!)
                  return 1;
                if (a.lastMessage?.createdAt! > b.lastMessage?.createdAt!)
                  return -1;
                return 0;
              });
            let lastEvaluatedKey: LastEvaluatedKey = data.data
              .lastEvaluatedKey as LastEvaluatedKey;

            dispatch(setConversationsList(filterConversationsList));
            if (conversationList.length > 0) {
              setConversations(filterConversationsList);
              let index = filterConversationsList.findIndex(
                (element: Conversation) =>
                  element.conversationId ===
                  conversation?.subject?.conversationId
              );
              setSelectIndex(index);
            }
          }
        },
        (error) => {
          console.log("Error: ", error);
          if (error?.response?.data?.code === EnumStatusResponse.FAILURE) {
            switch (error?.response?.data?.statusCode) {
              case EnumStatusCode.GET_USERS_ERROR:
                enqueueSnackbar(formatMessage({id: "something_when_wrong"}), {
                  variant: "error",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
                break;
              case EnumStatusCode.DYNAMO_ERROR:
                enqueueSnackbar(formatMessage({id: "something_when_wrong"}), {
                  variant: "error",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
                break;

              case EnumStatusCode.UNKNOWN_ERROR:
                enqueueSnackbar(formatMessage({id: "something_when_wrong"}), {
                  variant: "error",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
                break;
              default:
                enqueueSnackbar(formatMessage({id: "something_when_wrong"}), {
                  variant: "error",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
            }
          }
        }
      )
      .finally(() => {
        console.log("Completed request!");
        setIsLoading(false);
      });
  };
  const deleteConversation = async (conversationId: string) => {
    if (!window.navigator.onLine) {
      enqueueSnackbar(formatMessage({ id: "no_internet_connection" }), {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
      return;
    }
    setIsLoadingAction(true);
    await ChatService.deleteConversation(conversationId)
      .then(
        (result: any) => {
          if (
            result.code === EnumStatusCode.SUCCESS &&
            result.statusCode === EnumStatusCode.CONVERSATION_DELETED
          ) {
            console.log("c'est supprime");
            let newConversation: Conversation = conversationList.find(
              (conversation: Conversation) =>
                conversation.conversationId == conversationId
            );

            let newSubject: Topic = {
              conversationId: newConversation.conversationId,
              path: newConversation.path,
              progression: newConversation.progression,
              name:
                newConversation.topicLabel !== undefined
                  ? newConversation.topicLabel[
                      newConversation.topicLabel.length - 1
                    ]
                  : "",
              totalQuestions: newConversation.totalQuestions,
              quizStatus: EnumTopicStatus.QUIZ_NOT_STARTED,
              //code: conversationSelected.subject.code,
            };
            if (conversation?.subject?.path === newConversation?.path) {
              dispatch(setCurrentConversation({}));
            }
            dispatch(
              setCurrentSubject({
                subject: newSubject,
                action: EnumActionConversation.DELETE_CONVERSATION,
              })
            );

            let oldConversations = conversationList.filter(
              (conversation: Conversation) =>
                conversation.conversationId !== conversationId
            );
            enqueueSnackbar(formatMessage({id:"conversation_was_deleted"}), {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" },
            });
            dispatch(setConversationsList(oldConversations));
          }
        },
        (error) => {
          console.log("Error: ", error);
          if (error?.response?.data?.code === EnumStatusResponse.FAILURE) {
            switch (error?.response?.data?.statusCode) {
              case EnumStatusCode.GET_USERS_ERROR:
                enqueueSnackbar(formatMessage({id: "something_when_wrong"}), {
                  variant: "error",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
                break;
              case EnumStatusCode.DYNAMO_ERROR:
                enqueueSnackbar(formatMessage({id: "something_when_wrong"}), {
                  variant: "error",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
                break;

              case EnumStatusCode.UNKNOWN_ERROR:
                enqueueSnackbar(formatMessage({id: "something_when_wrong"}), {
                  variant: "error",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
                break;
              default:
                enqueueSnackbar(formatMessage({id: "something_when_wrong"}), {
                  variant: "error",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
            }
          }
        }
      )
      .finally(() => {
        console.log("Completed request!");
        setIsLoadingAction(false);
      });
  };
  //const mode = theme.palette.mode;
  const changeMode = () => {
    dispatch(setCurrentMode(mode === "dark" ? "light" : "dark"));
    console.log(mode);
  };
  const handleConversation = (
    subject: Topic,
    action: EnumActionConversation
  ) => {
    if (currentLoading.loading === false) {
      //on vérifi s'il n'ya pas de traitement encours sur une conversation
      if (action === EnumActionConversation.DELETE_CONVERSATION) {
        deleteConversation(subject.conversationId ?? "");
      } else {
        dispatch(setCurrentConversation({ subject, action }));
      }
    } else {
      enqueueSnackbar("Veuillez patienter svp...", {
        variant: "warning",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
    }
  };
  const selectConversation = (conversation: Conversation, index: number) => {
    //console.log(conversation);
    let topic: Topic = {
      conversationId: conversation.conversationId,
      path: conversation.path,
      progression: conversation.progression,
      name:
        conversation.topicLabel !== undefined
          ? conversation.topicLabel[conversation.topicLabel.length - 1]
          : "",
      totalQuestions: conversation.totalQuestions,
    };
    let action =
      conversation.status === EnumConversationStatus.IN_PROGRESS
        ? EnumActionConversation.CONTINUE_CONVERSATION
        : EnumActionConversation.BROWSE_CONVERSATION;
    handleConversation(topic, action);
    setSelectIndex(index);
  };
  const handleLocation = (page: string) => {
    switch (page) {
      case "policy":
        dispatch(setPage("policy"));
        break;

      default:
        break;
    }
  };
  const closeDialog = () => {
    setOpenDialog(false);
  };
  const showConfirm = (
    conversation: Conversation,
    action: EnumActionConversation
  ) => {
    console.log(conversation);
    let topic: Topic = {
      conversationId: conversation.conversationId,
      path: conversation.path,
      progression: conversation.progression,
      name:
        conversation.topicLabel !== undefined
          ? conversation.topicLabel[conversation.topicLabel.length - 1]
          : "",
      totalQuestions: conversation.totalQuestions,
    };
    setSelectedSubject(topic);
    setSelectedAction(action);
    setOpenDialog(true);
  };
  return !isLoading ? (
    <div className="conversation-list">
      <div className="btn-new-chat">
        <Stack direction="row">
          <Button
            onClick={() => {
              dispatch(setCurrentMenu({ title: "SubjectList", code: 4 }));
            }}
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              textTransform: "none",
              fontSize: "16px",
              color:
                mode === "dark"
                  ? theme.palette.colortext.dark
                  : theme.palette.colortext.light,
              borderColor:
                mode === "dark"
                  ? theme.palette.colortext.dark
                  : theme.palette.colortext.light,
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
            size="small"
            fullWidth
            variant="outlined"
            startIcon={
              <AddIcon
                style={{
                  fontSize: "0.85rem",
                }}
              />
            }
          >
            <Typography sx={{ marginLeft: "8px", fontSize: "0.85rem" }}>
              {formatMessage({id: "start_conversation"})}
            </Typography>
          </Button>
        </Stack>
      </div>
      <div className="list-item">
        {isLoadingAction && (
          <Box sx={{ pt: "10px", pb: "10px", width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        {conversationList.length > 0 ? (
          <List dense>
            {conversationList.map(
              (conversation: Conversation, index: number) => (
                <div key={index}>
                  <ConversationItem
                    index={index}
                    conversation={conversation}
                    theme={theme}
                    onSelectConversation={() =>
                      selectConversation(conversation, index)
                    }
                    //isSelected={selectIndex === index}
                    isSelected={
                      currentConversation?.subject?.path === conversation?.path
                    }
                    onRestartConversation={() => {
                      showConfirm(
                        conversation,
                        EnumActionConversation.RESTART_CONVERSATION
                      );
                    }}
                    onDeleteConversation={() => {
                      showConfirm(
                        conversation,
                        EnumActionConversation.DELETE_CONVERSATION
                      );
                    }}
                  />
                </div>
              )
            )}
          </List>
        ) : (
          <div>
            <div
              className="title title-bold"
              style={{
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              {mode === "dark" ? (
                <div style={{ paddingBottom: "15px" }}>
                  <Lottie
                    options={notFoundOptionsDark}
                    height={100}
                    width={100}
                    
                  />
                </div>
              ) : (
                <div style={{ paddingBottom: "15px" }}>

                  <Lottie
                    options={notFoundOptionsLight}
                    height={100}
                    width={100}
                  />
                </div>
              )}

              <div
                className="title"
                style={{
                  color:
                    mode === "dark"
                      ? theme.palette.colortext.dark
                      : theme.palette.colortext.light,
                }}
              >
                Aucune conversation démarrée
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="footer-box">
        <Divider variant="fullWidth" />
        <List dense>
          <ListItem disablePadding alignItems="flex-start">
            <ListItemButton
              sx={{
                padding: 0.8,
              }}
              style={{ gap: 10 }}
            >
              <img
                src={`${KEYS.HOST_RESOURCE}/flags/fr.png`.toLowerCase()}
                alt="flag"
                width={20}
                height={15}
              />

              <Switch
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
                size="small"
              />

              <img
                src={`${KEYS.HOST_RESOURCE}/flags/gb.png`.toLowerCase()}
                alt="flag"
                width={20}
                height={15}
              />
              <ListItemText
                sx={{
                  color:
                    mode === "dark"
                      ? theme.palette.colortext.dark
                      : theme.palette.colortext.light,
                }}
                primary={fullLangName}
                primaryTypographyProps={{ fontSize: "0.85rem" }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding alignItems="flex-start">
            <ListItemButton
              sx={{
                padding: 0.8,
                //fontSize: 16,
              }}
              style={{ gap: 10 }}
              onClick={changeMode}
            >
              <ListItemIcon style={{ minWidth: 45 }}>
                {mode !== "dark" ? (
                  <DarkModeOutlinedIcon
                    style={{
                      color: theme.palette.colortext.light,
                      fontSize: "0.85rem",
                    }}
                  />
                ) : (
                  <LightModeOutlined
                    style={{
                      color: theme.palette.colortext.dark,
                      fontSize: "0.85rem",
                    }}
                  />
                )}
              </ListItemIcon>
              <ListItemText
                sx={{
                  color:
                    mode === "dark"
                      ? theme.palette.colortext.dark
                      : theme.palette.colortext.light,
                }}
                primary={mode === "dark" ? formatMessage({id: "dark_mode"}) : formatMessage({id: "light_mode"})}
                primaryTypographyProps={{ fontSize: "0.85rem" }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding alignItems="flex-start">
            <ListItemButton
              sx={{
                padding: 0.8,
              }}
              style={{ gap: 10 }}
              onClick={() => {
                //handleLocation("policy");
                dispatch(setCurrentConversation({}));
                // dispatch(setPage("policy"));
                navigate("/privacy-policy");
              }}
            >
              <ListItemIcon style={{ minWidth: 45 }}>
                <Policy
                  style={{
                    color:
                      mode === "dark"
                        ? theme.palette.colortext.dark
                        : theme.palette.colortext.light,
                    fontSize: "0.85rem",
                  }}
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  color:
                    mode === "dark"
                      ? theme.palette.colortext.dark
                      : theme.palette.colortext.light,
                }}
                primary={formatMessage({id: "privacy_policy"})}
                primaryTypographyProps={{ fontSize: "0.85rem" }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding alignItems="flex-start">
            <ListItemButton
              sx={{
                padding: 0.8,
                //fontSize: 16,
              }}
              style={{ gap: 10 }}
              onClick={() => {
                //handleLocation("terms");
                dispatch(setCurrentConversation({}));
                // dispatch(setPage("terms"));
                navigate("/terms-usage");
              }}
            >
              <ListItemIcon style={{ minWidth: 45 }}>
                <Gavel
                  style={{
                    color:
                      mode === "dark"
                        ? theme.palette.colortext.dark
                        : theme.palette.colortext.light,
                    fontSize: "0.85rem",
                  }}
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  color:
                    mode === "dark"
                      ? theme.palette.colortext.dark
                      : theme.palette.colortext.light,
                }}
                primary={formatMessage({id: "usage_terms"})}
                primaryTypographyProps={{ fontSize: "0.85rem" }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding alignItems="flex-start">
            <ListItemButton
              sx={{
                padding: 0.8,
                
              }}
              style={{ gap: 10 }}
              onClick={() => {
                
                dispatch(setCurrentConversation({}));
                
                navigate("/help");
              }}
            >
              <ListItemIcon style={{ minWidth: 45 }}>
                <HelpOutline
                  style={{
                    color:
                      mode === "dark"
                        ? theme.palette.colortext.dark
                        : theme.palette.colortext.light,
                    fontSize: "0.85rem",
                  }}
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  color:
                    mode === "dark"
                      ? theme.palette.colortext.dark
                      : theme.palette.colortext.light,
                }}
                primary={formatMessage({id: "help"})}
                primaryTypographyProps={{ fontSize: "0.85rem" }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </div>
      <ConfirmRestart
        open={openDialog}
        action={selectedAction}
        handleClose={() => closeDialog()}
        theme={theme}
        confirm={() => {
          handleConversation(selectedSubjet!, selectedAction!);
        }}
      />
    </div>
  ) : (
    <Lottie options={loadOptions} height={80} width={80} />
  );
}

export default ConversationList;
