import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Avatar, Stack, useMediaQuery, useScrollTrigger } from "@mui/material";
import { Icon } from "@iconify/react";
import logo from "../../image/logo.webp";
import { Link } from "react-router-dom";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function ElevationScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 1 : 0,
  });
}

interface InavItems {
  id: string;
  label: string;
}
const drawerWidth = 240;
const navItems: InavItems[] = [
  { id: "FEATURES", label: "Features" },
  { id: "ABOUT", label: "About" },
  { id: "HELP", label: "Help" },
  { id: "CONTACT", label: "Contact" },
];

export default function ResponsiveAppBar(props: Props) {
  const isXs = useMediaQuery((theme: any) => theme.breakpoints.only("xs"));
  const isSm = useMediaQuery((theme: any) => theme.breakpoints.only("sm"));
  const isMd = useMediaQuery((theme: any) => theme.breakpoints.only("md"));

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Studa
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton
              component="a"
              href={`#${item.id}`}
              sx={{ textAlign: "center" }}
            >
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Box display="block">
        <Button
          component={Link}
          to={"/auth"}
          variant="contained"
          sx={{ textTransform: "none" }}
          disableElevation
          startIcon={<Icon icon="streamline:startup-solid" />}
        >
          Get Started
        </Button>
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar component="nav" sx={{ background: "#fff" }}>
          <Toolbar
            disableGutters
            sx={{
              px: isXs ? 1 : isSm ? 10 : 24,
            }}
          >
            <IconButton
              color="default"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>

            <Avatar
              alt="Custom Image"
              src={logo}
              sx={{ mr: 1, display: { xs: "none", sm: "block" } }}
            />
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                display: { xs: "none", sm: "block", color: "#000000" },
              }}
            >
              Studa
            </Typography>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={isMd ? 8 : 0}
              sx={{ display: { xs: "none", sm: "flex" } }}
            >
              <Box /* sx={{ display: { xs: "none", sm: "block" }, width:'100%' }} */
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                {navItems.map((item) => (
                  <Button
                    key={item.id}
                    href={`#${item.id}`}
                    sx={{
                      color: "#000000",
                      textTransform: "none",
                      fontSize: "16px",
                      mr: 2,
                    }}
                  >
                    {item.label}
                  </Button>
                ))}
              </Box>

              <Box display="block">
                <Button
                  component={Link}
                  to={"/auth"}
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  disableElevation
                  startIcon={<Icon icon="streamline:startup-solid" />}
                >
                  Get Started
                </Button>
              </Box>
            </Stack>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}
