import {
	ListItem,
	Typography,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Tooltip,
	Avatar,
	ListItemAvatar,
	Button,
	Stack,
	IconButton,
} from "@mui/material";
import React, { useState } from "react";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import TopicIcon from "@mui/icons-material/Topic";
import BookIcon from "@mui/icons-material/Book";
import PropTypes from "prop-types";
import { EnumActionConversation, EnumTopicStatus } from "../enums/chat.enums";
import { useStyles } from "../../../helpers/sharedStyle";
import { setCurrentMenu } from "../../../store/slices/menuSlice";
import { useDispatch, useSelector } from "react-redux";
import "./css/subject.scss";
import { selectCurrentMode } from "../../../store/slices/modeSlice";
import {
	CheckCircleOutlined,
	PauseCircleOutline,
	PauseCircleOutlined,
	PausePresentationOutlined,
	PlayCircleFilledOutlined,
	PlayCircleFilledWhiteOutlined,
} from "@mui/icons-material";

function SubjectItem({
	index,
	subject,
	onStartConversation,
	onContinueConversation,
	onRestartConversation,
	onBrowseConversation,
	onSelectedIndex,
	onSelectedMenu,
	theme,
}: any) {
	const dispatch = useDispatch();
	const classes = useStyles();

	//const storeSubjects: Array<Topic> = useSelector(getAllSubjectStore);
	//console.log("Store subjects: ", storeSubjects);
	const [selectedItem, setSelectedItem] = useState<number>(-1);

	const handleStartItem = (e: React.MouseEvent) => {
		e.stopPropagation();
		onStartConversation();
	};
	const handleContinueItem = (e: React.MouseEvent) => {
		e.stopPropagation();
		onContinueConversation();
	};
	const handleRestartItem = (e: React.MouseEvent) => {
		e.stopPropagation();
		onRestartConversation();
	};
	const handleBrowseItem = (e: React.MouseEvent) => {
		e.stopPropagation();
		onBrowseConversation();
	};
	const handleSelectedIndex = (e: React.MouseEvent) => {
		e.stopPropagation();
		onSelectedIndex();
	};
	const handleSelectedMenu = (e: React.MouseEvent) => {
		e.stopPropagation();
		onSelectedMenu();
	};
	const mode = useSelector(selectCurrentMode);

	return (
		<div style={{ width: "100%" }}>
			<ListItem
				sx={{ paddingLeft: "6px", paddingRight: "6px" }}
				disablePadding
				// disableGutters
				alignItems="flex-start"
				//   style={{
				//     backgroundColor:
				//       selectedItem === index ? "red" : "" /* marginBottom: 0.5 */,
				//   }}
				//   selected={selectedItem === index}
				onClick={
					(e) => handleSelectedMenu(e)
					/*dispatch(
						setCurrentMenu({
							title: "Theme",
							code: 2,
							subject,
						})
					)*/
				}
				secondaryAction={
					<Stack
						direction="row"
						spacing={2}
					>
						{subject.quizStatus === "QUIZ_NOT_STARTED" ? (
							<Tooltip
								title="Démarrer"
								placement="top"
							>
								<IconButton
									edge="end"
									aria-label="play-circle"
									onClick={(e) => {
										handleSelectedIndex(e);
										handleStartItem(e);
									}}
								>
									<PlayCircleFilledWhiteOutlined
										style={{
											color:
												mode === "dark"
													? theme.palette.colortext.dark
													: theme.palette.colortext.light,
											//backgroundColor: "#303F9",
											//borderRadius: "50%",
											fontSize: "1.2rem",
										}}
									/>
								</IconButton>
							</Tooltip>
						) : subject.quizStatus === "IN_PROGRESS" ? (
							<div>
								<Tooltip
									title="Continuer"
									placement="top"
								>
									<IconButton
										edge="end"
										aria-label="pause-circle"
										style={{ marginRight: "2px", color: "orange" }}
										onClick={(e) => {
											handleSelectedIndex(e);
											handleContinueItem(e);
										}}
									>
										<PauseCircleOutlined
											style={{
												borderRadius: "50%",
												fontSize: "1.2rem",
											}}
										/>
									</IconButton>
								</Tooltip>

								<Tooltip
									title="Redémarrer"
									placement="top"
								>
									<IconButton
										edge="end"
										aria-label="refresh"
										onClick={(e) => {
											handleSelectedIndex(e);
											handleRestartItem(e);
										}}
									>
										<RefreshIcon
											style={{
												color:
													mode === "dark"
														? theme.palette.colortext.dark
														: theme.palette.colortext.light,
												fontSize: "1.2rem",
											}}
										/>
									</IconButton>
								</Tooltip>
							</div>
						) : (
							<div>
								<Tooltip
									title="Parcourir"
									placement="top"
								>
									<IconButton
										edge="end"
										aria-label="check-circle"
										style={{ marginRight: "2px", color: "green" }}
										onClick={(e) => {
											handleSelectedIndex(e);
											handleBrowseItem(e);
										}}
									>
										<CheckCircleOutlined
											style={{
												borderRadius: "50%",
												fontSize: "1.2rem",
											}}
										/>
									</IconButton>
								</Tooltip>
								<Tooltip
									title="Redémarrer"
									placement="top"
								>
									<IconButton
										edge="end"
										aria-label="refresh"
										onClick={(e) => {
											handleSelectedIndex(e);
											//showConfirm(e);
											handleRestartItem(e);
										}}
									>
										<RefreshIcon
											style={{
												color:
													mode === "dark"
														? theme.palette.colortext.dark
														: theme.palette.colortext.light,
												fontSize: "1.2rem",
											}}
										/>
									</IconButton>
								</Tooltip>
							</div>
						)}
					</Stack>
				}
			>
				<ListItemButton
					sx={{
						padding: 0.8,
						fontSize: "0.85rem",
					}}
				>
					<ListItemAvatar
						sx={{
							fontSize: "0.85rem",
							minWidth: 0,
							paddingRight: "10px",
							display: "flex",
							justifyContent: "center",
						}}
					>
						<Avatar
							sx={{
								backgroundColor: "#EEEEEE",
								width: "20px !important",
								height: "20px !important",
								p: 0.25,
								display: "flex",
								justifyContent: "center",
							}}
						>
							<BookIcon
								style={{
									color: theme.palette.primary.main,
									fontSize: "0.85rem",
									textAlign: "center",
									verticalAlign: "middle",
								}}
							/>
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={
							<Tooltip
								title={subject.name}
								placement="top"
							>
								<Typography
									sx={{
										color:
											mode === "dark"
												? theme.palette.colortext.dark
												: theme.palette.colortext.light,
										fontSize: "0.85rem",

										//lineHeight: "0.8rem",
									}}
									classes={{ root: classes.customBox }}
								>
									{subject.name}
								</Typography>
							</Tooltip>
						}
						secondary={
							<Typography
								sx={{
									display: "inline",
									fontSize: "0.75rem",
									lineHeight: "0.75rem",
									color:
										mode === "dark"
											? theme.palette.secondarytext.dark
											: theme.palette.secondarytext.light,
								}}
								component="div"
								variant="body2"
								// color="primary"
							>
								{subject.quizStatus === EnumTopicStatus.QUIZ_NOT_STARTED ? (
									<span
										style={{
											color:
												mode === "dark"
													? theme.palette.secondarytext.dark
													: theme.palette.secondarytext.light,
										}}
									>
										Commencer
									</span>
								) : subject.quizStatus === EnumTopicStatus.IN_PROGRESS ? (
									<span style={{ color: "orange" }}>En cours</span>
								) : (
									<span style={{ color: "green" }}>Complète</span>
								)}
							</Typography>
						}
					/>
				</ListItemButton>
			</ListItem>
		</div>
	);
}

SubjectItem.defaultProps = {
	subject: {},
};

SubjectItem.propTypes = {
	index: PropTypes.number.isRequired,
	subject: PropTypes.object.isRequired,
	onStartConversation: PropTypes.func.isRequired,
	onContinueConversation: PropTypes.func.isRequired,
	onRestartConversation: PropTypes.func.isRequired,
	onBrowseConversation: PropTypes.func.isRequired,
	onSelectedIndex: PropTypes.func.isRequired,
	onSelectedMenu: PropTypes.func.isRequired,
	theme: PropTypes.object.isRequired,
};

export default SubjectItem;
