import {
  Button,
  Link,
  List,
  Typography,
  IconButton,
  Snackbar,
  Tooltip,
} from "@mui/material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import Add from "@mui/icons-material/Add";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import PaymentsIcon from "@mui/icons-material/Payments";
import PaymentIcon from "@mui/icons-material/Payment";
import PaidRoundedIcon from "@mui/icons-material/PaidRounded";
import Lottie, { Options } from "react-lottie";
import studaLogoStatic from "../../../assets/images/logo.png";
import React, { useEffect } from "react";
import {
  Payment,
  Subscription,
  SubscriptionType,
} from "../interfaces/subscription.interface";
import PropTypes from "prop-types";
import { subscribe, unsubscribe } from "../../../helpers/helpers";
import moment from "moment";
import { selectLanguageLocal } from "../../../store/slices/languageSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  EnumBackTo,
  EnumStatusPayment,
  EnumSubscriptionState,
  SubscriptionStatus,
} from "../enums/subscription.enums";
import StructureService from "../../structure-service";
import { selectUser } from "../../../store/slices/userSlice";
import { User } from "../../../interfaces/user.interface";
import { Box } from "@mui/system";
import { useStyles } from "../../../helpers/sharedStyle";
import { useSnackbar } from "notistack";
import { EnumStatusResponse } from "../../authentification/enums/auth.enums";
import { EnumStatusCode } from "../enums/subscription.enums";
import { ConversationRequest } from "../../conversation/interfaces/chat.interface";
import { useIntl } from "react-intl";
import SubscriptionService from "../services/subscription-service";
import studaLoad from "../../../assets/lotties/load.json";
import { isEmpty } from "class-validator";
import {
  selectCurrentSubscription,
  setCurrentSubscription,
} from "../../../store/slices/subscriptionSlice";
import { selectCurrentBackTo } from "../../../store/slices/backToSlice";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import BuildCancelModalSubscription from "../components/BuildCancelModalSubscription";
import { setCurrentMenu } from "../../../store/slices/menuSlice";
import "./css/subscription-detail.scss";
import { selectCurrentMode } from "../../../store/slices/modeSlice";
import { setCurrentConversation } from "../../../store/slices/conversationSlice";
import { setCurrentSubscriptionType } from "../../../store/slices/subscriptionTypeSlice";
import CinetPayService from "../services/CinetPayService";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function DetailSubscription({ theme }: any) {
  moment.locale(/* languageLocal */ "fr");
  const classes = useStyles();
  const dispatch = useDispatch();

  const intl = useIntl();
  const { formatMessage } = intl;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const user: User = useSelector(selectUser);
  const languageLocal: string = useSelector(selectLanguageLocal);

  const currentSubscription: Subscription = useSelector(
    selectCurrentSubscription
  );

  const currentBackTo = useSelector(selectCurrentBackTo);

  const isCheckFrom: boolean =
    currentBackTo.backTo === EnumBackTo.ABONNEMENTS ? true : false;

  const [classNameLabel, setclassNameLabel] = React.useState<string>("");
  const [subscription, setSubscription] = React.useState<Subscription>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isCheck, setIsCheck] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [transactionId, setTransactionId] = React.useState<string>("");

  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => {
    setIsOpen(true);
  };

  const loadOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: studaLoad,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const getLabelTopicPath = () => {
    StructureService.getLabelTopicPath(
      isCheckFrom ? currentSubscription?.path : user.currentClass!
    )
      .then((data: any) => {
        console.log("fullmame:: ", data);
        const fullnameTopicPath: string =
          `${data.data.data[0]} / ${data.data.data[1]}`.toUpperCase();
        setclassNameLabel(fullnameTopicPath);
      })
      .catch((error: any) => {
        console.log("Cold not get Current className !!!", error);
      });
  };

  const getCurrentSubscriptionUser = () => {
    setIsLoading(true);
    if (!window.navigator.onLine) {
      enqueueSnackbar(formatMessage({ id: "no_internet_connection" }), {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
      return;
    }

    let body: ConversationRequest = new ConversationRequest();
    body.path = user.currentClass;
    body.lang = languageLocal;

    SubscriptionService.getCurrentUserSubscription(body)
      .then(
        (data: any) => {
          console.log("data response current subscription++: ", data);
          if (
            data.code === EnumStatusCode.SUCCESS &&
            data.statusCode === EnumStatusCode.SUCCESS
          ) {
            setSubscription(data.data as Subscription);
            dispatch(setCurrentSubscription(data.data as Subscription));
          }
        },
        (error) => {
          console.log("Error: ", error);
          if (error?.response?.data?.code === EnumStatusResponse.FAILURE) {
            const code = error?.response?.data?.code;

            switch (error?.response?.data?.statusCode) {
              case EnumStatusCode.SUBSCRIPTION_NOT_FOUND:
                /*  enqueueSnackbar("Oupps!! Abonnement introuvable", {
                  variant: "info",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                }); */
                break;

              case EnumStatusCode.UNKNOWN_ERROR:
                enqueueSnackbar(
                  formatMessage({ id: "an_error_has_occurred" }),
                  {
                    variant: "error",
                    anchorOrigin: { vertical: "bottom", horizontal: "right" },
                  }
                );
                break;
              default:
                enqueueSnackbar(
                  formatMessage({ id: "an_error_has_occurred" }),
                  {
                    variant: "error",
                    anchorOrigin: { vertical: "bottom", horizontal: "right" },
                  }
                );
            }
          }
        }
      )
      .finally(() => {
        console.log("Completed request!");
        setIsLoading(false);
      });
  };

  const listener = async (data: any) => {
    console.log("event detail *****", data.detail.transactionId);
    const transId = data.detail.transactionId;
    let resultUpdate = await SubscriptionService.updatePayment(
      transId,
      languageLocal
    );
    const subscription = resultUpdate.data;

    await dispatch(setCurrentMenu({ title: "", code: 0 }));

    setTimeout(function () {
      dispatch(
        setCurrentMenu({
          title: formatMessage({ id: "details_of_subscription_types" }),
          code: 6,
        })
      );
    });
    dispatch(setCurrentSubscription(subscription));
  };

  useEffect(() => {
    navigator.serviceWorker.addEventListener("message", async (message) => {
      let subscriptionId = message.data.data.subscriptionId;
      if (subscriptionId == subscription?.subscriptionId) {
        let subscription = await SubscriptionService.getUserSubscriptionsById(
          languageLocal,
          subscriptionId
        );
        await dispatch(setCurrentMenu({ title: "", code: 0 }));
        setTimeout(async function () {
          setSubscription(subscription);
          dispatch(
            setCurrentMenu({
              title: formatMessage({ id: "details_of_subscription_types" }),
              code: 6,
            })
          );
        });
        dispatch(setCurrentSubscription(subscription));
        console.log("received message:", message);
      }
    });
  }, [subscription]);

  useEffect(() => {
    setIsCheck(currentBackTo.backTo === EnumBackTo.ABONNEMENTS ? true : false);
    if (isCheckFrom) {
      setSubscription(currentSubscription);
    }

    getLabelTopicPath();
    if (!isCheckFrom) {
      getCurrentSubscriptionUser();
    }

    subscribe("cinetpay", listener);
    return () => {
      unsubscribe("cinetpay", listener);
    };
  }, []);

  const handleClickCopyLink = (value: string) => {
    setOpen(true);
    navigator.clipboard.writeText(value.toString());
  };

  const handleClickShareLink = (value: string) => {
    window.open(`${value}`, "_blank", "noreferrer");
  };

  const continueDirectPayment = async (
    subscriptionType: SubscriptionType,
    payment: Payment
  ) => {
    console.log("subscriptionType: ", subscriptionType);
    console.log("payment: ", payment);

    const transId = payment.transactionId;
    setTransactionId(transId);
    await CinetPayService.pay(
      transId,
      subscriptionType.amount!,
      subscriptionType.currency!,
      subscriptionType.description!
    );
  };

  const showViewListPayments = (index: number, payment: Payment) => {
    return (
      <Box
        key={index}
        sx={{
          fontWeight: 300,
          borderBottom: "1px solid #eee",
          padding: "8px 0 8px 0",
        }}
      >
        <div
          className="wrap-toolbar"
          style={{
            color:
              mode === "dark"
                ? theme.palette.colortext.dark
                : theme.palette.colortext.light,
            fontWeight: 600,
            borderRadius: "5px",
          }}
        >
          <div className="toolbar-title" style={{ alignItems: "center" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <PaymentIcon style={{ color: "#303F9F", fontSize: "1rem" }} />
            </div>
            <div style={{ paddingLeft: "10px", fontSize: "0.8rem" }}>{`${
              payment.metadata != null
                ? `${payment.metadata?.paymentAmount} ${
                    payment.metadata?.paymentCurrency
                  } - ${
                    payment.status === EnumStatusPayment.SUCCES
                      ? formatMessage({ id: "active" }).toUpperCase()
                      : payment.status === EnumStatusPayment.PENDING
                      ? formatMessage({
                          id: "waiting_for_payment",
                        }).toUpperCase()
                      : "Inactif".toUpperCase()
                  }`
                : payment.status === EnumStatusPayment.SUCCES
                ? formatMessage({ id: "active" }).toUpperCase()
                : payment.status === EnumStatusPayment.PENDING
                ? formatMessage({ id: "waiting_for_payment" }).toUpperCase()
                : formatMessage({ id: "inactive" }).toUpperCase()
            }`}</div>
          </div>
        </div>
        <div className="content-paiement">
          <div style={{ fontSize: "0.85rem", paddingTop: "5px" }}>{`${moment(
            payment.startDate
          ).format("LLLL")}`}</div>
          {payment.paiementLink !== undefined &&
          payment.paiementLink !== "" &&
          payment.paiementLink !== null &&
          payment.status === EnumStatusPayment.PENDING ? (
            <Typography
              sx={{ fontSize: "0.85rem", paddingTop: "5px" }}
              classes={{ root: classes.customBox }}
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              {payment.paiementLink}
            </Typography>
          ) : null}

          {payment.paiementLink !== undefined &&
          payment.paiementLink !== "" &&
          payment.paiementLink !== null &&
          payment.status === EnumStatusPayment.PENDING ? (
            <div>
              <div style={{ marginTop: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <IconButton
                    onClick={() => handleClickCopyLink(payment.paiementLink!)}
                    color="primary"
                    style={{ padding: 0, color: "#303F9F" }}
                  >
                    <ContentCopyRoundedIcon style={{ fontSize: "0.85rem" }} />
                  </IconButton>
                  <span style={{ fontSize: "0.85rem" }}>
                    {formatMessage({ id: "copy_link" })}
                  </span>
                </div>

                <Snackbar
                  message="Copied to clibboard"
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  autoHideDuration={2000}
                  onClose={() => setOpen(false)}
                  open={open}
                >
                  <Alert severity="success">
                    {formatMessage({ id: "copy" })}
                  </Alert>
                </Snackbar>
              </div>
              <div style={{ marginTop: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <IconButton
                    onClick={() => handleClickShareLink(payment.paiementLink!)}
                    color="primary"
                    style={{ padding: 0, fontSize: "1rem", color: "#303F9F" }}
                  >
                    <OpenInNewRoundedIcon style={{ fontSize: "0.85rem" }} />
                  </IconButton>
                  <span style={{ fontSize: "0.85rem" }}>
                    {formatMessage({ id: "open_the_link_in_another_window" })}
                  </span>
                </div>
              </div>
            </div>
          ) : null}

          {payment &&
          payment.status === EnumStatusPayment.PENDING &&
          (payment.paiementLink === undefined ||
            payment.paiementLink === null) ? (
            <div style={{ marginTop: "5px" }}>
              <Button
                size="small"
                startIcon={<PaidRoundedIcon style={{ fontSize: "1rem" }} />}
                disableElevation
                style={{
                  textTransform: "none",
                  background: "green",
                  border: "none",
                  fontSize: "0.75rem",
                  color: "white",
                }}
                onClick={() => {
                  continueDirectPayment(
                    subscription!.subscriptionType!,
                    payment
                  );
                }}
              >
                {formatMessage({ id: "continue_payment" })}
              </Button>
            </div>
          ) : null}
        </div>
      </Box>
    );
  };
  const mode = useSelector(selectCurrentMode);
  return !isLoading && !isEmpty(subscription) ? (
    <div className="detail-subscription">
      <div className="first-card">
        <div
          className="detail-subscription2"
          style={{
            color:
              mode === "dark"
                ? theme.palette.colortext.dark
                : theme.palette.colortext.light,
            fontWeight: "600",
          }}
        >
          <div className="logo">
            <img
              src={studaLogoStatic}
              alt="Logo studa"
              height={30}
              width={30}
            />
          </div>
          <div>
            <div className="title" style={{}}>
              {`Abonnement ${subscription?.subscriptionType?.name}`}
            </div>
            <div className="">
              {`${subscription?.subscriptionType?.amount} ${subscription?.subscriptionType?.currency} / ${subscription?.subscriptionType?.durationQuantity} ${subscription?.subscriptionType?.durationType}`}
            </div>
          </div>
        </div>

        <div style={{ paddingLeft: "40px" }}>
          <div
            style={{
              color:
                mode === "dark"
                  ? theme.palette.colortext.dark
                  : theme.palette.colortext.light,
              fontWeight: "300",
            }}
          >{`Démarré le: ${
            subscription?.startDate == null
              ? moment(subscription?.createdAt).format("LLLL")
              : moment(subscription?.startDate).format("LLLL")
          }`}</div>
          <div
            style={{
              color:
                mode === "dark"
                  ? theme.palette.colortext.dark
                  : theme.palette.colortext.light,
              fontWeight: "300",
              paddingTop: "2px",
            }}
          >
            {`${formatMessage({ id: "Valid_until" })} ${moment(
              subscription?.nextBillingDate
            ).format("LLLL")}`}
          </div>

          {!isCheck &&
          new Subscription().showAlertButton(
            subscription?.nextBillingDate!,
            subscription?.subscriptionType!
          ) ? (
            <div style={{ paddingTop: "8px", paddingBottom: "10px" }}>
              <Button
                variant="contained"
                disableElevation
                style={{
                  textTransform: "none",
                  border: "none",
                  fontSize: "0.75rem",
                }}
                onClick={() => {
                  dispatch(setCurrentConversation({})); // Reset value conversationSlice
                  dispatch(
                    setCurrentSubscriptionType(subscription?.subscriptionType)
                  );
                  dispatch(setCurrentSubscription(subscription));
                }}
              >
                {formatMessage({ id: "renew_my_subscription" })}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      <div
        className="wrap-toolbar"
        style={{
          backgroundColor:
            mode === "dark"
              ? theme.palette.appbar.dark
              : theme.palette.appbar.light,
          color:
            mode === "dark"
              ? theme.palette.colortext.dark
              : theme.palette.colortext.light,
          fontWeight: 600,
          padding: "5px 8px 2px",
          borderRadius: "5px",
        }}
      >
        <div className="toolbar-title">
          <div>
            <SubscriptionsIcon style={{ color: "#303F9F" }} />
          </div>
          <div>{formatMessage({ id: "my_subscription" })}</div>
        </div>
      </div>
      <div
        className="second-card"
        style={{
          color:
            mode === "dark"
              ? theme.palette.colortext.dark
              : theme.palette.colortext.light,
          fontWeight: "600",
        }}
      >
        <div style={{ paddingBottom: "10px" }}>
          <div>Status</div>
          <div style={{ color: "green" }}>
            {subscription?.status === SubscriptionStatus.CURRENT
              ? formatMessage({ id: "in_progress" }).toUpperCase()
              : subscription?.status === EnumSubscriptionState.UNPAID
              ? formatMessage({ id: "unpaid" }).toUpperCase()
              : subscription?.status === SubscriptionStatus.ACTIVE
              ? formatMessage({ id: "active" }).toUpperCase()
              : subscription?.status === SubscriptionStatus.INACTIVE
              ? formatMessage({ id: "inactive" }).toUpperCase()
              : formatMessage({ id: "expired" }).toUpperCase()}
          </div>
        </div>
        <div style={{ paddingBottom: "10px" }}>
          <div>{formatMessage({ id: "class" })}</div>
          <div style={{ color: "green" }}>
            {`${classNameLabel.length <= 0 ? "Chargement.." : classNameLabel}`}
          </div>
        </div>
        <div>
          <div>{formatMessage({ id: "subscription_status" })}</div>
          <div style={{ color: "orange" }}>{`${
            subscription?.state === EnumSubscriptionState.PAID
              ? formatMessage({ id: "paid" }).toUpperCase()
              : formatMessage({ id: "unpaid" }).toUpperCase()
          }`}</div>
        </div>
      </div>
      <div
        className="wrap-toolbar"
        style={{
          backgroundColor:
            mode === "dark"
              ? theme.palette.appbar.dark
              : theme.palette.appbar.light,
          color:
            mode === "dark"
              ? theme.palette.colortext.dark
              : theme.palette.colortext.light,
          fontWeight: 600,
          padding: "5px 8px 2px",
          borderRadius: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
                    

          <div className="toolbar-title">
            <PaymentsIcon style={{ color: "#303F9F" }} />
            <span>{formatMessage({ id: "my_payments" })}</span>{" "}
          </div>

          <Tooltip title={formatMessage({ id: "add_payment" })}>
            <IconButton
              sx={{
                background: "green",
                color: "white",
              }}
              aria-label="add"
              size="small"
              color="success"
              onClick={() => {
                dispatch(setCurrentConversation({})); // Reset value conversationSlice
                dispatch(
                  setCurrentSubscriptionType(subscription?.subscriptionType)
                );
                dispatch(setCurrentSubscription(subscription));
              }}
            >
              <Add fontSize="small" />
            </IconButton>
          </Tooltip>

        </div>
      </div>
      <List
        sx={{
          color:
            mode === "dark"
              ? theme.palette.colortext.dark
              : theme.palette.colortext.light,
          fontWeight: "600",
        }}
      >
        <div
          style={{
            paddingLeft: "27px",
          }}
        >
          {subscription!.payments && subscription!.payments.length > 0
            ? [...subscription!.payments].reverse().map((payment: Payment, index: number) =>
                showViewListPayments(index, payment)
              )
            : null}
        </div>
      </List>
      <div
        className="second-card"
        style={{
          color:
            mode === "dark"
              ? theme.palette.colortext.dark
              : theme.palette.colortext.light,
        }}
      >
        {isCheck ? null : (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ fontStyle: "italic" }}>
              {formatMessage({ id: "to_cancel_your_subscription_click" })}
              <Link
                component="button"
                variant="body2"
                sx={{ fontStyle: "italic" }}
                onClick={() => {
                  console.info("I'm a button.");
                  handleOpen();
                }}
              >
                {formatMessage({ id: "cancel_now" })}
              </Link>
            </span>
          </div>
        )}
      </div>
      {
        <BuildCancelModalSubscription
          isOpen={isOpen}
          subscription={subscription!}
          theme={theme}
          onHandleOpen={(event: boolean) => {
            setIsOpen(true);
          }}
          onHandleClose={(event: boolean) => {
            setIsOpen(false);
          }}
        ></BuildCancelModalSubscription>
      }
    </div>
  ) : !isLoading && isEmpty(subscription) ? (
    <div
      className="title title-bold"
      style={{
        textAlign: "center",
        fontSize: "22px",
      }}
    >
      {formatMessage({ id: "no_package" })}
    </div>
  ) : (
    <Lottie
      options={loadOptions}
      height={80}
      width={80}
    />
  );
}

DetailSubscription.defaultProps = {
  subscription: {},
};

DetailSubscription.propTypes = {
  subscription: PropTypes.object.isRequired,
};

export default DetailSubscription;
