import {
  Typography,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React from "react";
import Lottie from "react-lottie";
import studaLogo from "../../../assets/lotties/studa.json";
import { selectCurrentMode } from "../../../store/slices/modeSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function _Drawer(props: any) {
  const navigate = useNavigate();

  const mode = useSelector(selectCurrentMode);
  const theme = props.theme;

  const handleDrawerToggle = () => {
    props.onHandleDrawerToggle();
  };


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: studaLogo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <div
          style={{
            color:
              mode === "dark"
                ? theme.palette.colortext.dark
                : theme.palette.colortext.light,
          }}
        >
          <Lottie options={defaultOptions} height={30} width={30} />
          <div className="title">Studa</div>
        </div>
      </Typography>
      <Divider />
      <List>
        <ListItem key={"Page d'acceuil"} disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() => {
              navigate("/dashboard/home");
            }}
          >
            <ListItemText primary={"Page d'acceuil"} />
          </ListItemButton>
        </ListItem>

        <ListItem key={"Politique de confidentialité"} disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() => {
              navigate("/privacy-policy");
            }}
          >
            <ListItemText primary={"Politique de confidentialité"} />
          </ListItemButton>
        </ListItem>

        <ListItem key={"conditions d’utilisation"} disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() => {
              navigate("/terms-usage");
            }}
          >
            <ListItemText primary={"conditions d’utilisation"} />
          </ListItemButton>
        </ListItem>

        <ListItem key={"Aide"} disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() => {
              navigate("/help");
            }}
          >
            <ListItemText primary={"Aide"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}

export default _Drawer;
