import { createSlice } from "@reduxjs/toolkit";

const initialState = { subject: {}, action: "" };
const conversationSlice = createSlice({
	name: "conversation",
	initialState: initialState,
	reducers: {
		setCurrentConversation: (state, action) => {
			state = action.payload;
			console.log("state2: ", state);
			return action.payload;
		},
	},
});

export const { setCurrentConversation } = conversationSlice.actions;

export const selectCurrentConversation = (state: any) => state.conversation;

export const conversationReducer = conversationSlice.reducer;
