import { createSlice } from "@reduxjs/toolkit";

const initialState = { backTo: "" };
const backToSlice = createSlice({
  name: "backTo",
  initialState: initialState,
  reducers: {
    setCurrentBackTo: (state, action) => {
      state = action.payload;
      console.log("state backTo: ", state);
      return action.payload;
    },
  },
});

export const { setCurrentBackTo } = backToSlice.actions;

export const selectCurrentBackTo = (state: any) => state.backTo;

export const backToReducer = backToSlice.reducer;
