import KEYS from "../../../helpers/KEYS";
const pay = async (
  transactionId: string,
  amount: number,
  currency: string,
  description: string
) => {
  let result = await window?.checkout?.(
    transactionId,
    amount,
    KEYS.CP_API_KEY,
    KEYS.CP_SITE_ID,
    KEYS.CP_NOTIFY_URL,
    currency,
    description
  );
  console.log("payment result", result);
}



declare global {
  interface Window {
    checkout: (
      transactionId: string,
      amount: number,
      apikey: string,
      site_id: string,
      notify_url: string,
      currency: string,
      description: string
    ) => Promise<any>;
    myGlobalVariable: any;
  }
}
const CinetPayService = {
  pay,
};
export default CinetPayService;
