import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "./BuildCancelModalSubscription.scss";
import {
	EnumStatusCode,
	EnumStatusResponse,
	EnumuCancelRaisonCode,
} from "../enums/subscription.enums";
import { useDispatch, useSelector } from "react-redux";
import { selectLanguageLocal } from "../../../store/slices/languageSlice";
import { useSnackbar } from "notistack";
import SubscriptionService from "../services/subscription-service";
import { useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {
	CancelSubscriptionRequest,
	Subscription,
} from "../interfaces/subscription.interface";
import { setCurrentMenu } from "../../../store/slices/menuSlice";
import { publish } from "../../../helpers/helpers";
import { setCurrentSubscription } from "../../../store/slices/subscriptionSlice";
import { selectCurrentMode } from "../../../store/slices/modeSlice";

function BuildCancelModalSubscription({
	isOpen,
	subscription,
	onHandleOpen,
	onHandleClose,
	theme,
}: any) {
	const dispatch = useDispatch();
	const intl = useIntl();
	const { formatMessage } = intl;
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const languageLocal: string = useSelector(selectLanguageLocal);
	// console.log("language Local: ", languageLocal);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isCode, setIsCode] = React.useState<string>();
	const [isCancelReasons, setIsCancelReasons] = React.useState<
		Array<Record<string, any>>
	>([]);
	const [isMessage, setIsMessage] = React.useState("");

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = (event.target as HTMLInputElement).value as string;
		setIsCode(value);
		console.log("option value++:: ", value);
	};

	const mode = useSelector(selectCurrentMode);
	const style = {
		position: "absolute" as "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 600,
		backgroundColor:
			mode === "dark"
				? theme.palette.leftbar.dark
				: theme.palette.leftbar.light,
		color:
			mode === "dark"
				? theme.palette.colortext.dark
				: theme.palette.colortext.light,
		//bgcolor: "background.paper",
		// border: "2px solid #000",
		// borderRadius: "20px",
		boxShadow: 24,
		pt: 2,
		px: 4,
		pb: 3,
	};

	// const handleOpen = () => {
	//   onHandleOpen();
	//   // setIsOpen(false);
	// };
	const handleClose = () => {
		onHandleClose();
		// setIsOpen(false);
	};

	useEffect(() => {
		getAllCancelReasons();
		console.log("subscription++ ", subscription);
	}, []);

	const getAllCancelReasons = () => {
		// setIsLoading(true);
		if (!window.navigator.onLine) {
			enqueueSnackbar(formatMessage({ id: "no_internet_connection" }), {
				variant: "error",
				anchorOrigin: { vertical: "bottom", horizontal: "right" },
			});
			return;
		}
		const lang = languageLocal;
		SubscriptionService.getCancelReasons(lang)
			.then(
				(data: any) => {
					console.log("data response subscriptions: ", data);
					if (
						data.code === EnumStatusCode.SUCCESS &&
						data.statusCode === EnumStatusCode.SUCCESS
					) {
						let cancelReasonsList: Array<Record<string, any>> =
							data.data as Array<Record<string, any>>;
						if (cancelReasonsList.length > 0) {
							setIsCancelReasons(data.data as Array<Record<string, any>>);
						}
					}
				},
				(error) => {
					console.log("Error: ", error);
					if (error?.response?.data?.code === EnumStatusResponse.FAILURE) {
						const code = error?.response?.data?.code;
						switch (error?.response?.data?.statusCode) {
							  case EnumStatusCode.SUBSCRIPTION_NOT_FOUND:
							   /*  enqueueSnackbar("Oupps!! Abonnement introuvable", {
							      variant: "info",
							      anchorOrigin: { vertical: "bottom", horizontal: "right" },
							    }); */
							    break;
							case EnumStatusCode.UNKNOWN_ERROR:
								enqueueSnackbar("Oupps!! Une erreur est survenue", {
									variant: "error",
									anchorOrigin: { vertical: "bottom", horizontal: "right" },
								});
								break;
							default:
								enqueueSnackbar("Oupps!! Une erreur est survenue", {
									variant: "error",
									anchorOrigin: { vertical: "bottom", horizontal: "right" },
								});
						}
					}
				}
			)
			.finally(() => {
				console.log("Completed request!");
				// setIsLoading(false);
			});
	};

	const cancelSubscription = (e: React.MouseEvent) => {
		e.stopPropagation();
		setIsLoading(true);
		if (!window.navigator.onLine) {
			enqueueSnackbar(formatMessage({ id: "no_internet_connection" }), {
				variant: "error",
				anchorOrigin: { vertical: "bottom", horizontal: "right" },
			});
			return;
		}

		const body: CancelSubscriptionRequest = {
			subscriptionId: subscription.subscriptionId,
			cancelRaisonMessage: isMessage,
			cancelReasonCode: isCode as EnumuCancelRaisonCode,
			lang: languageLocal,
		};

		console.log("body cancel subscription: ", body);

		SubscriptionService.cancelSubscription(body)
			.then(
				(data: any) => {
					console.log("data response cancel subscription: ", data);

					// dispatch(setCurrentSubscription({ ...new Subscription() }));
					dispatch(setCurrentSubscription(null));

					handleClose();
					dispatch(setCurrentMenu({ title: "", code: 0 })); // close first when you want to open #DisplayBox on another #DisplayBox

					setTimeout(function () {
						dispatch(
							setCurrentMenu({
								title: "List subscription type",
								code: 5,
							})
							// setCurrentMenu({ title: "List subscription type", code: 5 })
						);
					}, 2);

					enqueueSnackbar("Your subscription has been cancelled", {
						variant: "success",
						anchorOrigin: { vertical: "bottom", horizontal: "right" },
					});

					if (
						data.code === EnumStatusCode.SUCCESS &&
						data.statusCode === EnumStatusCode.SUCCESS
					) {
						/* dispatch(setCurrentSubscription({ ...new Subscription() }));
            //  dispatch(setCurrentSubscription(null));
            handleClose();
            dispatch(setCurrentMenu({ title: "", code: 0 })); // close first when you want to open #DisplayBox on another #DisplayBox

            setTimeout(function () {
              dispatch(
                setCurrentMenu({ title: "List subscription type", code: 5 })
              );
            }, 2);

            enqueueSnackbar("Your subscription has been cancelled", {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
            }); */
					}
				},
				(error) => {
					console.log("Error: ", error);
					if (error?.response?.data?.code === EnumStatusResponse.FAILURE) {
						const code = error?.response?.data?.code;

						switch (error?.response?.data?.statusCode) {
							  case EnumStatusCode.SUBSCRIPTION_NOT_FOUND:
							   /*  enqueueSnackbar("Oupps!! Abonnement introuvable", {
							      variant: "info",
							      anchorOrigin: { vertical: "bottom", horizontal: "right" },
							    }); */
							    break;
							case EnumStatusCode.UNKNOWN_ERROR:
								enqueueSnackbar("Oupps!! Une erreur est survenue", {
									variant: "error",
									anchorOrigin: { vertical: "bottom", horizontal: "right" },
								});
								break;
							default:
								enqueueSnackbar("Oupps!! Une erreur est survenue", {
									variant: "error",
									anchorOrigin: { vertical: "bottom", horizontal: "right" },
								});
						}
					}
				}
			)
			.finally(() => {
				console.log("Completed request!");
				setIsLoading(false);
			});
	};

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={isOpen}
				onClose={handleClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={isOpen}>
					<Box
						sx={style}
						style={{
							backgroundColor:
								mode === "dark"
									? theme.palette.leftbar.dark
									: theme.palette.leftbar.light,
							color:
								mode === "dark"
									? theme.palette.colortext.dark
									: theme.palette.colortext.light,
						}}
					>
						<Typography
							id="transition-modal-title"
							variant="h6"
							component="h2"
						>
							Annuler votre abonnement
						</Typography>
						<FormControl sx={{ mt: 1.5 }}>
							<FormLabel
								id="demo-radio-buttons-group-label"
								style={{
									color:
										mode === "dark"
											? theme.palette.colortext.dark
											: theme.palette.colortext.light,
								}}
							>
								Sélectionner la raison de l'annulation
							</FormLabel>
							<RadioGroup
								aria-labelledby="demo-radio-buttons-group-label"
								defaultValue="female"
								name="radio-buttons-group"
								onChange={handleChange}
							>
								{isCancelReasons.map(
									(reason: Record<string, any>, index: number) => (
										<FormControlLabel
											key={index}
											value={reason.code}
											control={<Radio />}
											label={reason.message}
										/>
									)
								)}
							</RadioGroup>
						</FormControl>
						<div style={{ marginTop: "8px" }}>
							<TextField
								id="outlined-multiline-flexible"
								value={isMessage}
								label="Entrez votre message"
								multiline
								maxRows={2}
								// size="small"
								style={{
									width: "100%",
									backgroundColor:
										mode === "dark"
											? theme.palette.backgroundchat.dark
											: "white",

									borderRadius: "8px",
									color:
										mode === "dark"
											? theme.palette.colortext.dark
											: theme.palette.colortext.light,
								}}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									setIsMessage(event.target.value);
								}}
							/>
						</div>
						<div style={{ marginTop: "10px" }}>
							<LoadingButton
								disableElevation
								loading={isLoading}
								className="btn"
								endIcon={<> </>}
								loadingPosition="end"
								variant="outlined"
								startIcon={<> </>}
								style={{
									textTransform: "none",
									//   marginLeft: "15px",
								}}
								onClick={(e) => cancelSubscription(e)}
							>
								{isLoading
									? formatMessage({ id: "in_progress" })
									: "Annuler mon abonnement"}
							</LoadingButton>
						</div>
					</Box>
				</Fade>
			</Modal>
		</div>
	);
}

BuildCancelModalSubscription.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	subscription: PropTypes.object.isRequired,
	onHandleOpen: PropTypes.func.isRequired,
	onHandleClose: PropTypes.func.isRequired,
	theme: PropTypes.object,
};

export default BuildCancelModalSubscription;
