import axios from "axios";
import cookieService from "../../../cookieService";
import KEYS from "../../../helpers/KEYS";
import { EditUserProfileInputDto } from "../dtos/inputs/editProfile";
import { ResendCodeInputDto } from "../dtos/inputs/resendCodeInput";
import { CodeValidationInputDto } from "../dtos/inputs/codeValidation";

const apiAuth = axios.create({ baseURL: KEYS.BACKEND_HOST_USER });
const authHeader = () => {
  const token = cookieService.getToken();
  if (token) {
    // for Node.js Express back-end
    return {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };
  } else {
    return {};
  }
};

const editUser = (body: EditUserProfileInputDto) => {
  const token = cookieService.getToken();
  if (token) {
    apiAuth.defaults.headers.common = { Authorization: `Bearer ${token}` };
  }
  return apiAuth({
    data: body,
    withCredentials: false,
    url: "/update-user-profil",
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const phoneValidationCode = (body: CodeValidationInputDto) => {
  const url: string = KEYS.BACKEND_HOST_USER + "/phone-validation";
  const headers = authHeader();
  console.log(body);
  return axios.post(url, { ...body }, { headers: headers });
};

const emailValidationCode = (body: CodeValidationInputDto) => {
  const url: string = KEYS.BACKEND_HOST_USER + "/email-validation";
  const headers = authHeader();
  console.log(body);
  return axios.post(url, { ...body }, { headers: headers });
};

const updatePhotoUser = (body: any) => {
  const token = cookieService.getToken();
  if (token) {
    apiAuth.defaults.headers.common = { Authorization: `Bearer ${token}` };
  }
  return apiAuth({
    data: body,
    withCredentials: false,
    url: "/set-user-photo",
    method: "PUT",
    headers: {
      "Content-Type": "multipart/form-data",
      // "Content-Type": "application/json",
    },
  });
};

const UserService = {
  editUser,
  phoneValidationCode,
  emailValidationCode,
  updatePhotoUser,
};
export default UserService;
