import { Box, Typography, List } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Lottie, { Options } from "react-lottie";
import { useSelector } from "react-redux";
import { User } from "../../../interfaces/user.interface";
import { selectUser } from "../../../store/slices/userSlice";
import { EnumStatusResponse } from "../../authentification/enums/auth.enums";
import { EnumStatusCode } from "../enums/subscription.enums";
import { SubscriptionType } from "../interfaces/subscription.interface";
import SubscriptionService from "../services/subscription-service";
import studaLoad from "../../../assets/lotties/load.json";
import studaNotFoundDark from "../../../assets/lotties/not-found-black.json";
import studaNotFoundLight from "../../../assets/lotties/not-found-white.json";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import "./css/subscriptionType.scss";
import SubscriptionTypeItem from "./SubscriptionTypeItem";
import { selectCurrentMode } from "../../../store/slices/modeSlice";

function SubscriptionTypeList({ theme }: any) {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { enqueueSnackbar } = useSnackbar();
  const user: User = useSelector(selectUser);
  const [dense, setDense] = React.useState(false);
  const [subscriptionAdvantages, setSubscriptionAdvantages] = React.useState<
    Array<{ advantage: string; isFree: boolean }>
  >([]);
  const [subscription, setSubscription] = React.useState<
    Array<SubscriptionType>
  >([]);
  const [classNameLabel, setclassNameLabel] = React.useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getUserClassesFullName();
    getAllSubscriptions();
  }, []);

  const getUserClassesFullName = () => {
    SubscriptionService.getUserClassesFullname()
      .then((data: any) => {
        let result = JSON.parse(JSON.stringify(data.data)) as Array<{
          name: string;
          code: string;
        }>;
        let currentClassName = result.find(
          (name) => name.code === user.currentClass
        );
        if (currentClassName) {
          const classFullName = currentClassName.name;
          setclassNameLabel(classFullName);
        }
      })
      .catch((error: any) => {
        console.log("Cold not get Current className !!!", error);
      });
  };

  const getAllSubscriptions = () => {
    setIsLoading(true);
    if (!window.navigator.onLine) {
      enqueueSnackbar(formatMessage({ id: "no_internet_connection" }), {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
      return;
    }
    SubscriptionService.getAllSubscriptions()
      .then(
        (data: any) => {
          if (
            data.code === EnumStatusCode.SUCCESS &&
            data.statusCode === EnumStatusCode.SUCCESS
          ) {
            let subscriptionList: Array<SubscriptionType> = data.data
              .subscriptionTypes as Array<SubscriptionType>;
            let subscriptionAdvantages = data.data
              .subscriptionAdvantages as Array<{
              advantage: string;
              isFree: boolean;
            }>;

            if (subscriptionAdvantages.length > 0) {
              setSubscriptionAdvantages(subscriptionAdvantages);
              console.log("subscriptionAdvantages:: ", subscriptionAdvantages);
            }

            if (subscriptionList.length > 0) {
              setSubscription(subscriptionList);
            }
          }
        },
        (error) => {
          console.log("Error: ", error);
          if (error?.response?.data?.code === EnumStatusResponse.FAILURE) {
            switch (error?.response?.data?.statusCode) {
              case EnumStatusCode.UNKNOWN_ERROR:
                enqueueSnackbar(
                  formatMessage({ id: "an_error_has_occurred" }),
                  {
                    variant: "error",
                    anchorOrigin: { vertical: "bottom", horizontal: "right" },
                  }
                );
                break;
            }
          }
        }
      )
      .finally(() => {
        console.log("Completed request!");
        setIsLoading(false);
      });
  };

  const loadOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: studaLoad,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const notFoundOptionsDark: Options = {
    loop: true,
    autoplay: true,
    animationData: studaNotFoundDark,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const notFoundOptionsLight: Options = {
    loop: true,
    autoplay: true,
    animationData: studaNotFoundLight,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const mode = useSelector(selectCurrentMode);
  return !isLoading && subscription.length > 0 ? (
    <Box
      sx={{ width: "100%", paddingLeft: 1, paddingRight: 1 }}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "100%" }}>
        <Typography
          variant="h6"
          component="h2"
          sx={{
            paddingLeft: 0,

            paddingTop: 1,
            fontWeight: "bold",
          }}
        >
          {classNameLabel}
        </Typography>

        <table
          className={mode === "dark" ? "styled-table-dark" : "styled-table"}
          style={{ width: "100%" }}
        >
          <thead>
            <tr>
              <th></th>
              <th>{formatMessage({ id: "free" })}</th>
              <th>{formatMessage({ id: "prenium" })}</th>
            </tr>
          </thead>
          <tbody>
            {subscriptionAdvantages.map((advantage, index) => (
              <tr key={index}>
                <td>
                  <Typography
                    key={index}
                    component="h2"
                    sx={{
                      paddingLeft: 1,
                      paddingTop: 1,
                      display: "inline",
                      fontSize: "14px",
                    }}
                  >
                    <span style={{ paddingBottom: 1 }}>
                      {advantage.advantage}
                      <br />
                    </span>
                  </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                  {advantage.isFree && (
                    <CheckIcon sx={{ color: "#057554", fontSize: "medium" }} />
                  )}
                  {!advantage.isFree && (
                    <CancelIcon sx={{ color: "#fc0317", fontSize: "medium" }} />
                  )}
                </td>
                <td style={{ textAlign: "center" }}>
                  <CheckIcon sx={{ color: "#057554", fontSize: "medium" }} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <List dense={dense} sx={{ width: "100%" }}>
        {subscription.map((subscriptionType, index) => (
          <SubscriptionTypeItem
            key={index}
            index={index}
            subscriptionType={subscriptionType}
            theme={theme}
          />
        ))}
      </List>
    </Box>
  ) : !isLoading && subscription.length === 0 ? (
    <div
      className="title title-bold"
      style={{
        textAlign: "center",
        paddingTop: "20px",
      }}
    >
      {mode === "dark" ? (
        <Lottie
          options={notFoundOptionsDark}
          height={100}
          width={100}
          style={{ paddingBottom: "15px" }}
        />
      ) : (
        <Lottie
          options={notFoundOptionsLight}
          height={100}
          width={100}
          style={{ paddingBottom: "15px" }}
        />
      )}

      <div
        className="title"
        style={{
          color:
            mode === "dark"
              ? theme.palette.colortext.dark
              : theme.palette.colortext.light,
        }}
      >
        {formatMessage({ id: "no_packages_available" })}
      </div>
    </div>
  ) : (
    <Lottie options={loadOptions} height={80} width={80} />
  );
}

export default SubscriptionTypeList;
