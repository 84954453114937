import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
// import img from "../../image/img.png";
// import img2 from "../../image/img2.png";
// import img3 from "../../image/img3.png";
// import img4 from "../../image/img4.png";
// import img5 from "../../image/img5.png";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export interface Image {
  label: string;
  imgPath: string;
}

/* const images: Image[] = [
  {
    label: "San Francisco – Oakland Bay Bridge, United States",
    imgPath: img,
  },
  {
    label: "Bird",
    imgPath: img2,
  },
  {
    label: "Bali, Indonesia",
    imgPath: img3,
  },
  {
    label: "Goč, Serbia",
    imgPath: img4,
  },
  {
    label: "Goč, Serbia 2",
    imgPath: img5,
  },
]; */

type Props = {
  images: Image[];
  //
  maxWidthBox: number;
  maxWidthImg: number;
  maxHeightImg: number;
  //
  isRounded?: boolean;
};

function SwipeableTextMobileStepper({
  images,
  maxWidthBox,
  maxWidthImg,
  maxHeightImg,
  isRounded,
}: Props) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box
      sx={{
        // maxWidth: 300,
        height: "100%",
        maxWidth: maxWidthBox,
        borderRadius: isRounded ? "8px" : "none",
      }}
    >
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  height: "100%",
                  // height: 255,
                  // maxWidth: 400,
                  // maxHeight: 600,
                  maxWidth: maxWidthImg,
                  maxHeight: maxHeightImg,
                  borderRadius: isRounded ? "8px" : "none",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={<></>}
        backButton={<></>}
        sx={{ background: "transparent" }}
      />
    </Box>
  );
}

export default SwipeableTextMobileStepper;
