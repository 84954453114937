import React from "react";
import "./leftDrawer.scss";
import ConversationList from "../modules/conversation/pages/ConversationList";
import SubjectList from "../modules/conversation/pages/SubjectList";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCurrentMode } from "../store/slices/modeSlice";

function LeftDrawer({ pathMenu, theme }: any) {
  const renderLeftDrawer = (pathMenu: string) => {
    switch (pathMenu) {
      case "conversation":
        return <ConversationList theme={theme} />;
      case "subject":
        return <SubjectList />;
      default:
        return <SubjectList />;
    }
  };
  const mode = useSelector(selectCurrentMode);
  return (
    <div
      className="left-drawer"
      style={{
        backgroundColor:
          mode === "dark"
            ? theme.palette.leftbar.dark
            : theme.palette.leftbar.light,
      }}
    >
      <Box>{renderLeftDrawer(pathMenu)}</Box>
    </div>
  );
}

export default LeftDrawer;
