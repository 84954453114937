import { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import AuthModule from "./modules/authentification/AuthModule";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { orange } from "@mui/material/colors";
import StudaLayout from "./layout/StudaLayout";
import { useSelector } from "react-redux";
import { selectUser } from "./store/slices/userSlice";
import AxiosInterceptorsSetup from "./helpers/interceptorsRequest";
import { getMessaging, onMessage } from "firebase/messaging";
import { useSnackbar } from "notistack";
import Terms from "./modules/conversation/pages/Terms";
import Policy from "./modules/conversation/pages/Policy";
import Help from "./modules/conversation/pages/Help";
import { useIntl } from "react-intl";
import DemandDeleteAccount from "./modules/authentification/pages/demand-delete-account";
import HomePage from "./modules/home/home";

function App() {
  const intl = useIntl();
  const { formatMessage } = intl;
  let colors = {
    cblue: ["#3f51b5", "#5c6bc0", "#303F9F"],
    cblack: ["#495057", "#343A40", "#6C757D", "#ADB5BD","#161b22","#0d1116"],
    cgreen: ["#E0FBFC"],
    cred: ["#f08080"],
    cwhite: ["#ffffff", "#e9ecef", "#f2f2f2"],
  };
  const [mode, setMode] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = createTheme({
    palette: {
      mode: mode ? "dark" : "light",
      primary: {
        main: colors.cblue[0],
        light: colors.cblue[0],
        dark: colors.cblue[0],
      },
      secondary: {
        main: orange[500],
      },
      text: {
        primary: "#41525d",
      },
      appbar: {
        main: "rgba(240,242,245,0.8)",
        light: "rgba(240,242,245,0.8)",
        dark: colors.cblack[4],
      },
      iconcolor: {
        main: "#41525d",
        light: "#41525d",
        dark: "#e1dede",
      },
      colortext: {
        main: "#41525d",
        light: colors.cblack[1],
        dark: colors.cwhite[1],
      },
      secondarytext: {
        main: "#343A40",
        light: colors.cblack[4],
        dark: colors.cblack[3],
      },
      leftbar: {
        main: "rgba(240,242,245,0.8)",
        light: colors.cwhite[0],
        dark: colors.cblack[4],
      },
      contentbox: {
        main: "rgba(240,242,245,0.8)",
        light: colors.cwhite[1],
        dark: colors.cblack[5],
      },
      labelinput: {
        main: "#41525d",
        light: "#333", //"#2e2e2e",
        dark: "#e1dede",
      },
      outlinedinput: {
        main: "#41525d",
        light: "rgba(0, 0, 0, 0.23)", //"#2e2e2e",
        dark: "#ccc",
      },
      backgroundchat: {
        main: "rgba(240,242,245,0.8)",
        light: colors.cwhite[0],
        dark: colors.cblack[4],
      },
      chatResponse: {
        main: colors.cblue[1],
        light: colors.cblue[2],
        dark: colors.cblue[2],
      },
      backgroundPositive: {
        main: colors.cgreen[0],
        light: colors.cgreen[0],
        dark: colors.cgreen[0],
      },
      textchat: {
        main: "#41525d",
        light: "rgba(0, 0, 0, 0.6)",
        dark: "#e1dede",
      },
      faildchat: {
        main: colors.cred[0],
        light: colors.cred[0],
        dark: colors.cred[0],
      },
      cardContent: {
        main: "#41525d",
        light: "#f0f0f2",
        dark: "#1f2123",
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: orange[500]
            
          },
        },
      },
      MuiAppBar: {
        defaultProps: {
          sx: {
            height: 60,
            zIndex: 3,
          },
        },
      },
    },
  });
  const redirectUrl = window.location.pathname + "" + window.location.search;
  useEffect(() => {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      enqueueSnackbar(payload?.data?.title, {
        variant: "info",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
    });
  }, []);

  const user = useSelector(selectUser);
  const isConnected =
    user != null && user.currentClass != undefined && user.currentClass != ""
      ? true
      : false;

  function AxiosInterceptorNavigate() {
    AxiosInterceptorsSetup();
    return <></>;
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {<AxiosInterceptorNavigate />}
        <Routes>
          {/*           <Route
            path="/"
            element={
              <Navigate
                to={{
                  pathname: "/home",
                }}
                replace
              />
            }
          /> */}

          <Route
            path="/"
            element={<HomePage /* theme={theme} */ />}
            // element={<HomeCopy /* theme={theme} */ />}
          />
          <Route
            path="/dashboard/*"
            element={
              isConnected ? (
                <StudaLayout theme={theme} />
              ) : (
                <Navigate
                  to={{
                    pathname: "/auth",
                    search: "?redirect_url=" + encodeURIComponent(redirectUrl),
                  }}
                  replace
                />
              ) /*  <AuthModule /> */
            }
          />
          <Route
            path="/auth/*"
            element={
              !isConnected ? (
                <AuthModule theme={theme} />
              ) : (
                <Navigate
                  to={{
                    pathname: "/dashboard",
                    search: "?redirect_url=" + encodeURIComponent(redirectUrl),
                  }}
                  replace
                />
              )
            }
          />
          <Route
            path="/delete-account"
            element={
              !isConnected ? (
                <AuthModule theme={theme} />
              ) : (
                <DemandDeleteAccount theme={theme} />
              )
            }
          />
          <Route path="/privacy-policy" element={<Policy theme={theme} />} />
          <Route path="/terms-usage" element={<Terms theme={theme} />} />
          <Route path="/help" element={<Help theme={theme} />} />
          <Route
            path="*"
            element={<>{formatMessage({ id: "page_not_found" })} </>}
          />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
