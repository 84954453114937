import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { Icon } from "@iconify/react";

export default function ControlledAccordions() {
  // const [expanded, setExpanded] = React.useState<string | false>(false);
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        elevation={0}
        disableGutters
        sx={{
          p: 2,
          backgroundColor:
            expanded === "panel1" ? "rgba(32, 101, 209, 0.16)" : "none",
        }}
      >
        <AccordionSummary
          expandIcon={
            expanded === "panel1" ? (
              <Icon icon="lets-icons:remove-light" width={35} color="#000000" />
            ) : (
              <Icon
                icon="lets-icons:add-ring-light"
                width={35}
                color="#000000"
              />
            )
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography
            variant="h5"
            component="div"
            color="#000000"
            fontWeight="semibold"
            mr={6}
            sx={{}}
          >
            01
          </Typography>

          <Typography variant="h6" component="div" color="#000000">
            What the features does the Web App have?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 11.5 }}>
          <Typography color="#000000">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        elevation={0}
        disableGutters
        sx={{
          p: 2,
          backgroundColor:
            expanded === "panel2" ? "rgba(32, 101, 209, 0.16)" : "none",
        }}
      >
        <AccordionSummary
          expandIcon={
            expanded === "panel2" ? (
              <Icon icon="lets-icons:remove-light" width={35} color="#000000" />
            ) : (
              <Icon
                icon="lets-icons:add-ring-light"
                width={35}
                color="#000000"
              />
            )
          }
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography
            variant="h5"
            component="div"
            color="#000000"
            mr={6}
            sx={{}}
          >
            02
          </Typography>

          <Typography variant="h6" component="div" color="#000000">
            Is the Web App Secure?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 11.5 }}>
          <Typography color="#000000">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        elevation={0}
        disableGutters
        sx={{
          p: 2,
          backgroundColor:
            expanded === "panel3" ? "rgba(32, 101, 209, 0.16)" : "none",
        }}
      >
        <AccordionSummary
          expandIcon={
            expanded === "panel3" ? (
              <Icon icon="lets-icons:remove-light" width={35} color="#000000" />
            ) : (
              <Icon
                icon="lets-icons:add-ring-light"
                width={35}
                color="#000000"
              />
            )
          }
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography
            variant="h5"
            component="div"
            color="#000000"
            mr={6}
            sx={{}}
          >
            03
          </Typography>

          <Typography variant="h6" component="div" color="#000000">
            How do I get the Mobile App for my phone?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 11.5 }}>
          <Typography color="#000000">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
        elevation={0}
        disableGutters
        sx={{
          p: 2,
          backgroundColor:
            expanded === "panel4" ? "rgba(32, 101, 209, 0.16)" : "none",
        }}
      >
        <AccordionSummary
          expandIcon={
            expanded === "panel4" ? (
              <Icon icon="lets-icons:remove-light" width={35} color="#000000" />
            ) : (
              <Icon
                icon="lets-icons:add-ring-light"
                width={35}
                color="#000000"
              />
            )
          }
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography
            variant="h5"
            component="div"
            color="#000000"
            mr={6}
            sx={{}}
          >
            04
          </Typography>

          <Typography variant="h6" component="div" color="#000000">
            How does Airlert differ from usual apps?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 11.5 }}>
          <Typography color="#000000">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
        elevation={0}
        disableGutters
        sx={{
          p: 2,
          backgroundColor:
            expanded === "panel5" ? "rgba(32, 101, 209, 0.16)" : "none",
        }}
      >
        <AccordionSummary
          expandIcon={
            expanded === "panel5" ? (
              <Icon icon="lets-icons:remove-light" width={35} color="#000000" />
            ) : (
              <Icon
                icon="lets-icons:add-ring-light"
                width={35}
                color="#000000"
              />
            )
          }
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography
            variant="h5"
            component="div"
            color="#000000"
            mr={6}
            sx={{}}
          >
            05
          </Typography>

          <Typography variant="h6" component="div" color="#000000">
            What features does the Web App have?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 11.5 }}>
          <Typography color="#000000">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
