import { useEffect, useState } from "react";
import { Button, Link } from "@mui/material";
import "./css/Connexion.scss";
import Lottie from "react-lottie";
import studaLogo from "../../../assets/lotties/studa.json";
import { TextsmsOutlined, Google } from "@mui/icons-material";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import {
  IResponseAuthCredentialGoogle,
  IResponseProfileAuthGoogle,
  ResponseAuthCredentialsGoogle,
  UserCredentialsFromAuthAPIDto,
  UserDataFromAuthAPIDto,
} from "../interfaces/auth.interface";
import AuthService from "../services/auth-service";
import { AuthSigninInputDto } from "../dtos/inputs/authSignIn";
import {
  EnumConnectedVia,
  EnumStatusCode,
  EnumStatusResponse,
} from "../enums/auth.enums";
import LoadingButton from "@mui/lab/LoadingButton";
import cookieService from "../../../cookieService";
import { setCon } from "../../../store/slices/connexionSlice";
import { useDispatch } from "react-redux";
import { setUser } from "../../../store/slices/userSlice";
import { User } from "../../../interfaces/user.interface";
import { useSnackbar } from "notistack";
import KEYS from "../../../helpers/KEYS";
import { getDevice } from "../../../helpers/helpers";

function Connexion({ theme }: any) {
  const intl = useIntl();
  const { formatMessage } = intl;
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: studaLogo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const responseProfileAuthGoogle: IResponseProfileAuthGoogle = {
    email: "",
    family_name: "",
    given_name: "",
    id: "",
    name: "",
    picture: "",
  };

  const [user, setUser_] = useState<IResponseAuthCredentialGoogle>(
    new ResponseAuthCredentialsGoogle()
  );
  const [profile, setProfile] = useState<IResponseProfileAuthGoogle>(
    responseProfileAuthGoogle
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const doLoginGoogle = async (
    payload: IResponseProfileAuthGoogle,
    credential: UserCredentialsFromAuthAPIDto,
    idToken: string
  ) => {
    if (!window.navigator.onLine) {
      enqueueSnackbar(formatMessage({ id: "no_internet_connection" }), {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
      return;
    }

    let userDataFromApiGoogle: UserDataFromAuthAPIDto = {
      id: payload.id,
      email: payload.email,
      status: true,
      firstName: payload.given_name as string,
      lastName: payload.family_name as string,
      picture: payload.picture as string,
    };

    let credentials: UserCredentialsFromAuthAPIDto = {
      accessToken: idToken,
      tokenType: credential.tokenType,
      expiresIn: credential.expiresIn,
    };

    let device;
    await getDevice().then((res) => {
      if (res) {
        device = res;

        let body: AuthSigninInputDto = new AuthSigninInputDto();

        body.service = EnumConnectedVia.GOOGLE;
        body.phone = "";
        body.data = userDataFromApiGoogle;
        body.device = device;
        body.credentials = credentials;

        AuthService.login(body)
          .then(
            (data: any) => {
              let user: User | null = null;
              console.log("user:: ", data);

              if (
                data.code === EnumStatusCode.SUCCESS &&
                data.statusCode === EnumStatusCode.LOGIN_SUCCESS
              ) {
                cookieService.saveToken(data.data.accessToken.token);
                cookieService.saveRefreshToken(data.data.refreshToken.token);
                user = data.data.payload;
                dispatch(setUser(user));
                dispatch(setCon(true));

                navigate("/dashboard");

                enqueueSnackbar(formatMessage({ id: "success_connexion" }), {
                  variant: "success",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
              }
            },
            (error) => {
              console.log("Error: ", error);
              if (error?.response?.data?.code === EnumStatusResponse.FAILURE) {
                switch (error?.response?.data?.statusCode) {
                  case EnumStatusCode.GOOGLE_TOKEN_ERROR:
                    enqueueSnackbar(
                      formatMessage({ id: "an_error_has_occurred" }),
                      {
                        variant: "error",
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "right",
                        },
                      }
                    );
                    break;
                  case EnumStatusCode.FACEBOOK_GOOGLE_USER_DATA_NOT_PROVIDED:
                    enqueueSnackbar(
                      formatMessage({ id: "an_error_has_occurred" }),
                      {
                        variant: "error",
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "right",
                        },
                      }
                    );
                    break;

                  case EnumStatusCode.SERVICE_NOT_PROVIDED:
                    enqueueSnackbar(
                      formatMessage({ id: "an_error_has_occurred" }),
                      {
                        variant: "error",
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "right",
                        },
                      }
                    );
                    break;

                  case EnumStatusCode.PHONE_NOT_PROVIDED:
                    enqueueSnackbar(
                      formatMessage({ id: "an_error_has_occurred" }),
                      {
                        variant: "error",
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "right",
                        },
                      }
                    );
                    break;

                  case EnumStatusCode.CREATE_USER_ERROR:
                    enqueueSnackbar(
                      formatMessage({ id: "failed_to_create_account" }),
                      {
                        variant: "error",
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "right",
                        },
                      }
                    );
                    break;

                  case EnumStatusCode.INVALID_SOCIAL_TYPE:
                    enqueueSnackbar(
                      formatMessage({ id: "an_error_has_occurred" }),
                      {
                        variant: "error",
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "right",
                        },
                      }
                    );
                    break;

                  case EnumStatusCode.UNKNOWN_ERROR:
                    enqueueSnackbar(
                      formatMessage({ id: "an_error_has_occurred" }),
                      {
                        variant: "error",
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "right",
                        },
                      }
                    );
                    break;
                  default:
                    enqueueSnackbar(
                      formatMessage({ id: "an_error_has_occurred" }),
                      {
                        variant: "error",
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "right",
                        },
                      }
                    );
                }
              }
            }
          )
          .finally(() => {
            console.log("Completed request!");
            setIsLoading(false);
          });
      }
    });
  };

  useEffect(() => {
    console.log("clientId: ", KEYS.GOOGlE_OAUTH2_CLIENT_ID);
    function start() {
      gapi.client.init({
        clientId: KEYS.GOOGlE_OAUTH2_CLIENT_ID,
        scope: "profile email",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  const onSuccess = (response: any) => {
    setIsLoading(true);
    console.log("[Login success]: ", response);
    console.log("[Login success] currentUser: ", response.profileObj);
    console.log("[Login success] currentUser: ", response.tokenObj);

    let resProfile = response.profileObj;
    let resCredentials = response.tokenObj;

    profile.email = resProfile["email"];
    profile.family_name = resProfile["familyName"];
    profile.given_name = resProfile["givenName"];
    profile.id = resProfile["googleId"];
    profile.name = resProfile["name"];
    profile.picture = resProfile["imageUrl"];

    let credential: UserCredentialsFromAuthAPIDto = {
      accessToken: resCredentials["access_token"],
      tokenType: resCredentials["token_type"],
      expiresIn: resCredentials["expires_in"],
    };

    doLoginGoogle(profile, credential, resCredentials["id_token"]);
  };

  const onFailure = (response: any) => {
    console.log("[Login failed] res: ", response);
    setIsLoading(false);
  };

  return (
    <div className="connexion-box">
      <div className="wrapper">
        <div className="container">
          <div className="logo">
            <Lottie
              options={defaultOptions}
              height={100}
              width={100}
              style={{ paddingBottom: "15px" }}
            />
            <div className="title">
              {formatMessage({ id: "welcome" })}
              <span style={{ fontWeight: "bold" }}>Studa</span>{" "}
            </div>
          </div>
          <div className="">
            <div className="subtitle">
              {formatMessage({ id: "studying_pleasure_right_tools" })}
            </div>
            <div className="subtitle">
              {formatMessage({ id: "log_enjoy_it" })}
            </div>
            <div className="btn-connexion" style={{ marginTop: "20px" }}>
              <Button
                className="btn"
                variant="contained"
                startIcon={<TextsmsOutlined />}
                disableElevation
                style={{ textTransform: "none" }}
                onClick={() => {
                  navigate("/auth/connexion-sms");
                }}
              >
                {formatMessage({ id: "continue_with_sms" })}
              </Button>

              <GoogleLogin
                clientId="493964662446-24d3g150mriicr2j0ghg3alr9t0vgnph.apps.googleusercontent.com"
                buttonText={formatMessage({ id: "continue_with_google" })}
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={"single_host_origin"}
                render={(renderProps) => (
                  <LoadingButton
                    disableElevation
                    loading={isLoading}
                    className="btn"
                    endIcon={<> </>}
                    loadingPosition="end"
                    variant="contained"
                    startIcon={<Google />}
                    style={{
                      textTransform: "none",
                      marginLeft: "15px",
                    }}
                    onClick={renderProps.onClick}
                  >
                    {isLoading
                      ? formatMessage({ id: "operation_in_progress" })
                      : formatMessage({ id: "continue_with_google" })}
                  </LoadingButton>
                )}
              ></GoogleLogin>
              <br />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  width: "410px",
                  marginTop: "22px",
                  textAlign: "center",
                  fontSize: "14px",
                }}
                dangerouslySetInnerHTML={{__html: formatMessage({ id: "agree_terms_and_policy" })}}
              >
                
              </div>
            </div>

            <div style={{flexDirection:"row", display: "flex", alignItems:"center", justifyContent:"center", marginTop: "50px"}}>
              <Link
                  component="button"
                  variant="body2"
                  
                  onClick={() => {
                    navigate("/");
                  }}
                  style={{ fontWeight: 600 }}
                >
                  {formatMessage({ id: "return_to_home" })}
                </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Connexion;

