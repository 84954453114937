import { createSlice } from "@reduxjs/toolkit";
import localStorageService from "../../localStorageService";
//import KEYS from '../../utils/key';

const initialState = localStorageService.getUserLocal();

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      localStorageService.saveUserLocal(action.payload);
      return action.payload;
    },

    clearUser: (state) => {
      localStorageService.removeUserLocal();
      return null;
    },

    /*setSelectedCountry: (state, action) => {
        // state.selectedCountry = action.payload;
        state = { ...state, selectedCountry: action.payload };
        localStorage.setItem(KEYS.CURRENT_COUNTRY, JSON.stringify(state));
        return state;
      }*/
  },
});

export const { setUser, clearUser } = userSlice.actions;

export const selectUser = (state: any) => state.user;

/*export const selectedCountry = (state) => (state.user != null ? state.user.selectedCountry : null);*/

export const userReducer = userSlice.reducer;
