import { Box, Stack, Button, Typography, List, Divider } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Lottie, { Options } from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import { User } from "../../../interfaces/user.interface";
import { selectUser } from "../../../store/slices/userSlice";
import {
  EnumStatusCode,
  EnumStatusResponse,
} from "../../authentification/enums/auth.enums";
import {
  Conversation,
  LastEvaluatedKey,
  Topic,
  TopicPathRequest,
} from "../interfaces/chat.interface";
import ChatService from "../services/chat-service";
import SubjectItem from "./SubjectItem";
import studaLoad from "../../../assets/lotties/load.json";
import { useNavigate } from "react-router-dom";
import { EnumActionConversation } from "../enums/chat.enums";
import { setCurrentConversation } from "../../../store/slices/conversationSlice";
import {
  getAllSubjectStore,
  saveAllSubject,
} from "../../../store/slices/subjectSlice";
import { setCurrentSubscriptionType } from "../../../store/slices/subscriptionTypeSlice";
import ConfirmRestart from "../components/ConfirmRestart";
import { setCurrentMenu } from "../../../store/slices/menuSlice";
import { setCurrentBackTo } from "../../../store/slices/backToSlice";
import { EnumBackTo } from "../../subscription/enums/subscription.enums";
import { selectCurrentMode } from "../../../store/slices/modeSlice";

function SubjectList({ theme }: any) {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { formatMessage } = intl;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const user: User = useSelector(selectUser);
  const storeSubjects: Array<Topic> = useSelector(getAllSubjectStore);
  console.log("Subjects: ", user.currentClass);
  console.log("getAllSubjectStore: ", storeSubjects);
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(true);
  const [subjects, setSubjects] = React.useState<Array<Topic>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<number>(-1);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedSubjet, setSelectedSubject] = useState<Topic>();
  useEffect(() => {
    // getAllSubject();
    console.log("Mise à jour de la store", storeSubjects);
    if (storeSubjects.length > 0) {
      setSubjects(storeSubjects);
    } else {
      getAllSubject();
    }
  }, [user]);

  /* useEffect(() => {
    // getAllSubject();
    console.log("Mise à jour de la store", storeSubjects);
    if (storeSubjects.length > 0) {
      setSubjects(storeSubjects);
    } else {
      getAllSubject();
    }
  }, []); */

  const handleConversation = (
    subject: Topic,
    action: EnumActionConversation
  ) => {
    dispatch(setCurrentSubscriptionType({})); // Reset value subscriptionTypeSlice
    dispatch(setCurrentConversation({ subject, action }));
  };

  const getAllSubject = () => {
    setIsLoading(true);
    if (!window.navigator.onLine) {
      // setMessage({ content: formatMessage({ id: "no_internet_connection" }) });
      enqueueSnackbar(formatMessage({ id: "no_internet_connection" }), {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
      return;
    }

    let body: TopicPathRequest = new TopicPathRequest();
    body.path = user.currentClass;

    ChatService.getAllSubject(body)
      .then(
        (data: any) => {
          console.log("data response: ", data);
          if (
            data.code === EnumStatusCode.SUCCESS &&
            data.statusCode === EnumStatusCode.SUCCESS
          ) {
            let subjectList: Array<Topic> = data.data.items as Array<Topic>;
            console.log("setSubjects: ", subjectList);
            if (subjectList.length > 0) {
              // setSubjects(subjectList);
              let storeSubjectList: Array<Topic> = dispatch(
                saveAllSubject(subjectList)
              ).payload;
              // console.log("Store subjects: ", storeSubjectList);
              setSubjects(storeSubjectList);
            }
          }
        },
        (error) => {
          console.log("Error: ", error);
          if (error?.response?.data?.code === EnumStatusResponse.FAILURE) {
            switch (error?.response?.data?.statusCode) {
              case EnumStatusCode.PATH_VALIDATION_FAILED:
                enqueueSnackbar("Oupps!! Une erreur est survenue", {
                  variant: "error",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
                break;
              case EnumStatusCode.COUNTRY_NOT_FOUND:
                enqueueSnackbar("Oupps!! Une erreur est survenue", {
                  variant: "error",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
                break;

              case EnumStatusCode.SUBJECT_NOT_FOUND:
                enqueueSnackbar("Oupps!! Une erreur est survenue", {
                  variant: "error",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
                break;
              default:
                enqueueSnackbar("Oupps!! Une erreur est survenue", {
                  variant: "error",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
            }
          }
        }
      )
      .finally(() => {
        console.log("Completed request!");
        setIsLoading(false);
      });
  };

  const selectedIndex = (index: number) => {
    setSelectedItem(index);
  };

  const showConfirm = (subject: Topic, action: EnumActionConversation) => {
    setSelectedSubject(subject);
    setOpenDialog(true);
  };
  const closeDialog = () => {
    setOpenDialog(false);
  };

  const goToTopicList = (subject: Topic) => {
    dispatch(setCurrentMenu({ title: "", code: 0 })); // init #currentMenu #DisplayBox

    setTimeout(() => {
      dispatch(
        setCurrentMenu({
          title: "Theme",
          code: 2,
          subject,
        })
      );
    });
    dispatch(setCurrentBackTo({ backTo: EnumBackTo.SUBJECTS }));
  };

  const loadOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: studaLoad,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const mode = useSelector(selectCurrentMode);
  return !isLoading && subjects.length > 0 ? (
    <div>
      <Box sx={{ width: "100%" }}>
        <div>
          <List
            dense
            sx={{
              width: "100%",
              position: "relative",
            }}
          >
            {
              /* subjects */ storeSubjects.map(
                (subject: Topic, index: number) => (
                  <div
                    key={index}
                    style={{
                      backgroundColor:
                        selectedItem === index
                          ? mode === "dark"
                            ? theme.palette.appbar.dark
                            : "rgb(238, 238, 238)"
                          : "",
                    }}
                  >
                    <SubjectItem
                      index={index}
                      subject={subject}
                      theme={theme}
                      onStartConversation={() =>
                        handleConversation(
                          subject,
                          EnumActionConversation.START_CONVERSATION
                        )
                      }
                      onContinueConversation={() =>
                        handleConversation(
                          subject,
                          EnumActionConversation.CONTINUE_CONVERSATION
                        )
                      }
                      onRestartConversation={() =>
                        /*handleConversation(
												subject,
												EnumActionConversation.RESTART_CONVERSATION
											)*/
                        showConfirm(
                          subject,
                          EnumActionConversation.RESTART_CONVERSATION
                        )
                      }
                      onBrowseConversation={() =>
                        handleConversation(
                          subject,
                          EnumActionConversation.BROWSE_CONVERSATION
                        )
                      }
                      onSelectedIndex={() => {
                        selectedIndex(index);
                      }}
                      onSelectedMenu={() => {
                        goToTopicList(subject);
                      }}
                    />
                  </div>
                )
              )
            }
          </List>
        </div>
      </Box>
      <ConfirmRestart
        open={openDialog}
        handleClose={() => closeDialog()}
        confirm={() => {
          handleConversation(
            selectedSubjet!,
            EnumActionConversation.RESTART_CONVERSATION
          );
        }}
      />
    </div>
  ) : !isLoading && subjects.length === 0 ? (
    <div
      className="title title-bold"
      style={{
        //marginTop: "10rem",
        textAlign: "center",
        fontSize: "22px",
      }}
    >
      Aucune matières
    </div>
  ) : (
    <Lottie
      options={loadOptions}
      height={80}
      width={80}
      style={
        {
          //marginTop: "8rem"
          /* paddingBottom: "55px" */
        }
      }
    />
  );
}

export default SubjectList;
