import React from "react";
import { useSelector } from "react-redux";
import { selectCurrentMode } from "../../../store/slices/modeSlice";
import "./css/apropos.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, CssBaseline, Drawer, Toolbar, Fab } from "@mui/material";
import AppBarDrawer from "../components/AppBarDrawer";
import _Drawer from "../components/_Drawer";
import _ScrollTop from "../components/_ScrollTop";
import { selectLanguageLocal } from "../../../store/slices/languageSlice";
import { EnumLocalLang } from "../components/enums/conversation.enums";

const drawerWidth = 240;

function Help(props: any) {
  const mode = useSelector(selectCurrentMode);
  const languageLocal: EnumLocalLang = useSelector(selectLanguageLocal);
  const theme = props.theme;

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <React.Fragment>
      <Box
        sx={{ display: "flex" }}
        style={{
          color:
            mode === "dark"
              ? theme.palette.colortext.dark
              : theme.palette.colortext.light,
          backgroundColor:
            mode === "dark"
              ? theme.palette.leftbar.dark
              : theme.palette.leftbar.light,
        }}
      >
        <CssBaseline />
        <AppBarDrawer
          theme={props.theme}
          onHandleDrawerToggle={handleDrawerToggle}
        />
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <_Drawer
              theme={props.theme}
              onHandleDrawerToggle={handleDrawerToggle}
            />
          </Drawer>
        </Box>
        <Box component="main" sx={{ pl: 3, pr: 3 }}>
          <Toolbar id="back-to-top-anchor" />
          <div
            className="container wrap-apropos"
            style={
              {
                /* color:
                mode === "dark"
                  ? theme.palette.colortext.dark
                  : theme.palette.colortext.light, */
              }
            }
          >
            {languageLocal === EnumLocalLang.FR ? (
              <div>
                {" "}
                <h2>Page d'aide Studa</h2>
                <p>
                  <h3>Téléchargement et installation</h3>
                </p>
                <p>
                  L'application Studa est compatible avec les versions suivantes
                  des systèmes d'exploitation :
                </p>
                <ul>
                  <li>Android version 5 et plus récente</li>
                </ul>
                <p>
                  Une fois que vous êtes sûr d'avoir au moins une des versions
                  des systèmes d'exploitation cités ci-dessus, vous pouvez
                  télécharger l'application immédiatement et commencer à
                  préparer votre examen de fin d'études.
                </p>
                <p>
                  Notez que vous ne pouvez pas créer plus d'un compte avec les
                  mêmes informations d'identification et des versions
                  différentes des systèmes d'exploitation.
                </p>
                <p>
                  <h3>
                    Que faire si mon système d'exploitation ne prend pas en
                    charge l'application ?
                  </h3>
                </p>
                <p>
                  Si vous êtes sûr de l'un des systèmes d'exploitation
                  ci-dessus, assurez-vous de vérifier la version du téléphone.
                  La version de votre système d'exploitation peut être
                  inférieure.
                </p>
                <p>
                  <h3>Comment installer l'application ?</h3>
                </p>
                <p>
                  Le principe est simple. Vous pouvez télécharger Studa en vous
                  connectant à Google Play si vous avez un Android ou à App
                  Store si vous détenez un iPhone, puis tapez "Studa" dans la
                  barre de recherche et cliquez sur rechercher. Si vous
                  rencontrez des difficultés, suivez l'un des liens ci-dessous :
                </p>
                <p>
                  <strong>Google Play:</strong>{" "}
                  <a href="https://play.google.com/store/apps/details?id=com.codezys.studa">
                    https://play.google.com/store/apps/details?id=com.codezys.studa
                  </a>
                </p>
                <p>
                  <h2>
                    Création, activation et vérification du code du compte
                  </h2>
                </p>
                <p>
                  Après avoir installé l'application, vous devez créer et
                  vérifier votre compte à l'aide d'un code de vérification
                  envoyé au numéro de téléphone que vous avez fourni.
                </p>
                <p>
                  <h3>Comment cela fonctionne-t-il ?</h3>
                </p>
                <p>
                  Il existe plusieurs façons de créer votre compte sur Studa.
                  Vous pouvez le faire avec Google, si vous avez un compte
                  Gmail, avec Facebook si vous avez un compte ou vous pouvez
                  simplement utiliser votre numéro de téléphone pour créer votre
                  compte.
                </p>
                <p>
                  Notez que l'utilisation de votre numéro de téléphone
                  nécessitera une vérification. Ne vous inquiétez pas, c'est
                  simple et direct.
                </p>
                <p>
                  <h3>Comment vérifier et saisir le code ?</h3>
                </p>
                <p>
                  Dans le message qui vous est envoyé par l'application sur
                  votre numéro de téléphone, vous trouverez un code comme le
                  suivant : 444-444. Veuillez saisir le code exactement comme
                  vous le voyez, sinon, il ne sera pas pris en compte.
                </p>
                <p>
                  <h3>
                    Comment cela fonctionne-t-il pour Facebook et Google ?
                  </h3>
                </p>
                <p>
                  Après avoir téléchargé et installé l'application, la prochaine
                  chose à faire est de l'ouvrir. En ouvrant Studa, vous verrez
                  une fenêtre pop up affichant les trois méthodes mentionnées
                  ci-dessus. Choisissez-en une et continuez.
                </p>
                <p>
                  Note : Si vous en choisissez un, mais que vous rencontrez des
                  difficultés pour créer votre compte, revenez et changez
                  immédiatement de méthode. Par exemple : si vous choisissez de
                  créer votre compte avec Google, mais que cela ne fonctionne
                  pas, n'hésitez pas à essayer avec SMS (numéro de téléphone).
                </p>
                <p>
                  <h3>
                    Que se passe-t-il si j'ai déjà installé et utilisé
                    l'application ?
                  </h3>
                </p>
                <p>
                  Si vous aviez installé Studa dans le passé et que vous
                  souhaitez utiliser les mêmes informations pour vous connecter,
                  c'est simple. Ouvrez simplement l'application et continuez à
                  apprendre là où vous vous êtes arrêté.
                </p>
                <p>
                  <h2>Profil</h2>
                </p>
                <p>
                  Les éléments clés des sections de votre profil comprennent
                  "votre abonnement", votre numéro de téléphone, votre date de
                  naissance, votre pays de résidence, votre classe et la langue
                  que vous avez choisie, qui peut être l'anglais ou le français.
                </p>
                <p>
                  <h3>Puis-je inviter mes amis ou camarades de classe ?</h3>
                </p>
                <p>
                  Oui, vous pouvez inviter tous ceux qui utilisent un smartphone
                  à réviser et à préparer leurs examens de fin d'année comme
                  vous. Il est préférable d'inviter des élèves d'autres écoles à
                  se joindre à vous pour préparer les examens de fin d'année.
                </p>
                <p>
                  <h3>
                    Comment puis-je inviter mes camarades de classe à préparer
                    notre examen ensemble ?
                  </h3>
                </p>
                <p>
                  Il suffit de cliquer sur "inviter un ami" et de partager le
                  lien qui apparaît sur vos différents médias sociaux ou
                  directement à un contact que vous connaissez déjà.
                </p>
                <p>
                  <h3>Comment publier mon avis sur Studa ?</h3>
                </p>
                <p>
                  Vous pouvez partager votre expérience de l'utilisation de
                  l'application en la postant dans la section des commentaires
                  de Google Play ou App Store. Si vous pouvez visiter la page de
                  l'application directement sur Google, il suffit d'aller dans
                  les paramètres et de chercher.
                </p>
                <p>
                  <h2>Tarification</h2>
                </p>
                <p>
                  Comme vous le remarquerez, l’appli n'est pas gratuite, mais
                  vous pouvez accéder gratuitement à certaines sections
                  limitées. Et pour obtenir un accès complet, vous devez
                  souscrire un abonnement.
                </p>
                <p>
                  <h3>Comment s'abonner à Studa ?</h3>
                </p>
                <p>
                  C'est très simple. Pour cela, il vous suffit de cliquer sur
                  "votre abonnement" qui vous dirigera sur une nouvelle page.
                  Vous y trouverez de nombreux forfaits avec chacun leur
                  avantage. Sous chaque forfait, il y a un bouton "s'abonner
                  maintenant". Une fois que vous aurez cliqué dessus, une autre
                  page s'affichera et vous pourrez choisir votre mode de
                  paiement.
                </p>
                <p>
                  <h3>Combien de forfaits Studa propose-t-il ?</h3>
                </p>
                <p>
                  Studa vous donne la possibilité de choisir l'une des formules
                  qui vous convient le mieux.
                </p>
                <p>
                  <h2>Paiements</h2>
                </p>
                <p>
                  En effet, pour pratiquer efficacement la langue de votre
                  choix, vous devez vous abonner en choisissant une formule
                  particulière. Le paiement sur Studa est supporté dans tous les
                  pays. Le paiement est très sécurisé et vous ne risquez aucune
                  fraude en ligne.
                </p>
                <p>
                  <h3>Comment effectuer un paiement?</h3>
                </p>
                <p>
                  C'est très simple et rapide. Une fois que vous avez choisi la
                  formule d'abonnement qui vous convient, cliquez simplement sur
                  "S'abonner maintenant". Une page s'affichera avec un espace
                  pour remplir les informations de votre carte de débit ou de
                  payer en utilisant OrangeMoney ou MTN MobileMoney.
                </p>
                <p>
                  <h3>Y a-t-il une période d'essai ?</h3>
                </p>
                <p>
                  Oui, Studa vous offre un nombre limité de questions que vous
                  pouvez accéder gratuitement. Cela est possible dans chaque
                  thème pour toute les matières et les classes.
                </p>
                <p>
                  <h3>Puis-je me désabonner ?</h3>
                </p>
                <p>
                  Oui, vous êtes libre de vous désabonner à tout moment, mais
                  vous ne manquerez pas grand-chose. Il y a beaucoup
                  d’opportunités que vous allez laisser passer
                </p>
                <p>
                  <h2>
                    Politique de confidentialité et conditions d’utilisation
                  </h2>
                </p>
                <p>
                  Ceci est très important. Avant de commencer à utiliser Studa,
                  assurez-vous que vous êtes d'accord avec sa politique de
                  confidentialité et ses conditions générales.
                </p>
                <p>Contacts</p>
                <p>
                  Par email :{" "}
                  <a href="mailto:info@codezys.com">info@codezys.com</a>
                </p>
                <p>
                  En visitant cette page sur notre site web:  
                   <a href="codezys.io">codezys.io</a>
                </p>
              </div>
            ) : (
              <div>
                <h2>Studa Help Centre</h2>
                <p>
                  <h3>Download and installation</h3>
                </p>

                <p>
                  The Studa app supports the following versions of operating
                  systems:
                </p>
                <ul>
                  <li>Android version 5 and newer</li>
                </ul>
                <p>
                  Once you are sure to have at least one of the above-cited
                  versions of operating systems, you can download the app
                  immediately and start preparing for your final year exam.
                </p>
                <p>
                  Note that you cannot create more than one account with same
                  credentials and different versions of the operating systems.
                </p>
                <p>
                  <h3>What if my operating system does not support the app?</h3>
                </p>
                <p>
                  If you have one of the operating systems above, make sure you
                  check the phone version. The version of your operating system
                  may be lesser.
                </p>
                <p>
                  <h3>How do I install the app?</h3>
                </p>
                <p>
                  It is simple. You can download Studa by logging to Google Play
                  if you have an Android or to App Store if you hold an iPhone,
                  then type “Studa” on the search bar and click search. If you
                  find it difficult, follow either of the links below:
                </p>
                <p>
                  <strong>Google Play:</strong>{" "}
                  <a href="https://play.google.com/store/apps/details?id=com.codezys.studa">
                    https://play.google.com/store/apps/details?id=com.codezys.studa
                  </a>
                </p>

                <p>
                  <h2>Account creation, activation and code verification</h2>
                </p>
                <p>
                  After installation of the app, you are required to create and
                  verify your account with a verification code sent to your
                  provided phone number.
                </p>
                <p>
                  <h3>How does this work?</h3>
                </p>
                <p>
                  There are many ways you can create your account on Studa. You
                  can do it with Google, if you have a Gmail account, with
                  Facebook if you have an account or you can simply use your
                  phone number to create your account.
                </p>
                <p>
                  Note that using your phone number will require verification.
                  Do not worry, this is simple and straight forward.
                </p>
                <p>
                  <h3>How do I verify and enter the code?</h3>
                </p>
                <p>
                  In the message sent to you by the app to your phone number,
                  you will find a code like the following: 444-444. Please,
                  enter the code exactly as you see with the dash (-) otherwise,
                  it will not be considered.
                </p>
                <p>
                  <h3>How does it work and Google?</h3>
                </p>
                <p>
                  After you have downloaded and installed the app, the next
                  thing you would do immediately is to open it. Upon opening
                  Studa, you will see a pop up displaying the three above
                  mentioned ways. Choose one and proceed.
                </p>
                <p>
                  Note: If you choose one, however face difficulties in setting
                  up your account, go back and switch immediately by changing
                  the method. For example: if you choose to create your account
                  with Google, but it does not work, feel free to try with SMS
                  (phone number).
                </p>
                <p>
                  <h3>What if I have previously installed and used the app?</h3>
                </p>
                <p>
                  If you had installed Studa in the past and wish to use the
                  same information to log in, it is okay, it is cool. Just open
                  the app and continue learning from where you stopped. Have
                  fun.
                </p>
                <p>
                  <h2>Profile</h2>
                </p>
                <p>
                  Key elements under your profile sections include “your
                  subscription”, your phone number, your date of birth, your
                  country of residence, your class and your chosen language
                  which could be English or French.
                </p>
                <p>
                  <h3>Can I invite my friends or classmates?</h3>
                </p>
                <p>
                  Yes, you can invite everybody that uses a smartphone to
                  practice and prepare their final year exams like you. It is
                  more preferable to invite students from other schools to join
                  you and prepare for end of year exams.
                </p>
                <p>
                  <h3>
                    How do I invite my classmates to prepare for our exam
                    together?
                  </h3>
                </p>
                <p>
                  Simply click on “invite a friend” and share the link that
                  appears on your different social media or directly to a
                  contact you already know.
                </p>
                <p>
                  <h3>How do I post my review on Studa?</h3>
                </p>
                <p>
                  You can share your experience using the app by posting on
                  Google play or App Store’s comment section. If you can visit
                  the app page directly on Google, simply go to settings and
                  search
                </p>
                <p>
                  <h2>Pricing</h2>
                </p>
                <p>
                  As you will notice, is not free, but there are limited
                  sections you can access for free. And for you to gain full
                  access, you need to buy a subscription.
                </p>
                <p>
                  <h3>How do I subscribe to Studa?</h3>
                </p>
                <p>
                  This is simple. You will do this by simply clicking on “your
                  subscription” which will direct you on a new page. You will
                  find many packages with their advantage each. Under each
                  package, there is a button, “subscribe now”. Once you click on
                  it, another page will display and you will choose your payment
                  method.
                </p>
                <p>
                  <h3>How many packages does Studa offer?</h3>
                </p>
                <p>
                  Studa gives you the opportunity to choose one of the packages
                  that suits you the most.
                </p>
                <p>
                  <h2>Payments</h2>
                </p>
                <p>
                  Clearly, for you to practise your chosen language effectively,
                  you have to subscribe by choosing a particular package.
                  Payment on Studa is supported in all country. The payment is
                  very secured and you do not risk any online fraud.
                </p>
                <p>
                  <h3>How do I perform a payment?</h3>
                </p>
                <p>
                  It is very quick and simple. Once you choose a subscription
                  package that suits you, just click on subscribe now. A page
                  will display with space to fill your card info.
                </p>
                <p>
                  <h3>Is there a trial period?</h3>
                </p>
                <p>No, Studa no offers trial</p>
                <p>
                  <h3>Can I unsubscribe?</h3>
                </p>
                <p>
                  Yes, you are free to unsubscribe anytime, but not that you are
                  going to miss a lot. There are many advantages that you are
                  going to miss and this includes:
                </p>
                <p>
                  <h2>Privacy Policy</h2>
                </p>
                <p>
                  This is very important. Before you start using Studa, make
                  sure you agree with its privacy policy and terms and
                  conditions.
                </p>
                <p>Contacts</p>
                <p>
                  By email :{" "}
                  <a href="mailto:info@codezys.com">info@codezys.com</a>
                </p>
                <p>
                  By visiting this page on our website: 
                  <a href="codezys.io">codezys.io</a>
                </p>
              </div>
            )}
          </div>
        </Box>

        <_ScrollTop {...props}>
          <Fab size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </_ScrollTop>
      </Box>
    </React.Fragment>
  );
}

export default Help;
