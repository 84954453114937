import KEYS from "./helpers/KEYS";
import { User } from "./interfaces/user.interface";

class LocalStorageService {
	getUserLocal = () => {
		return localStorage.getItem("user") != null
			? JSON.parse(localStorage.getItem("user")!)
			: null;
	};

	saveUserLocal = (user: User) => {
		localStorage.setItem("user", JSON.stringify(user));
	};
	removeUserLocal = () => {
		localStorage.removeItem("user");
	};

	getLanguageLocal = () => {
		return localStorage.getItem(`${KEYS.USER_LOCALE}`) != null
			? localStorage.getItem(`${KEYS.USER_LOCALE}`)
			: null;
	};

	saveLanguageLocal = (lang: string) => {
		localStorage.setItem(KEYS.USER_LOCALE, lang);
		localStorage.setItem("langue", lang);
	};

	setThemeMode = (mode: string) => {
		localStorage.setItem("mode", mode);
	};
	getThemeMode = () => {
		return localStorage.getItem("mode") != null
			? localStorage.getItem("mode")
			: "dark";
	};
}

export default new LocalStorageService();
