import * as React from "react";
import { Grid, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
// import { useSelector } from "react-redux";
// import { selectCurrentMode } from "../../../../store/slices/modeSlice";
import { EnumStatusCode } from "../../../authentification/enums/auth.enums";
import { ContactInputDto } from "../../../authentification/interfaces/auth.interface";
import AuthService from "../../../authentification/services/auth-service";
import LoadingButton from "@mui/lab/LoadingButton";

export default function ContactForm() {
  const intl = useIntl();
  const { formatMessage } = intl;
  const [disabledBtn, setDisabledBtn] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  // const mode = useSelector(selectCurrentMode);

  const [message, setMessage] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState<string>("");
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    const emailRegExp: RegExp =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isEmail: boolean = emailRegExp.test(email);
    setDisabledBtn(!(name.length > 0) || !(message.length > 0) || !isEmail);
  }, [name, message, email]);

  const doSend = async () => {
    setIsLoading(true);
    const contactInputDto: ContactInputDto = {
      name,
      message,
      email,
    };
    AuthService.contactUs(contactInputDto)
      .then(() => {
        setIsLoading(false);
        enqueueSnackbar(
          formatMessage({ id: "delete_request_send_successfully" }),
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
      })
      .catch((error: any) => {
        console.log("error====", error.code);
        setIsLoading(false);

        console.log("Error: ", error);

        switch (error?.response?.data?.statusCode) {
          case EnumStatusCode.UNKNOWN_ERROR:
            enqueueSnackbar(formatMessage({ id: "an_error_has_occurred" }), {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });
            break;

          case EnumStatusCode.ERR_BAD_RESPONSE:
            enqueueSnackbar(formatMessage({ id: "an_error_has_occurred" }), {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });
            break;
          default:
            enqueueSnackbar(formatMessage({ id: "an_error_has_occurred" }), {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });
        }
      });
  };

  const handleMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    console.log("event.target.value", event.target.value);
    setMessage(event.target.value);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    console.log("event.target.value", event.target.value);
    setName(event.target.value);
  };

  const handleTextInputEmailChange = (event: any) => {
    console.log("event.target.value", event.target.value);
    setEmail(event.target.value);
  };

  return (
    <>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            fullWidth
            label="Name*"
            id="fullWidth"
            onChange={handleNameChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            fullWidth
            label="Email*"
            id="fullWidth"
            onChange={handleTextInputEmailChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-multiline-static"
            label="Message*"
            onChange={handleMessageChange}
            multiline
            rows={6}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <LoadingButton
            disabled={disabledBtn}
            disableElevation
            loading={isLoading}
            className="btn btn-lg"
            endIcon={<></>}
            loadingPosition="end"
            variant="contained"
            style={{
              textTransform: "none",
              float: "right",
              // fontSize: "16px",
              // boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
            }}
            onClick={doSend}
          >
            {formatMessage({ id: "send" })}
          </LoadingButton>
        </Grid>
      </Grid>

      {/* <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
      >
        <TextField
          // fullWidth
          label="Name*"
          id="fullWidth"
          onChange={handleNameChange}
        />

        <TextField
          // fullWidth
          label="Email*"
          id="fullWidth"
          onChange={handleTextInputEmailChange}
        />

        <TextField
          id="outlined-multiline-static"
          label="Message*"
          onChange={handleMessageChange}
          multiline
          rows={6}
          fullWidth
        />

        <LoadingButton
          disabled={disabledBtn}
          disableElevation
          loading={isLoading}
          className="btn btn-lg"
          endIcon={<></>}
          loadingPosition="end"
          variant="contained"
          style={{
            textTransform: "none",
            fontSize: "16px",
            boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
          }}
          onClick={doSend}
        >
          {formatMessage({ id: "send" })}
        </LoadingButton>
      </Stack> */}
    </>
  );
}
