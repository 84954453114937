import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    maxWidth: 100,
  },
  media: {
    height: 100,
  },
  customBox: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-all",
    overflow: "hidden",
    maxWidth: 250,
    // maxWidth: 200,
    // border: "1px solid red",
  },
});

const useStyles1 = makeStyles({
  cardRoot: {
    width: "100%",
    // width: "430px",
    fontSize: "1.125rem",
    fontWeight: "bold",
    backgroundColor: "#f0f0f2",
    padding: 1,
    borderRadius: "10px",
    fontFamily: "Nunito",
    cursor: "pointer",
    // lineHeight: "1.5rem",
    // marginLeft: "50px",
    // padding: "10px",
    //maxWidth: 360,
  },
  bullet: {
    // display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontWeight: 700,
    // fontWeight: "bold",
    lineHeight: "1.5rem",
  },
  pos: {
    // marginBottom: 12,
  },
  badge: {
    top: "0px",
    left: "0px",
    // marginLeft: "60px",
    right: "auto",
  },
  viewContainer: {
    top: "-30px",
    left: "26px",
    position: "relative",
    padding: "10px",
    cursor: "pointer",
    width: "fit-content",
    float: "right",
    "&>*": {
      marginLeft: "5px",
      fontSize: "1.1rem",
      lineHeight: "1.25rem",
      color: "crimson",
    },
    "&:hover": {
      backgroundColor: "whitesmoke",
    },
  },
});
export { useStyles, useStyles1 };
