import { Box, Grid, Stack, Typography } from "@mui/material";

const Footer = () => {
  return (
    <footer>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" color="#000000">
            Address
          </Typography>
          <Box height={15} />
          <Stack spacing={1}>
            <Typography variant="body1">Codezys Esn</Typography>
            <Typography variant="body1">
              49 RUE DE PONTHIEU 75008 PARIS
            </Typography>
            <Typography variant="body1">Email : info@codezys.com</Typography>
            <Typography variant="body1">©2024 CODEZYS, RELEASE, Studa. Tous droits réservés.  </Typography>
          </Stack>
        </Grid>
        {/* <Grid item xs={12} sm={4}>
          <Typography variant="h6" color="#000000">
            Column 2
          </Typography>
          <Box height={15} />
          <Typography variant="body1">Content for column 2</Typography>
        </Grid> */}
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" color="#000000">
            Liens utiles
          </Typography>
          <Box height={15} />
          <Stack spacing={1}>
            <Typography
              component="a"
              variant="body1"
              href="/terms-usage"
              target="blank"
              color="#41525d"
              sx={{
                textDecoration: "none",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Conditions d'utilisations
            </Typography>

            <Typography
              component="a"
              variant="body1"
              href="/privacy-policy"
              target="blank"
              color="#41525d"
              sx={{
                textDecoration: "none",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Politique de confidentialité
            </Typography>

            <Typography
              component="a"
              variant="body1"
              href="/help"
              target="blank"
              color="#41525d"
              sx={{
                textDecoration: "none",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Page d'aide
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
