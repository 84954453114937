import React from "react";
import { useSelector } from "react-redux";
import { selectCurrentMode } from "../../../store/slices/modeSlice";
import "./css/apropos.scss";
import { Box, CssBaseline, Drawer, Toolbar, Fab } from "@mui/material";
import AppBarDrawer from "../components/AppBarDrawer";
import _Drawer from "../components/_Drawer";
import _ScrollTop from "../components/_ScrollTop";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { EnumLocalLang } from "../components/enums/conversation.enums";
import { selectLanguageLocal } from "../../../store/slices/languageSlice";

const drawerWidth = 240;

function Terms(props: any) {
  const mode = useSelector(selectCurrentMode);
  const languageLocal: EnumLocalLang = useSelector(selectLanguageLocal);
  const theme = props.theme;

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <React.Fragment>
      <Box
        sx={{ display: "flex" }}
        style={{
          color:
            mode === "dark"
              ? theme.palette.colortext.dark
              : theme.palette.colortext.light,
          backgroundColor:
            mode === "dark"
              ? theme.palette.leftbar.dark
              : theme.palette.leftbar.light,
        }}
      >
        <CssBaseline />
        <AppBarDrawer
          theme={props.theme}
          onHandleDrawerToggle={handleDrawerToggle}
        />
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <_Drawer
              theme={props.theme}
              onHandleDrawerToggle={handleDrawerToggle}
            />
          </Drawer>
        </Box>
        <Box component="main" sx={{ pl: 3, pr: 3 }}>
          <Toolbar id="back-to-top-anchor" />
          <div
            className="container wrap-apropos"
            style={
              {
                /* color:
                mode === "dark"
                  ? theme.palette.colortext.dark
                  : theme.palette.colortext.light, */
              }
            }
          >
            {languageLocal === EnumLocalLang.FR ? (
              <div>
                <p>
                  <h2>Termes et conditions d’utilisation</h2>
                </p>
                <p>Bienvenue sur Studa !</p>
                <p>
                  Les présentes termes et conditions définissent les règles
                  d'utilisation du site Web de Codezys, à l'adresse
                  www.codezys.com
                </p>
                <p>
                  En accédant à ce site, nous supposons que vous acceptez ces
                  termes et conditions. Ne continuez pas à utiliser Studa si
                  vous n'acceptez pas de prendre toutes les conditions énoncées
                  sur cette page.
                </p>
                <p>
                  La terminologie suivante s'applique aux présentes conditions
                  générales, à la déclaration de confidentialité et à l'avis de
                  non-responsabilité ainsi qu'à tous les accords : "Client",
                  "Vous" et "Votre" font référence à vous, la personne qui se
                  connecte à ce site web et qui se conforme aux termes et
                  conditions de la Société. "La Société", "Nous", "Notre", "Nos"
                  et "Nous", désigne notre Société. "Partie", "Parties", ou
                  "Nous", désigne à la fois le client et nous-mêmes. Tous les
                  termes font référence à l'offre, à l'acceptation et à la prise
                  en compte du paiement nécessaire pour entreprendre le
                  processus de notre assistance au Client de la manière la plus
                  appropriée dans le but exprès de répondre aux besoins du
                  Client en ce qui concerne la fourniture des services déclarés
                  de la Société, conformément et sous réserve de la loi en
                  vigueur. Toute utilisation de la terminologie ci-dessus ou
                  d'autres mots au singulier, au pluriel, en majuscules et/ou
                  il/elle ou ils/elles, sont considérés comme interchangeables
                  et donc comme se référant au même.
                </p>
                <p>
                  <h3>Les cookies</h3>
                </p>
                <p>
                  Nous utilisons des cookies. En accédant à Studa, vous avez
                  accepté d'utiliser des cookies en accord avec la politique de
                  confidentialité de Codezys.
                </p>
                <p>
                  La plupart des sites Web interactifs utilisent des cookies
                  pour nous permettre de récupérer les données de l'utilisateur
                  à chaque visite. Les cookies sont utilisés par notre site Web
                  pour permettre la fonctionnalité de certaines zones afin de
                  faciliter la tâche des personnes qui visitent notre site Web.
                  Certains de nos partenaires affiliés/publicitaires peuvent
                  également utiliser des cookies.
                </p>
                <p>
                  <h3>Licence</h3>
                </p>
                <p>
                  Sauf indication contraire, Codezys et/ou ses concédant de
                  licence détiennent les droits de propriété intellectuelle de
                  l'ensemble du matériel présent sur Studa. Tous les droits de
                  propriété intellectuelle sont réservés. Vous pouvez accéder au
                  contenu de Studa pour votre usage personnel, sous réserve des
                  restrictions définies dans les présentes conditions générales.
                </p>
                <p>Vous ne devez pas :</p>
                <ul>
                  <li>Republier le matériel de Studa</li>
                  <li>
                    Vendre, louer ou accorder une sous-licence pour le matériel
                    de Studa
                  </li>
                  <li>Reproduire, dupliquer ou copier le matériel de Studa</li>
                  <li>Redistribuer le contenu de Studa.</li>
                </ul>
                <p>
                  Certaines parties de ce site Web offrent aux utilisateurs la
                  possibilité de publier et d'échanger des opinions et des
                  informations dans certaines zones du site. Codezys ne filtre
                  pas, n'édite pas, ne publie pas et ne révise pas les
                  Commentaires avant leur présence sur le site Web. Les
                  commentaires ne reflètent pas les points de vue et les
                  opinions de Codezys, de ses agents et/ou de ses affiliés. Les
                  commentaires reflètent les points de vue et les opinions de la
                  personne qui les publie. Dans la mesure où les lois
                  applicables le permettent, Codezys ne peut être tenu
                  responsable des commentaires ni de toute responsabilité, de
                  tout dommage ou de toute dépense causés et/ou subis à la suite
                  de l'utilisation et/ou de la publication et/ou de l'apparition
                  des commentaires sur ce site web.
                </p>
                <p>
                  Codezys se réserve le droit de surveiller tous les
                  Commentaires et de supprimer tout commentaire qui peut être
                  considéré comme inapproprié, offensant ou qui constitue une
                  violation des présentes conditions générales.
                </p>
                <p>Vous garantissez et déclarez que</p>
                <ul>
                  <li>
                    Vous avez le droit de publier les Commentaires sur notre
                    site Web et disposez de toutes les licences et autorisations
                    nécessaires pour le faire ;
                  </li>
                  <li>
                    Les Commentaires ne portent atteinte à aucun droit de
                    propriété intellectuelle, y compris, sans s'y limiter, les
                    droits d'auteur, les brevets ou les marques de commerce d'un
                    tiers ;
                  </li>
                  <li>
                    Les Commentaires ne contiennent pas d'éléments
                    diffamatoires, calomnieux, offensants, indécents ou
                    autrement illégaux qui constituent une atteinte à la vie
                    privée.
                  </li>
                  <li>
                    Les Commentaires ne seront pas utilisés pour solliciter ou
                    promouvoir des affaires ou des coutumes ou présenter des
                    activités commerciales ou des activités illégales.
                  </li>
                </ul>
                <p>
                  Vous accordez par la présente à Codezys une licence non
                  exclusive pour utiliser, reproduire, éditer et autoriser
                  d'autres personnes à utiliser, reproduire et éditer l'un
                  quelconque de vos Commentaires sous toutes formes, tous
                  formats ou tous médias.
                </p>
                <p>
                  <h3>Hyperlien vers notre contenu</h3>
                </p>
                <p>
                  Les organisations suivantes peuvent établir un lien avec notre
                  site Web sans autorisation écrite préalable :
                </p>
                <ul>
                  <li>Les agences gouvernementales ;</li>
                  <li>Moteurs de recherche ;</li>
                  <li>Organismes de presse ;</li>
                  <li>
                    Les distributeurs d'annuaires en ligne peuvent créer des
                    liens vers notre site Web de la même manière qu'ils créent
                    des hyperliens vers les sites Web d'autres entreprises
                    répertoriées ; et
                  </li>
                  <li>
                    Les entreprises accréditées à l'échelle du système, à
                    l'exception des organisations à but non lucratif, des
                    centres commerciaux caritatifs et des groupes de collecte de
                    fonds caritatifs, qui ne peuvent pas établir d'hyperlien
                    vers notre site Web.
                  </li>
                </ul>
                <p>
                  Ces organisations peuvent établir un lien vers notre page
                  d'accueil, vers des publications ou vers d'autres informations
                  du site Web, à condition que le lien : (a) ne soit en aucun
                  cas trompeur ; (b) n'implique pas faussement le parrainage,
                  l'approbation ou le soutien de la partie qui propose le lien
                  et de ses produits et/ou services ; et (c) s'inscrive dans le
                  contexte du site de la partie qui propose le lien.
                </p>
                <p>
                  Nous pouvons prendre en considération et approuver d'autres
                  demandes de liens provenant des types d'organisations suivants
                  :
                </p>
                <ul>
                  <li>
                    des sources d'information connues des consommateurs et/ou
                    des entreprises ;
                  </li>
                  <li>les sites communautaires point.com ;</li>
                  <li>
                    Les associations ou autres groupes représentant des
                    organisations caritatives ;
                  </li>
                  <li>distributeurs d'annuaires en ligne ;</li>
                  <li>portails Internet ;</li>
                  <li>
                    des cabinets comptables, juridiques et de conseil ; et
                  </li>
                  <li>
                    des établissements d'enseignement et des associations
                    professionnelles.
                  </li>
                </ul>
                <p>
                  Nous approuverons les demandes de liens de ces organisations
                  si nous décidons que : (a) le lien ne nous donnera pas une
                  image défavorable de nous-mêmes ou des entreprises accréditées
                  ; (b) l'organisation n'a pas d'antécédents négatifs avec nous
                  ; (c) l'avantage pour nous de la visibilité de l'hyperlien
                  compense l'absence de Codezys ; et (d) le lien s'inscrit dans
                  le contexte d'une information générale sur les ressources.
                </p>
                <p>
                  Ces organisations peuvent créer un lien vers notre page
                  d'accueil à condition que le lien : (a) ne soit pas trompeur
                  de quelque façon que ce soit ; (b) n'implique pas faussement
                  le parrainage, l'approbation ou le soutien de la partie liée
                  et de ses produits ou services ; et (c) s'inscrive dans le
                  contexte du site de la partie liée.
                </p>
                <p>
                  Si vous êtes l'une des organisations énumérées au paragraphe 2
                  ci-dessus et que vous souhaitez établir un lien avec notre
                  site Web, vous devez nous en informer en envoyant un courrier
                  électronique à Codezys. Veuillez inclure votre nom, le nom de
                  votre organisation, vos coordonnées ainsi que l'URL de votre
                  site, une liste de toutes les URL à partir desquelles vous
                  avez l'intention de créer un lien vers notre site Web, et une
                  liste des URL de notre site vers lesquelles vous souhaitez
                  créer un lien. Attendez 2 à 3 semaines pour recevoir une
                  réponse.
                </p>
                <p>
                  Les organisations approuvées peuvent créer un hyperlien vers
                  notre site Web de la manière suivante :
                </p>
                <ul>
                  <li>En utilisant notre dénomination sociale ; ou</li>
                  <li>
                    en utilisant le localisateur de ressources uniforme auquel
                    le lien renvoie ; ou
                  </li>
                  <li>
                    En utilisant toute autre description de notre site Web vers
                    lequel le lien est établi et qui a un sens dans le contexte
                    et le format du contenu du site de la partie qui établit le
                    lien.
                  </li>
                </ul>
                <p>
                  L'utilisation du logo ou d'autres illustrations de Codezys ne
                  sera pas autorisée pour la création de liens en l'absence d'un
                  accord de licence de marque.
                </p>
                <p>
                  <h3>iFrames</h3>
                </p>
                <p>
                  Sans autorisation préalable et écrite, vous ne pouvez pas
                  créer de cadres autour de nos pages Web qui modifient de
                  quelque manière que ce soit la présentation visuelle ou
                  l'apparence de notre site Web.
                </p>
                <p>
                  <h3>Responsabilité du contenu</h3>
                </p>
                <p>
                  Nous ne serons pas tenus responsables du contenu qui apparaît
                  sur votre site web. Vous acceptez de nous protéger et de nous
                  défendre contre toutes les réclamations qui s'élèvent sur
                  votre site Web. Aucun lien ne doit apparaître sur un site Web
                  qui pourrait être interprété comme étant diffamatoire, obscène
                  ou criminel, ou qui enfreint, viole de quelque manière que ce
                  soit, ou préconise l'enfreinte ou toute autre violation des
                  droits d'un tiers.
                </p>
                <p>
                  <h3>Votre confidentialité</h3>
                </p>
                <p>Veuillez lire la politique de confidentialité</p>
                <p>
                  <h3>Réserve des droits</h3>
                </p>
                <p>
                  Nous nous réservons le droit de vous demander de supprimer
                  tous les liens ou tout lien particulier vers notre site Web.
                  Vous acceptez de retirer immédiatement tous les liens vers
                  notre site Web sur demande. Nous nous réservons également le
                  droit de modifier à tout moment les présentes conditions
                  générales et la politique en matière de liens. En établissant
                  un lien permanent avec notre site Web, vous acceptez d'être
                  lié à ces conditions et de les respecter.
                </p>
                <p>
                  <h3>Suppression des liens de notre site web</h3>
                </p>
                <p>
                  Si vous trouvez un lien sur notre site Web qui est offensant
                  pour une raison quelconque, vous êtes libre de nous contacter
                  et de nous en informer à tout moment. Nous examinerons les
                  demandes de suppression de liens, mais nous ne sommes pas
                  obligés de le faire ou de vous répondre directement.
                </p>
                <p>
                  Nous ne garantissons pas l'exactitude des informations
                  contenues dans ce site web, ni leur exhaustivité ou leur
                  précision ; nous ne promettons pas non plus de garantir que le
                  site web reste disponible ou que le matériel contenu dans le
                  site web soit maintenu à jour.
                </p>
                <p>
                  <h3>Clause de non-responsabilité</h3>
                </p>
                <p>
                  Dans la mesure maximale autorisée par la loi applicable, nous
                  excluons toutes les représentations, garanties et conditions
                  relatives à notre site web et à l'utilisation de ce dernier.
                  Rien dans cette clause de non-responsabilité ne pourra :
                </p>
                <ul>
                  <li>
                    limiter ou exclure notre ou votre responsabilité en cas de
                    décès ou de préjudice corporel ;
                  </li>
                  <li>
                    limiter ou exclure notre ou votre responsabilité en cas de
                    fraude ou de déclaration frauduleuse ;
                  </li>
                  <li>
                    limiter l'une quelconque de nos ou de vos responsabilités
                    d'une manière qui n'est pas autorisée par la loi applicable
                    ; ou
                  </li>
                  <li>
                    exclure l'une quelconque de nos ou de vos responsabilités
                    qui ne peut être exclue en vertu de la loi applicable.
                  </li>
                </ul>
                <p>
                  Les limitations et interdictions de responsabilité énoncées
                  dans cette section et ailleurs dans la présente clause de
                  non-responsabilité : (a) sont soumises au paragraphe précédent
                  ; et (b) régissent toutes les responsabilités découlant de la
                  clause de non-responsabilité, y compris les responsabilités
                  contractuelles, délictuelles et pour violation d'une
                  obligation légale.
                </p>
                <p>
                  Tant que le site web et les informations et services qu'il
                  contient sont fournis gratuitement, nous ne serons pas
                  responsables de toute perte ou dommage de quelque nature que
                  ce soit.
                </p>
              </div>
            ) : (
              <div>
                <h2>Terms and Conditions</h2>
                <p>Welcome to Studa!</p>
                <p>
                  These terms and conditions outline the rules and regulations
                  for the use of Codezys's Website, located at www.codezys.com.
                </p>
                <p>
                  By accessing this website, we assume you accept these terms
                  and conditions. Do not continue to use Studa if you do not
                  agree to take all of the terms and conditions stated on this
                  page.
                </p>
                <p>
                  The following terminology applies to these Terms and
                  Conditions, Privacy Statement and Disclaimer Notice and all
                  Agreements: "Client", "You" and "Your" refers to you, the
                  person log on this website and compliant to the Company’s
                  terms and conditions. "The Company", "Ourselves", "We", "Our"
                  and "Us", refers to our Company. "Party", "Parties", or "Us",
                  refers to both the Client and ourselves. All terms refer to
                  the offer, acceptance and consideration of payment necessary
                  to undertake the process of our assistance to the Client in
                  the most appropriate manner for the express purpose of meeting
                  the Client’s needs in respect of provision of the Company’s
                  stated services, in accordance with and subject to, prevailing
                  law of France. Any use of the above terminology or other words
                  in the singular, plural, capitalization and/or he/she or they,
                  are taken as interchangeable and therefore as referring to
                  same.
                </p>
                <p>
                  <h3>Cookies</h3>
                </p>
                <p>
                  We employ the use of cookies. By accessing Studa, you agreed
                  to use cookies in agreement with Codezys's Privacy Policy.
                </p>
                <p>
                  Most interactive websites use cookies to let us retrieve the
                  user’s details for each visit. Cookies are used by our website
                  to enable the functionality of certain areas to make it easier
                  for people visiting our website. Some of our
                  affiliate/advertising partners may also use cookies.
                </p>
                <p>
                  <h3>License</h3>
                </p>
                <p>
                  Unless otherwise stated, Codezys and/or its licensors own the
                  intellectual property rights for all material on Studa. All
                  intellectual property rights are reserved. You may access this
                  from Studa for your own personal use subjected to restrictions
                  set in these terms and conditions.
                </p>
                <p>
                  <strong>You must not:</strong>
                </p>
                <ul>
                  <li>Republish material from Studa</li>
                  <li>Sell, rent or sub-license material from Studa</li>
                  <li>Reproduce, duplicate or copy material from Studa</li>
                  <li>Redistribute content from Studa</li>
                </ul>
                <p>
                  Parts of this website offer an opportunity for users to post
                  and exchange opinions and information in certain areas of the
                  website. Codezys does not filter, edit, publish or review
                  Comments prior to their presence on the website. Comments do
                  not reflect the views and opinions of Codezys, its agents
                  and/or affiliates. Comments reflect the views and opinions of
                  the person who post their views and opinions. To the extent
                  permitted by applicable laws, Codezys shall not be liable for
                  the Comments or for any liability, damages or expenses caused
                  and/or suffered as a result of any use of and/or posting of
                  and/or appearance of the Comments on this website.
                </p>
                <p>
                  Codezys reserves the right to monitor all Comments and to
                  remove any Comments which can be considered inappropriate,
                  offensive or causes breach of these Terms and Conditions.
                </p>
                <p>
                  <strong>You warrant and represent that:</strong>
                </p>
                <ul>
                  <li>
                    You are entitled to post the Comments on our website and
                    have all necessary licenses and consents to do so;
                  </li>
                  <li>
                    The Comments do not invade any intellectual property right,
                    including without limitation copyright, patent or trademark
                    of any third party;
                  </li>
                  <li>
                    The Comments do not contain any defamatory, libelous,
                    offensive, indecent or otherwise unlawful material which is
                    an invasion of privacy
                  </li>
                  <li>
                    The Comments will not be used to solicit or promote business
                    or custom or present commercial activities or unlawful
                    activity.
                  </li>
                </ul>
                <p>
                  You hereby grant Codezys a non-exclusive license to use,
                  reproduce, edit and authorize others to use, reproduce and
                  edit any of your Comments in any and all forms, formats or
                  media.
                </p>
                <p>
                  <h3>Hyperlinking to our Content</h3>
                </p>
                <p>
                  The following organizations may link to our Website without
                  prior written approval:
                </p>
                <ul>
                  <li>Government agencies;</li>
                  <li>Search engines;</li>
                  <li>News organizations;</li>
                  <li>
                    Online directory distributors may link to our Website in the
                    same manner as they hyperlink to the Websites of other
                    listed businesses; and
                  </li>
                  <li>
                    System wide Accredited Businesses except soliciting
                    non-profit organizations, charity shopping malls, and
                    charity fundraising groups which may not hyperlink to our
                    Web site.
                  </li>
                </ul>
                <p>
                  These organizations may link to our home page, to publications
                  or to other Website information so long as the link: (a) is
                  not in any way deceptive; (b) does not falsely imply
                  sponsorship, endorsement or approval of the linking party and
                  its products and/or services; and (c) fits within the context
                  of the linking party’s site.
                </p>
                <p>
                  We may consider and approve other link requests from the
                  following types of organizations:
                </p>
                <ul>
                  <li>
                    commonly-known consumer and/or business information sources;
                  </li>
                  <li>dot.com community sites;</li>
                  <li>associations or other groups representing charities;</li>
                  <li>online directory distributors;</li>
                  <li>internet portals;</li>
                  <li>accounting, law and consulting firms; and</li>
                  <li>educational institutions and trade associations.</li>
                </ul>
                <p>
                  We will approve link requests from these organizations if we
                  decide that: (a) the link would not make us look unfavourably
                  to ourselves or to our accredited businesses; (b) the
                  organization does not have any negative records with us; (c)
                  the benefit to us from the visibility of the hyperlink
                  compensates the absence of Codezys; and (d) the link is in the
                  context of general resource information.
                </p>
                <p>
                  These organizations may link to our home page so long as the
                  link: (a) is not in any way deceptive; (b) does not falsely
                  imply sponsorship, endorsement or approval of the linking
                  party and its products or services; and (c) fits within the
                  context of the linking party’s site.
                </p>
                <p>
                  If you are one of the organizations listed in paragraph 2
                  above and are interested in linking to our website, you must
                  inform us by sending an e-mail to Codezys. Please include your
                  name, your organization name, contact information as well as
                  the URL of your site, a list of any URLs from which you intend
                  to link to our Website, and a list of the URLs on our site to
                  which you would like to link. Wait 2-3 weeks for a response.
                </p>
                <p>
                  Approved organizations may hyperlink to our Website as
                  follows:
                </p>
                <ul>
                  <li>By use of our corporate name; or</li>
                  <li>
                    By use of the uniform resource locator being linked to; or
                  </li>
                  <li>
                    By use of any other description of our Website being linked
                    to that makes sense within the context and format of content
                    on the linking party’s site.
                  </li>
                </ul>
                <p>
                  No use of Codezys's logo or other artwork will be allowed for
                  linking absent a trademark license agreement.
                </p>
                <p>
                  <h3>iFrames</h3>
                </p>
                <p>
                  Without prior approval and written permission, you may not
                  create frames around our Webpages that alter in any way the
                  visual presentation or appearance of our Website.
                </p>
                <p>
                  <h3>Content Liability</h3>
                </p>
                <p>
                  We shall not be hold responsible for any content that appears
                  on your Website. You agree to protect and defend us against
                  all claims that is rising on your Website. No link(s) should
                  appear on any Website that may be interpreted as libelous,
                  obscene or criminal, or which infringes, otherwise violates,
                  or advocates the infringement or other violation of, any third
                  party rights.
                </p>
                <p>
                  <h3>Your Privacy</h3>
                </p>
                <p>Please read Privacy Policy</p>
                <p>
                  <h3>Reservation of Rights</h3>
                </p>
                <p>
                  We reserve the right to request that you remove all links or
                  any particular link to our Website. You approve to immediately
                  remove all links to our Website upon request. We also reserve
                  the right to amen these terms and conditions and its linking
                  policy at any time. By continuously linking to our Website,
                  you agree to be bound to and follow these linking terms and
                  conditions.
                </p>
                <p>
                  <h3>Removal of links from our website</h3>
                </p>
                <p>
                  If you find any link on our Website that is offensive for any
                  reason, you are free to contact and inform us any moment. We
                  will consider requests to remove links but we are not
                  obligated to or so or to respond to you directly.
                </p>
                <p>
                  We do not ensure that the information on this website is
                  correct, we do not warrant its completeness or accuracy; nor
                  do we promise to ensure that the website remains available or
                  that the material on the website is kept up to date.
                </p>
                <p>
                  <h3>Disclaimer</h3>
                </p>
                <p>
                  To the maximum extent permitted by applicable law, we exclude
                  all representations, warranties and conditions relating to our
                  website and the use of this website. Nothing in this
                  disclaimer will:
                </p>
                <ul>
                  <li>
                    limit or exclude our or your liability for death or personal
                    injury;
                  </li>
                  <li>
                    limit or exclude our or your liability for fraud or
                    fraudulent misrepresentation;
                  </li>
                  <li>
                    limit any of our or your liabilities in any way that is not
                    permitted under applicable law; or
                  </li>
                  <li>
                    exclude any of our or your liabilities that may not be
                    excluded under applicable law.
                  </li>
                </ul>
                <p>
                  The limitations and prohibitions of liability set in this
                  Section and elsewhere in this disclaimer: (a) are subject to
                  the preceding paragraph; and (b) govern all liabilities
                  arising under the disclaimer, including liabilities arising in
                  contract, in tort and for breach of statutory duty.
                </p>
                <p>
                  As long as the website and the information and services on the
                  website are provided free of charge, we will not be liable for
                  any loss or damage of any nature.
                </p>
              </div>
            )}
          </div>
        </Box>

        <_ScrollTop {...props}>
          <Fab size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </_ScrollTop>
      </Box>
    </React.Fragment>
  );
}

export default Terms;
