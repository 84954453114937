import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import cookieService from "../cookieService";
import localStorageService from "../localStorageService";
import { clearUser } from "../store/slices/userSlice";

const AxiosInterceptorsSetup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      const code: string = err.response.data.code;
      const statusCode: string = err.response.data.statusCode;
      const status: number = err.response.status;

      if (code && statusCode && status === 401) {
        // localStorageService.removeUserLocal();
        dispatch(clearUser());
        cookieService.removeToken();
        cookieService.removeRefreshToken();

        navigate("/auth");
      } else {
        return Promise.reject(err);
      }
      // return Promise.reject(err);
    }
  );
};

export default AxiosInterceptorsSetup;
