import { Box } from "@mui/material";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Connexion from "./pages/Connexion";
import ConnexionSms from "./pages/ConnexionSms";
import SelectClass from "./pages/SelectClass";
import SelectCountry from "./pages/SelectCountry";
import ValidationCode from "./pages/ValidationCode";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../store/slices/userSlice";

function AuthModule(props: any) {
	const user = useSelector(selectUser);
	const isConnected = user != null ? true : false;
	const hasConfigureClass =
		user != null && (user.currentClass == undefined || user.currentClass == "")
			? false
			: true;
	return (
		<>
			<Routes>
				<Route
					path="/"
					element={
						!isConnected ? (
							<Connexion theme={props.theme} />
						) : !hasConfigureClass ? (
							<Navigate
								to="/auth/select-country"
								replace
							/>
						) : (
							<></>
						)
					}
				/>
				<Route
					path="connexion-sms"
					element={<ConnexionSms theme={props.theme} />}
				/>
				<Route
					path="/validation-code"
					element={<ValidationCode theme={props.theme} />}
				/>
				<Route
					path="/select-country"
					element={<SelectCountry theme={props.theme} />}
				/>
				<Route
					path="/select-class"
					element={<SelectClass theme={props.theme} />}
				/>
				<Route
					path="*"
					element={<>Page introuvable</>}
				/>
			</Routes>
		</>
	);
}

export default AuthModule;
