import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React from "react";
import Lottie from "react-lottie";
import studaLogo from "../../../assets/lotties/studa.json";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import KEYS from "../../../helpers/KEYS";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  selectLanguageLocal,
  setLanguageLocal,
} from "../../../store/slices/languageSlice";
import { useDispatch, useSelector } from "react-redux";
import { EnumLocalLang } from "./enums/conversation.enums";
import { useIntl } from "react-intl";

function AppBarDrawer(props: any) {
  const intl = useIntl();
  const { formatMessage } = intl;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const languageLocal: string = useSelector(selectLanguageLocal);

  const handleDrawerToggle = () => {
    props.onHandleDrawerToggle();
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: studaLogo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const options = [
    { code: "en", img: "gb.png", name: "Anglais" },
    { code: "fr", img: "fr.png", name: "Français" },
  ];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(
    options.findIndex((obj) => obj.code === languageLocal)
  );

  const open = Boolean(anchorEl);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    code: string
  ) => {
    setSelectedIndex(index);
    handleChangeLanguage(code);
    setAnchorEl(null);
  };

  const handleChangeLanguage = (code: string) => {
    dispatch(setLanguageLocal(code));
    console.log("new local", languageLocal);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <AppBar component="nav">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <Lottie options={defaultOptions} height={30} width={30} />
        </Box>

        <Typography
          sx={{ flexGrow: 1, display: { xs: "none", sm: "block", cursor: "pointer" } }}
          variant="button"
          onClick={()=>navigate("/")}
          style={{
            fontWeight: 600,
            fontSize: "1rem",
            fontFamily: "Nunito",
            marginLeft: "10px",
          }}
        >
          STUDA
        </Typography>

        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <Button
            key={"Page d'acceuil"}
            sx={{ color: "#fff" }}
            style={{ textTransform: "none" }}
            onClick={() => {
              navigate("/");
            }}
            disableElevation
          >
            {formatMessage({ id: "home_page" })}
          </Button>

          <Button
            key={"Politique de confidentialité"}
            sx={{ color: "#fff" }}
            style={{ textTransform: "none" }}
            onClick={() => {
              navigate("/privacy-policy");
            }}
            disableElevation
          >
            {formatMessage({ id: "privacy_policy" })}
          </Button>

          <Button
            key={"conditions d’utilisation"}
            sx={{ color: "#fff" }}
            style={{ textTransform: "none" }}
            onClick={() => {
              navigate("/terms-usage");
            }}
            disableElevation
          >
            {formatMessage({ id: "conditions_of_use" })}
          </Button>

          <Button
            key={"Aide"}
            sx={{ color: "#fff" }}
            style={{ textTransform: "none" }}
            onClick={() => {
              navigate("/help");
            }}
            disableElevation
          >
            {formatMessage({ id: "help" })}
          </Button>

          <Button
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            style={{
              color: "white",
              textTransform: "none" /* backgroundColor: '#2196F3' */,
            }}
            variant="contained"
            color="success"
            disableElevation
            endIcon={<KeyboardArrowDownIcon />}
          >
            <img
              src={`${KEYS.HOST_RESOURCE}/flags/${
                languageLocal === EnumLocalLang.FR
                  ? options[1].img
                  : options[0].img
              }`.toLowerCase()}
              alt="flag"
              width={20}
              height={15}
            />
            &nbsp;
            <span>
              {languageLocal === EnumLocalLang.FR
                ? options[1].name
                : options[0].name}
            </span>
          </Button>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {options.map((option, index) => (
              <MenuItem
                key={option.name}
                disabled={index === selectedIndex}
                // disabled={index === 0}
                selected={index === selectedIndex}
                onClick={(event) =>
                  handleMenuItemClick(event, index, option.code)
                }
              >
                <img
                  src={`${KEYS.HOST_RESOURCE}/flags/${option.img}`.toLowerCase()}
                  alt="flag"
                  width={20}
                  height={15}
                />
                &nbsp;
                <span>{option.name}</span>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default AppBarDrawer;
