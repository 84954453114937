import { ConnexionData, User } from "../../../interfaces/user.interface";
import {
  EnumUserSex,
  EnumDeviceStatus,
  AuthSignInInputDtoTokenType,
  EnumConnectedVia,
} from "../enums/auth.enums";

export interface TokenData {
  token: string;
  expiresIn: number;
}

export interface DeviceData {
  deviceID: string;
  OS: string;
  name: string;
  status: EnumDeviceStatus;
  notificationToken: string;
  isConnected?: boolean;
  isDeleted: boolean;
  deletedBy?: string;
  connexions?: ConnexionData[];
}

export interface UserPayload {
  username: string;
  enabled?: boolean;
  phoneValidated?: boolean;
  emailValidated?: boolean;
  connectedVia: EnumConnectedVia;
  name?: string;
  email?: string;
  newEmail?: string;
  phone?: string;
  newPhone?: string;
  sex?: EnumUserSex;
  image?: string;
  currentClass: string;
  classes?: string[];
  currentDevice?: string;
  devices: DeviceData[];
  isDeleted: boolean;
  disabledByAdmin?: boolean;
  disabledAt?: number;
  disabledReason?: string;
  birthday?: number;
}

export interface DeleteAccountInputDto {
  checkedValues: Array<string>;
  reason?: string;
  email:string;
}

export class ContactInputDto {
  public name: string;
  public email: string;
  public message: string;
}

export interface UserDataFromAuthAPIDto {
  id?: string;
  email?: string;
  status: boolean;
  firstName: string;
  lastName: string;
  picture: string;
  locale?: string;
  oauthId?: string;
}

export interface UserCredentialsFromAuthAPIDto {
  accessToken: string;
  tokenType?: AuthSignInInputDtoTokenType;
  expiresIn: number;
}

//  New add
export interface IResponseAuthCredentialGoogle {
  access_token?: string;
  authuser?: string;
  expires_in?: number;
  prompt?: string;
  scope?: string;
  token_type?: string;
}

export interface IResponseProfileAuthGoogle {
  email?: string;
  family_name?: string;
  given_name?: string;
  id?: string;
  name?: string;
  picture?: string;
  locale?: string;
}

/* Classes */
export class ResponseAuthCredentialsGoogle
  implements IResponseAuthCredentialGoogle
{
  access_token?: string;
  authuser?: string;
  expires_in?: number;
  prompt?: string;
  scope?: string;
  token_type?: string;

  constructor(
    access_token?: string,
    authuser?: string,
    expires_in?: number,
    prompt?: string,
    scope?: string,
    token_type?: string
  ) {
    this.access_token = access_token;
    this.authuser = authuser;
    this.expires_in = expires_in;
    this.prompt = prompt;
    this.scope = scope;
    this.token_type = token_type;
  }
}

export class ResponseProfileAuthGoogle implements IResponseProfileAuthGoogle {
  email?: string;
  family_name?: string;
  given_name?: string;
  id?: string;
  locale?: string;
  name?: string;
  picture?: string;

  constructor(
    email?: string,
    family_name?: string,
    given_name?: string,
    id?: string,
    locale?: string,
    name?: string,
    picture?: string
  ) {
    this.email = email;
    this.family_name = family_name;
    this.given_name = given_name;
    this.id = id;
    this.locale = locale;
    this.name = name;
    this.picture = picture;
  }
}
