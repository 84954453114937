export enum EnumUserSex {
	MALE = "MALE",
	FEMALE = "FEMALE",
}

export enum EnumDeviceStatus {
	DESACTIVATED,
	ACTIVATED,
}

export enum EnumConnectedVia {
	SMS = "SMS",
	FACEBOOK = "FACEBOOK",
	GOOGLE = "GOOGLE",
}
export enum EnumSMSCodeStatus {
	SENDED = "SENDED",
	AWAITING = "AWAITING",
}
export enum EnumStatusResponse {
	SUCCESS = "SUCCESS",
	FAILURE = "FAILURE",
}

export enum EnumStatusCode {
	SERVER_IS_ALIVE = "SERVER_IS_ALIVE",
	CODE_NOT_PROVIDED = "CODE_NOT_PROVIDED",
	PHONE_NOT_PROVIDED = "PHONE_NOT_PROVIDED",
	SERVICE_NOT_PROVIDED = "SERVICE_NOT_PROVIDED",
	FACEBOOK_GOOGLE_USER_DATA_NOT_PROVIDED = "FACEBOOK_GOOGLE_USER_DATA_NOT_PROVIDED",
	REFRESHTOKEN_NOT_PROVIDED = "REFRESHTOKEN_NOT_PROVIDED",
	USERNAME_NOT_PROVIDED = "USERNAME_NOT_PROVIDED",
	USER_NOT_FOUND = "USER_NOT_FOUND",
	GET_USERS_ERROR = "GET_USERS_ERROR",
	DEVICE_NOT_FOUND = "DEVICE_NOT_FOUND",
	CODE_EXPIRED = "CODE_EXPIRED",
	CODE_INCORRECT = "CODE_INCORRECT",
	PHONE_VALIDATED = "PHONE_VALIDATED",
	EMAIL_VALIDATED = "EMAIL_VALIDATED",
	DYNAMO_ERROR = "DYNAMO_ERROR",
	TIME_REMAINING_ERROR = "TIME_REMAINING_ERROR",
	VERIFICATION_CODE_SENDED = "VERIFICATION_CODE_SENDED",
	RESEND_CODE_LIMIT_ERROR = "RESEND_CODE_LIMIT_ERROR",
	SMS_SUCCESSFULLY_SENDED = "SMS_SUCCESSFULLY_SENDED",
	AWS_SNS_ERROR = "AWS_SNS_ERROR",
	SEND_SMS_ERROR = "SEND_SMS_ERROR",
	SEND_EMAIL_ERROR = "SEND_EMAIL_ERROR",
	EMPTY_PARAMS_ERROR = "EMPTY_PARAMS_ERROR",
	UPDATE_USER_ERROR = "UPDATE_USER_ERROR",
	CREATE_USER_ERROR = "CREATE_USER_ERROR",
	UNKNOWN_ERROR = "UNKNOWN_ERROR",
	ERR_BAD_RESPONSE = "ERR_BAD_RESPONSE",
	INVALID_SOCIAL_TYPE = "INVALID_SOCIAL_TYPE",
	LOGIN_SUCCESS = "LOGIN_SUCCESS",
	LOGIN_ERROR = "LOGIN_ERROR",
	FACEBOOK_TOKEN_VERIFIED = "FACEBOOK_TOKEN_VERIFIED",
	GOOGLE_TOKEN_VERIFIED = "GOOGLE_TOKEN_VERIFIED",
	FACEBOOK_TOKEN_ERROR = "FACEBOOK_TOKEN_ERROR",
	GOOGLE_TOKEN_ERROR = "GOOGLE_TOKEN_ERROR",
	TOKEN_REFRESH_SUCCESS = "TOKEN_REFRESH_SUCCESS",
	DELETE_SUCCESS = "DELETE_SUCCESS",
	SUCCESS = "SUCCESS",
	COUNTRY_NOT_FOUND = "COUNTRY_NOT_FOUND",
	COUNTRY_NOT_PROVIDED = "COUNTRY_NOT_PROVIDED",
	SUBJECT_NOT_FOUND = "SUBJECT_NOT_FOUND",
	CLASS_NOT_FOUND = "CLASS_NOT_FOUND",
	STRUCTURE_FILE_NOT_FOUND = "STRUCTURE_FILE_NOT_FOUND",
	PARSING_STRUCTURE_FILE_ERROR = "PARSING_STRUCTURE_FILE_ERROR",
	UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
	FILE_NOT_PROVIDED = "FILE_NOT_PROVIDED",
	FILE_UPLOAD_FAILED = "FILE_UPLOAD_FAILED",
	PHOTO_UPDATED_SUCCESSFULLY = "PHOTO_UPDATED_SUCCESSFULLY",
	INVALID_COUNTRY_CODE = "INVALID_COUNTRY_CODE",
	INVALID_PHONE_NUMBER = "INVALID_PHONE_NUMBER",
	PATH_NOT_PROVIDED = "PATH_NOT_PROVIDED",
	PATH_VALIDATION_FAILED = "PATH_VALIDATION_FAILED",
	VALIDATION_MIDDLEWARE_ERROR = "VALIDATION_MIDDLEWARE_ERROR",
	WRONG_AUTH_TOKEN = "WRONG_AUTH_TOKEN",
	MISSING_AUTH_TOKEN = "MISSING_AUTH_TOKEN",
	AUTH_TOKEN_ERROR = "AUTH_TOKEN_ERROR",
	TOKEN_EXPIRED_ERROR = "TOKEN_EXPIRED_ERROR",
	REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR",
	INVALID_REFRESH_TOKEN = "INVALID_REFRESH_TOKEN",
	S3_UPLAOD_ERROR = "S3_UPLAOD_ERROR",
	S3_GETOBJECT_ERROR = "S3_GETOBJECT_ERROR",
	S3_DELETE_OBJECT_ERROR = "S3_DELETE_OBJECT_ERROR",
	IMAGE_DOWNLOAD_ERROR = "IMAGE_DOWNLOAD_ERROR",
	EMAIL_OCCUPIED = "EMAIL_OCCUPIED",
	PHONE_OCCUPIED = "PHONE_OCCUPIED",
	USER_DISABLED_BY_ADMIN = "USER_DISABLED_BY_ADMIN",
	ERR_NETWORK = "ERR_NETWORK",
	CONVERSATION_DELETED = "CONVERSATION_DELETED",
}

export enum AuthSignInInputDtoTokenType {
	BEARER = "BEARER",
}
