import { createSlice } from "@reduxjs/toolkit";

const initialState = {};
const subscriptionTypeSlice = createSlice({
  name: "subscriptionType",
  initialState: initialState,
  reducers: {
    setCurrentSubscriptionType: (state, action) => {
      console.log("payload: ", action.payload);
      state = action.payload;
      return action.payload;
    },
  },
});

export const { setCurrentSubscriptionType } = subscriptionTypeSlice.actions;

export const selectCurrentSubscriptionType = (state: any) =>
  state.subscriptionType;

export const subscriptionTypeReducer = subscriptionTypeSlice.reducer;
