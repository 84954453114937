import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCurrentMode } from "../../../store/slices/modeSlice";
import "./css/apropos.scss";
import { Box, CssBaseline, Drawer, Toolbar } from "@mui/material";
import AppBarDrawer from "../components/AppBarDrawer";
import _Drawer from "../components/_Drawer";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import _ScrollTop from "../components/_ScrollTop";
import { selectLanguageLocal } from "../../../store/slices/languageSlice";
import { EnumLocalLang } from "../components/enums/conversation.enums";

const drawerWidth = 240;

function Policy(props: any) {
  const mode = useSelector(selectCurrentMode);
  const languageLocal: EnumLocalLang = useSelector(selectLanguageLocal);
  const theme = props.theme;

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  useEffect(() => {
    console.log("languageLocal:: ", languageLocal);
  });

  return (
    <React.Fragment>
      <Box
        sx={{ display: "flex" }}
        style={{
          color:
            mode === "dark"
              ? theme.palette.colortext.dark
              : theme.palette.colortext.light,
          backgroundColor:
            mode === "dark"
              ? theme.palette.leftbar.dark
              : theme.palette.leftbar.light,
        }}
      >
        <CssBaseline />
        <AppBarDrawer
          theme={props.theme}
          onHandleDrawerToggle={handleDrawerToggle}
        />
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <_Drawer
              theme={props.theme}
              onHandleDrawerToggle={handleDrawerToggle}
            />
          </Drawer>
        </Box>
        <Box component="main" sx={{ pl: 3, pr: 3 }}>
          <Toolbar id="back-to-top-anchor" />
          <div
            className="container wrap-apropos"
            style={
              {
                /* color:
                mode === "dark"
                  ? theme.palette.colortext.dark
                  : theme.palette.colortext.light,
              backgroundColor:
                mode === "dark"
                  ? theme.palette.colortext.dark
                  : theme.palette.colortext.light, */
              }
            }
          >
            {languageLocal === EnumLocalLang.FR ? (
              <div>
                <p>
                  <h2>Politique de confidentialité</h2>
                </p>
                <p>
                  Chez Codezys, accessible depuis codezys.io, l'une de nos
                  principales priorités est la confidentialité de nos
                  utilisateurs. Cette politique de confidentialité contient les
                  types d’information qui sont collectées et enregistrées par
                  Studa et comment nous les utilisons.
                </p>
                <p>
                  Si vous avez des questions supplémentaires ou si vous
                  souhaitez obtenir plus d'informations sur notre politique de
                  confidentialité, n'hésitez pas à nous contacter.
                </p>
                <p>
                  Cette politique de confidentialité s'applique uniquement à nos
                  activités en ligne et est valable pour les visiteurs de notre
                  site Web en ce qui concerne les informations qu'ils ont
                  partagées et/ou collectées sur Studa. Cette politique n'est
                  pas applicable aux informations collectées hors ligne ou via
                  des canaux autres que ce site web.
                </p>
                <p>
                  <h2>Définition des termes clés</h2>
                </p>
                <p>Pour cette Politique de confidentialité :</p>
                <p>
                  <strong>Compte</strong> désigne un compte unique créé pour
                  Vous afin d'accéder à notre Service ou à certaines parties de
                  notre Service.
                </p>
                <p>
                  <strong>Affilié</strong> désigne une entité qui contrôle, est
                  contrôlée par ou est sous contrôle commun avec une partie, où
                  " contrôle " signifie la propriété de 50 % ou plus des
                  actions, de la participation au capital ou d'autres titres
                  donnant droit de vote pour l'élection des administrateurs ou
                  d'autres pouvoirs de gestion.
                </p>
                <p>
                  <strong>Application</strong> désigne le programme logiciel
                  fourni par la Société, téléchargé par Vous sur tout appareil
                  électronique, nommé Studa
                </p>
                <p>
                  <strong>La Société</strong> (désignée par " la Société ", "
                  Nous ", " Notre " ou " Nos " dans le présent contrat) désigne
                  Codezys, 49, Rue de Ponthieu, Paris, Île-de-France 75008, FR.
                </p>
                <p>
                  <strong>Pays</strong> fait référence à : France
                </p>
                <p>
                  <strong>Appareil</strong> désigne tout appareil permettant
                  d'accéder au Service tel qu'un ordinateur, un téléphone
                  portable ou une tablette numérique.
                </p>
                <p>
                  <strong>Données personnelles</strong> désigne toute
                  information qui se rapporte à un individu identifié ou
                  identifiable.
                </p>
                <p>
                  <strong>Services</strong> désigne l’application mobile
                </p>
                <p>
                  <strong>Prestataire de services</strong> désigne toute
                  personne physique ou morale qui traite les données pour le
                  compte de la Société. Il s'agit de sociétés tierces ou de
                  personnes employées par la Société pour faciliter le Service,
                  pour fournir le Service au nom de la Société, pour exécuter
                  des services liés au Service ou pour aider la Société à
                  analyser la façon dont le Service est utilisé.
                </p>
                <p>
                  <strong>Les Données d'utilisation</strong> désignent les
                  données collectées automatiquement, soit générées par
                  l'utilisation du Service ou par l'infrastructure du Service
                  elle-même (par exemple, la durée d'une visite de page).
                </p>
                <p>
                  <strong>Vous</strong> désigne la personne qui accède au
                  Service ou l'utilise, ou la société ou autre entité juridique
                  au nom de laquelle cette personne accède au Service ou
                  l'utilise, selon le cas.
                </p>
                <p>
                  <h2>Consentement</h2>
                </p>
                <p>
                  En utilisant notre application, vous consentez par la présente
                  à notre politique de confidentialité et acceptez ses
                  conditions.
                </p>
                <p>
                  <h2>Information collectée</h2>
                </p>
                <p>
                  Les informations personnelles que l'on vous demande de
                  fournir, et les raisons pour lesquelles on vous demande de les
                  fournir, vous seront précisées au moment où nous vous
                  demanderons de fournir vos informations personnelles.
                </p>
                <p>
                  Si vous nous contactez directement, nous pouvons recevoir des
                  informations supplémentaires vous concernant, telles que votre
                  nom, votre adresse électronique, votre numéro de téléphone, le
                  contenu du message et/ou des pièces jointes que vous pouvez
                  nous envoyer, et toute autre information que vous pouvez
                  choisir de fournir.
                </p>
                <p>
                  Lorsque vous vous inscrivez à un compte, nous vous demandons
                  vos coordonnées, notamment votre nom, le nom de votre société,
                  votre adresse, votre adresse électronique et votre numéro de
                  téléphone.
                </p>
                <p>
                  <h2>Comment vos informations sont utilisées</h2>
                </p>
                <p>
                  Nous utilisons les informations que nous recueillons de
                  diverses manières, notamment pour :
                </p>
                <p>o Fournir, exploiter et maintenir notre appli ;</p>
                <p>o Améliorer, personnaliser et développer notre appli ;</p>
                <p>
                  o Comprendre et analyser la façon dont vous utilisez notre
                  appli ;
                </p>
                <p>
                  o Développer de nouveaux produits, services, caractéristiques
                  et fonctionnalités ;
                </p>
                <p>
                  Communiquer avec vous, soit directement, soit par
                  l'intermédiaire de l'un de nos partenaires, notamment dans le
                  cadre du service clientèle, pour vous fournir des mises à jour
                  et d'autres informations relatives au site Web, et à des fins
                  de marketing et de promotion.
                </p>
                <p>o Vous envoyer des emails ;</p>
                <p>o Trouver et prévenir les fraudes.</p>
                <h2>Supprimer vos données personnelles</h2>
                <p>
                  Vous avez le droit de supprimer ou de demander que Nous vous
                  aidions à supprimer les données personnelles que Nous avons
                  recueillies à votre sujet.
                </p>
                <p>
                  Notre Service peut vous donner la possibilité de supprimer
                  certaines informations vous concernant à partir du Service.
                </p>
                <p>
                  Vous pouvez mettre à jour, modifier ou supprimer vos
                  informations à tout moment en vous connectant à votre compte,
                  si vous en avez un, et en consultant la section des paramètres
                  du compte qui vous permet de gérer vos informations
                  personnelles. Vous pouvez également nous contacter pour
                  demander l'accès, la correction ou la suppression de toute
                  information personnelle que vous nous avez fournie.
                </p>
                <p>
                  Veuillez noter, toutefois, que nous pouvons être amenés à
                  conserver certaines informations lorsque nous avons une
                  obligation légale ou un motif légitime de le faire.
                </p>
                <h2>Divulgation de vos données personnelles</h2>
                <h3>Transactions commerciales</h3>
                <p>
                  Si la Société est impliquée dans une fusion, une acquisition
                  ou une vente d'actifs, Vos Données personnelles peuvent être
                  transférées. Nous fournirons un avis avant que Vos Données
                  personnelles ne soient transférées et ne soient soumises à une
                  Politique de confidentialité différente.
                </p>
                <h3>Application de la loi</h3>
                <p>
                  Dans certaines circonstances, la Société peut être tenue de
                  divulguer Vos Données personnelles si la loi l'exige ou en
                  réponse à des demandes valables des autorités publiques (par
                  exemple, un tribunal ou une agence gouvernementale).
                </p>
                <h3>Autres exigences légales</h3>
                <p>
                  La Société peut divulguer vos Données personnelles si elle
                  croit de bonne foi qu'une telle action est nécessaire pour :
                </p>
                <ul>
                  <li>Se conformer à une obligation légale</li>
                  <li>
                    protéger et défendre les droits ou la propriété de la
                    Société
                  </li>
                  <li>
                    prévenir ou enquêter sur d'éventuels actes répréhensibles en
                    rapport avec le Service
                  </li>
                  <li>
                    protéger la sécurité personnelle des utilisateurs du Service
                    ou du public
                  </li>
                  <li>se protéger contre toute responsabilité légale</li>
                </ul>
                <h2>Sécurité de vos données personnelles</h2>
                <p>
                  La sécurité de vos données personnelles est importante pour
                  Nous, mais n'oubliez pas qu'aucune méthode de transmission sur
                  Internet ou de stockage électronique n'est sûre à 100 %. Bien
                  que Nous nous efforcions d'utiliser des moyens commercialement
                  acceptables pour protéger Vos Données Personnelles, Nous ne
                  pouvons pas garantir leur sécurité absolue.
                </p>
                <p>
                  <h2>Fichiers journaux</h2>
                </p>
                <p>
                  Studa suit une procédure standard d'utilisation de fichiers
                  journaux. Ces fichiers enregistrent les visiteurs lorsqu'ils
                  se rendent sur des sites Web. Toutes les sociétés
                  d'hébergement le font et cela fait partie de l'analyse des
                  services d'hébergement. Les informations recueillies par les
                  fichiers journaux comprennent les adresses de protocole
                  Internet (IP), le type de navigateur, le fournisseur d'accès
                  Internet (FAI), la date et l'heure, les pages de renvoi et de
                  sortie et éventuellement le nombre de clics. Ces données ne
                  sont pas liées à des informations permettant d'identifier une
                  personne. Elles servent à analyser les tendances, à
                  administrer le site, à suivre les déplacements des
                  utilisateurs sur le site et à recueillir des informations
                  démographiques.
                </p>
                <p>
                  <h2>
                    Politiques de confidentialité des partenaires publicitaires
                  </h2>
                </p>
                <p>
                  Les serveurs publicitaires ou réseaux publicitaires tiers
                  utilisent des technologies telles que les cookies, le
                  JavaScript ou les balises Web qui sont utilisées dans leurs
                  publicités et liens respectifs qui apparaissent sur Studa, et
                  qui sont envoyées directement au navigateur des utilisateurs.
                  Ils reçoivent automatiquement votre adresse IP lorsque cela se
                  produit. Ces technologies sont utilisées pour mesurer
                  l'efficacité de leurs campagnes publicitaires et/ou pour
                  personnaliser le contenu publicitaire que vous voyez sur les
                  sites Web que vous visitez.
                </p>
                <p>
                  Notez que Studa n'a aucun accès ou contrôle sur ces cookies
                  utilisés par des annonceurs tiers.
                </p>
                <p>
                  <h2>Politiques de confidentialité des tiers</h2>
                </p>
                <p>
                  La politique de confidentialité de Studa ne s'applique pas aux
                  autres annonceurs ou sites Web. Nous vous conseillons donc de
                  consulter les politiques de confidentialité respectives de ces
                  serveurs publicitaires tiers pour obtenir des informations
                  plus détaillées. Il peut s'agir de leurs pratiques et
                  d'instructions sur la manière de refuser certaines options.
                </p>
                <p>
                  Vous pouvez choisir de désactiver les cookies par le biais des
                  options de votre navigateur individuel. Pour connaître des
                  informations plus détaillées sur la gestion des cookies avec
                  des navigateurs web spécifiques, vous pouvez consulter les
                  sites web respectifs de ces navigateurs.
                </p>
                <p>
                  <h2>Droits de protection des données GDPR</h2>
                </p>
                <p>
                  Nous tenons à nous assurer que vous êtes pleinement conscient
                  de tous vos droits en matière de protection des données.
                  Chaque utilisateur a droit à ce qui suit :
                </p>
                <p>
                  Le droit de rectification - Vous avez le droit de demander que
                  nous corrigions les informations que vous pensez être
                  inexactes. Vous avez également le droit de nous demander de
                  compléter les informations que vous jugez incomplètes.
                </p>
                <p>
                  Le droit à l'effacement - Vous avez le droit de nous demander
                  d'effacer vos données personnelles, sous certaines conditions.
                </p>
                <p>
                  Le droit de restreindre le traitement - Vous avez le droit de
                  nous demander de restreindre le traitement de vos données
                  personnelles, sous certaines conditions.
                </p>
                <p>
                  Le droit de s'opposer au traitement - Vous avez le droit de
                  vous opposer au traitement de vos données personnelles, sous
                  certaines conditions.
                </p>
                <p>
                  Le droit à la portabilité des données - Vous avez le droit de
                  nous demander de transférer les données que nous avons
                  recueillies à une autre organisation ou directement à vous,
                  sous certaines conditions.
                </p>
                <p>
                  Si vous faites une demande, nous avons un mois pour vous
                  répondre. Si vous souhaitez exercer l'un de ces droits,
                  veuillez nous contacter.
                </p>
                <p>
                  <h2>Informations pour les enfants</h2>
                </p>
                <p>
                  Une autre partie de notre priorité est d'ajouter la protection
                  des enfants lorsqu'ils utilisent l'internet. Nous encourageons
                  les parents et les tuteurs à observer, participer, et/ou
                  surveiller et guider leur activité en ligne.
                </p>
                <p>
                  Studa ne collecte pas sciemment d'informations personnelles
                  identifiables auprès d'enfants âgés de moins de 13 ans. Si
                  vous pensez que votre enfant a fourni ce type d'informations
                  sur notre site Web, nous vous encourageons vivement à nous
                  contacter immédiatement et nous ferons tout notre possible
                  pour supprimer rapidement ces informations de nos dossiers.
                </p>
                <p>
                  <h2>Nous contacter</h2>
                </p>
                <p>
                  Si vous avez des questions concernant cette politique de
                  confidentialité, nous contacter :
                </p>
                <p>
                  Par email :{" "}
                  <a href="mailto:info@codezys.com">info@codezys.com</a>
                </p>
                <p>
                  En visitant cette page sur notre site web:
                  <a href="codezys.io">codezys.io</a>
                </p>
              </div>
            ) : (
              <div>
                <p>
                  <h2>Privacy Policy for Codezys</h2>
                </p>
                <p>
                  At Codezys, accessible from the website codezys.io, one of our
                  main priorities is the privacy of our visitors. This Privacy
                  Policy document contains types of information that is
                  collected and recorded by Studa and how we use it.
                </p>
                <p>
                  If you have additional questions or require more information
                  about our Privacy Policy, do not hesitate to contact us.
                </p>
                <p>
                  This Privacy Policy applies only to our online activities and
                  is valid for visitors to our App with regards to the
                  information that they shared and/or collect in Studa. This
                  policy is not applicable to any information collected offline
                  or via channels other than this website and app.
                </p>
                <p>
                  <h2>Definition of key terms</h2>
                </p>
                <p>For the purposes of this Privacy Policy:</p>
                <p>
                  <strong>Account</strong> means a unique account created for
                  You to access our Service or parts of our Service.
                </p>
                <p>
                  <strong>Affiliate</strong> means an entity that controls, is
                  controlled by or is under common control with a party, where
                  "control" means ownership of 50% or more of the shares, equity
                  interest or other securities entitled to vote for election of
                  directors or other managing authority.
                </p>
                <p>
                  <strong>Application</strong> means the software program
                  provided by the Company downloaded by You on any electronic
                  device, named
                  <strong>Studa</strong>
                </p>
                <p>
                  <strong>Company</strong> (referred to as either "the Company",
                  "We", "Us" or "Our" in this Agreement) refers to Codezys, 49,
                  Rue de Ponthieu, Paris, Île-de-France 75008, FR.
                </p>
                <p>
                  <strong>Country</strong> refers to: France
                </p>
                <p>
                  <strong>Device</strong> means any device that can access the
                  Service such as a computer, a cellphone or a digital tablet.
                </p>
                <p>
                  <strong>Personal Data</strong> is any information that relates
                  to an identified or identifiable individual.
                </p>
                <p>
                  <strong>Service</strong> refers to the Application.
                </p>
                <p>
                  <strong>Service Provider</strong> means any natural or legal
                  person who processes the data on behalf of the Company. It
                  refers to third-party companies or individuals employed by the
                  Company to facilitate the Service, to provide the Service on
                  behalf of the Company, to perform services related to the
                  Service or to assist the Company in analyzing how the Service
                  is used.
                </p>
                <p>
                  <strong>Usage Data</strong> refers to data collected
                  automatically, either generated by the use of the Service or
                  from the Service infrastructure itself (for example, the
                  duration of a page visit).
                </p>
                <p>
                  <strong>You</strong> means the individual accessing or using
                  the Service, or the company, or other legal entity on behalf
                  of which such individual is accessing or using the Service, as
                  applicable.
                </p>
                <p>
                  <h2>Consent</h2>
                </p>
                <p>
                  By using our App, you hereby consent to our Privacy Policy and
                  agree to its terms.
                </p>
                <p>
                  <h2>Information we collect</h2>
                </p>
                <p>
                  The personal information that you are asked to provide, and
                  the reasons why you are asked to provide it, will be made
                  clear to you at the point we ask you to provide your personal
                  information.
                </p>
                <p>
                  If you contact us directly, we may receive additional
                  information about you such as your name, email address, phone
                  number, the contents of the message and/or attachments you may
                  send us, and any other information you may choose to provide.
                </p>
                <p>
                  When you register for an Account, we ask for your contact
                  information, including items such as name, address, email
                  address, and telephone number.
                </p>
                <p>
                  <h2>How we use your information</h2>
                </p>
                <p>
                  We use the information we collect in various ways, including
                  to:
                </p>
                <p>o Provide, operate, and maintain our app;</p>
                <p>o Improve, personalize, and expand our app;</p>
                <p>o Understand and analyze how you use our app;</p>
                <p>
                  o Develop new products, services, features, and functionality;
                </p>
                <p>
                  Communicate with you, either directly or through one of our
                  partners, including for customer service, to provide you with
                  updates and other information relating to the website, and for
                  marketing and promotional purposes
                </p>
                <p>o Send you emails or SMS;</p>
                <p>o Find and prevent fraud.</p>
                <h2>Delete Your Personal Data</h2>
                <p>
                  You have the right to delete or request that We assist in
                  deleting the Personal Data that We have collected about You.
                </p>
                <p>
                  Our Service may give You the ability to delete certain
                  information about You from within the Service.
                </p>
                <p>
                  You may update, amend, or delete Your information at any time
                  by signing in to Your Account, if you have one, and visiting
                  the account settings section that allows you to manage Your
                  personal information. You may also contact Us to request
                  access to, correct, or delete any personal information that
                  You have provided to Us.
                </p>
                <p>
                  Please note, however, that We may need to retain certain
                  information when we have a legal obligation or lawful basis to
                  do so.
                </p>
                <h2>Disclosure of Your Personal Data</h2>
                <h3>Business Transactions</h3>
                <p>
                  If the Company is involved in a merger, acquisition or asset
                  sale, Your Personal Data may be transferred. We will provide
                  notice before Your Personal Data is transferred and becomes
                  subject to a different Privacy Policy.
                </p>
                <h3>Law enforcement</h3>
                <p>
                  Under certain circumstances, the Company may be required to
                  disclose Your Personal Data if required to do so by law or in
                  response to valid requests by public authorities (e.g. a court
                  or a government agency).
                </p>
                <h3>Other legal requirements</h3>
                <p>
                  The Company may disclose Your Personal Data in the good faith
                  belief that such action is necessary to:
                </p>
                <ul>
                  <li>Comply with a legal obligation</li>
                  <li>
                    Protect and defend the rights or property of the Company
                  </li>
                  <li>
                    Prevent or investigate possible wrongdoing in connection
                    with the Service
                  </li>
                  <li>
                    Protect the personal safety of Users of the Service or the
                    public
                  </li>
                  <li>Protect against legal liability</li>
                </ul>
                <h2>Security of Your Personal Data</h2>
                <p>
                  The security of Your Personal Data is important to Us, but
                  remember that no method of transmission over the Internet, or
                  method of electronic storage is 100% secure. While We strive
                  to use commercially acceptable means to protect Your Personal
                  Data, We cannot guarantee its absolute security.
                </p>
                <p>
                  <h2>Log Files</h2>
                </p>
                <p>
                  Studa follows a standard procedure of using log files. These
                  files log visitors when they visit websites. All hosting
                  companies do this and a part of hosting services' analytics.
                  The information collected by log files include internet
                  protocol (IP) addresses, browser type, Internet Service
                  Provider (ISP), date and time stamp, referring/exit pages, and
                  possibly the number of clicks. These are not linked to any
                  information that is personally identifiable. The purpose of
                  the information is for analyzing trends, administering the
                  site, tracking users' movement on the app, and gathering
                  demographic information.
                </p>
                <p>
                  <h2>Advertising Partners Privacy Policies</h2>
                </p>
                <p>
                  Third-party ad servers or ad networks uses technologies like
                  cookies, JavaScript, or Web Beacons that are used in their
                  respective advertisements and links that appear on Studa,
                  which are sent directly to users' browser. They automatically
                  receive your IP address when this occurs. These technologies
                  are used to measure the effectiveness of their advertising
                  campaigns and/or to personalize the advertising content that
                  you see on websites that you visit.
                </p>
                <p>
                  Note that Studa has no access to or control over these cookies
                  that are used by third-party advertisers.
                </p>
                <p>
                  <h2>Third Party Privacy Policies</h2>
                </p>
                <p>
                  Studa's Privacy Policy does not apply to other advertisers or
                  websites and apps. Thus, we are advising you to consult the
                  respective Privacy Policies of these third-party ad servers
                  for more detailed information. It may include their practices
                  and instructions about how to opt-out of certain options.
                </p>
                <p>
                  You can choose to disable cookies through your individual
                  browser options. To know more detailed information about
                  cookie management with specific web browsers, it can be found
                  at the browsers' respective websites.
                </p>
                <p>
                  If you make a request, we have one month to respond to you. If
                  you would like to exercise any of these rights, please contact
                  us.
                </p>
                <p>
                  <h2>GDPR Data Protection Rights</h2>
                </p>
                <p>
                  We would like to make sure you are fully aware of all of your
                  data protection rights. Every user is entitled to the
                  following:
                </p>
                <p>
                  The right to rectification – You have the right to request
                  that we correct any information you believe is inaccurate. You
                  also have the right to request that we complete the
                  information you believe is incomplete.
                </p>
                <p>
                  The right to erasure – You have the right to request that we
                  erase your personal data, under certain conditions.
                </p>
                <p>
                  The right to restrict processing – You have the right to
                  request that we restrict the processing of your personal data,
                  under certain conditions.
                </p>
                <p>
                  The right to object to processing – You have the right to
                  object to our processing of your personal data, under certain
                  conditions.
                </p>
                <p>
                  The right to data portability – You have the right to request
                  that we transfer the data that we have collected to another
                  organization, or directly to you, under certain conditions.
                </p>
                <p>
                  If you make a request, we have one month to respond to you. If
                  you would like to exercise any of these rights, please contact
                  us.
                </p>
                <p>
                  <h2>Children's Information</h2>
                </p>
                <p>
                  Another part of our priority is adding protection for children
                  while using the internet. We encourage parents and guardians
                  to observe, participate in, and/or monitor and guide their
                  online activity.
                </p>
                <p>
                  Studa does not knowingly collect any Personal Identifiable
                  Information from children under the age of 13. If you think
                  that your child provided this kind of information on our
                  website, we strongly encourage you to contact us immediately
                  and we will do our best efforts to promptly remove such
                  information from our records.
                </p>
                <p>
                  <h2>Contacting Us</h2>
                </p>
                <p>
                  If you have any questions about this Privacy Policy, you can
                  contact us:
                </p>

                <p>
                  By email :{" "}
                  <a href="mailto:info@codezys.com">info@codezys.com</a>
                </p>
                <p>
                  By visiting this page on our website:
                  <a href="codezys.io">codezys.io</a>
                </p>
              </div>
            )}
          </div>
        </Box>

        <_ScrollTop {...props}>
          <Fab size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </_ScrollTop>
      </Box>
    </React.Fragment>
  );
}

export default Policy;
