import React, { useEffect, useState } from "react";
import StructureService from "../../structure-service";
import Lottie from "react-lottie";
import studaLoad from "../../../assets/lotties/load.json";
import studaLogo from "../../../assets/lotties/studa.json";
import LoadingButton from "@mui/lab/LoadingButton";
import { Country } from "../../../interfaces/structure.interface";
import { useLocation, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/userSlice";
import {
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import { CheckCircle, Drafts, Inbox } from "@mui/icons-material";
import KEYS from "../../../helpers/KEYS";
import {
	selectCurrentMenu,
	setCurrentMenu,
} from "../../../store/slices/menuSlice";
import { publish } from "../../../helpers/helpers";
import { selectLanguageLocal } from "../../../store/slices/languageSlice";
import { setCurrentBackTo } from "../../../store/slices/backToSlice";
import { EnumBackTo } from "../../subscription/enums/subscription.enums";
import { selectCurrentMode } from "../../../store/slices/modeSlice";

function SelectCountry({ theme }: any) {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const pathname = location.pathname.split("/dashboard");
	const path = pathname.length > 0 ? pathname[1] : null;
	console.log("path: ", path);
	console.log("theme: ", theme);
	const user = useSelector(selectUser);

	const currentMenu = useSelector(selectCurrentMenu);
	// console.log("currentMenu: ", currentMenu);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [countries, setCountries] = useState<any>([]);
	const [selectedOption, setselectedOption] = useState<any>(null);
	const [selectedIndex, setSelectedIndex] = useState<number>(-1);
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: studaLogo,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	const loadOptions = {
		loop: true,
		autoplay: true,
		animationData: studaLoad,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	useEffect(() => {
		getAllCountries();
		console.log("theme=======", theme);
	}, [theme]);

	const handleChange = (selectedOption: any) => {
		setselectedOption(selectedOption);
		console.log(selectedOption);
		/*this.setState({
		  selectedOption: selectedOption ? selectedOption.label : ""
		});*/
	};

	const getAllCountries = () => {
		setIsLoading(true);
		StructureService.getCountries()
			.then((res) => {
				let data: any = [];
				data = res.data.data.map((element: any) => {
					let country: any = {
						code: element.code,
						name_en: element.name_en,
						name_fr: element.name_fr,
						label: element.name_fr,
						value: element.code,
						flag:
							KEYS.HOST_RESOURCE +
							"/flags/" +
							element.code.toLowerCase() +
							".png",
					};
					console.log(country);
					return country;
				});
				setIsLoading(false);
				setCountries(data);
				if (user) {
					let countryCode =
						user.currentClass != undefined && user.currentClass != ""
							? user.currentClass.split("#")[0]
							: null;
					if (countryCode) {
						let index = 0;
						data.forEach((element: Country) => {
							if (element.code == countryCode) {
								setselectedOption(element);
								setSelectedIndex(index);
								publish("currentCountry", element); // new add
							}
							index++;
						});
					}
				}
			})
			.catch((err) => {
				setIsLoading(false);
			});
	};

	const saveCountry = () => {
		if (path !== undefined && path !== null) {
			dispatch(setCurrentMenu({ title: "", code: 0 })); // close first when you want to open #DisplayBox on another #DisplayBox

			setTimeout(function () {
				dispatch(
					setCurrentMenu({
						title: "Select class",
						code: 8,
						countryCode: selectedOption?.code ?? "",
					})
				);
			});

			dispatch(setCurrentBackTo({ backTo: EnumBackTo.COUNTRY }));
		} else {
			/* navigate("/dashboard/select-class", {
        state: { countryCode: selectedOption?.code ?? "" },
      }); */
			navigate("/auth/select-class", {
				state: { countryCode: selectedOption?.code ?? "" },
			});
		}
	};

	const onSelectCountry = (index: number) => {
		setSelectedIndex(index);
		setselectedOption(countries[index]);

		// console.log("country selected", countries[index]);
		publish("currentCountry", countries[index]); // New add: publish event with data #country
	};
	const mode = useSelector(selectCurrentMode);

	return (
		<div
			className="connexion-box"
			style={{
				backgroundColor:
					mode === "dark"
						? theme.palette.leftbar.dark
						: theme.palette.leftbar.light,
			}}
		>
			<div
				className={
					/* path */ currentMenu.code === 7 && path !== undefined
						? "wrapper-2"
						: "wrapper"
				}
			>
				{!isLoading ? (
					<div
						className="container"
						style={{
							paddingBottom: "25px",
							paddingLeft:
								currentMenu.code === 7 && path !== undefined ? "10px" : "",
							paddingRight:
								currentMenu.code === 7 && path !== undefined ? "10px" : "",
						}}
					>
						{path !== undefined ? null : (
							<div className="logo">
								<Lottie
									options={defaultOptions}
									width={60}
									style={{
										paddingBottom:
											currentMenu.code === 7 && path !== undefined
												? "25px"
												: "55px",
									}}
								/>
							</div>
						)}

						<div className="">
							<div
								className="title title-bold"
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<div style={{ maxWidth: "250px" }}>
									<span
										style={{
											fontSize: "1rem",
											color:
												mode === "dark"
													? theme.palette.colortext.dark
													: theme.palette.colortext.light,
										}}
									>
										Sélectionnez le pays dans lequel vous souhaitez étudier
									</span>
								</div>
							</div>

							<div
								className="wrap-data"
								style={{
									minWidth: "100%",
									paddingTop: "25px",
									textAlign: "center",
								}}
							>
								{/*<CustumSelect
									options={countries}
									onChange={handleChange}
							/>*/}

								<nav aria-label="main mailbox folders">
									<List>
										{countries.map((country: any, index: number) => {
											return (
												<ListItem
													key={index}
													disablePadding
													style={{
														backgroundColor:
															selectedIndex === index
																? theme.palette.primary.main
																: "#eee",
														color: selectedIndex === index ? "white" : "#333",
														borderRadius: "5px",
													}}
													sx={{
														mb: 1,
													}}
												>
													<ListItemButton
														onClick={() => onSelectCountry(index)}
													>
														<ListItemIcon>
															<img
																src={`${country.flag}`}
																alt={country.name_fr}
																loading="lazy"
																width={20}
																height={15}
																// width={30}
															/>
														</ListItemIcon>

														<ListItemText
															primary={country.name_fr}
															sx={{
																marginLeft: "6px",
															}}
															primaryTypographyProps={{ fontSize: "0.8rem" }}
														/>
														{selectedIndex == index ? (
															<ListItemIcon
																style={{
																	minWidth: "35px",
																	paddingRight: "10px",
																}}
															>
																<CheckCircle style={{ color: "white" }} />
															</ListItemIcon>
														) : (
															<></>
														)}
													</ListItemButton>
												</ListItem>
											);
										})}
									</List>
								</nav>
							</div>

							<div className="btn-connexion btn-connexion-2">
								<LoadingButton
									disableElevation
									disabled={selectedIndex < 0}
									loading={isLoading}
									className="btn btn-lg"
									endIcon={<></>}
									loadingPosition="end"
									variant="contained"
									style={{ textTransform: "none", fontSize: "0.80rem" }}
									onClick={saveCountry}
								>
									Valider
								</LoadingButton>
							</div>
						</div>
					</div>
				) : (
					<Lottie
						options={loadOptions}
						height={100}
						width={80}
						style={{ paddingBottom: "55px" }}
					/>
				)}
			</div>
		</div>
	);
}

export default SelectCountry;
