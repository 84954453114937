import { configureStore } from "@reduxjs/toolkit";
import { backToReducer } from "./slices/backToSlice";
import { conversationReducer } from "./slices/conversationSlice";
import { conversationsReducer } from "./slices/conversationsSlice";
import { languageLocalReducer } from "./slices/languageSlice";
import { loadingReducer } from "./slices/loadingSlice";
import { menuReducer } from "./slices/menuSlice";
import { modeReducer } from "./slices/modeSlice";
import { pageReducer } from "./slices/pageSlice";
import { subjectReducer } from "./slices/subjectSlice";
import { subscriptionReducer } from "./slices/subscriptionSlice";
import { subscriptionsReducer } from "./slices/subscriptionsSlice";
import { subscriptionTypeReducer } from "./slices/subscriptionTypeSlice";
import { userReducer } from "./slices/userSlice";
export const store = configureStore({
	reducer: {
		user: userReducer,
		menu: menuReducer,
		conversation: conversationReducer,
		conversations: conversationsReducer,
		subject: subjectReducer,
		subscriptionType: subscriptionTypeReducer,
		subscription: subscriptionReducer,
		subscriptions: subscriptionsReducer,
		language: languageLocalReducer,
		mode: modeReducer,
		backTo: backToReducer,
		page: pageReducer,
		loading: loadingReducer,
	},

	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
