import { Box, Button, Stack, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import SwipeableTextMobileStepper, {
  Image,
} from "./swipeableTextMobileStepper";
import img from "../../image/img.png";
import img2 from "../../image/img2.png";
import img3 from "../../image/img3.png";
import img4 from "../../image/img4.png";
import img5 from "../../image/img5.png";
import { Link } from "react-router-dom";

const images: Image[] = [
  {
    label: "Img",
    imgPath: img,
  },
  {
    label: "Img2",
    imgPath: img2,
  },
  {
    label: "Img3",
    imgPath: img3,
  },
  {
    label: "Img4",
    imgPath: img4,
  },
  {
    label: "Img5",
    imgPath: img5,
  },
];

const WhatSection = () => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      // justifyContent="space-between"
      // justifyContent="flex-start"
      alignItems="center"
      spacing={{ md: 20, xm: 5, xs: 5 }}
    >
      {/* Right content */}
      <Stack
        color="#000000"
        spacing={3}
        maxWidth={{ md: "100%", xs: "100%", sm: "100%" }}
        // maxWidth={{ md: 380, xs: "100%", sm: "100%" }}
      >
        <Typography variant="h4" component="div" fontWeight="semi-bold">
          What is Studa?
        </Typography>
        <Typography variant="body1">
          Studa est une application mobile qui permet aux élèves de s'évaluer
          sur des unités d'enseignements qui sont présents dans leurs programmes
          scolaires. Studa les aide à se préparer aux examens officiels comme le
          BEPC, Le Probatoire et le Baccalauréat à l'aides des question à
          réponses ouvertes et des quiz.
        </Typography>

        <Box display="block">
          <Button
            component={Link}
            to={"/auth"}
            variant="contained"
            sx={{ textTransform: "none" }}
            disableElevation
            startIcon={<Icon icon="streamline:startup-solid" />}
          >
            Get Started
          </Button>
        </Box>
      </Stack>

      <SwipeableTextMobileStepper
        images={images}
        maxWidthBox={300}
        maxWidthImg={400}
        maxHeightImg={600}
      />
    </Stack>
  );
};

export default WhatSection;
