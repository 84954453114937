import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  CircularProgress,
  Typography,
  ListItemText,
  TextField,
} from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";
import LinkIcon from "@mui/icons-material/Link";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import DateAndTimePickers from "./DateAndTimePickers";
import SubscriptionService from "../services/subscription-service";
import {
  PaymentRequest,
  Subscription,
} from "../interfaces/subscription.interface";
import { useDispatch, useSelector } from "react-redux";
import { selectLanguageLocal } from "../../../store/slices/languageSlice";
import { EnumStatusResponse } from "../enums/subscription.enums";
import { useIntl } from "react-intl";
import { setCurrentSubscription } from "../../../store/slices/subscriptionSlice";
import { setCurrentMenu } from "../../../store/slices/menuSlice";
import { setCurrentSubscriptionType } from "../../../store/slices/subscriptionTypeSlice";
import "./css/BoxPayement.scss";
import CinetPayService from "../services/CinetPayService";
import { subscriptionAdded } from "../../../store/slices/subscriptionsSlice";
import { useStyles1 } from "../../../helpers/sharedStyle";
import { selectCurrentMode } from "../../../store/slices/modeSlice";
import { useSnackbar } from "notistack";

function BoxPayment({ subscriptionTypeSelected, onSubscription, theme }: any) {
  const intl = useIntl();
  const { formatMessage } = intl;
  const dispatch = useDispatch();
  const hasSubscription =
    onSubscription !== null && onSubscription.hasOwnProperty("subscriptionId");
  const languageLocal: string = useSelector(selectLanguageLocal);
  const [isByLink, setisByLink] = React.useState(false);
  const [isDirect, setisDirect] = React.useState(false);
  const [isPayementUrl, setIsPayementUrl] = React.useState<string>("");
  const [transactionId, setTransactionId] = React.useState<string>("");
  const [isStartDate, setIsStartDate] = React.useState<number>();
  const [voucherCode, setVoucherCode] = React.useState<string>("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles1();

  const listener = async (data: any) => {
    const transId = data.detail.transactionId;
    let resultUpdate = await SubscriptionService.updatePayment(
      transId,
      languageLocal
    );
    const subscription = resultUpdate.data;

    await dispatch(setCurrentMenu({ title: "", code: 0 }));

    setTimeout(function () {
      dispatch(
        setCurrentMenu({
          title: formatMessage({ id: "details_of_subscription_types" }),
          code: 6,
        })
      );
    });
    dispatch(setCurrentSubscription(subscription));

    dispatch(subscriptionAdded(subscription as Subscription)); // Add subscription in store #My subscriptions
  };

  function subscribe(
    eventName: string,
    listener: EventListenerOrEventListenerObject
  ) {
    document.addEventListener(eventName, listener);
  }

  function unsubscribe(
    eventName: string,
    listener: EventListenerOrEventListenerObject
  ) {
    document.removeEventListener(eventName, listener);
  }

  useEffect(() => {});

  useEffect(() => {
    subscribe("cinetpay", listener);
    return () => {
      unsubscribe("cinetpay", listener);
    };
  }, [listener]);

  const getDirectPaymentId = async () => {
    const paymentRequest1: PaymentRequest = {
      subscriptionTypeCode: subscriptionTypeSelected.code,
      lang: languageLocal,
      startDate: isStartDate,
      voucherCode: voucherCode?.trim(),
    };

    const paymentRequest2: PaymentRequest = {
      subscriptionId:
        onSubscription !== null &&
        onSubscription.hasOwnProperty("subscriptionId")
          ? onSubscription.subscriptionId
          : null,
      lang: languageLocal,
      startDate: isStartDate,
    };
    SubscriptionService.createDirectPayment(
      onSubscription !== null && onSubscription.hasOwnProperty("subscriptionId")
        ? paymentRequest2
        : paymentRequest1
    )
      .then((response) => {
        console.log("Create Direct payment Response: ",response );

        displayBoxDetailSubscription(response.data.data.subscription);

        const transId = response.data.data.transactionId;
        setTransactionId(transId);
        const payments = response?.data?.data?.subscription?.payments;
        let price = payments.length
          ? payments[payments.length - 1].amount
          : subscriptionTypeSelected.amount;

        CinetPayService.pay(
          transId,
          parseInt(price),
          subscriptionTypeSelected.currency,
          subscriptionTypeSelected.description
        );
      })
      .catch((error) => {
        enqueueSnackbar(formatMessage({ id: "an_error_has_occurred" }), {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
          autoHideDuration: 2000,
        });
        setisDirect(false);
      });
    setisByLink(false);
    setisDirect(true);
  };

  const getPaymentLink = () => {
    const paymentRequest1: PaymentRequest = {
      subscriptionTypeCode: subscriptionTypeSelected.code,
      lang: languageLocal,
      startDate: isStartDate,
      voucherCode: voucherCode?.trim(),
    };

    const paymentRequest2: PaymentRequest = {
      subscriptionId:
        onSubscription !== null &&
        onSubscription.hasOwnProperty("subscriptionId")
          ? onSubscription.subscriptionId
          : null,
      lang: languageLocal,
      startDate: isStartDate,
    };
    setisByLink(true);
    setisDirect(false);
    SubscriptionService.createPaymentLinkPayment(
      onSubscription !== null && onSubscription.hasOwnProperty("subscriptionId")
        ? paymentRequest2
        : paymentRequest1
    )
      .then(
        (response: any) => {
             if (
            response.data.code == EnumStatusResponse.SUCCESS &&
            response.data.statusCode == EnumStatusResponse.SUCCESS
          ) {

            setIsPayementUrl(response.data.data.paymentUrl as string);
            displayBoxDetailSubscription(response.data.data.subscription);
            /*dispatch(
              setCurrentSubscription(data.data.subscription as Subscription)
            );

            dispatch(subscriptionAdded(data.data.subscription as Subscription)); // Add subscription in store #My subscriptions

            dispatch(setCurrentSubscriptionType({})); // Reset value subscriptionTypeSlice

            dispatch(setCurrentMenu({ title: "", code: 0 })); // close first when you want to open #DisplayBox on another #DisplayBox
            setTimeout(function () {
              // Display Box #Detail abonnement
              dispatch(
                setCurrentMenu({
                  title: formatMessage({ id: "details_of_subscription_types" }),
                  code: 6,
                })
              );
            });*/
          }
        },
        (error) => {
          console.log("Error: ", error);
          enqueueSnackbar(formatMessage({ id: "an_error_has_occurred" }), {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }
      )
      .finally(() => {
        console.log("Completed request!");
        setisByLink(false);
      });
  };

  const handleClickDate = (event: number) => {
    console.log("event: ", event);
    setIsStartDate(event);
  };

  const displayBoxDetailSubscription = (subscription: any) => {
    dispatch(setCurrentMenu({ title: "", code: 0 }));
    setTimeout(function () {
      dispatch(
        setCurrentMenu({
          title: formatMessage({ id: "details_of_subscription_types" }),
          code: 6,
        })
      );
    });
    dispatch(setCurrentSubscription(subscription));
    dispatch(setCurrentSubscriptionType({})); // Reset value subscriptionTypeSlice To hide #BoxPayment
  };
  const mode = useSelector(selectCurrentMode);
  return (
    <Box>
      <List
        sx={{
          width: "100%",
          maxWidth: 680,
          background: "white",
        }}
        style={{
          width: "100%",
          color:
            mode === "dark"
              ? theme.palette.colortext.dark
              : theme.palette.colortext.light,
          fontWeight: "600",

          backgroundColor:
            mode === "dark"
              ? theme.palette.backgroundchat.dark
              : theme.palette.contentbox.light,

          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, 70%)",
          padding: 0,
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            paddingLeft: "32px",
            paddingTop: "10px",
            paddingBottom: "10px",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <Typography
            component="span"
            className={classes.title}
            style={{ fontSize: "1rem" }}
          >
            Pack {subscriptionTypeSelected.name}
          </Typography>
        </div>

        <div
          style={{
            paddingLeft: "32px",
            paddingTop: "10px",
            paddingBottom: "10px",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <span style={{ fontSize: "18px" }}>
            {formatMessage({ id: "my_payment_will_take_effect_from" })}
          </span>
          <DateAndTimePickers
            theme={theme}
            onStartDate={
              onSubscription && onSubscription.nextBillingDate
                ? onSubscription.nextBillingDate
                : ""
            }
            onHandleClickDate={(event: number) => handleClickDate(event)}
          />
        </div>
        {!hasSubscription && (
          <div
            style={{
              paddingLeft: "32px",
              paddingBottom: "10px",
              color: "white",
            }}
          >
            <br />
            <Typography>{formatMessage({ id: "voucher_message" })}</Typography>
            <TextField
              InputProps={
                {
                  // style: {
                  //    color: "text.secondary",
                  // },
                  // color: "secondary",
                }
              }
              color="secondary"
              label={formatMessage({ id: "voucher_label" })}
              variant="outlined"
              value={voucherCode}
              onChange={(e) => {
                setVoucherCode(e.target.value);
              }}
            />
          </div>
        )}

        <div
          style={{
            paddingLeft: "48px",
            paddingTop: "20px",
            paddingBottom: "10px",
          }}
        >
          <Typography>
            {formatMessage({ id: "payment_sub_message" })}
          </Typography>
        </div>
        <ListItem
          disablePadding
          secondaryAction={
            isDirect && (
              <CircularProgress
                size="1.5rem"
                disableShrink
                color="primary"
                style={{ marginLeft: "1rem" }}
              />
            )
          }
          onClick={() => getDirectPaymentId()}
          style={{}}
        >
          <ListItemButton
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <ListItemIcon style={{}}>
              <PaidIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              style={{ marginTop: 0 }}
              primary={
                <Typography
                  component={"span"}
                  sx={{}}
                  style={{ fontSize: "21px", fontWeight: 600 }}
                >
                  {formatMessage({ id: "direct_payment" })}
                </Typography>
              }
              secondary={
                <Typography style={{ width: "480px", fontStyle: "italic" }}>
                  {formatMessage({ id: "make_payment_from_you" })}
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>

        <ListItem
          disablePadding
          secondaryAction={
            isByLink && (
              <CircularProgress size="1.5rem" disableShrink color="primary" />
            )
          }
          onClick={() => getPaymentLink()}
        >
          <ListItemButton
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "8px",
              marginBottom: "20px",
            }}
          >
            <ListItemIcon>
              <LinkIcon color="primary" />
            </ListItemIcon>

            <ListItemText
              primary={
                <Typography
                  component={"span"}
                  sx={{}}
                  style={{ fontSize: "21px", fontWeight: 600 }}
                >
                  {formatMessage({ id: "generate_link" })}
                </Typography>
              }
              secondary={
                <Typography style={{ width: "480px", fontStyle: "italic" }}>
                  {formatMessage({ id: "generate_copy_share_the_link" })}
                </Typography>
              }
              style={{ marginTop: 0 }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}

BoxPayment.defaultProps = {
  subscriptionTypeSelected: {},
};

BoxPayment.propTypes = {
  subscriptionTypeSelected: PropTypes.object.isRequired,
  onSubscription: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

export default BoxPayment;
