import { getToken } from "firebase/messaging";
import messaging from "../firebaseConf";
import platform from "platform";
import { DeviceData } from "../modules/authentification/interfaces/auth.interface";
import "moment/locale/fr";
import { EnumDurationType } from "../modules/subscription/enums/subscription.enums";
import moment from "moment";

moment().locale("fr");

const getCorrectPhoneNumber = (phone: string) => {
  if (phone != "" && phone != null) {
    let data = phone.split("#");
    return data.length == 2 ? data[1] : null;
  }
  return null;
};
const timeStampToStringDate = (time: number) => {
  var dateFormat = new Date(time);
  let dateNow =
    dateFormat.getFullYear() +
    "" +
    (dateFormat.getMonth() + 1 < 10
      ? "0" + (dateFormat.getMonth() + 1)
      : dateFormat.getMonth() + 1) +
    "" +
    (dateFormat.getDate() < 10
      ? "0" + dateFormat.getDate()
      : dateFormat.getDate());
  return dateNow;
};
const getDurationTime = (time: number) => {
  var dateInString = timeStampToStringDate(time);
  return moment(dateInString, "YYYYMMDD").fromNow();
};

const getTimeInString = (time: number) => {
  var dateInString = timeStampToStringDate(time);
  return moment(dateInString, "YYYYMMDD").format(/* "MMMM Do YYYY" */);
};

const getDeviceId = () => {
  let deviceId: string = window.navigator.userAgent;
  return deviceId;
};

const getDevice = async () => {
  let deviceData: DeviceData = {
    deviceID: getDeviceId(),
    OS: platform.os?.family ?? "",
    name: platform.name ?? "",
    status: 1,
    notificationToken: "",
    isDeleted: false,
  };

  const userAgent = "";

  return await getToken(messaging)
    .then((currentToken: any) => {
      if (currentToken) {
        //let uuid = DeviceUUID.get();

        deviceData.notificationToken = currentToken;
        return deviceData;
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        return deviceData;
      }
    })
    .catch((err: any) => {
      console.log("An error occurred while retrieving token. ", err);
      return deviceData;
    });
};

const phoneNumberToDisplay = (phone?: string) => {
  let number: string = "";
  if (phone !== "" && phone !== undefined && phone != null) {
    let phone_: Array<string> = phone.split("#");
    number = `+${phone_[0]}${phone_[1]}`;
  }

  debugger;
  debugger;
  return number;
};

const getNewDateAtGmt = (time: number) => {
  // const oldDate = new Date();
  const oldDate = new Date(time);
  const timezoneTo = 0; //GMT_TIMEZONE
  const newDate = new Date(
    oldDate.getTime() + 60000 * (oldDate.getTimezoneOffset() - timezoneTo)
  );
  return newDate;
};

const convertDateToDateObject = (time: number) => {

  const date = new Date(time);
  const [month, day, year, hour, minutes, seconds] = [
    date.getMonth(),
    date.getDate(),
    date.getFullYear(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ];

  const extractDate = `${year} ${
    month + 1
  } ${day} ${hour} ${minutes} ${seconds}`;

  return extractDate;
};

//  Convertion de l'heure en heure local du pc
const convertUTCDateToLocalDate = (date: Date) => {
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();
  newDate.setHours(hours - offset);

  return newDate;
};

//  Convertion de l'heure en heure local du pc
const convertUTCDateToLocalDate2 = (date: Date) => {
  var newDate = new Date(date);
  newDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return newDate;
};

const getOffsetTime = (
  quantity: number = 2,
  durationType: EnumDurationType = EnumDurationType.HOUR
) => {
  let timeSeconds: number = 0;
  console.log("la quantite est: ", quantity);
  console.log("La duration est: ", durationType);
  switch (durationType) {
    case EnumDurationType.HOUR:
      timeSeconds = 3600;
      break;
    case EnumDurationType.DAY:
      timeSeconds = 24 * 3600;
      break;
    case EnumDurationType.WEEK:
      timeSeconds = 7 * 24 * 3600;
      break;
    case EnumDurationType.MONTH:
      timeSeconds = 30 * 24 * 3600;
      break;
    case EnumDurationType.YEAR:
      timeSeconds = 12 * 30 * 24 * 3600;
      break;
    default:
      break;
  }

  let timeMilliSeconds: number = timeSeconds * 1000 * quantity!;
  return timeMilliSeconds;
};

const publish = (eventName: any, data: any) => {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
};

const subscribe = (
  eventName: string,
  listener: EventListenerOrEventListenerObject
) => {
  document.addEventListener(eventName, listener);
};

const unsubscribe = (
  eventName: string,
  listener: EventListenerOrEventListenerObject
) => {
  console.log("La desouscription");
  document.removeEventListener(eventName, listener);
};

/**
 * Fonction permettant de formatter les dates et heures
 *  des conversations comme dans Whatsapp
 * @param startDate
 * @param lang
 * @returns
 */
const formatDate = (startDate: number, langue: string) => {

  const lang = langue && langue != null ? langue : "fr";
  moment.locale(lang);

/*   const startDateLocal = convertUTCDateToLocalDate2(
    new Date(startDate)
  ).getTime(); */
  
  const startDateLocal = startDate

  const currentDate = new Date().getTime();
  const startDateMoment = moment(startDateLocal);

  switch (true) {
    case moment(startDateLocal).isSame(moment(currentDate), "day"):
      return moment(startDateLocal).format("LT");

    case moment(startDateMoment).isSame(
      moment(currentDate).subtract(1, "day"),
      "day"
    ):
      return lang == "fr" ? `Hier` : `Yesterday`;

    case moment(startDateMoment).isSame(currentDate, "week"):
      return moment(startDateMoment).format("dddd");

    default:
      return moment(startDateMoment).format("L");
  }
};

export {
  getCorrectPhoneNumber,
  getDurationTime,
  getTimeInString,
  getDevice,
  getDeviceId,
  phoneNumberToDisplay,
  timeStampToStringDate,
  getNewDateAtGmt,
  convertUTCDateToLocalDate,
  formatDate,
  publish,
  subscribe,
  unsubscribe,
  convertDateToDateObject,
  getOffsetTime,
};
