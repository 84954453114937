import React, { useEffect, useState } from "react";
import StructureService from "../../structure-service";
import Lottie from "react-lottie";
import studaLoad from "../../../assets/lotties/load.json";
import studaLogo from "../../../assets/lotties/studa.json";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation, useNavigate } from "react-router-dom";
import AuthService from "../services/auth-service";
import { User } from "../../../interfaces/user.interface";
import { setUser } from "../../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/userSlice";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Book, CheckCircle } from "@mui/icons-material";
import {
  selectCurrentMenu,
  setCurrentMenu,
} from "../../../store/slices/menuSlice";
import { useSnackbar } from "notistack";
import { publish } from "../../../helpers/helpers";
import { setCurrentSubscription } from "../../../store/slices/subscriptionSlice";
import { useIntl } from "react-intl";
import { ConversationRequest } from "../../conversation/interfaces/chat.interface";
import {
  EnumStatusCode,
  EnumStatusResponse,
} from "../../subscription/enums/subscription.enums";
import { Subscription } from "../../subscription/interfaces/subscription.interface";
import SubscriptionService from "../../subscription/services/subscription-service";
import { selectLanguageLocal } from "../../../store/slices/languageSlice";
import { setCurrentBackTo } from "../../../store/slices/backToSlice";
import { selectCurrentMode } from "../../../store/slices/modeSlice";
import { saveAllSubject } from "../../../store/slices/subjectSlice";
import { setCurrentSubscriptionType } from "../../../store/slices/subscriptionTypeSlice";
import { setCurrentConversation } from "../../../store/slices/conversationSlice";

function SelectClass({ theme }: any) {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathname = location.pathname.split("/dashboard");
  const path = pathname.length > 0 ? pathname[1] : null;
  console.log("State location:: ", location?.state);

  const user = useSelector(selectUser);
  const currentMenu = useSelector(selectCurrentMenu);
  const languageLocal: string = useSelector(selectLanguageLocal);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoading2, setIsLoading2] = useState<boolean>(false);
  const [classes, setClasses] = useState<any>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [selectedOption, setselectedOption] = useState<any>(null);
  const [countryCode, setCountryCode] = useState<any>(
    location?.state?.countryCode ?? currentMenu.countryCode
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: studaLogo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const loadOptions = {
    loop: true,
    autoplay: true,
    animationData: studaLoad,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    getAllClasses();
  }, []);

  const handleChange = (selectedOption: any) => {
    setselectedOption(selectedOption);
    console.log(selectedOption);
  };

  const getAllClasses = () => {
    setIsLoading(true);
    StructureService.getClasses(countryCode)
      .then((res) => {
        let data: any = [];
        data = res.data.data.map((element: any) => {
          let classe: any = {
            code: element.code,
            name: element.name,
            level: element.level,
            label: element.name,
            value: element.code,
          };

          return classe;
        });
        console.log(data);
        setIsLoading(false);
        console.log(user.currentClass);
        setClasses(data);

        if (user) {
          let classCode =
            user.currentClass != undefined && user.currentClass != ""
              ? user.currentClass.split("#")[1]
              : null;

          if (classCode) {
            let index = 0;
            data.forEach((element: any) => {
              console.log(element.code);
              if (element.code == classCode) {
                setselectedOption(element);
                setSelectedIndex(index);
              }
              index++;
            });
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const changeClasse = () => {
    setIsLoading2(true);
    let newClass: string = countryCode + "#" + selectedOption.code;
    AuthService.changeClass(newClass)
      .then((res) => {
        let user: User = res.data.data;
        dispatch(setUser(user));
        console.log("User+++", user);
        //localStorageService.saveUserLocal(user);
        setIsLoading2(false);

        getCurrentSubscriptionUser(user.currentClass!); // Mettre a jour le #Top Menu
        dispatch(setCurrentBackTo({ backTo: "" }));

        dispatch(saveAllSubject([])); // Mettre la liste des matieres a jour
        dispatch(setCurrentSubscriptionType(null)); // Reset value subscriptionTypeSlice To hide component #BoxPayment
        dispatch(setCurrentConversation(null)); // Reset value conversationSlice To hide component #ConversationScreen

        enqueueSnackbar("Your class has been modified", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });

        if (currentMenu.code === 8 && path !== undefined) {
          dispatch(setCurrentMenu({ title: "", code: 0 })); // init #currentMenu #DisplayBox
        } else {
          navigate("/dashboard/home");
        }
      })
      .catch((err) => {
        setIsLoading2(false);
        enqueueSnackbar("Oupps!! Une erreur est survenue", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      });
  };

  const onSelectClasse = (index: number) => {
    setSelectedIndex(index);
    setselectedOption(classes[index]);

    // console.log("classe selected", classes[index]);
    publish("currentClasse", classes[index]); // New add: publish event with data #classes
  };

  const getCurrentSubscriptionUser = async (currentClass: string) => {
    // setIsLoading(true);
    if (!window.navigator.onLine) {
      enqueueSnackbar(formatMessage({ id: "no_internet_connection" }), {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
      return;
    }

    let body: ConversationRequest = new ConversationRequest();
    body.path = currentClass /* user.currentClass */;
    body.lang = languageLocal;

    await SubscriptionService.getCurrentUserSubscription(body)
      .then(
        (data: any) => {
          console.log("data response subscription: ", data);
          if (
            data.code === EnumStatusCode.SUCCESS &&
            data.statusCode === EnumStatusCode.SUCCESS
          ) {
            dispatch(setCurrentSubscription(data.data as Subscription));
          }
        },
        (error) => {
          console.log("Error: ", error);
          if (error?.response?.data?.code === EnumStatusResponse.FAILURE) {
            const code = error?.response?.data?.code;
            dispatch(setCurrentSubscription({ ...new Subscription() }));
            switch (error?.response?.data?.statusCode) {
              case EnumStatusCode.SUBSCRIPTION_NOT_FOUND:
                /* enqueueSnackbar("Oupps!! Abonnement introuvable", {
                  variant: "info",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                }); */
                break;

              case EnumStatusCode.UNKNOWN_ERROR:
                enqueueSnackbar("Oupps!! Une erreur est survenue", {
                  variant: "error",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
                break;
              default:
                enqueueSnackbar("Oupps!! Une erreur est survenue", {
                  variant: "error",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
            }
          }
        }
      )
      .finally(() => {
        console.log("Completed request!");
        // setIsLoading(false);
      });
  };
  const mode = useSelector(selectCurrentMode);

  return (
    <div
      className="connexion-box"
      style={{
        backgroundColor:
          mode === "dark"
            ? theme.palette.leftbar.dark
            : theme.palette.leftbar.light,
      }}
    >
      <div
        className={
          /* path */ currentMenu.code === 8 && path !== undefined
            ? "wrapper-2"
            : "wrapper"
        }
      >
        {!isLoading ? (
          <div
            className="container"
            style={{
              paddingBottom: "25px",
              paddingLeft:
                currentMenu.code === 8 && path !== undefined
                  ? "5px" /* "10px" */
                  : "",
              paddingRight:
                currentMenu.code === 8 && path !== undefined
                  ? "5px" /* "10px" */
                  : "",
            }}
          >
            {path !== undefined ? null : (
              <div className="logo">
                <Lottie
                  options={defaultOptions}
                  width={60}
                  // height={60}
                  // width={60}
                  style={{
                    paddingBottom:
                      currentMenu.code === 8 && path !== undefined
                        ? "25px"
                        : "55px",
                  }}
                />
              </div>
            )}

            <div className="">
              <div
                className="title title-bold"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ maxWidth: "280px" }}>
                  <span
                    style={{
                      fontSize: "1rem",
                      color:
                        mode === "dark"
                          ? theme.palette.colortext.dark
                          : theme.palette.colortext.light,
                    }}
                  >
                    Sélectionnez la classe dans laquelle vous souhaitez étudier
                  </span>
                </div>
              </div>

              <div
                className=""
                style={{
                  paddingTop: "25px",
                  textAlign: "center",
                }}
              >
                <nav aria-label="main mailbox folders">
                  <List>
                    {classes.map((classe: any, index: number) => {
                      return (
                        <ListItem
                          key={index}
                          disablePadding
                          style={{
                            backgroundColor:
                              selectedIndex == index
                                ? "#303F9F"
                                : index % 2 == 0
                                ? mode === "dark"
                                  ? theme.palette.appbar.dark
                                  : "#eee"
                                : mode === "dark"
                                ? theme.palette.leftbar.dark
                                : theme.palette.leftbar.light,
                            color: selectedIndex == index ? "white" : "#333",
                            fontFamily: "nunito",
                            borderRadius: "5px",
                          }}

                          /**
					   * mode === "dark"
					? theme.palette.appbar.dark
					: theme.palette.appbar.light,
					   */
                        >
                          <ListItemButton onClick={() => onSelectClasse(index)}>
                            <ListItemIcon
                              style={{ minWidth: "35px", paddingRight: "15px" }}
                            >
                              <Book
                                style={{
                                  color:
                                    selectedIndex == index
                                      ? "white"
                                      : "#303F9F",

                                  fontSize: "0.85rem",
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={classe.name}
                              style={{
                                fontSize: "0.8rem",
                                color:
                                  mode === "dark"
                                    ? index % 2 == 0
                                      ? theme.palette.colortext.dark
                                      : "#eee"
                                    : theme.palette.colortext.light,
                              }}
                              primaryTypographyProps={{ fontSize: "0.8rem" }}
                            />
                            {selectedIndex == index ? (
                              <ListItemIcon style={{ minWidth: "35px" }}>
                                <CheckCircle
                                  style={{
                                    color: "white",
                                    fontSize: "1rem",
                                  }}
                                />
                              </ListItemIcon>
                            ) : (
                              <></>
                            )}
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </nav>
              </div>

              <div className="btn-connexion btn-connexion-2">
                <LoadingButton
                  disableElevation
                  disabled={selectedIndex < 0}
                  loading={isLoading2}
                  className="btn btn-lg"
                  endIcon={<></>}
                  loadingPosition="end"
                  variant="contained"
                  style={{
                    textTransform: "none",
                    fontSize: "0.80rem",
                    backgroundColor: isLoading2
                      ? theme.palette.appbar.dark
                      : "#303F9F",
                    color: theme.palette.colortext.dark,
                  }}
                  onClick={changeClasse}
                >
                  Valider
                </LoadingButton>
              </div>
            </div>
          </div>
        ) : (
          <Lottie
            options={loadOptions}
            // height={80}
            height={100}
            width={80}
            style={{ paddingBottom: "55px" }}
          />
        )}
      </div>
    </div>
  );
}

export default SelectClass;
