import {
  ListItem,
  Typography,
  ListItemText,
  Badge,
  Card,
  CardContent,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import { setCurrentSubscriptionType } from "../../../store/slices/subscriptionTypeSlice";
import {
  selectCurrentConversation,
  setCurrentConversation,
} from "../../../store/slices/conversationSlice";
import { PaymentRequest } from "../interfaces/subscription.interface";
import SubscriptionService from "../services/subscription-service";
import { makeStyles } from "@material-ui/styles";
import "./css/subscriptionTypeItem.scss";
import { useSelector } from "react-redux";
import { selectCurrentMode } from "../../../store/slices/modeSlice";
import { useIntl } from "react-intl";
const useStyles = makeStyles({
  cardRoot: {
    width: "100%",
    fontSize: "1.125rem",
    fontWeight: "bold",
    backgroundColor: "#f0f0f2",
    padding: 1,
    borderRadius: "10px",
    fontFamily: "Nunito",
    cursor: "pointer",
  },
  bullet: {
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontWeight: 700,
    lineHeight: "1.5rem",
  },
  pos: {},
  badge: {
    top: "0px",
    left: "0px",
    right: "auto",
  },
  viewContainer: {
    top: "-30px",
    left: "26px",
    position: "relative",
    padding: "10px",
    cursor: "pointer",
    width: "fit-content",
    float: "right",
    "&>*": {
      marginLeft: "5px",
      fontSize: "1.1rem",
      lineHeight: "1.25rem",
      color: "crimson",
    },
    "&:hover": {
      backgroundColor: "whitesmoke",
    },
  },
});

function SubscriptionTypeItem({ subscriptionType, theme }: any) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const conversation = useSelector(selectCurrentConversation);
  const mode = useSelector(selectCurrentMode);
  const intl = useIntl();
  const { formatMessage } = intl;

  return (
    <>
      <Box
        sx={{ width: "100%", marginTop: "25px", position: "relative" }}
        onClick={() => {
          if (conversation.subject) dispatch(setCurrentConversation({}));
          dispatch(setCurrentSubscriptionType(subscriptionType));
        }}
      >
        <div
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            zIndex: 1000,
            width: "100%",
            position: "absolute",
            display: "flex",
            justifyContent: "space-between",
            top: "-15px",
          }}
        >
          {subscriptionType.recommended ? (
            <div
              className="recommended"
              style={{
                backgroundColor:
                  theme.palette.mode == "dark"
                    ? theme.palette.primary.dark
                    : theme.palette.primary.light,
              }}
            >
              {formatMessage({ id: "recommended" })}
            </div>
          ) : (
            <div></div>
          )}

          {subscriptionType.economy &&
          parseInt(subscriptionType.economy) > 0 ? (
            <div className="economise">
              {formatMessage({ id: "save_money" })} {subscriptionType.economy}{" "}
              {subscriptionType.currency}
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <Card
          className={classes.cardRoot}
          elevation={0}
          sx={{ width: "100%" }}
          style={{
            width: "100%",
            color:
              mode === "dark"
                ? theme.palette.cardContent.dark
                : theme.palette.cardContent.light,
            fontWeight: "600",
            backgroundColor:
              mode == "dark"
                ? theme.palette.appbar.dark
                : theme.palette.contentbox.light,
          }}
        >
          <CardContent
            sx={{ width: "100%" }}
            style={{
              color:
                mode === "dark"
                  ? theme.palette.colortext.dark
                  : theme.palette.colortext.light,
              fontWeight: 600,
              padding: "5px 8px 2px",
              borderRadius: "5px",
            }}
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              style={{ marginBottom: "5px" }}
            >
              <Box /* p={1} */>
                <Typography
                  style={{ fontSize: "0.85rem", marginBottom: "20px" }}
                  component="span"
                >
                  {subscriptionType.periodicity}
                </Typography>
              </Box>
            </Box>
            <Box
              /*  p={1} */ style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginBottom: "5px",
              }}
            >
              <Typography
                component="span"
                className={classes.title}
                style={{ fontSize: "0.9rem" }}
              >
                {`Pack ${subscriptionType.name}`}
              </Typography>
              <Typography component="span" style={{ fontSize: "0.85rem" }}>
                {subscriptionType.amount} {subscriptionType.currency}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "50px",
                width: "100%",
                marginBottom: "5px",
              }}
            >
              <Typography style={{ fontSize: "0.85rem" }} component="span">
                {subscriptionType.description}
              </Typography>
              <Typography
                style={{ fontSize: "0.85rem" }}
                component="span"
              ></Typography>
            </Box>
            <Box></Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

SubscriptionTypeItem.defaultProps = {
  subscriptionType: {},
};

SubscriptionTypeItem.propTypes = {
  index: PropTypes.number.isRequired,
  subscriptionType: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default SubscriptionTypeItem;
