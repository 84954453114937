import { Box } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";
import WelcomeSection from "./components/home/welcome-section";
import ProviderSection from "./components/home/provider-section";
// import LatestBlogSection from "./components/home/latest-blog-section";
// import SolutionSection from "./components/home/solution-section";
import WhatSection from "./components/home/what-section";
import FrequentlyAskedQuestions from "./components/home/frequently-asked-questions";
import ContactSection from "./components/home/contact-section";
import ResponsiveAppBar from "./components/home/app-bar";
import Footer from "./components/home/footer";

const Home = () => {
  // const theme = useTheme();
  // const screenSM = useMediaQuery(theme.breakpoints.up("sm"));
  // const screenMD = useMediaQuery(theme.breakpoints.up("md"));
  // const screenXS = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      {/* New */}
      <ResponsiveAppBar children={<></>} />

      <Box height={30} />

      <Box px={{ sm: 10, md: 24 }} py={10}>
        <WelcomeSection />
      </Box>

      <Box
        id="ABOUT"
        px={{ sm: 10, md: 24 }}
        py={10}
        sx={{ backgroundColor: "rgba(32, 101, 209, 0.16)" }}
      >
        <WhatSection />
      </Box>

      <Box id="FEATURES" px={{ sm: 10, md: 24 }} py={10}>
        <ProviderSection />
      </Box>

      {/* <Box
        px={{ sm: 10, md: 24 }}
        py={10}
        sx={{ backgroundColor: "rgba(32, 101, 209, 0.16)" }}
      >
        <SolutionSection />
      </Box> */}

      {/* <Box px={24} py={10} sx={{ backgroundColor: "rgba(32, 101, 209, 0.16)" }}>
        <LatestBlogSection />
      </Box> */}

      <Box id="HELP" px={{ sm: 10, md: 24 }} py={10} sx={{ backgroundColor: "rgba(32, 101, 209, 0.16)" }}>
        <FrequentlyAskedQuestions />
      </Box>

      <Box
        id="CONTACT"
        px={{ sm: 10, md: 24 }}
        py={10}
        //sx={{ backgroundColor: "rgba(32, 101, 209, 0.16)" }}
      >
        <ContactSection />
      </Box>

      <Box px={{ sm: 10, md: 24 }} sx={{ backgroundColor: "rgba(32, 101, 209, 0.16)" }} py={10}>
        <Footer />
      </Box>
    </>
  );
};

export default Home;
