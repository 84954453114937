import { createSlice } from "@reduxjs/toolkit";
import localStorageService from "../../localStorageService";
import { publish } from "../../helpers/helpers";

const initialState = localStorageService.getLanguageLocal();

const languageSlice = createSlice({
  name: "language",
  initialState: initialState,
  reducers: {
    setLanguageLocal: (state, action) => {
      state = action.payload;

      localStorageService.saveLanguageLocal(state as string);
      publish("language-change",{value:state})
      return state;
    },

    clearLanguageLocal: (state) => {},
  },
});

export const { setLanguageLocal, clearLanguageLocal } = languageSlice.actions;

export const selectLanguageLocal = (state: any) => state.language;
export const languageLocalReducer = languageSlice.reducer;
