import { createSlice } from "@reduxjs/toolkit";
import { Subscription } from "../../modules/subscription/interfaces/subscription.interface";

const initialState = /* new Subscription() */ {};
const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: initialState,
  reducers: {
    setCurrentSubscription: (state, action) => {
      console.log("payload: ", action.payload);
      state = action.payload;
      // console.log("state2: ", state);
      return action.payload;
    },
  },
});

export const { setCurrentSubscription } = subscriptionSlice.actions;

export const selectCurrentSubscription = (state: any) => state.subscription;

export const subscriptionReducer = subscriptionSlice.reducer;
