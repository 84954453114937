import { createSlice } from "@reduxjs/toolkit";
import { Subscription } from "../../modules/subscription/interfaces/subscription.interface";

const initialState: Array<Subscription> = [];

const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState: initialState,
  reducers: {
    subscriptionAdded(state, action) {
      // "Mutate" the existing state, no return value needed
      state.push(action.payload);
    },
    subscriptionDeleted(state, action) {
      // Construct a new result array immutably and return it
      // return state.filter(todo => todo.id !== action.payload)
    },
    saveAllSubscriptions: (state, action): Array<Subscription> => {
      state = action.payload as Array<Subscription>; // Valeur recu depuis le dispatch
      // console.log("All subscription: ", state);

      return action.payload;
    },
    setCurrentSubject: (state, action) => {},
  },
});

export const { setCurrentSubject, saveAllSubscriptions, subscriptionAdded } =
  subscriptionsSlice.actions;

// export const selectCurrentSubject = (state: Topic) => state.subject;
export const selectCurrentSubscriptions = (state: any) => state.subscriptions;

export const subscriptionsReducer = subscriptionsSlice.reducer;
