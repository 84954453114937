import { createSlice } from "@reduxjs/toolkit";
import {
	EnumActionConversation,
	EnumTopicStatus,
} from "../../modules/conversation/enums/chat.enums";
import { Topic } from "../../modules/conversation/interfaces/chat.interface";

const initialState: Array<Topic> = [];
const subjectSlice = createSlice({
	name: "subject",
	initialState: initialState,
	reducers: {
		saveAllSubject: (state, action): Array<Topic> => {
			state = action.payload as Array<Topic>; // Valeur recu depuis le dispatch
			// console.log("All subject: ", state);

			return action.payload;
		},
		setCurrentSubject: (state, action) => {
			console.log("current subject: ", action.payload);

			const code = action.payload.subject.code;
			const action_ = action.payload.action;
			const path = action.payload.subject.path;
			let newArraySubjects: Array<Topic> = state.map((item, index) => {
				if (item.code === code || item.path === path) {
					switch (action_) {
						case EnumActionConversation.START_CONVERSATION:
							item.quizStatus = EnumTopicStatus.IN_PROGRESS /* "IN_PROGRESS" */;
							break;
						case EnumActionConversation.CONTINUE_CONVERSATION:
							item.quizStatus =
								EnumTopicStatus.IN_PROGRESS /* "QUIZ_NOT_STARTED" */;
							break;
						case EnumActionConversation.RESTART_CONVERSATION:
							// code block
							break;
						case EnumActionConversation.DELETE_CONVERSATION:
							item.quizStatus =
								EnumTopicStatus.QUIZ_NOT_STARTED /* "QUIZ_NOT_STARTED" */;
							break;
						default:
						// code block
					}
				}
				return item;
			});

			state = newArraySubjects;
			console.log("new array: ", newArraySubjects);
		},
	},
});

export const { setCurrentSubject, saveAllSubject } = subjectSlice.actions;

// export const selectCurrentSubject = (state: Topic) => state.subject;
export const getAllSubjectStore = (state: any) => state.subject;

export const subjectReducer = subjectSlice.reducer;
