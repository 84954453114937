import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Avatar, Grid, IconButton, Stack } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { green } from "@mui/material/colors";
import { Icon } from "@iconify/react";

interface CardData {
  title: string;
  descritpion: string;
}
const cardData: CardData[] = [
  {
    title: "Configuration de classe dans le système Camerounais",
    descritpion:
      "Etant élève vous avez la possibilité de configurer votre classe  dans l'application",
  },
  {
    title: "Démarrage d'une discussion sur une matière",
    descritpion:
      "Vous pouvez démarrer une discussion sur l'ensemble d'une matière",
  },
  {
    title: "Démarrage d'une discussion sur un chapitre d'une matère ",
    descritpion:
      "vous pouvez plutot démarrer une discussion sur une un chapitre bien précis de la matière",
  },
  {
    title: "Redémarrer une discussion déjà en cours",
    descritpion:
      "Si quelque chose vous a échappé dans une discussion, vous pouvez toujours derémarrer la discussion",
  },
  {
    title: "Supprimer une discussion",
    descritpion:
      "Si la discussion ne vous plait pas, vous pouvez toujours le supprimer",
  },
  /* {
    title: "Share your date to anyone",
    descritpion:
      "The will make a list of potential employees to be interviewed based",
  }, */
];

const ProviderSection = () => {
  return (
    <Box>
      <Stack alignItems="center" spacing={1}>
        <Typography variant="h4" color="#000000" textAlign="center">
          Fonctionnalités de l'application Studa
        </Typography>

        <Typography
          variant="subtitle1"
          textAlign="center"
          maxWidth={{ sm: "100%", xs: "100%", lg: "25%" }}
        >
          Les fonctionnalités de l'application web et mobile.
        </Typography>
      </Stack>

      <Box height={30} />

      <Grid item xs={12}>
        <Grid container spacing={3} justifyContent="center">
          {cardData.map((item, index) => (
            <Grid key={index} item>
              <Card
                sx={{
                  // maxWidth: 275,
                  maxWidth: 250,
                  color: "#000000",
                  borderRadius: "8px",
                  // border: "1px solid #303F9F"
                  border: "none",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                }}
                variant="outlined"
              >
                <CardCnt item={item} />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProviderSection;

type Props = {
  item: CardData;
};

const CardCnt = ({ item }: Props) => {
  return (
    <React.Fragment>
      <CardContent>
        <Avatar sx={{ bgcolor: green[500], mb: 2 }} variant="rounded">
          <AssignmentIcon />
        </Avatar>
        <Typography variant="h5" component="div" fontWeight="semi-bold" mb={2}>
          {item.title}
        </Typography>

        <Typography variant="body2">{item.descritpion}</Typography>
      </CardContent>
      <CardActions>
        {/* <IconButton aria-label="share">
          <Icon icon="grommet-icons:form-next-link" color="#000" />
        </IconButton> */}
      </CardActions>
    </React.Fragment>
  );
};
