import axios from "axios";
import cookieService from "../../../cookieService";
import KEYS from "../../../helpers/KEYS";
import { AuthSigninInputDto } from "../dtos/inputs/authSignIn";
import { ResendCodeInputDto } from "../dtos/inputs/resendCodeInput";
import { ValidationCodeInputDto } from "../dtos/inputs/validationCodeInput";
import { ContactInputDto, DeleteAccountInputDto } from "../interfaces/auth.interface";

const apiAuth = axios.create({ baseURL: KEYS.BACKEND_HOST_AUTH });

const setToken = () => {
  const token = cookieService.getToken();
  if (token) {
    apiAuth.defaults.headers.common = { Authorization: `Bearer ${token}` };
  }
};

const authHeader = () => {
  const token = cookieService.getToken();
  if (token) {
    // for Node.js Express back-end
    return {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };
  } else {
    return {};
  }
};
/*const login = (body: AuthSigninInputDto) => {
	const token = cookieService.getToken();
	if (token) {
		apiAuth.defaults.headers.common = { Authorization: `Bearer ${token}` };
	}
	apiAuth.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
	return apiAuth({
		data: body,
		url: "/login",
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
	});
};*/
const login = async (body: AuthSigninInputDto) => {
  const url: string = KEYS.BACKEND_HOST_AUTH + "/login";
  console.log(KEYS);
  // const url: string = `${KEYS.BACKEND_HOST_AUTH}/login`;
  const headers = authHeader();
  console.log("body: ", body);
  const response = await axios.post(url, body, { headers: headers });
  return response.data;
};
const validationCode = (body: ValidationCodeInputDto) => {
  //const url = KEYS.BACKEND_HOST_AUTH + "/code-validation";
  const url: string = KEYS.BACKEND_HOST_AUTH + "/code-validation";
  const headers = authHeader();
  console.log(body);
  return axios.post(url, { ...body }, { headers: headers });
};

const changeClass = (currentClass: string) => {
  //const url = KEYS.BACKEND_HOST_USER + "/update-current-class";
  const url: string = KEYS.BACKEND_HOST_USER + "/update-current-class";
  const headers = authHeader();
  console.log(`La currentClasse ${currentClass}`);
  return axios.patch(url, { currentClass }, { headers });
};

const resendCode = (body: ResendCodeInputDto) => {
  const url = KEYS.BACKEND_HOST_AUTH + "/resend-code";
  const headers = authHeader();
  console.log(body);
  return axios.post(url, { ...body });
};

const demandDeleteAccount = async (deleteAccountInputDto: DeleteAccountInputDto) => {
  console.log("checkedValues", deleteAccountInputDto);
  const url: string = KEYS.BACKEND_HOST_USER + "/demand-delete-account";
  const headers = authHeader();
  console.log("checkedValues: ", deleteAccountInputDto);
  const response = await axios.post(url, deleteAccountInputDto, {
    headers: headers,
  });
  console.log("Résultats de la suppression", response.data);
  return response.data;
};

const contactUs = async (contactInputDto: ContactInputDto) => {
  console.log("checkedValues", contactInputDto);
  const url: string = KEYS.BACKEND_HOST_USER + "/contact-us";
  const response = await axios.post(url, contactInputDto, {
  });
  console.log("Résultats de la suppression", response.data);
  return response.data;
};

const logOutUser = () => {};

const AuthService = {
  contactUs,
  login,
  validationCode,
  changeClass,
  resendCode,
  demandDeleteAccount,
};
export default AuthService;
