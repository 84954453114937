import {
	Avatar,
	Box,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Paper,
	Tooltip,
	Typography,
} from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import PropTypes from "prop-types";
import { Conversation, Message, Topic } from "../interfaces/chat.interface";
import { useEffect, useState } from "react";
import React from "react";
import {
	convertUTCDateToLocalDate,
	formatDate,
	getDurationTime,
	getNewDateAtGmt,
	timeStampToStringDate,
} from "../../../helpers/helpers";
import { makeStyles } from "@material-ui/styles";
import Moment from "react-moment";
import ReactTimeAgo from "react-time-ago";
import { useStyles } from "../../../helpers/sharedStyle";
import { useSelector } from "react-redux";
import { getAllSubjectStore } from "../../../store/slices/subjectSlice";
import { selectCurrentMode } from "../../../store/slices/modeSlice";
import { Bolt, DeleteOutlined, KeyboardArrowDown } from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { PauseCircleOutlined } from "@mui/icons-material";
import { selectLanguageLocal } from "../../../store/slices/languageSlice";
import { EnumAccompanyType } from "../enums/chat.enums";
import moment from "moment";
import { light } from "@mui/material/styles/createPalette";
function ConversationItem({
	index,
	conversation,
	theme,
	onSelectConversation,
	isSelected,
	onRestartConversation,
	onDeleteConversation,
}: any) {
	const classes = useStyles();
	const languageLocal: string = useSelector(selectLanguageLocal);

	const [secondary, setSecondary] = useState(true);

	const getLabel = (): string => {
		if (conversation.topicLabel != null && conversation.topicLabel.length > 0) {
			let label: string =
				conversation.topicLabel[conversation.topicLabel.length - 1];
			return label;
		}
		return "";
	};

	const getSubLabel = (): string => {
		if (conversation.topicLabel != null && conversation.topicLabel.length > 0) {
			if (conversation.topicLabel!.length > 3) {
				let subLabel: string = conversation.topicLabel[2];
				return subLabel;
			}
		}
		return "";
	};

	useEffect(() => {
		var timeStampDate = new Date(conversation.createdAt);
	});
	const mode = useSelector(selectCurrentMode);
	function format(inputDate: Date) {
		let date, month, year;

		date = inputDate.getDate();
		month = inputDate.getMonth() + 1;
		year = inputDate.getFullYear();

		date = date.toString().padStart(2, "0");

		month = month.toString().padStart(2, "0");

		return `${date}/${month}/${year}`;
	}
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault(); // prevent the default behaviour when right clicked
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const getLastMessage = (message?: Message): string => {
		if (!message) return "";
		switch (message.type) {
			case EnumAccompanyType.WELCOME:
				return message.content?.message ?? "";
			case EnumAccompanyType.QUESTION:
				return message.question?.content ?? "";
			default:
				return "";
		}
	};
	return (
		<ListItem
			disablePadding
			alignItems="flex-start"
			sx={{
				position: "relative",
				".MuiListItemText-secondary": {},
				".MuiListItemSecondaryAction-root": {
					right: "6px",
				},
				".MuiListItemSecondaryAction-root .icon-action": {
					visibility: "hidden",
				},
				/*"&:hover .MuiListItemSecondaryAction-root .icon-action": {
					border: "1px solid blue",
				},*/
				"&:hover .MuiListItemSecondaryAction-root": {
					".icon-action": {
						display: "flex",
						visibility: "visible",
					},
				},
			}}
			secondaryAction={
				<Box>
					<Typography
						sx={{
							fontSize: "0.65rem",
							paddingTop: "5px",
							right: 0,
							color:
								mode === "dark"
									? theme.palette.colortext.dark
									: theme.palette.colortext.light,
						}}
					>

						{conversation.lastMessage && conversation.lastMessage != null ? (
							<Tooltip
								title={moment(
									convertUTCDateToLocalDate(
										new Date(conversation?.lastMessage?.createdAt)
									)
								).format("LLLL")}
								placement="top"
							>
								<span>
									{formatDate(
										parseInt(conversation?.lastMessage?.createdAt),
										languageLocal
									)}
								</span>
							</Tooltip>
						) : (
							<Tooltip
								title={moment(
									convertUTCDateToLocalDate(new Date(conversation?.createdAt))
								).format("LLLL")}
								placement="top"
							>
								<span>
									{formatDate(parseInt(conversation?.createdAt), languageLocal)}
								</span>
							</Tooltip>
						)}
					</Typography>
					<Box
						sx={{
							display: "flex",
							justifyContent: "right",
							//border: "1px solid red",
							padding: "0px",
						}}
						className="icon-action"
					>
						<IconButton
							aria-label="show"
							size="small"
							sx={{
								padding: "0px",
								width: "1.2rem",
								height: "1.2rem",
								textAlign: "right",
								color:
									mode === "dark"
										? theme.palette.colortext.dark
										: theme.palette.colortext.light,
							}}
							onClick={handleClick}
						>
							<KeyboardArrowDown />
						</IconButton>
					</Box>

					<Menu
						className="menu-list"
						anchorEl={anchorEl}
						id="basic-menu"
						open={open}
						onClose={handleClose}
						onClick={handleClose}
						style={{
							marginTop: -12,
							marginRight: 15,
						}}
						PaperProps={{
							elevation: 0,
							sx: {
								overflow: "visible",
								filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
								mt: 1.5,
								"& .MuiAvatar-root": {
									width: 32,
									height: 32,
									ml: -0.5,
									mr: 1,
								},
								"&:before": {
									content: '""',
									display: "block",
									position: "absolute",
									top: -10,
									right: 17,
									width: 10,
									height: 10,
									backgroundColor:
										mode === "dark"
											? theme.palette.contentbox.dark
											: theme.palette.contentbox.light,
									transform: "translateY(50%) rotate(45deg)",
									zIndex: 0,
								},
								fontFamily: "Nunito",
								backgroundColor:
									mode === "dark"
										? theme.palette.contentbox.dark
										: theme.palette.contentbox.light,
							},
						}}
						transformOrigin={{ horizontal: "right", vertical: "top" }}
						anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
					>
						<MenuItem
							onClick={() => {
								onSelectConversation();
								setAnchorEl(null);
							}}
							// onClick={handleClose}
							sx={{
								fontFamily: "Nunito",
								color:
									mode === "dark"
										? theme.palette.colortext.dark
										: theme.palette.colortext.light,
								fontSize: "0.85rem",
							}}
						>
							<Avatar
								sx={{
									backgroundColor: "#EEEEEE",
									width: "inherit !important",
									height: "inherit !important",
									p: 0.1,
								}}
							>
								<PauseCircleOutlined
									style={{
										color: "#303F9F",
										fontSize: "0.9rem",
									}}
								/>
							</Avatar>
							{/* <Avatar /> */}
							Continuer
						</MenuItem>
						<MenuItem
							onClick={() => {
								onRestartConversation();
								setAnchorEl(null);
							}}
							// onClick={handleClose}
							sx={{
								fontFamily: "Nunito",
								color:
									mode === "dark"
										? theme.palette.colortext.dark
										: theme.palette.colortext.light,
								fontSize: "0.85rem",
							}}
						>
							<Avatar
								sx={{
									backgroundColor: "#EEEEEE",
									width: "inherit !important",
									height: "inherit !important",
									p: 0.1,
								}}
							>
								<RefreshIcon
									style={{
										color: "#303F9F",
										fontSize: "0.9rem",
									}}
								/>
							</Avatar>
							Redémarrer
						</MenuItem>
						<MenuItem
							onClick={() => {
								onDeleteConversation();
								setAnchorEl(null);
							}}
							sx={{
								fontFamily: "Nunito",
								color:
									mode === "dark"
										? theme.palette.colortext.dark
										: theme.palette.colortext.light,
								fontSize: "0.85rem",
							}}
						>
							<Avatar
								sx={{
									backgroundColor: "#EEEEEE",
									width: "inherit !important",
									height: "inherit !important",
									p: 0.1,
								}}
							>
								<DeleteOutlined
									style={{
										color: "#303F9F",
										fontSize: "0.9rem",
									}}
								/>
							</Avatar>
							Supprimer
						</MenuItem>
					</Menu>
				</Box>
			}
			style={{
				gap: 10,
				backgroundColor:
					mode === "dark"
						? isSelected
							? theme.palette.appbar.dark
							: "inherit"
						: isSelected
						? theme.palette.appbar.light
						: "inherit",
			}}
		>
			<ListItemButton
				sx={{
					paddingLeft: "7px",
					paddingTop: "2px",
					paddingBottom: "2px",
					paddingRight: "0px",
				}}
				onClick={onSelectConversation}
				onContextMenu={(e) => {
					e.preventDefault(); // prevent the default behaviour when right clicked
					setAnchorEl(e.currentTarget);
				}}
			>
				<ListItemIcon style={{ minWidth: "0px !important" }}>
					<ChatBubbleOutlineIcon
						style={{
							color:
								mode === "dark"
									? theme.palette.colortext.dark
									: theme.palette.colortext.light,
							fontSize: "0.85rem",
						}}
					/>
				</ListItemIcon>
				<ListItemText
					primary={
						<Tooltip
							title={
								getLabel() != null && getLabel().length > 0 ? getLabel() : ""
							}
							placement="top"
						>
							<Typography
								sx={{
									color:
										mode === "dark"
											? theme.palette.colortext.dark
											: theme.palette.colortext.light,
									fontSize: "0.85rem",
									paddingRight: "8px",
									
								}}
								classes={{ root: classes.customBox }}
								fontWeight={600}
							>
								{getLabel() != null && getLabel().length > 0 ? getLabel() : ""}
							</Typography>
						</Tooltip>
					}
					secondary={
						<Typography
							sx={{
								fontSize: "0.75rem",
								textOverflow: "ellipsis",
								overflow: "hidden",
								whiteSpace: "nowrap",
								color:
									mode === "dark"
										? theme.palette.secondarytext.dark
										: theme.palette.secondarytext.light,
							}}
							component="div"
							variant="body2"
						>
							{getLastMessage(conversation.lastMessage)}
						</Typography>
					}
					sx={{
						marginLeft: 1.5,
					}}
				/>
			</ListItemButton>
		</ListItem>
	);
}

ConversationItem.defaultProps = {
	conversation: {},
};

ConversationItem.propTypes = {
	index: PropTypes.number.isRequired,
	conversation: PropTypes.object.isRequired,
	theme: PropTypes.object,
	onSelectConversation: PropTypes.func.isRequired,
	isSelected: PropTypes.bool,
	onRestartConversation: PropTypes.func.isRequired,
	onDeleteConversation: PropTypes.func.isRequired,
};

export default ConversationItem;
