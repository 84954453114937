import cookie from 'react-cookies';
import { TokenData } from './modules/authentification/interfaces/auth.interface';

class CookieService {
  getToken = () => {
    return cookie.load('token');
  };

  saveToken = (token:TokenData) => {
    cookie.save('token', token, { path: '/' });
  };

  getRefreshToken = ()=>{
    return cookie.load('refresh-token')
  };

  saveRefreshToken = (token:TokenData) => {
    cookie.save('refresh-token', token, { path: '/' });
  };

  removeToken = ()=>{
    cookie.remove("token");
  }
  removeRefreshToken = ()=>{
    cookie.remove("refresh-token");
  }
}

// export default new CookieService();
export default new CookieService();
