import { Stack, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
// import phone from "../../image/phone.png";
import SwipeableTextMobileStepper, {
  Image,
} from "./swipeableTextMobileStepper";
import connexion from "../../image/connexion.webp";
import choiceClass from "../../image/choise-class.webp";
import chat from "../../image/chat.webp";
import img1 from "../../image/image1.webp";
import img2 from "../../image/image2.webp";
import img3 from "../../image/image3.webp";
import img6 from "../../image/img6.png";
import img7 from "../../image/img7.png";
import img8 from "../../image/img8.png";
import img9 from "../../image/img9.png";
import img10 from "../../image/img10.png";
import { useIntl } from "react-intl";

const images: Image[] = [
  {
    label: "Picture connexion",
    imgPath: connexion,
  },
  {
    label: "Picture choice class",
    imgPath: choiceClass,
  },
  {
    label: "Picture chat",
    imgPath: chat,
  },
  {
    label: "Picture img1",
    imgPath: img1,
  },
  {
    label: "Picture img2",
    imgPath: img2,
  },
  {
    label: "Picture img3",
    imgPath: img3,
  },
  {
    label: "Picture img6",
    imgPath: img6,
  },
  {
    label: "Picture img7",
    imgPath: img7,
  },
  {
    label: "Picture img8",
    imgPath: img8,
  },
  {
    label: "Picture img9",
    imgPath: img9,
  },
  {
    label: "Picture img10",
    imgPath: img10,
  },
];

const WelcomeSection = () => {
  const intl = useIntl();
	const { formatMessage } = intl;
  return (
    <Stack
      direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      spacing={{ xs: 10, sm: 10, lg: 18 }}
    >
      <Stack color="#000000" spacing={3}>
        {/* <Typography variant="h3" color="#000000">
          Manage All of Your Data Using{" "}
          <span style={{ color: "#303F9F" }}>One App</span>
        </Typography> */}
        <Typography variant="h3" color="#000000">
          Studa: Préparer votre examen
        </Typography>
        <Typography variant="subtitle1">
          {" "}
          L'application
          <span style={{ color: "#303F9F", fontWeight: "semibold" }}>
            {" "}
            studa
          </span>{" "}
          est conçu pour les éleves et vous aide à préparer votre examen. Avec
          studa étudier devient un plaisir quand l'on a les outils adéquats, et
          quand l'on peut le faire partout où l'on est.
        </Typography>
        <Stack direction="row" spacing={3}>
          {/* Play Store */}
          <Stack
            // component="button"
            component="a"
            target="blank"
            href="https://play.google.com/store/apps/details?id=com.codezys.studa"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              backgroundColor: "#000000",
              color: "#fff",
              py: 0.5,
              borderRadius: "8px",
              cursor: "pointer",
              border: "none",
              textDecoration: "none",
            }}
          >
            <Icon icon="bxl:play-store" height={50} />
            <Stack mr={1} direction="column" alignItems="flex-start">
              <Typography variant="caption">Get it on</Typography>
              <Typography variant="button" sx={{ textTransform: "none" }}>
                Google play
              </Typography>
            </Stack>
          </Stack>

          {/* App Web */}
          <Stack
            // component="button"
            component="a"
            href="/auth"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            sx={{
              backgroundColor: "#000000",
              color: "#fff",
              px: 1.5,
              py: 0.5,
              borderRadius: "8px",
              cursor: "pointer",
              border: "none",
              textDecoration: "none",
            }}
          >
            <Icon icon="streamline:startup-solid" height={35} />
            <Typography variant="button" p={0} sx={{ textTransform: "none" }}>
              Get Started
            </Typography>
          </Stack>

          {/* <Stack
            component="button"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              backgroundColor: "#000000",
              color: "#fff",
              py: 0.5,
              borderRadius: "8px",
              cursor: "pointer",
              border: "none",
            }}
          >
            <Icon icon="ic:baseline-apple" height={50} />
            <Stack mr={1} direction="column" alignItems="flex-start">
              <Typography variant="caption" sx={{ textTransform: "none" }}>
                Download on the
              </Typography>
              <Typography variant="button" sx={{ textTransform: "none" }}>
                App Store
              </Typography>
            </Stack>
          </Stack> */}
        </Stack>

        {/* <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <AvatarGroup
            renderSurplus={(surplus) => <span>+{surplus.toString()[0]}k</span>}
            total={4251}
          >
            <Avatar alt="Remy Sharp" src={image} />
            <Avatar alt="Travis Howard" src={image} />
            <Avatar alt="Agnes Walker" src={image} />
            <Avatar alt="Trevor Henderson" src={image} />
          </AvatarGroup>

          <Box>
            <Typography variant="h6" color="#000000">
              Daily review
            </Typography>
            <Typography variant="subtitle1">
              200+ User review everyday
            </Typography>
          </Box>
        </Stack> */}
      </Stack>

      <SwipeableTextMobileStepper
        images={images}
        maxWidthBox={500}
        maxWidthImg={500}
        maxHeightImg={600}
        isRounded
      />

      {/* <img
        src={phone}
        alt=""
        height="100%"
        width="100%"
        style={{ maxHeight: 500, maxWidth: 400 }}
      /> */}
    </Stack>
  );
};

export default WelcomeSection;
