import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box } from "@mui/material";
import ReactCodeInput from "react-code-input";
import { LoadingButton } from "@mui/lab";
import MyTimer from "./MyTimer";
import { useDispatch, useSelector } from "react-redux";
import AuthService from "../services/auth-service";
import { CodeValidationInputDto } from "../dtos/inputs/codeValidation";
import { EnumStatusCode } from "../enums/auth.enums";
import { setUser } from "../../../store/slices/userSlice";
import UserService from "../services/user-service";
import { ResendCodeInputDto } from "../dtos/inputs/resendCodeInput";
import { User } from "../../../interfaces/user.interface";
import { selectCurrentMode } from "../../../store/slices/modeSlice";
import { useSnackbar } from "notistack";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ValidationCodeComponent(props: any) {
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();

  const [code, setCode] = React.useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoading2, setIsLoading2] = useState<boolean>(false);
  const [timeRemaining, setTimeRemaining] = useState<number>(0);
  const [showTimer, setShowTimer] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState<boolean>(false);
  const theme = props.theme;
  const mode = useSelector(selectCurrentMode);


  const handlePinChange = (pinCode: string) => {
    setCode(pinCode);
    setHasError(false);
    setErrorMessage("");
  };

  const handleClose = (user?: User) => {
    setOpen(false);
    props.handleClose(user);
  };

  const doValidationCode = () => {
    setIsLoading(true);

    let body: CodeValidationInputDto = {
      code: code,
    };
    if (props.typeValidCode === 1) {
      UserService.phoneValidationCode(body)
        .then((result) => {
          setIsLoading(false);
          let user = null;
          if (result.data.code === "SUCCESS") {
            user = result.data.data;
            dispatch(setUser(user));
            handleClose(user);
            const statusCode = result.data.statusCode;
            switch (statusCode) {
              case EnumStatusCode.PHONE_VALIDATED:
                enqueueSnackbar("Your phone address has changed", {
                  variant: "success",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
                break;
              default:
                break;
            }
          }
        })
        .catch((err) => {
          let statusCode = err?.response?.data?.statusCode;
          switch (statusCode) {
            case EnumStatusCode.CODE_INCORRECT:
              setErrorMessage("Le code est incorrect");
              break;
            case EnumStatusCode.CODE_EXPIRED:
              setErrorMessage("Le code a expiré");
              break;
            case EnumStatusCode.CODE_NOT_PROVIDED:
              setErrorMessage("Vous devez renseigner le code");
              break;
            default:
              break;
          }
          setHasError(true);

          setIsLoading(false);
        });
    } else {
      UserService.emailValidationCode(body)
        .then((result) => {
          setIsLoading(false);
          let user = null;
          if (result.data.code === "SUCCESS") {
            user = result.data.data;
            dispatch(setUser(user));
            handleClose(user);
            const statusCode = result.data.statusCode;
            switch (statusCode) {
              case EnumStatusCode.EMAIL_VALIDATED:
                enqueueSnackbar("Your email address has changed", {
                  variant: "success",
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
                break;
              default:
                break;
            }
          }
        })
        .catch((err) => {
          let statusCode = err?.response?.data?.statusCode;
          switch (statusCode) {
            case EnumStatusCode.CODE_INCORRECT:
              setErrorMessage("Le code est incorrect");
              break;
            case EnumStatusCode.CODE_EXPIRED:
              setErrorMessage("Le code a expiré");
              break;
            case EnumStatusCode.CODE_NOT_PROVIDED:
              setErrorMessage("Vous devez renseigner le code");
              break;
            default:
              break;
          }
          setHasError(true);

          setIsLoading(false);
        });
    }
  };
  const doResendCode = () => {
    setIsLoading2(true);

    let body: ResendCodeInputDto = {
      username: props.metadata.username,
    };
    AuthService.resendCode(body)
      .then((result) => {
        console.log(result);
        setIsLoading2(false);
        let time_ = result?.data?.data?.timeRemaining ?? 0;
        setShowTimer(true);
        setTimeRemaining(time_);
      })
      .catch((err) => {
        console.log(err);
        let time_ = err?.response?.data?.data?.timeRemaining ?? 0;
        setShowTimer(true);
        setTimeRemaining(time_);
        setIsLoading2(false);
      });
  };

  const removeTimer = () => {
    setShowTimer(false);
  };
  return (
    <Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent
          sx={{
            paddingTop: "30px",
            paddingBottom: "30px",
            backgroundColor:
              mode === "dark"
                ? theme?.palette.leftbar.dark
                : theme?.palette.leftbar.light,
            color:
              mode === "dark"
                ? theme?.palette.colortext.dark
                : theme?.palette.colortext.light,
          }}
        >
          <div className="">
            <div className="">
              <div className="">
                <div className="">
                  <div
                    className="title title-bold"
                    style={{ textAlign: "center" }}
                  >
                    Saississez le code que vous avez reçu par{" "}
                    {props.typeValidCode === 1
                      ? "SMS au numéro " + props.metadata.newPhone
                      : "email à l'adresse " + props.metadata.newEmail}
                  </div>

                  <div
                    className=""
                    style={{
                      minWidth: "100%",
                      paddingTop: "25px",
                      textAlign: "center",
                    }}
                  >
                    <>
                      <ReactCodeInput
                        inputStyle={{
                          width: 50,
                          height: 42,
                          marginLeft: 10,
                          fontSize: 20,
                          textAlign: "center",
                          borderRadius: 6,
                          backgroundColor:
                            mode === "dark"
                              ? theme.palette.appbar.dark
                              : theme.palette.appbar.light,
                          color:
                            mode === "dark"
                              ? theme.palette.colortext.dark
                              : theme.palette.colortext.light,
                          boxShadow: "none",
                          border: "0px",
                        }}
                        name={""}
                        inputMode={"tel"}
                        type="text"
                        fields={4}
                        onChange={handlePinChange}
                        value={code}
                      />
                      {hasError && (
                        <div
                          className="error"
                          style={{
                            color: "red",
                            fontStyle: "italic",
                            paddingTop: "15px",
                          }}
                        >
                          {errorMessage}
                        </div>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            paddingBottom: "30px",
            marginTop: "-15px",
            backgroundColor:
              mode === "dark"
                ? theme?.palette.leftbar.dark
                : theme?.palette.leftbar.light,
          }}
        >
          <LoadingButton
            disabled={showTimer}
            disableElevation
            loading={isLoading2}
            className="btn btn-lg"
            endIcon={<></>}
            loadingPosition="end"
            variant="outlined"
            style={{ textTransform: "none", fontSize: "16px" }}
            onClick={doResendCode}
          >
            Renvoyer le code{" "}
            {showTimer && (
              <MyTimer timeRemaining={timeRemaining} moveTimer={removeTimer} />
            )}
          </LoadingButton>
          <LoadingButton
            disableElevation
            loading={isLoading}
            className="btn btn-lg"
            endIcon={<></>}
            loadingPosition="end"
            variant="contained"
            style={{
              textTransform: "none",
              fontSize: "16px",
              minWidth: "150px",
            }}
            onClick={doValidationCode}
          >
            Valider
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ValidationCodeComponent;
