const HOST_RESOURCE = process.env.REACT_APP_HOST_RESOURCE;
const HOST_RESOURCE_IMAGE_QUESTION = process.env.REACT_APP_HOST_RESOURCE_IMAGE_QUESTION;
const FOLDER_BILL = process.env.REACT_APP_FOLDER_BILL;
const BACKEND_HOST_AUTH = process.env.REACT_APP_BACKEND_HOST_AUTH;
const BACKEND_HOST_USER = process.env.REACT_APP_BACKEND_HOST_USER;
const BACKEND_HOST_CHAT = process.env.REACT_APP_BACKEND_HOST_CHAT;
const BACKEND_HOST_SUBSCRIPTION = process.env.REACT_APP_BACKEND_HOST_SUBSCRIPTION;
const USER_LOCALE = process.env.REACT_APP_USER_LOCALE as string
const GOOGlE_OAUTH2_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID;
const BACKEND_HOST_STRUCTURE = process.env.REACT_APP_BACKEND_HOST_STRUCTURE;
const PICTURE = process.env.REACT_APP_PICTURE;
const LIMIT = process.env.REACT_APP_LIMIT;
const CP_NOTIFY_URL = process.env.REACT_APP_CP_NOTIFY_URL as string;
const CP_SITE_ID = process.env.REACT_APP_CP_SITE_ID as string;
const CP_API_KEY = process.env.REACT_APP_CP_API_KEY as string;
const CP_PAYEMENT_URL = process.env.REACT_APP_CP_PAYEMENT_URL as string;
const CP_PAYMENT_VERIFICATION_URL = process.env.REACT_APP_CP_PAYMENT_VERIFICATION_URL as string;

//const LIMIT = process.env.REACT_APP_LIMIT;
//const PICTURE = process.env.REACT_APP_PICTURE;

//const CP_NOTIFY_URL = process.env.REACT_APP_CP_NOTIFY_URL as string;
//const CP_SITE_ID = process.env.REACT_APP_CP_SITE_ID as string;
//const CP_API_KEY = process.env.REACT_APP_CP_API_KEY as string;
//const CP_PAYEMENT_URL = process.env.REACT_APP_CP_PAYEMENT_URL as string;
//const CP_PAYMENT_VERIFICATION_URL = process.env.REACT_APP_CP_PAYMENT_VERIFICATION_URL;

const KEYS = {
	HOST_RESOURCE,
	HOST_RESOURCE_IMAGE_QUESTION,
	FOLDER_BILL,
	BACKEND_HOST_AUTH,
	BACKEND_HOST_USER,
	BACKEND_HOST_CHAT,
	BACKEND_HOST_SUBSCRIPTION,
	USER_LOCALE,
	GOOGlE_OAUTH2_CLIENT_ID,
	BACKEND_HOST_STRUCTURE,
	LIMIT,
	CP_NOTIFY_URL,
	CP_SITE_ID,
	CP_API_KEY,
	CP_PAYEMENT_URL,
	CP_PAYMENT_VERIFICATION_URL,
	PICTURE,
};

export default KEYS;
