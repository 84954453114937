import {
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useEffect, useState } from "react";
import "./css/Connexion.scss";
import AuthService from "../services/auth-service";
import { EnumStatusCode } from "../enums/auth.enums";
import { useIntl } from "react-intl";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import { selectCurrentMode } from "../../../store/slices/modeSlice";
import "./css/apropos.scss";
import { Box, CssBaseline, Drawer, Toolbar } from "@mui/material";

import { useSelector } from "react-redux";
import AppBarDrawer from "../../conversation/components/AppBarDrawer";
import _Drawer from "../../conversation/components/_Drawer";
import { DeleteAccountInputDto } from "../interfaces/auth.interface";
import { Email } from "@mui/icons-material";
import { useSnackbar } from "notistack";

const drawerWidth = 240;

function DemandDeleteAccount(props: any) {
  const intl = useIntl();
  const { formatMessage } = intl;
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const mode = useSelector(selectCurrentMode);
  const theme = props.theme;
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [checkedValues, setCheckedValues] = useState<string[]>([]);
  const [reason, setReason] = useState("");
  const [email, setEmail] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  useEffect(() => {
    const emailRegExp: RegExp =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isEmail: boolean = emailRegExp.test(email);
    setDisabledBtn(!(checkedValues.length > 0) || !isEmail);
  }, [checkedValues, email]);

  const doSend = async () => {
    setIsLoading(true);
    const deleteAccountInputDto: DeleteAccountInputDto = {
      checkedValues,
      reason: reason,
      email,
    };
    AuthService.demandDeleteAccount(deleteAccountInputDto)
      .then(() => {
        setIsLoading(false);
        enqueueSnackbar(
          formatMessage({ id: "delete_request_send_successfully" }),
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
        /*       navigate("/auth/validation-code", {
        state: { infoPhone: infoPhone },
      }); */
      })
      .catch((error) => {
        console.log("error====", error.code);
        setIsLoading(false);

        console.log("Error: ", error);

        switch (error?.response?.data?.statusCode) {
          case EnumStatusCode.USER_NOT_FOUND:
            enqueueSnackbar(formatMessage({ id: "user_not_found" }), {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });
            break;

          case EnumStatusCode.UNKNOWN_ERROR:
            enqueueSnackbar(formatMessage({ id: "an_error_has_occurred" }), {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });
            break;

          case EnumStatusCode.ERR_BAD_RESPONSE:
            enqueueSnackbar(formatMessage({ id: "an_error_has_occurred" }), {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });
            break;
          default:
            enqueueSnackbar(formatMessage({ id: "an_error_has_occurred" }), {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });
        }
      });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    if (checked) {
      // Si la case est cochée, ajoutez sa valeur à l'état des cases cochées
      setCheckedValues([...checkedValues, value]);
    } else {
      // Si la case est décochée, retirez sa valeur de l'état des cases cochées
      setCheckedValues(checkedValues.filter((item) => item !== value));
    }
  };

  const handleReasonsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    console.log("event.target.value", event.target.value);
    setReason(event.target.value);
  };

  const uiRoundedTextField = {
    "& .MuiOutlinedInput-root": {
      borderRadius: "15px",
      legend: {
        marginLeft: "30px",
      },
      color:
        mode === "dark"
          ? theme.palette.colortext.dark
          : theme.palette.colortext.light,
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingLeft: "20px !important",
      borderRadius: "50px",
    },
    "& .MuiInputLabel-outlined": {},
    "& .MuiInputLabel-shrink": {
      marginLeft: "25px",
      paddingRight: 0,
      background: "white",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor:
        mode === "dark"
          ? theme.palette.outlinedinput.dark
          : theme.palette.outlinedinput.light,
    },
  };
  const handleTextInputEmailChange = (event: any) => {
    console.log("event.target.value", event.target.value);
    setEmail(event.target.value);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center", // Centrer verticalement le contenu
          justifyContent: "center", // Centrer horizontalement le contenu
          minHeight: "100vh", // Assure que la boîte prend toute la hauteur de la page
        }}
        style={{
          color:
            mode === "dark"
              ? theme.palette.colortext.dark
              : theme.palette.colortext.light,
          backgroundColor:
            mode === "dark"
              ? theme.palette.leftbar.dark
              : theme.palette.leftbar.light,
        }}
      >
        <CssBaseline />
        <AppBarDrawer
          theme={props.theme}
          onHandleDrawerToggle={handleDrawerToggle}
        />
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <_Drawer
              theme={props.theme}
              onHandleDrawerToggle={handleDrawerToggle}
            />
          </Drawer>
        </Box>
        <Box component="main" sx={{ pl: 3, pr: 3 }}>
          <Toolbar id="back-to-top-anchor" />
          <div className="container wrap-apropos">
            <div>
              <h2>{formatMessage({ id: "select_informations_to_delete" })}</h2>
            </div>
          </div>
          <Stack spacing={2}>
            <FormGroup>
              
              <FormControlLabel
                control={
                  <Checkbox value="username" onChange={handleCheckboxChange} />
                }
                label={
                  <div>
                    <Typography variant="body1" sx={{ pt: 3 }}>
                      Username
                    </Typography>
                    <Typography variant="caption">
                      {formatMessage({ id: "the_username_is_used_to" })}
                    </Typography>
                  </div>
                }
              />

              <FormControlLabel
                control={
                  <Checkbox value="phone" onChange={handleCheckboxChange} />
                }
                label={
                  <div>
                    <Typography variant="body1" sx={{ pt: 3 }}>
                      Phone
                    </Typography>
                    <Typography variant="caption">
                      {formatMessage({
                        id: "the_phone_number_allows_you_to_connect",
                      })}
                    </Typography>
                  </div>
                }
              />

              <FormControlLabel
                control={
                  <Checkbox value="email" onChange={handleCheckboxChange} />
                }
                label={
                  <div>
                    <Typography variant="body1" sx={{ pt: 3 }}>
                      Email
                    </Typography>
                    <Typography variant="caption">
                      {formatMessage({id:"the_email_address_allows_you_to"})}
                    </Typography>
                  </div>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox value="name" onChange={handleCheckboxChange} />
                }
                label="name"
              />

              <FormControlLabel
                control={
                  <Checkbox value="sex" onChange={handleCheckboxChange} />
                }
                label={
                  <div>
                    <Typography variant="body1" sx={{ pt: 3 }}>
                      Sex
                    </Typography>
                    <Typography variant="caption">
                      {formatMessage({id:"the_sex_allows_you_to"})}
                    </Typography>
                  </div>
                }
              />
              <div style={{ paddingTop: 20 }}>
                <TextField
                  fullWidth
                  label="Confirmation email"
                  id="email"
                  onChange={handleTextInputEmailChange}
                  sx={uiRoundedTextField}
                  size="small"
                  InputLabelProps={{
                    style: {
                      fontFamily: "Nunito",
                      backgroundColor:
                        mode === "dark"
                          ? theme.palette.leftbar.dark
                          : theme.palette.leftbar.light,
                      color:
                        mode === "dark"
                          ? theme.palette.labelinput.dark
                          : theme.palette.labelinput.light,
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email
                          sx={{
                            width: 24,
                            color:
                              mode === "dark"
                                ? theme.palette.colortext.dark
                                : theme.palette.colortext.light,
                          }}
                        />
                      </InputAdornment>
                    ),
                    style: {
                      fontFamily: "Nunito",
                    },
                  }}
                />
              </div>
            </FormGroup>

            <TextField
              sx={{ width: "100%" }}
              InputProps={{
                style: {
                  borderRadius: "20px",
                },
              }}
              onChange={handleReasonsChange}
              fullWidth
              multiline
              rows={6}
              label="Reasons"
            />

            <LoadingButton
              disabled={disabledBtn}
              disableElevation
              loading={isLoading}
              className="btn btn-lg"
              endIcon={<></>}
              loadingPosition="end"
              variant="contained"
              style={{ textTransform: "none", fontSize: "16px" }}
              sx={{
                "&.Mui-disabled": {
                  backgroundColor: "#e6e6eb",
                  color: "black",
                },
              }}
              onClick={doSend}
            >
              {formatMessage({ id: "send" })}
            </LoadingButton>
          </Stack>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default DemandDeleteAccount;
